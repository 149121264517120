import React from 'react';
import { TextField, InputLabel, InputAdornment, IconButton, Select, MenuItem } from '@mui/material';
import { ValidationMessage } from '../errorMessage/validationErrorMessage';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const TextFieldComponent = ({ label, isError, isTouched, errMsg, classes = '', inputClasses = '', isRequired = true, ...props }) => {
  return (
    <div className={classes}>
      <InputLabel className="text-[#4A5568] font-normal text-[16px]" style={{padding:'10px 0px 5px'}}>
        {label} {isRequired && <span className='text-md text-[#ab0505]'>*</span>}
      </InputLabel>
      <TextField
        className={`m-0  ${inputClasses} ${isError && isTouched ? 'is-' : ''} input-control`}
        {...props}
        sx={{
          bgcolor: "#FFFFFF",
          border: 0,
          "& input": {
            // border: "1px solid #B8B8B8 !important",
            border: isError && isTouched ? "1px solid #FF4E43 !important" :"1px solid #B8B8B8 !important",
            borderRadius: "5px",
            padding:'0 20px',
            boxSizing:'border-box',
            height:'44px',
            fontSize:'14px'
          },
          // "& fieldset": { border: "none" },
          ":hover": {
            "& input": { bgcolor: "rgba(217, 217, 217, 0.3)" },
          },
          ":active": {
            "& input": {
              bgcolor: "#ffffff",
            },
          },
        }}
      />
      <ValidationMessage
        isError={isError}
        isTouch={isTouched}
        message={errMsg}
      />
    </div>
  )
}

export default TextFieldComponent

export const PasswordInputField = ({
  showPassword,
  handleClickShowPassword,
  label,
  isError,
  isTouched,
  errMsg,
  ...props
}) => {
  return (
    <div>
      <InputLabel className="text-[#4A5568] font-normal text-[16px]" style={{padding:'10px 0px 5px'}}>
        {label}
      </InputLabel>
      <TextField
        className={`m-0 ${isError && isTouched ? 'is-error' : ''}`}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                              sx={{
                                color: "#B8B8B8",
                              }}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          bgcolor: "#FFFFFF",
          border: 0,
          "& input" : {
            padding:'0 20px',
            boxSizing:'border-box',
            height:'44px',
            fontSize:'14px'
          },
          "&fieldset": {
            border: "1px solid #B8B8B8 !important",
            // borderRadius: "5px",
          },
          ":hover": {
            bgcolor: "rgba(217, 217, 217, 0.3)",
            "&fieldset": {
              borderColor: "#B8B8B8 !important",
            },
          },
          ":active": {
            bgcolor: "#ffffff",
            "&fieldset": {
              borderColor: "#4A5568 !important",
            },
          }
        }}
      />
      <ValidationMessage
        isError={isError}
        isTouch={isTouched}
        message={errMsg}
      />
    </div>)
}

export const SelectFieldComponent = ({ label, isError, isTouched, errMsg, classes = '', menulist, isRequired = true, ...props }) => {
  return (
    <div className={classes}>
      <InputLabel className="text-[#4A5568] font-normal text-[16px]">
        {label} {isRequired && <span className='text-md text-[#ab0505]'>*</span>}
      </InputLabel>
      <Select
        placeholder='Select'
        className={`m-0 ${isError && isTouched ? 'is-error' : ''}`}
        {...props}
        sx={{
          bgcolor: "#FFFFFF",
          border: 0,
          "&input": {
            border: "1px solid #B8B8B8",
            borderRadius: "5px",
          },
          "&fieldset": { border: "none" },
          ":hover": {
            bgcolor: "rgba(217, 217, 217, 0.3)",
          },
          ":active": {
            bgcolor: "#ffffff",
            "&input": {
              borderColor: "#4A5568 !important",
            },
          },
        }}
      >
        {
          menulist.map((item, index) => <MenuItem key={`${item.title}_${index}`} value={item.value}>{item.title}</MenuItem>)
        }
      </Select>
      <ValidationMessage
        isError={isError}
        isTouch={isTouched}
        message={errMsg}
      />
    </div>
  )
}
