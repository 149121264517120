import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import moment from 'moment';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Toolbar, Typography, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
// import { useUserHook } from '../useUserHook';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from '../../routes/appRoutes';
import Button from "@mui/material/Button";
import FilterModal from './FilterModal';
import OutlinedInput from '@mui/material/OutlinedInput';
import { setSaveFilter } from '../../stores/actionTypes/equipmentActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import CSVExportBtn from "../../components/CSVExportBtn/Index"
import FlashOnIcon from '@mui/icons-material/FlashOn';
import BoltIcon from '@mui/icons-material/Bolt';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation, useNavigate, Link } from "react-router-dom";

import current from "../../assets/current.svg";
import frequency from "../../assets/frequency.svg";
import powerFactor from "../../assets/powerFactor.svg";
import tHD from "../../assets/tHD.svg";
import voltage from "../../assets/voltage.svg";
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { decryptData, encryptData, getBarColor } from '../../constants/Helper';
import TextFieldComponent from '../../components/formFields/common/textFieldComponent';
import SliderSvg from "../../assets/Slider.svg"
import { generateNodeName, checkEquipmetIsActive } from '../../constants/Helper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from "../../assets/magnifyingglass2.png"
import { useEquipmentHook } from './useEquipmentsHook';
import MapMarkerIcon from "../../assets/MapMarker.svg"
import { data } from 'autoprefixer';
import { useHook as useDashboardHook } from '../dashboard/useHook';
import ToastAlert from '../../constants/ToastAlert';
import { SLUG_FREQUENCY_BARCHART, SLUG_POWER_FACTOR_BARCHART, SLUG_THD_BARCHART, SLUG_VOLTAGE_BARCHART } from 'constants/variables';
import RemoveEquipmentModal from './remove';
import { CrossRoadIcon } from 'assets';
import arrowUp from "../../assets/arrowUp.png"
import arrowDown from "../../assets/arrowDown.png"
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import ViewHeadlineOutlinedIcon from '@mui/icons-material/ViewHeadlineOutlined';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Edit from '../../assets/edit.svg'
import Trash from "../../assets/Trash Can.svg"  
import {TimePicker} from '@mui/x-date-pickers'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'


var _ = require("lodash");

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const convertToDecimal = (value) => {
  if (typeof value === 'number' && value !== 'N/A') {
    return value.toFixed(2)
  } else {
    return value;
  }
}


const FolderOpenIcon = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="icon FolderOpenIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 0 0-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z"></path></svg>
  )
}

const Index = () => {
  const { users } = useSelector(state => state.user);
  const { instEnergy, deviceInstParam, nodeList, deviceTHD } = useSelector(state => state.dashboard);
  const { fetchNodeMetaData } = useEquipmentHook()
  const { filters, nodeMetaData } = useSelector(state => state.equipment);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [filterOptionList, setFilterOptionList] = useState([])
  const search = useLocation()?.search;
  const params = new URLSearchParams(search);
  var url = new URL(window.location.href);
  var component = url.searchParams.get("component");
  var start = url.searchParams.get("start");
  var end = url.searchParams.get("end");
  var filter = url.searchParams.get("filter");
  const STREET_LIGHT_SHOW = process.env.REACT_APP_STREET_LIGHT;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const opn = Boolean(anchorEl);
  const [editId, setEditId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showAction, setShowAction] = React.useState('');
  const { fetchNodeList, fetchInstEnergyList } = useDashboardHook();

  // console.log('nodeMetaData:', Object.keys(nodeMetaData))


  const [queryPerms, setQueryPerms] = useState({
    component: "",
    start: "",
    end: ""
  })

  const [queryString, setQueryString] = useState('')

  const [componentList, setComponentList] = useState([
    { title: "Voltage (v)", value: 'voltageValue' },
    { title: "Current (A)", value: 'currentValue' },
    { title: "Total Current", value: 'totalcurrentValue' },
    { title: "THD", value: 'maximumTHD' },
    { title: "Frequency", value: 'frequency' },
    { title: "Power Factor", value: 'totalPowerFactorValue' },
    { title: "Power Factor average", value: 'powerFactorValue' },


  ])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100000);
  const [csvData, setCsvData] = useState([])
  const [rowsvalue, setRowsValue] = useState([])
  // console.log('rowsvalue==============', rowsvalue);
  const [maxTHDList, setMaxTHDList] = useState([])
  const [filterOptions, setFilterOptions] = useState({
    components: {},
    operation: "",
    value: "",
    start: "",
    end: "",
    condition: "and"
  })

  const [treeData, setTreeData] = useState({})
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [searchEq, setSearchEq] = React.useState('');
  const [tempRowsvalue, setTempRowsValue] = useState([])
  const [allNodeList, setAllNodeList] = React.useState([]);
  const [removeEquipmentModal, setRemoveEquipmentModal] = React.useState({
    removeModalOpen: false,
    removeModalId: null,
    equipmentId: null,
  });
  const [treeView, setTreeView] = useState(false);

  // console.log('treeData', treeData);
  const handleOpenRemoveModal = (id, equipmentId) => {
    setRemoveEquipmentModal(({
      removeModalOpen: true,
      removeModalId: id,
      equipmentId: equipmentId,
    }))
  }

  const handleCloseRemoveModal = () => {
    setRemoveEquipmentModal(({
      removeModalOpen: false,
      removeModalId: null,
      equipmentId: null,
    }))
  }


  const initialTableHeading = [
    {
      id: "name",
      sortId: "node_name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      show: true
    },
    {
      id: "consumptionValue",
      sortId: "calculatedConsumptionValue",
      numeric: true,
      disablePadding: false,
      label: "Consumption",
      show: true
    },
    {
      id: "voltageValue",
      numeric: true,
      disablePadding: false,
      label: "Voltage",
      show: true
    },
    {
      id: "currentValue",
      numeric: false,
      disablePadding: false,
      label: "Current",
      show: true
    },
    {
      id: "lastUpdatedTime",
      sortId: "timestamp",
      numeric: false,
      disablePadding: false,
      label: "Last Updated",
      show: true
    },

  ]

  const [tableHeading, setTableHeading] = React.useState(initialTableHeading);

  let storageNodeList = localStorage.getItem('nodeList')

  useEffect(() => {
    (async () => {


      if (storageNodeList) {
        setAllNodeList(JSON.parse(storageNodeList))

      } else {
        await fetchNodeList({ "offset": 0, "order": "asc" })
      }

      if (storageNodeList && JSON.parse(storageNodeList).length > 0 && Object.values(instEnergy).length === 0) {
        await fetchInstEnergyList({ "nodes": JSON.parse(storageNodeList) });
      }

    })()
  }, [storageNodeList])

  useEffect(() => {
    (async () => {
      if (nodeMetaData !== null && Object.keys(nodeMetaData).length == 0 || allNodeList.length && nodeMetaData == null) {

        await fetchNodeMetaData({ nodes: allNodeList })
      }

    })()

  }, [allNodeList, nodeMetaData])

  useEffect(() => {
    // console.log('deviceInstParam:', deviceInstParam)
    if (deviceInstParam.length > 0) {
      let temp = []
      let tempAll = []


      deviceInstParam.map((val, k) => {

        if (temp.find(e => e.node === val.node)) {
          tempAll.push(val)
          let oj = temp.find(e => e.node === val.node);
          if (oj.value < val.value) {
            let ind = temp.findIndex(e => e.node === val.node);
            temp[ind] = val;
          }
        } else {
          temp.push(val);
        }
      })
      // console.log('temp:', temp)
      setMaxTHDList(temp);

    }
  }, [deviceInstParam])

  const addFilterBtn = () => {

    if (filterOptions.components !== '' && filterOptions.operation !== '' && (filterOptions.operation == 'between' ? filterOptions.start !== '' && filterOptions.end !== '' : filterOptions.value !== '')) {

      let filterList = [...filterOptionList]
      filterList = [...filterList, filterOptions]

      setFilterOptionList(filterList)

      setFilterOptions({
        ...filterOptions,
        operation: "",
        value: "",
        start: "",
        end: "",
        condition: "and"
      })

    } else {

      // alert('All fields are required')
      ToastAlert({ msg: "All fields are required!", msgType: "warn" })

    }
  }

  const headers = [
    { label: "Name", key: "node_name" },
    { label: "Active", key: "active" },
    { label: "Consumption", key: "consumption" },
    { label: "Voltage", key: "liveVoltage" },
    { label: "Current", key: "totalCurrent" },
    { label: "Frequency", key: "frequency" },
    { label: "Description", key: "description" },
    { label: "Remarks", key: "remarks" },
    { label: "", key: "" },
    { label: "Total Current", key: "totalCurrent" },
    { label: "Current P1", key: "totalCurrent_phase1" },
    { label: "Current P2", key: "totalCurrent_phase2" },
    { label: "Current P3", key: "totalCurrent_phase3" },
    { label: "", key: "" },
    { label: "Avarage Voltage", key: "avarageVoltage" },
    { label: "Voltage P1", key: "avarageVoltage_phase1" },
    { label: "Voltage P2", key: "avarageVoltage_phase2" },
    { label: "Voltage P3", key: "avarageVoltage_phase3" },
    { label: "", key: "" },
    { label: "Avarage Power Factor", key: "avaragePowerFactor" },
    { label: "Power Factor P1", key: "avaragePowerFactor_phase1" },
    { label: "Power Factor P2", key: "avaragePowerFactor_phase2" },
    { label: "Power Factor P3", key: "avaragePowerFactor_phase3" },
    { label: "", key: "" },
    { label: "Maximum THD", key: "maximumTHD" },
    { label: "THD P1", key: "maximumTHD_phase1" },
    { label: "THD P2", key: "maximumTHD_phase2" },
    { label: "THD P3", key: "maximumTHD_phase3" },
  ];

  Array.prototype.diff = function (a) {
    return this.filter(function (i) { return a.indexOf(i) < 0; });
  };

  const setReletiveTime = (time) => {

    switch (true) {
      case time == 'an hour ago':
        return '1 hour ago';
      case time == 'a day ago':
        return '1 day ago';
      case time == 'a year ago':
        return '1 year ago';
      case time.includes('years ago') && time !== "1 year ago":
        return '-';
      default:
        return time;
    }
  }

  const arrMap = (data) => {
    if (data?.children?.length > 0) {

      data?.children?.map((d) => {
        d['name'] = d?.name === d?.nodeId ? d?.equipment_id : d?.name;
        arrMap(d)
      })

    } else {
      delete data?.children
    }

    return data

  }

  const genrateTreeStructure = (array) => {

    let nested_array = [...array].filter(el => el.node_path !== 'N/A' && el.node_path !== '' && el.node_path !== undefined);
    nested_array.sort((a, b) => {
      if (a.node_path < b.node_path)
        return -1;
      if (a.node_path > b.node_path)
        return 1;
      return 0;
    })
    const tree = {
      name: 'root',
      url: '',
      children: [],
    }

    for (const e of nested_array) {
      let node = tree
      if (e.node_path && e.node_path !== 'N/A') {
        const nodenames = e.node_path?.split('/')
        while (nodenames?.length > 0) {
          const nodename = nodenames.shift()
          if (!node?.children?.map(e => e.nodeName)?.includes(nodename)) {

            node?.children?.push({
              ...e,
              name: nodename,
              equipment_id: e?.equipment_id,
              url: [node.url, nodename]?.join('/'),
              status: e.active,
              lat: e?.lat,
              lng: e?.lng,
              nodeId: e.name,
              nodeName: nodename,
              isNode: e?.isNode,
              children: [],
              nickname: Math.random().toString(36).substring(2, 7),
            })
          }
          node = node?.children?.filter(e => e?.nodeName === nodename)[0]
        }
      }
    }
    return arrMap(JSON.parse(JSON.stringify(tree, null, 2)))
  }

  useEffect(() => {
    let tempCsvDataArray = []
    let temp = instEnergy ? Object.entries(instEnergy) : [];
    let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];

    let tempIdArray = temp.map((d) => {
      return d[0]
    })

    let d_nodes = allNodeList?.diff(tempIdArray)

    if (temp.length > 0 || allNodeMetaData.length > 0) {

      temp.map((v, i) => {
        let calculatedConsumptionValue = Number(Number(v[1].w1) + Number(v[1].w2) + Number(v[1].w3)).toFixed(2)
        let consumptionValue = calculatedConsumptionValue > 1000 ? Number(Number(calculatedConsumptionValue) / 1000) : Number(calculatedConsumptionValue)
        let consumptionParams = calculatedConsumptionValue > 1000 ? " kW" : " W"
        let voltageValue = (Number(v[1].v2) == 0 && Number(v[1].v3) == 0) ? Number(Number(v[1].v1)).toFixed(2) : Number(Number(Number(v[1].v1) + Number(v[1].v2) + Number(v[1].v3)) / Number(3)).toFixed(2)
        let currentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3)).toFixed(2)
        let totalcurrentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3) / 3).toFixed(2)
        let powerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3)) / Number(3)).toFixed(2)
        let totalPowerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3))).toFixed(2)



        let csvObj = {}
        csvObj.calculatedConsumptionValue = Number(calculatedConsumptionValue)

        let total_rated_power = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.total_rated_power : null

        let total_rated_power_value = total_rated_power > 1000 ? Number(Number(total_rated_power) / 1000) : Number(total_rated_power)
        let total_rated_power_params = total_rated_power > 1000 ? " kW" : " W"

        csvObj.consumptionValue = total_rated_power ? `${consumptionValue.toFixed(2)} ${consumptionParams} / ${total_rated_power_value.toFixed(2)} ${total_rated_power_params}` : `${consumptionValue.toFixed(2)} ${consumptionParams}`

        // csvObj.consumptionValue = Number(consumptionValue)
        csvObj.consumptionParams = consumptionParams
        csvObj.voltageValue = Number(voltageValue)
        csvObj.currentValue = Number(currentValue)
        csvObj.totalcurrentValue = Number(totalcurrentValue)
        csvObj.powerFactorValue = powerFactorValue
        csvObj.totalPowerFactorValue = totalPowerFactorValue

        var relateiveTime = moment(v[1].timestamp * 1000).startOf("minute").fromNow();
        var relateiveTimeArr = relateiveTime.split(' ');
        var lastUpdatedTime = 'N/A';
        if (relateiveTimeArr.includes('days') && typeof relateiveTimeArr[0] == 'number' && relateiveTimeArr[0] > 3) {
          lastUpdatedTime = moment(v[1].timestamp * 1000).format('DD/MM/YYYY hh:mm:ss A');
        } else {
          lastUpdatedTime = setReletiveTime(relateiveTime);
        }
        csvObj.lastUpdatedTime = lastUpdatedTime
        csvObj.timestamp = v[1].timestamp
        csvObj.name = v[0]
        csvObj.active = checkEquipmetIsActive(v[1].timestamp * 1000) ? true : false


        csvObj.consumption = `${consumptionValue} ${consumptionParams}`
        csvObj.liveVoltage = voltageValue + " V "
        csvObj.ratedPower = ""
        csvObj.description = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_desc : 'N/A'


        csvObj.totalCurrent = currentValue + " A "
        csvObj.totalCurrent_phase1 = (v[1].i1)
        csvObj.totalCurrent_phase2 = (v[1].i2)
        csvObj.totalCurrent_phase3 = (v[1].i3)


        csvObj.avarageVoltage = voltageValue + "V"
        csvObj.avarageVoltage_phase1 = Number(v[1].v1) + ' V '
        csvObj.avarageVoltage_phase2 = Number(v[1].v2) + ' V '
        csvObj.avarageVoltage_phase3 = Number(v[1].v3) + ' V '

        csvObj.avarageVoltage = voltageValue + "V"
        csvObj.avarageVoltage_phase1_ = Number(v[1].v1)
        csvObj.avarageVoltage_phase2_ = Number(v[1].v2)
        csvObj.avarageVoltage_phase3_ = Number(v[1].v3)


        csvObj.avaragePowerFactor = powerFactorValue
        csvObj.avaragePowerFactor_phase1 = Number(v[1].pf1)
        csvObj.avaragePowerFactor_phase2 = Number(v[1].pf2)
        csvObj.avaragePowerFactor_phase3 = Number(v[1].pf3)

        let maximumTHD = maxTHDList?.find((e) => e?.node === v[0])?.value || 0;
        csvObj.maximumTHD = Number(maximumTHD)
        csvObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[0]?.filter((e) => e.node === v[0])[0]?.value : '0'
        csvObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[1]?.filter((e) => e.node === v[0])[0]?.value : '0'
        csvObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[2]?.filter((e) => e.node === v[0])[0]?.value : '0'

        csvObj.frequency = v[1].frequency
        csvObj.remarks = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.Remark : 'N/A'

        const nodeName = (allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 && allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name : generateNodeName(v[0])
        csvObj.node_name = nodeName
        csvObj.equipment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : ''
        csvObj.equipment_type = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_type : ''
        csvObj.node_path = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
          allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_path : ''
        tempCsvDataArray.push(csvObj);
      })

      if (d_nodes.length > 0) {
        d_nodes.map((n) => {

          let tempObj = {}
          tempObj.consumptionValue = 'N/A'
          tempObj.consumptionParams = 'N/A'
          tempObj.voltageValue = 'N/A'
          tempObj.currentValue = 'N/A'
          tempObj.totalcurrentValue = 'N/A'
          tempObj.powerFactorValue = 'N/A'
          tempObj.totalPowerFactorValue = 'N/A'
          tempObj.lastUpdatedTime = 'N/A'

          tempObj.name = n
          tempObj.active = false
          tempObj.consumption = 'N/A'
          tempObj.liveVoltage = 'N/A'
          tempObj.ratedPower = 'N/A'
          tempObj.description = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
            allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_desc : 'N/A'

          tempObj.equipment_type = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
            allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_type : ''

          tempObj.node_path = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
            allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_path : ''

          tempObj.totalCurrent = 'N/A'
          tempObj.totalCurrent_phase1 = 'N/A'
          tempObj.totalCurrent_phase2 = 'N/A'
          tempObj.totalCurrent_phase3 = 'N/A'


          tempObj.avarageVoltage = 'N/A'
          tempObj.avarageVoltage_phase1 = 'N/A'
          tempObj.avarageVoltage_phase2 = 'N/A'
          tempObj.avarageVoltage_phase3 = 'N/A'

          tempObj.avarageVoltage = 'N/A'
          tempObj.avarageVoltage_phase1_ = 'N/A'
          tempObj.avarageVoltage_phase2_ = 'N/A'
          tempObj.avarageVoltage_phase3_ = 'N/A'


          tempObj.avaragePowerFactor = 'N/A'
          tempObj.avaragePowerFactor_phase1 = 'N/A'
          tempObj.avaragePowerFactor_phase2 = 'N/A'
          tempObj.avaragePowerFactor_phase3 = 'N/A'

          let maximumTHD = maxTHDList?.find((e) => e?.node === n)?.value || 0;
          tempObj.maximumTHD = Number(maximumTHD)
          tempObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[0]?.filter((e) => e.node === n)[0]?.value : '0'
          tempObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[1]?.filter((e) => e.node === n)[0]?.value : '0'
          tempObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[2]?.filter((e) => e.node === n)[0]?.value : '0'

          tempObj.frequency = 'N/A'
          tempObj.remarks = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
            allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.Remark : 'N/A'

          const nodeName = (allNodeMetaData.find((e) => e[0] === n)?.length > 0 && allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name : generateNodeName(n)
          tempObj.node_name = nodeName
          tempObj.equipment_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
            allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_id : ''
          tempCsvDataArray.push(tempObj);

        })
      }

      const Operations = {
        lessThan: '<',
        greaterThan: '>',
        or: '||',
        and: '&&',
      };

      let filterArray = []
      if (filter && filter !== null && filter !== undefined) {

        let fltr = decryptData(filter);

        if (fltr && fltr !== null && fltr !== undefined) {
          filterArray = JSON.parse(fltr);
        }

      }
      setFilterOptionList(filterArray)
      dispatch(setSaveFilter(filterArray))

      let cond = '';
      if (filterArray.length > 0) {

        filterArray.map((f, i2) => {

          if (f.operation !== 'between') {
            cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' ' + Operations[f.operation] + ' ' + f.value + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ')
          } else {
            cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' >= ' + f.start + ' && ' + f?.components?.value + ' <= ' + f.end + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ');
          }

        })

      }

      if (cond !== '' && cond !== null) {
        let condition = cond;

        let cb = new Function('o', 'return ' + condition.replace(new RegExp(Object.keys(tempCsvDataArray[0]).join('|'), 'g'), 'o.$&'));

        let data = tempCsvDataArray.filter(cb);
        // let csvData = data.map(item => { return { ...item, name: item?.node_name ? item?.node_name : generateNodeName(item.name) } });
        setCsvData(data)
        setRowsValue(data)
        setTempRowsValue(data)

        setTreeData((genrateTreeStructure(data)))

      } else {
        setCsvData(tempCsvDataArray)
        setRowsValue(tempCsvDataArray)
        setTempRowsValue(tempCsvDataArray)
        setTreeData((genrateTreeStructure(tempCsvDataArray)))
      }

    } else {
      setCsvData([])
      setRowsValue([])
      setTreeData({})
    }
  }, [instEnergy, maxTHDList, deviceInstParam, filter, allNodeList, nodeMetaData])

  const [searchTreeTerm, setSearchTreeTerm] = useState('');


  const hasDeep = (list, search) => {
    if (search == null || search == '') return list
    const result = [];
    function traverse(arr) {
      arr.forEach(element => {
        if (element.name.toLowerCase().includes(search.toLowerCase())) {
          result.push(element);
        }
        if (element.children && element.children.length > 0 && Array.isArray(element.children)) {
          traverse(element.children, search);
        }
      });
    }
    traverse(list)
    return result;
  }

  const handleChange = (e) => {

    let name = e.target.name
    let value;

    if (name === 'components') {
      value = componentList.find((e1) => e1?.value == e?.target?.value)
    } else if (name === 'operation') {
      value = e.target.id
    } else {
      value = e.target.value
    }

    setFilterOptions({
      ...filterOptions,
      [name]: value
    })

    // setFilterOptions(
    //   filterOptions.map((item, i1) => {
    //     if (i1 === i) {
    //       return {
    //         ...item,
    //         [name]: value
    //       };
    //     } else {
    //       return item;
    //     }
    //   })
    // );
  };

  const removeFilterOption = (i, type = null) => {
    let myArray = [...filterOptionList]
    myArray.splice(i, 1);
    // delete myArray[i]
    setFilterOptionList(myArray)
    if (type == 'list') {
      dispatch(setSaveFilter(myArray))
      let encodedFilter = encryptData(JSON.stringify(myArray))
      navigate('/equipments?filter=' + encodedFilter)
    }

  }

  const closeModal = () => {
    setShowFilterModal(false)
  }

  const openModal = () => {
    // let t = [...filters]
    // setFilterOptionList(t)
    setFilterOptions({
      ...filterOptions,
      operation: "",
      value: "",
      start: "",
      end: "",
      condition: "and"
    })
    setShowFilterModal(true)

  }

  const saveFilter = () => {
    let encodedFilter = encryptData(JSON.stringify(filterOptionList))
    dispatch(setSaveFilter(filterOptionList))
    setShowFilterModal(false)
    navigate('/equipments?filter=' + encodedFilter)

  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    boxShadow: 'none',
    backgroundColor: "transparent",
  }));

  const ActionMenuLayout = React.memo(({ rowId, equipmentId }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (e) => {
      setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
      setAnchorEl(null);
    }
    
    return (
      <div>  
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleMenuClick(e)}>
          <MoreHorizIcon sx={{ color: '#7F7F7F' }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              border: '1px solid #eff0f6',
              borderRadius: "10px",
              boxShadow: '0 0 10px hsla(0,0%,55%,.1)',
            }
          }}
        >
          <MenuItem key="1" sx={{ color: '#888' }} onClick={() => handleOpenRemoveModal(rowId, equipmentId)}>
            Remove
          </MenuItem>
          <MenuItem key="2" sx={{ color: '#888' }} component={Link} to={`${Routes.editEquipment}/${rowId}`}>
            Edit
          </MenuItem>
        </Menu>
      </div>
    )

  })
  const Row = React.memo((props) => {
    const { row } = props;
    // console.log('row----', row);
const [open, setOpen] = React.useState(false);
const[openModal,setOpenModal]= React.useState(false);
const [checkedSwitch, setCheckedSwitch] = React.useState(true);
const[equipmentName,setEquipmentName]=React.useState('');
const[deleteModal,setDeleteModal]=React.useState({open:false,value:null});

const handleOpen = (value) => {
setOpenModal(true)
setEquipmentName(value)};

const handleClose = () => setOpenModal(false);

const handledeleteClose=()=>setDeleteModal({open:false,value:null});

const handledeleteEquiment=(index)=>{
forms.splice(index ,1);
setDeleteModal({open:false,value:null});
}

const handleApply = () => {
  let validationError = false;

  forms.forEach((data) => {
    if (data.startTimeValue === "" || data.endTimeValue === "") {
      validationError = true;
    }
  });

  if (validationError) {
    // Return the JSX for the error message
    alert("Please enter valid values for start and end times.")
  } else {
    // console.log(forms);
    setOpenModal(false); // Assuming you have a state variable called `setOpenModal` to control a modal
  }
};

const handleSwitchChange = (event) => {
  setCheckedSwitch(event.target.checked);
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '630px',
  bgcolor: 'background.paper',
  borderRadius:"6px",
  boxShadow: 24,
  p: 4,
  textAlign:"center",
  outline:"none",
};
const timePicker={
  width:'145px',
  padding:'5px',
  top:'-10',
}
const modalDeleteStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  borderRadius:"6px",
  boxShadow: 24,
  p: 4,
  textAlign:"center",
  outline:"none",
};
    const marks = [
      {
        value: 0,
        label: '00',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
    ];
    const IOSSlider = styled(Slider)(({ theme }) => ({
      color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
      height: 2,
      padding: '15px 0',
      '& .MuiSlider-valueLabel': {
        fontSize: 8,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      
      '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
          opacity: 1,
          backgroundColor: 'currentColor',
        },
      },
    }));
    
    function valuetext(value) {
      return value;
    }
    const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

    const [forms, setForms] = useState([{Event: 1,checked:true,startTimeValue:"",endTimeValue:null,slider:50}]);

     const addForm = () => {
          // setForms([...forms,{Event:`Event ${forms.length+1}`,checked:true,startTimeValue:"",endTimeValue:"",slider:50}]);
         let events= forms.map((event)=>event.Event)
   setForms([...forms,{Event:events.length>0?events.pop()+1:1,checked:true,startTimeValue:"",endTimeValue:null,slider:50}]);
    };
   
    const handleInputChange = (index, field, value) => {
      const updatedForms = forms.map((form, i) =>
          i === index ? { ...form, [field]: value } : form
    );
   setForms(updatedForms);
  };

  const deleteContent =(index)=>{
    setDeleteModal({open:true,value:index})
  }

  const timeOptions = [];

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const timeValue = `${formattedHours}:${formattedMinutes}`;
      const label = `${formattedHours}:${formattedMinutes}`;

      timeOptions.push(
        <MenuItem key={timeValue} value={timeValue}>
          {label}
        </MenuItem>
      );
    }
  }
 
    return (
      <React.Fragment>

        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={`list-table ${open && `list-table-open`}`}>

          {
            tableHeading.length > 0 &&
            tableHeading.map((heading) => {
              switch (heading.id) {
                case 'name':
                  data = <TableCell component="th" scope="row" align="" width={400}
                    className={`pl-[3%] Mediumlg:pl-[2%] py-[10px]  name-field-cell border-b-0 border-t-[1px] border-[#e0e0e0] border-l-0 border-r-0 border-solid ${open && `border-b-[1px] border-l-[1px]  rounded-tl-[20px] rounded-bl-[20px]`} `}
                    // style={{
                    //   display: 'flex',
                    //   alignItems: 'center',
                    //   justifyContent: 'end',
                    //   position: 'sticky'
                    // }}
                    style={{
                      position: 'sticky',
                      left: 0,
                      background: 'white',
                      zIndex: 800,
                    }}>




                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      // justifyContent: 'end',
                      position: 'sticky'
                    }}> 
                      
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                      {/* </TableCell> */}
                      {row.active ? <FiberManualRecordIcon style={{ color: '#02F5A0' }} /> : <FiberManualRecordIcon style={{ color: '#FF4438' }} />}&nbsp;
                      <Link to={'/equipment/view/' + row.name}  state={data={status:true}} className='name-field eq-name' style={{ textDecoration: "none", color: '#1B53F9' }}>{row.node_name}</Link>
                    </div>
                  </TableCell>;
                  break;
                case 'consumptionValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.consumptionValue !== 'N/A' ? row.consumptionValue : '-'}</TableCell>
                    ;
                  break;
                case 'voltageValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.voltageValue !== 'N/A' ? row.voltageValue + ' V' : '-'}</TableCell>
                    ;
                  break;
                case 'maximumTHD':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`} >{Number(row.maximumTHD).toFixed(2)}</TableCell>
                    ;
                  break;
                case 'frequency':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`} >{Number(row.frequency).toFixed(2)}</TableCell>
                    ;
                  break;
                case 'currentValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.currentValue !== 'N/A' ? row.currentValue + ' A' : '-'}</TableCell>
                    ;
                  break;
                case 'totalcurrentValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.totalcurrentValue !== 'N/A' ? row.totalcurrentValue + ' A' : '-'}</TableCell>
                    ;
                  break;
                case 'totalPowerFactorValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.totalPowerFactorValue !== 'N/A' ? row.totalPowerFactorValue : '-'}</TableCell>
                    ;
                  break;

                case 'powerFactorValue':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.powerFactorValue !== 'N/A' ? row.powerFactorValue : '-'}</TableCell>
                    ;
                  break;
                case 'lastUpdatedTime':
                  data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.lastUpdatedTime !== 'N/A' ? row.lastUpdatedTime : '-'}</TableCell>
                    ;
                  break;

              }
              return data;

            })

          }

          {/* action-cell-icon-active */}         
          <TableCell className={`relative py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] border-r-[1px]  rounded-tr-[20px] rounded-br-[20px]`}`} align="right">
            {/* <div className={`cursor-pointer h-[34px] w-[34px] ml-auto rounded-[5px] flex items-center justify-center ${showAction === row.name && `bg-[#424242] bg-opacity-[0.1]`}`}
              onClick={(e) => handleClick(e, row.name)}>
              <MoreHorizIcon className='opacity-[0.5]' />
            </div> */}
            <div style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
            {STREET_LIGHT_SHOW === 'YES' && row?.equipment_type === 'gateway' && (    <Box width={100}  style={{cursor:"pointer"}} marginRight={5} onClick={()=>handleOpen(row.node_name)}> <IOSSlider
        value={50}
        // getAriaValueText={valuetext}
        marks={marks}
        aria-label="Disabled slider"
      />
      </Box>)}
            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'end' }}>
              {STREET_LIGHT_SHOW === 'YES' && row?.equipment_type === 'gateway' && (
                <IconButton
                  component={Link} to={`${Routes.equipments}/${row.name}`}
                >
                  <img src={CrossRoadIcon} alt="street" />
                </IconButton>
              )}
              <ActionMenuLayout rowId={row.name} equipmentId={row.node_name} />
            </Box>
            {/* <div className='action-main-div' style={showAction === row.name ? {} : { display: "none" }}>
              <ul className='action-main-ul'>
                <li style={{ cursor: "pointer" }}>Remove</li>
                <Link to={`${Routes.editEquipment}/${row.name}`} style={{ textDecoration: "none", cursor: "pointer" }}> <li>Edit</li></Link>
              </ul>
            </div> */}
            </div>
          </TableCell>

        </TableRow>
        <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
        <h3 className='modalEquipmentName'>{equipmentName}</h3>
        <div className='modalEquipmentSwitch'>
        <p>Live Status</p> <Switch
      // checked={checkedSwitch}
      // onChange={handleSwitchChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
    </div>
    {forms.map((form, index) => (
                    <div key={index}className='modalEquipmentslider'>
                    <FormControlLabel
                    label={`Event ${form.Event}`}
                    control={<Checkbox checked={checkedSwitch?form.checked:false}
                    onChange={(e)=> handleInputChange(index, 'checked', e.target.checked)}
                    />}
                  />
                  <FormControl sx={{ m: 1}} style={{width:"115px"}} size="small">
                    <Select
                      value={form.startTimeValue}
                      onChange={(e)=> handleInputChange(index, 'startTimeValue', e.target.value)}
                      MenuProps={MenuProps}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      disabled={checkedSwitch&&form.checked?false:true}
                      sx={{width:'145px',height:"45px",textAlign:"start"}}
                     >
                       <MenuItem value="" disabled>Select</MenuItem>
                       <MenuItem value="On">On</MenuItem>
                       <MenuItem value="Off" >Off</MenuItem>
                       <MenuItem value="intensity" >Intensity</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1}} size="small" style={{width:"115px"}}>
                  
                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                        <TimePicker sx={timePicker}
                        onChange={(newValue) => handleInputChange(index, 'endTimeValue', newValue)}
                        value={form.endTimeValue}/>
                    </LocalizationProvider>

                  </FormControl>
                  <div className='modalslider_img'>
                  <Slider style={{width:"100px"}} aria-label="Volume" value={form.slider} 
                      onChange={(e)=> handleInputChange(index, 'slider', e.target.value)}
                      disabled={checkedSwitch&&form.checked?false:true} 
                      valueLabelDisplay="auto"
                      />
                   <div>
                    <img src={Trash} onClick={()=>deleteContent(index)}/>
                   </div>
                   </div>
             </div>
            ))}
        <button className='addButton' onClick={addForm}>+</button>
        <div  className='EquipmentModalBtns'>
        <button  className='EquipmentModalBtns_Apply'onClick={handleApply}>Apply</button>
         <button  className='EquipmentModalBtns_Close'onClick={handleClose}>Cancel</button>
        </div>
        </Box>
      </Modal>
      <Modal 
       open={deleteModal.open}
       onClose={handledeleteClose}
       >
                <Box sx={modalDeleteStyle}>
<h2 style={{fontSize:"24px",fontWeight:"600"}}>Are you sure you want to remove the device?</h2>
<h3 style={{fontSize:"18px",fontWeight:"400"}}>This action can’t be undone</h3>
<div className="EquipmentModalDelBtns">
 <button  className='EquipmentModalBtns_Apply'onClick={()=>handledeleteEquiment(deleteModal.value)}>Delete</button>
 <button  className='EquipmentModalBtns_Close'onClick={handledeleteClose}>Cancel</button>
 </div>
</Box>
      </Modal>

        <TableRow className={`${!open && `h-0`}`}>
          <TableCell style={{ padding: 0 }} colSpan={12} className="rounded-l-[10px] !border-b-0">
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className='box-1 bg-[#ededed] bg-opacity-[0.2] rounded-b-[15px] py-[20px] px-[25px] Mediumlg:px-[15px] '>
                <Grid container spacing={2} className='grid-1  flex-nowrap'>
                  <Grid item className='grid-2 discription pl-[3%] Mediumlg:w-[18%]'>
                    <Item className='item-1 kkk'>
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"10px !important"}
                        className='Typography'
                      >
                        Description
                      </Typography>
                      <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="rgba(0, 0, 0, 0.7)"
                        className='Typography'
                      >
                        {row.description}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>
                    <Item className='item-1'>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"5px"}
                        className='Typography title-1 leading-[1]'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        <img src={current} alt="current Icon" className='current max-w-[18px]' />&nbsp;Total Current
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color="rgba(0, 0, 0, 1)"
                        className='Typography subtitle-1 '
                      // style={{ display: "flex", justifyContent: "center" }}
                      >
                        {convertToDecimal(row.currentValue)}
                        <Typography
                          fontSize={14}
                          fontWeight={700}
                          color="#8d8d8d"
                          className='Typography-1  text-[10px] leading-[1.8]'
                        >
                          {row.currentValue !== 'N/A' ? 'A' : ''}
                        </Typography>
                      </Typography>

                      <Grid item xs={12} className='grid-1 phase-main-div'>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 1)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 1
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color="rgba(0, 0, 0, 1)"
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                          >
                            {convertToDecimal(row.totalCurrent_phase1)}
                            <Typography
                              fontSize={12}
                              fontWeight={700}
                              color="#8d8d8d"
                              className='Typography-1  text-[10px] leading-[1.8]'
                            >
                              <span>{row.totalCurrent_phase1 !== 'N/A' ? 'A' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 2
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color="rgba(0, 0, 0, 1)"
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                          >
                            {convertToDecimal(row.totalCurrent_phase2)}  <Typography
                              className='Typography-1  text-[10px] leading-[1.8]'
                              fontSize={12}
                              fontWeight={700}
                              color="#8d8d8d"
                            >
                              {/* <span>A</span> */}
                              <span>{row.totalCurrent_phase2 !== 'N/A' ? 'A' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 3
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color="rgba(0, 0, 0, 1)"
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                          >
                            {convertToDecimal(row.totalCurrent_phase3)}
                            <Typography
                              fontSize={12}
                              fontWeight={700}
                              color="#8d8d8d"
                              className='Typography-2'
                            >
                              {/* <span>A</span> */}
                              <span>{row.totalCurrent_phase3 !== 'N/A' ? 'A' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid item xs={2} className='Mediumlg:mx-[5px]'>
                    <Item className='item-1'>
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"5px"}
                        className='Typography title-1'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        <img src={voltage} alt="voltage_Icon" className='max-w-[18px]' />&nbsp;Avarage Voltage
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        // color="rgba(0, 0, 0, 1)"
                        color={getBarColor(SLUG_VOLTAGE_BARCHART, row.voltageValue)}
                        className='Typography subtitle-1'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        {convertToDecimal(row.voltageValue)}
                        <Typography
                          fontSize={14}
                          fontWeight={700}
                          color={getBarColor(SLUG_VOLTAGE_BARCHART, row.voltageValue)}
                          className='Typography-1  text-[10px] leading-[1.8]'
                        >
                          {row.voltageValue !== 'N/A' ? 'V' : ''}
                        </Typography>
                      </Typography>
                      {/* {console.log('voltageValue:', row.voltageValue)} */}
                      <Grid item xs={12} className='grid-1 phase-main-div'>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 1)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 1
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase1_)}
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                          >
                            {convertToDecimal(row.avarageVoltage_phase1_)}
                            <Typography
                              fontSize={12}
                              fontWeight={700}
                              color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase1_)}
                              className='Typography-1'
                            >

                              {/* <span>V</span> */}
                              <span>{row.avarageVoltage_phase1_ !== 'N/A' ? 'V' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 2
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase2_)}
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                          >
                            {convertToDecimal(row.avarageVoltage_phase2_)}
                            <Typography
                              fontSize={12}
                              fontWeight={700}
                              color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase2_)}
                              className='Typography-1  text-[10px] leading-[1.8]'
                            >
                              {/* <span>V</span> */}
                              <span>{row.avarageVoltage_phase2_ !== 'N/A' ? 'V' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 3
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase3_)}
                            className='Typography phase-div'
                          // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                          >
                            {convertToDecimal(row.avarageVoltage_phase3_)}
                            <Typography
                              fontSize={12}
                              fontWeight={700}
                              color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase3_)}
                              className='Typography-1  text-[10px] leading-[1.8]'
                            >
                              <span>{row.avarageVoltage_phase3_ !== 'N/A' ? 'V' : ''}</span>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid item xs={2} className='grid-1 Mediumlg:mx-[5px]'>
                    <Item className='item-1'>
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"5px"}
                        className='Typography  title-1'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        <img src={powerFactor} alt="Power_Factor_Icon" className='max-w-[18px]' />
                        &nbsp;Avarage Power Factor
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        // color="#FE5C52"
                        color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.powerFactorValue)}
                        className='Typography subtitle-1'
                      >
                        {row.powerFactorValue}
                      </Typography>

                      <Grid item xs={12}
                        className='grid-1 phase-main-div'
                      // style={{ display: "flex", marginTop: "20px" }}
                      >
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 1)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 1
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase1)}
                            className='Typography phase-div'
                          >
                            {convertToDecimal(row.avaragePowerFactor_phase1)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 2
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase2)}
                            className='Typography phase-div'
                          >
                            {convertToDecimal(row.avaragePowerFactor_phase2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 3
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase3)}
                            className='Typography phase-div'
                          >
                            {convertToDecimal(row.avaragePowerFactor_phase3)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>


                    <Item className='item-2'>
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"5px"}
                        className='Typography  title-1'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        <img src={tHD} alt="Power_Factor_Icon" className='max-w-[18px]' />
                        &nbsp;Maximum THD
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        // color="#FE5C52"
                        color={Number(row.maximumTHD) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD)}
                        className='Typography subtitle-1'
                      >
                        {row.maximumTHD ? convertToDecimal(row.maximumTHD) : 'N/A'}
                      </Typography>

                      <Grid item xs={12}
                        className='grid-1 phase-main-div'
                      // style={{ display: "flex", marginTop: "20px" }}
                      >
                        <Grid item xs={4}>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 1)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 1
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={Number(row.maximumTHD_phase1) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase1)}
                            className='Typography phase-div'
                          >
                            {Number(row.maximumTHD_phase1) ? convertToDecimal(row.maximumTHD_phase1) : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 2
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            color={Number(row.maximumTHD_phase2) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase2)}
                            className='Typography phase-div'
                          >
                            {Number(row.maximumTHD_phase2) ? convertToDecimal(row.maximumTHD_phase2) : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className='grid-2'>
                          <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="rgba(0, 0, 0, 0.7)"
                            marginBottom={"10px"}
                            className='Typography pahse-text'
                          >
                            Phase 3
                          </Typography>
                          <Typography
                            fontSize={15}
                            fontWeight={400}
                            // color="rgba(0, 0, 0, 0.7)"
                            color={Number(row.maximumTHD_phase3) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase3)}
                            marginBottom={"10px"}
                            className='Typography phase-div'
                          >
                            {Number(row.maximumTHD_phase3) ? convertToDecimal(row.maximumTHD_phase3) : 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                  <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>
                    <Item>

                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"5px"}
                        className='Typography title-1'
                      // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        <img src={frequency} alt="Power_Factor_Icon" className='max-w-[18px]' />&nbsp;Frequency
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        // color="rgba(0, 0, 0, 1)"
                        color={getBarColor(SLUG_FREQUENCY_BARCHART, Number(row.frequency))}
                        className='Typography subtitle-1'
                      // style={{ display: "flex", justifyContent: "center" }}
                      >
                        {convertToDecimal(row.frequency)}
                        <Typography
                          fontSize={14}
                          fontWeight={700}
                          // color="#7A7A7A"
                          color={getBarColor(SLUG_FREQUENCY_BARCHART, Number(row.frequency))}
                          className='Typography-1  text-[10px] leading-[1.8]'
                        >
                          {row.frequency !== 'N/A' ? 'Hz' : ''}
                        </Typography>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={1} className='grid-2 discription Mediumlg:mx-[5px]'>
                    <Item className='item-1 '>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="rgba(0, 0, 0, 0.7)"
                        marginBottom={"10px"}
                        className='Typography'
                      >
                        Remarks
                      </Typography>
                      <Typography
                        fontSize={10}
                        fontWeight={300}
                        className='Typography'
                        // style={{ border: "1px solid #000", borderRadius: "15px", padding: "10px", backgroundColor: "white" }}
                        color="rgba(0, 0, 0, 0.7)"
                      >
                        {row.remarks}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment >
    );
  });

  const handleChangeCondition = (e, key) => {
    let lst = [...filterOptionList]
    lst[key].condition = e.target.value
    setFilterOptionList(lst)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {

    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      show: true
    },

    {
      id: "consumptionValue",
      numeric: true,
      disablePadding: false,
      label: "Consumption",
      show: true
    },
    {
      id: "voltageValue",
      numeric: true,
      disablePadding: false,
      label: "Voltage",
      show: true
    },

    {
      id: "maximumTHD",
      numeric: true,
      disablePadding: false,
      label: "THD",
      show: filters.some(e => e.components?.title === 'THD') || component === 'maximumTHD' ? true : false
    },
    {
      id: "frequency",
      numeric: true,
      disablePadding: false,
      label: "Frequency",
      show: filters.some(e => e.components?.title === 'Frequency') || component === 'frequency' ? true : false
    },
    {
      id: "currentValue",
      numeric: false,
      disablePadding: false,
      label: "Current",
      show: true
    },

  ];

  const serachEquipment = (e) => {
    // console.log(rowsvalue.filter(entry => Object.values(entry).some((val)=>console.log(val))))
    // return false
    setSearchEq(e.target.value)
    const query = e.target.value;
    if (e.target.value) {
      // const filtered = rowsvalue.filter(entry => Object.values(entry).some(val => val?.includes(e.target.value)));
      const searchList = tempRowsvalue.filter((item) => {
        return item.node_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;;
      });
      // console.log(rowsvalue)
      // console.log(searchList)
      setRowsValue(searchList)
    } else {
      setRowsValue(tempRowsvalue)
    }

  }

  const serachEquipmentTree = (e) => {
    setSearchTreeTerm(e.target.value)
  }


  useEffect(() => {
    let t = document.getElementsByClassName('eq-table')[0]?.clientWidth
    // console.log(t)
    if (t > window.innerHeight) {
      // alert('jdfh')
    }
  }, [document.getElementsByClassName('eq-table')[0]?.clientWidth]);

  useEffect(() => {
    let tabHead = [...initialTableHeading]

    if (filters && filters.length > 0) {
      filters.map((f) => {
        const res = tabHead.find(x => f.components.value == x.id);
        if (!res) {
          tabHead.push({
            id: f.components.value,
            numeric: false,
            disablePadding: true,
            label: f.components.title,
            show: true
          })
        }

      })
      setTableHeading(tabHead)
    } else {
      setTableHeading(initialTableHeading)

    }

  }, [filters, filter]);


  const onClickMapView = () => {
    if (url?.search) {
      navigate('/map/view' + url.search)
    } else {
      navigate('/map/view')
    }
  }

  const tableRecords = React.useMemo(() => {
    return stableSort(rowsvalue, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, [rowsvalue, order, orderBy, page, rowsPerPage])

  const tableTreeRecords = React.useMemo(() => {
    return hasDeep(treeData.children, searchTreeTerm)
  }, [treeData, searchTreeTerm])

  // console.log('tableRecords', tableRecords);

  //------------------------------------------------------------------NESTED TABLE START
  const NestedRowDetailsLayout = React.memo(({ row, open, isFolder }) => {
    if (isFolder) {
      return (
        <TableRow className={`${!open && `h-0`}`}>
          <TableCell style={{ padding: 0 }} colSpan={12} className="rounded-l-[10px] !border-b-0">
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className='box-1 bg-[#ededed] bg-opacity-[0.2] rounded-b-[15px] py-[20px] px-[25px] Mediumlg:px-[15px] '>
                <Table aria-label="collapsible table" className='eq-table custom-table border-separate'>
                  <TableBody>
                    {row?.children.map((rowEl, index) => (
                      <NestedTableRowLayout key={index} row={rowEl} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow >
      )
    }

    return (
      <TableRow className={`${!open && `h-0`}`}>
        <TableCell style={{ padding: 0 }} colSpan={12} className="rounded-l-[10px] !border-b-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className='box-1 bg-[#ededed] bg-opacity-[0.2] rounded-b-[15px] py-[20px] px-[25px] Mediumlg:px-[15px] '>
              <Grid container spacing={2} className='grid-1  flex-nowrap'>
                <Grid item className='grid-2 discription pl-[3%] Mediumlg:w-[18%]'>
                  <Item className='item-1 kkk'>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"10px !important"}
                      className='Typography'
                    >
                      Description
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontWeight={300}
                      color="rgba(0, 0, 0, 0.7)"
                      className='Typography'
                    >
                      {row.description}
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>
                  <Item className='item-1'>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"5px"}
                      className='Typography title-1 leading-[1]'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <img src={current} alt="current Icon" className='current max-w-[18px]' />&nbsp;Total Current
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      color="rgba(0, 0, 0, 1)"
                      className='Typography subtitle-1 '
                    // style={{ display: "flex", justifyContent: "center" }}
                    >
                      {convertToDecimal(row.currentValue)}
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color="#8d8d8d"
                        className='Typography-1  text-[10px] leading-[1.8]'
                      >
                        {row.currentValue !== 'N/A' ? 'A' : ''}
                      </Typography>
                    </Typography>

                    <Grid item xs={12} className='grid-1 phase-main-div'>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 1)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 1
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color="rgba(0, 0, 0, 1)"
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                        >
                          {convertToDecimal(row.totalCurrent_phase1)}
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#8d8d8d"
                            className='Typography-1  text-[10px] leading-[1.8]'
                          >
                            <span>{row.totalCurrent_phase1 !== 'N/A' ? 'A' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 2
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color="rgba(0, 0, 0, 1)"
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                        >
                          {convertToDecimal(row.totalCurrent_phase2)}  <Typography
                            className='Typography-1  text-[10px] leading-[1.8]'
                            fontSize={12}
                            fontWeight={700}
                            color="#8d8d8d"
                          >
                            {/* <span>A</span> */}
                            <span>{row.totalCurrent_phase2 !== 'N/A' ? 'A' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 3
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color="rgba(0, 0, 0, 1)"
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}

                        >
                          {convertToDecimal(row.totalCurrent_phase3)}
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#8d8d8d"
                            className='Typography-2'
                          >
                            {/* <span>A</span> */}
                            <span>{row.totalCurrent_phase3 !== 'N/A' ? 'A' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={2} className='Mediumlg:mx-[5px]'>
                  <Item className='item-1'>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"5px"}
                      className='Typography title-1'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <img src={voltage} alt="voltage_Icon" className='max-w-[18px]' />&nbsp;Avarage Voltage
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      // color="rgba(0, 0, 0, 1)"
                      color={getBarColor(SLUG_VOLTAGE_BARCHART, row.voltageValue)}
                      className='Typography subtitle-1'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      {convertToDecimal(row.voltageValue)}
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color={getBarColor(SLUG_VOLTAGE_BARCHART, row.voltageValue)}
                        className='Typography-1  text-[10px] leading-[1.8]'
                      >
                        {row.voltageValue !== 'N/A' ? 'V' : ''}
                      </Typography>
                    </Typography>
                    {/* {console.log('voltageValue:', row.voltageValue)} */}
                    <Grid item xs={12} className='grid-1 phase-main-div'>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 1)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 1
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase1_)}
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                        >
                          {convertToDecimal(row.avarageVoltage_phase1_)}
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase1_)}
                            className='Typography-1'
                          >

                            {/* <span>V</span> */}
                            <span>{row.avarageVoltage_phase1_ !== 'N/A' ? 'V' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 2
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase2_)}
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                        >
                          {convertToDecimal(row.avarageVoltage_phase2_)}
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase2_)}
                            className='Typography-1  text-[10px] leading-[1.8]'
                          >
                            {/* <span>V</span> */}
                            <span>{row.avarageVoltage_phase2_ !== 'N/A' ? 'V' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 3
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase3_)}
                          className='Typography phase-div'
                        // style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
                        >
                          {convertToDecimal(row.avarageVoltage_phase3_)}
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            color={getBarColor(SLUG_VOLTAGE_BARCHART, row.avarageVoltage_phase3_)}
                            className='Typography-1  text-[10px] leading-[1.8]'
                          >
                            <span>{row.avarageVoltage_phase3_ !== 'N/A' ? 'V' : ''}</span>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={2} className='grid-1 Mediumlg:mx-[5px]'>
                  <Item className='item-1'>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"5px"}
                      className='Typography  title-1'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <img src={powerFactor} alt="Power_Factor_Icon" className='max-w-[18px]' />
                      &nbsp;Avarage Power Factor
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      // color="#FE5C52"
                      color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.powerFactorValue)}
                      className='Typography subtitle-1'
                    >
                      {row.powerFactorValue}
                    </Typography>

                    <Grid item xs={12}
                      className='grid-1 phase-main-div'
                    // style={{ display: "flex", marginTop: "20px" }}
                    >
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 1)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 1
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase1)}
                          className='Typography phase-div'
                        >
                          {convertToDecimal(row.avaragePowerFactor_phase1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 2
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase2)}
                          className='Typography phase-div'
                        >
                          {convertToDecimal(row.avaragePowerFactor_phase2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 3
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={getBarColor(SLUG_POWER_FACTOR_BARCHART, row.avaragePowerFactor_phase3)}
                          className='Typography phase-div'
                        >
                          {convertToDecimal(row.avaragePowerFactor_phase3)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>


                  <Item className='item-2'>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"5px"}
                      className='Typography  title-1'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <img src={tHD} alt="Power_Factor_Icon" className='max-w-[18px]' />
                      &nbsp;Maximum THD
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      // color="#FE5C52"
                      color={Number(row.maximumTHD) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD)}
                      className='Typography subtitle-1'
                    >
                      {row.maximumTHD ? convertToDecimal(row.maximumTHD) : 'N/A'}
                    </Typography>

                    <Grid item xs={12}
                      className='grid-1 phase-main-div'
                    // style={{ display: "flex", marginTop: "20px" }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 1)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 1
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={Number(row.maximumTHD_phase1) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase1)}
                          className='Typography phase-div'
                        >
                          {Number(row.maximumTHD_phase1) ? convertToDecimal(row.maximumTHD_phase1) : 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 2
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          color={Number(row.maximumTHD_phase2) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase2)}
                          className='Typography phase-div'
                        >
                          {Number(row.maximumTHD_phase2) ? convertToDecimal(row.maximumTHD_phase2) : 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className='grid-2'>
                        <Typography
                          fontSize={10}
                          fontWeight={300}
                          color="rgba(0, 0, 0, 0.7)"
                          marginBottom={"10px"}
                          className='Typography pahse-text'
                        >
                          Phase 3
                        </Typography>
                        <Typography
                          fontSize={15}
                          fontWeight={400}
                          // color="rgba(0, 0, 0, 0.7)"
                          color={Number(row.maximumTHD_phase3) == 0 ? '#8d8d8d' : getBarColor(SLUG_THD_BARCHART, row.maximumTHD_phase3)}
                          marginBottom={"10px"}
                          className='Typography phase-div'
                        >
                          {Number(row.maximumTHD_phase3) ? convertToDecimal(row.maximumTHD_phase3) : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={2} className='grid-2 Mediumlg:mx-[5px]'>
                  <Item>

                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"5px"}
                      className='Typography title-1'
                    // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <img src={frequency} alt="Power_Factor_Icon" className='max-w-[18px]' />&nbsp;Frequency
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      // color="rgba(0, 0, 0, 1)"
                      color={getBarColor(SLUG_FREQUENCY_BARCHART, Number(row.frequency))}
                      className='Typography subtitle-1'
                    // style={{ display: "flex", justifyContent: "center" }}
                    >
                      {convertToDecimal(row.frequency)}
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        // color="#7A7A7A"
                        color={getBarColor(SLUG_FREQUENCY_BARCHART, Number(row.frequency))}
                        className='Typography-1  text-[10px] leading-[1.8]'
                      >
                        {row.frequency !== 'N/A' ? 'Hz' : ''}
                      </Typography>
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={1} className='grid-2 discription Mediumlg:mx-[5px]'>
                  <Item className='item-1 '>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color="rgba(0, 0, 0, 0.7)"
                      marginBottom={"10px"}
                      className='Typography'
                    >
                      Remarks
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontWeight={300}
                      className='Typography'
                      // style={{ border: "1px solid #000", borderRadius: "15px", padding: "10px", backgroundColor: "white" }}
                      color="rgba(0, 0, 0, 0.7)"
                    >
                      {row.remarks}
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  })

  const TreeFolderIcon = React.memo(({ open }) => {
    return (
      !open ? <FolderOutlinedIcon sx={{ width: 20, height: 20 }} /> : <FolderOpenIcon />
    )
  })

  const TreeArrowIcon = React.memo(({ open }) => {
    return (
      !open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
    )
  })

  const NestedTableRowLayout = React.memo((props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const isFolder = row?.children && row?.children?.length > 0;
    const isFolderTree = row?.nodeId === row?.nodeName;

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={`list-table ${open && `list-table-open`}`}>
          <TableCell component="th" scope="row" align="" width={400}
            className={`pl-[1%] Mediumlg:pl-[2%] py-[10px]  name-field-cell border-b-0 border-t-[1px] border-[#e0e0e0] border-l-0 border-r-0 border-solid ${open && `border-b-[1px] border-l-[1px]  rounded-tl-[20px] rounded-bl-[20px]`} `}
            style={{
              position: 'sticky',
              left: 0,
              background: 'white',
              zIndex: 800,
            }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              position: 'sticky'
            }}>
              {isFolder ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpen(!open)
                    }}
                    sx={{ width: 36, height: 36 }}
                  >
                    {isFolderTree ? <TreeArrowIcon open={open} /> : <TreeFolderIcon open={open} />}
                  </IconButton>
                  {isFolderTree && (
                    <FiberManualRecordIcon style={{ color: row.status ? '#02F5A0' : '#FF4438' }} />
                  )}
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpen(!open)
                    }}
                    sx={{ width: 36, height: 36 }}
                  >
                    {<TreeArrowIcon open={open} />}
                  </IconButton>
                  <FiberManualRecordIcon style={{ color: row.status ? '#02F5A0' : '#FF4438' }} />
                </>
              )}&nbsp;
              {(isFolder && !isFolderTree) ? row.name : <Link to={'/equipment/view/' + row.nodeId} className='name-field eq-name' style={{ textDecoration: "none", color: '#1B53F9' }}>{row.name}</Link>}
            </div>
          </TableCell>
          <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row?.consumptionValue !== 'N/A' ? row?.consumptionValue : '-'}</TableCell>
          <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row?.voltageValue !== 'N/A' ? row?.voltageValue + ' V' : '-'}</TableCell>
          <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row?.currentValue !== 'N/A' ? row?.currentValue + ' A' : '-'}</TableCell>
          <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row?.lastUpdatedTime !== 'N/A' ? row?.lastUpdatedTime : '-'}</TableCell>
          <TableCell className={`relative py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] border-r-[1px]  rounded-tr-[20px] rounded-br-[20px]`}`} align="right">
            {!isFolder && (
              <Box component={'div'} sx={{ display: 'flex', justifyContent: 'end' }}>
                {STREET_LIGHT_SHOW === 'YES' && row?.equipment_type === 'gateway' && (
                  <IconButton
                    component={Link} to={`${Routes.equipments}/${row.nodeId}`}
                  >
                    <img src={CrossRoadIcon} alt="street" />
                  </IconButton>
                )}
                <ActionMenuLayout rowId={row.nodeId} equipmentId={row.equipment_id} />
              </Box>
            )}
          </TableCell>
        </TableRow>
        <NestedRowDetailsLayout open={open} row={row} isFolder={isFolder} />
      </React.Fragment >
    );
  });

  const NestedTableLayout = React.memo(({ rows }) => {
    return (
      <>
        {rows.map((row, index) => (
          <NestedTableRowLayout key={index} row={row} />
        ))}
      </>
    )
  })

  const GridTableLayout = React.memo(() => {
    return (
      <>
        {tableRecords?.map((row, i) => (
          <Row key={i} row={row} />
        ))}
      </>
    )
  })
  //----------------------------------------------------------------NESTED TABLE END


  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          bgcolor: "#F5F5F5",
        }}
        className='dashboard-main'
      >
        <Toolbar />
        {/* <div className='py-[32px] px-[32px] bg-white w-full h-full'> */}
        <Box
          bgcolor={"#fff"}
          boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
          borderRadius={5}
          className="h-auto mt-[3%] mb-[2%] mx-[32px] p-[20px] Mediumlg:mx-[20px] Mediumlg:h-auto"
        >

          <Box component="div" >
            <Box className='flex items-center justify-between mb-5 p-2'>
              <div className='search-filter'>
                <div className='search-input'>
                  <img src={SearchIcon} />
                  {treeView ? (
                    <input type='text' placeholder='Search for Device' value={searchTreeTerm} onChange={serachEquipmentTree} />
                  ) : (
                    <input type='text' placeholder='Search for Components, Operators, Parameters, etc.' value={searchEq} onChange={serachEquipment} />
                  )}
                </div>
                {!treeView && (
                  <Button
                    type="submit"
                    variant=""
                    onClick={() => openModal()}
                    style={{
                      height: '38px',
                      border: '1px solid #444444',
                      borderRadius: '14.5px',
                      textTransform: "capitalize"
                    }}
                    className="w-[137px] Mediumlg:w-[137px] Mediumlg:text-[16px]"
                  >

                    <img src={SliderSvg} style={{ marginRight: "10px" }}></img>
                    Filter
                  </Button>
                )}
                <Button
                  type="submit"
                  variant=""
                  onClick={() => onClickMapView()}
                  style={{
                    height: '38px',
                    border: '1px solid #444444',
                    borderRadius: '14.5px',
                    textTransform: "capitalize"
                  }}
                  className="w-[246px] Mediumlg:w-[145px] Mediumlg:text-[16px]"
                >

                  <img src={MapMarkerIcon} style={{ marginRight: "10px" }}></img>
                  Map View
                </Button>
                <Button
                  type="submit"
                  variant=""
                  title={treeView ? 'Toggle to Grid view' : 'Toggle to Tree view'}
                  onClick={() => setTreeView(treeView ? false : true)}
                  style={{
                    height: '38px',
                    border: '1px solid #444444',
                    borderRadius: '14.5px',
                    textTransform: "capitalize"
                  }}
                  className="Mediumlg:w-[145px] Mediumlg:text-[16px]"
                >
                  {treeView ? <ViewHeadlineOutlinedIcon /> : <FormatIndentIncreaseOutlinedIcon />}
                </Button>
              </div>
              <div className='add-export'>
                <Link to={Routes.addEquipment} className='' style={{
                  textDecoration: "none",
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '17px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'right',
                  letterSpacing: '-0.154px',
                  color: '#1B53F9',
                }}>
                  + Add Equipment
                </Link>
                <CSVExportBtn
                  headers={headers}
                  csvData={csvData}
                  label='Export'
                  prifix='_equipments'
                />
              </div>
            </Box>
            {!treeView && (
              <div style={{ display: "flex" }}>
                {
                  filters.length > 0 &&
                  filters.map((val, key) => {
                    return (
                      <div className='fltr-btn'>
                        {
                          val.operation !== 'between' ?
                            <span> {val?.components?.title} <span style={{ color: "black", fontSize: "smaller", fontWeight: "300" }}>{(val.operation == "greaterThan" ? '>' : '<') + val.value}</span></span> :
                            <span> {val?.components?.title} <span style={{ color: "black", fontSize: "smaller", fontWeight: "300" }}>: {val.start} - {val.end}</span></span>
                        }
                        <HighlightOffIcon color="primary" style={{ cursor: "pointer" }} onClick={() => removeFilterOption(key, 'list')} />
                      </div>
                    )

                  })
                }
              </div>
            )}
          </Box>
          <Box>
            <TableContainer sx={{ boxShadow: "unset", maxHeight: 'calc(100vh - 290px)' }} component={Paper} className='h-full'>
              <Table aria-label="collapsible table" className='eq-table custom-table border-separate'>
                <TableHead className='equipment-table-header custom-table-header'>
                  <TableRow className='custom-table-row'>

                    {tableHeading.map((headCell) => {
                      let SORT_ID = headCell?.sortId ? headCell?.sortId : headCell?.id;
                      return (
                        <TableCell
                          key={headCell.id}
                          className={`${headCell.id + '-cell'} first:pl-[7%] Mediumlg:first:pl-[3%]`}
                          // align={'right'}
                          // padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={orderBy === SORT_ID ? order : false}
                          style={headCell.show ? { background: "#f5f5f5", borderBottom: "unset" } : { display: "none" }}
                        >
                          <TableSortLabel
                            active={orderBy === SORT_ID}
                            direction={orderBy === SORT_ID ? order : "asc"}
                            onClick={createSortHandler(headCell?.sortId ? headCell?.sortId : headCell?.id)}
                          >
                            <span style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "#7F7F7F",
                            }}>
                              {headCell.label}</span>
                            {orderBy === SORT_ID ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      )
                    })}
                    <TableCell style={{ background: "#f5f5f5", borderBottom: "unset", cursor: "pointer" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRecords.length > 0 ? (
                    treeView ? <NestedTableLayout rows={tableTreeRecords} /> : (
                      <GridTableLayout />
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} textAlign={'center'} sx={{ textAlign: 'center' }}>No Record Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!treeView && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: 100000 }]}
                colSpan={3}
                count={rowsvalue.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                style={{ display: "block", borderBottom: "unset" }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className='equipment-pagination'
              />
            )}
          </Box>
          {/* </Paper> */}
        </Box>
        {/* </div> */}
      </Box>
      {removeEquipmentModal.removeModalOpen && (
        <RemoveEquipmentModal
          open={removeEquipmentModal.removeModalOpen}
          id={removeEquipmentModal.removeModalId}
          equipmentId={removeEquipmentModal.equipmentId}
          handleClose={handleCloseRemoveModal}
        />
      )}
      <FilterModal
        showFilterModal={showFilterModal}
        closeModal={closeModal}
        filterOptions={filterOptions}
        addFilterBtn={addFilterBtn}
        handleChange={handleChange}
        removeFilterOption={removeFilterOption}
        saveFilter={saveFilter}
        componentList={componentList}
        filterOptionList={filterOptionList}
        handleChangeCondition={handleChangeCondition}
      />
    </>
  );
}

export default Index;