import * as React from 'react';
import Box from '@mui/material/Box';
import { Toolbar, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useUserHook } from '../useUserHook';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/appRoutes';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TablePagination from '@mui/material/TablePagination';
// import AvatartImg from "../../../assets/Customer.svg"
import TextFieldComponent from '../../../components/formFields/common/textFieldComponent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserRemoveModal from "../UserRemoveModal/Index"
import jwt from "jwt-decode";
import { hasPermission, isDateIsOlder, hasEditDelPermission } from '../../../constants/Helper';
import { ROLE } from "../../../constants/Permissions"
import AvatartImg from "../../../assets/User-avatar.png"
import SearchIcon from "../../../assets/magnifyingglass2.png"
import moment from 'moment';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const UserDetail = () => {
  const { users } = useSelector(state => state.user);
  const { token } = useSelector(state => state.auth);
  const { RoleId } = jwt(token);
  const navigate = useNavigate()
  const { fetchUserList, deleteUser, isLoading } = useUserHook();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsvalue, setRowsValue] = React.useState([])
  const [search, setSearch] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [editId, setEditId] = React.useState('');
  const [editName, setEditName] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showAction, setShowAction] = React.useState('');

  useEffect(() => {
    if (!hasPermission(ROLE[RoleId], 'USERS')) {
      navigate('/')
    }
    fetchUserList();
  }, []);

  useEffect(() => {
    setRowsValue(users)
  }, [users]);

  const serachEmp = (e) => {
    setSearch(e.target.value)
    if (e.target.value) {
      const filtered = users.filter(entry => Object.values(entry).some(val => val.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())));
      setRowsValue(filtered)
    } else {
      setRowsValue(users)
    }

  }

  const handleClick = (event, id, name) => {
    if (showAction === id) {
      setEditId('')
      setAnchorEl('');
      setShowAction('')
    } else {

      setEditId(id)
      setAnchorEl(event.currentTarget);
      setShowAction(id)
      setEditName(name)
    }
    // console.log(id)

    // setEditName(name)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headCells = [
    {
      id: "userid",
      numeric: false,
      disablePadding: true,
      label: "Employee Id",
      show: true
    },

    {
      id: "first_name",
      numeric: true,
      disablePadding: false,
      label: "Name",
      show: true
    },
    {
      id: "roleId",
      numeric: true,
      disablePadding: false,
      label: "Role Assigned",
      show: true
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email Address",
      show: true
    },
    {
      id: "mobile",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
      show: true
    },
    {
      id: "last_login",
      numeric: false,
      disablePadding: false,
      label: "Last Login",
      show: true
    },


  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {

    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generateName = (id) => {
    let name = id?.substring(0, 5);
    return name = 'EMP - ' + name
  }


  const ed = (userRole, userId) => {

    if (hasEditDelPermission(ROLE[RoleId], userRole)) {
      navigate('/users/edit/' + userId)
    }

    // if (ROLE[RoleId] === 'SUPER_ADMIN') {
    //   navigate('/users/edit/' + editId)
    // } else {
    //   if (userRole !== 'ADMIN') {
    //     navigate('/users/edit/' + editId)
    //   }

    // }
  }
  const deleteUserFunction = async () => {
    let payload = { uuid: editId }
    await deleteUser(payload, editName)
    setModalOpen(false)
    setAnchorEl(null);
    setPage(0)
  }

  const onClickRemove = (userRole) => {
    if (hasEditDelPermission(ROLE[RoleId], userRole)) {
      setModalOpen(true)
    }
    // if (ROLE[RoleId] === 'SUPER_ADMIN') {
    //   setModalOpen(true)
    // } else {
    //   if (userRole !== 'ADMIN') {
    //     setModalOpen(true)
    //   }

    // }
  }
  // console.log(users)


  const ActionMenuLayout = React.memo(({ roleId, userId }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (e) => {
      setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleMenuClick(e)}>
          <MoreHorizIcon sx={{ color: '#7F7F7F' }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              border: '1px solid #eff0f6',
              borderRadius: "10px",
              boxShadow: '0 0 10px hsla(0,0%,55%,.1)',
            }
          }}
        >
          <MenuItem key="1" sx={{ color: '#888', cursor: hasEditDelPermission(ROLE[RoleId], ROLE[roleId]) ? "pointer" : "not-allowed" }} onClick={() => onClickRemove(ROLE[roleId])}>
            Remove
          </MenuItem>
          <MenuItem key="2" sx={{ color: '#888', cursor: hasEditDelPermission(ROLE[RoleId], ROLE[roleId]) ? "pointer" : "not-allowed" }} onClick={() => ed(ROLE[roleId], userId)}>
            Edit
          </MenuItem>
        </Menu>
      </div>
    )

  })

  return (
    <>
      {/* {console.log(rowsvalue)} */}
      <UserRemoveModal
        title={"Do you wish to remove " + editName + "?"}
        body="This action can’t be undone."
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        deleteUserFunction={deleteUserFunction}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          bgcolor: "#F5F5F5",
        }}
        className='dashboard-main'
      >
        <Toolbar />
        {/* <div className='py-[32px] px-[32px]  w-full h-full' > */}
        <Box
          p="1%"
          mb="1%"
          width="95%"
          bgcolor={"#fff"}
          boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
          borderRadius={5}
          my={3} mx={4}
        >
          <Box component="div" className='flex items-center justify-between mb-5 p-2' >

            {/* <TextFieldComponent
              // label="Start"
              id="search"
              placeholder="Search for User"
              classes="w-1/2  items-start"
              name="start"
              autoFocus={false}
              isRequired={false}
              value={search}
              onChange={(e) => serachEmp(e)}
              // margin="normal"
              fullWidth
            /> */}
            <div className='search-input' style={{ width: "65%" }}>
              <img src={SearchIcon} />
              <input type='text' placeholder='Search for User' value={search} onChange={(e) => serachEmp(e)} />
            </div>

            <Link to={Routes.addUser} className='' style={{
              // fontFamily: 'Inter',
              textDecoration: "none",
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '17px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'right',
              letterSpacing: '-0.154px',
              color: '#1B53F9',
            }}>
              + Add Employee
            </Link>


          </Box>
          {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
          <TableContainer sx={{ maxHeight: 470, boxShadow: "unset" }} component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" className='custom-table'>
              <TableHead className='custom-table-header'>
                <TableRow className='custom-table-row'>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      // align={'right'}
                      // padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      style={headCell.show ? { background: "#f5f5f5", borderBottom: "unset" } : { display: "none" }}
                    // style={{background:"#f5f5f5"}}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}


                      >
                        <span style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#7F7F7F",
                        }}>
                          {headCell.label}</span>
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align=""
                    style={{ background: "#f5f5f5", borderBottom: "unset" }}
                  ></TableCell>
                  {/* <TableCell>First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Mobile</TableCell>
                <TableCell align="right">Role</TableCell> */}
                  {/* <TableCell align="right">Created at</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} textAlign={'center'} sx={{ textAlign: 'center' }}><CircularProgress sx={{ color: 'rgb(27, 83, 249)' }} /></TableCell>
                  </TableRow>
                ) : (
                  rowsvalue.length > 0 ? (
                    (rowsPerPage > 0
                      ? stableSort(rowsvalue, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rowsvalue
                    )?.map((user, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Link to={'/users/view/' + user?.userid} style={{ textDecoration: "none", color: "#1B53F9", fontWeight: "500", fontSize: "14px" }}>
                            {/* {generateName(user.userid)} */}
                            {user.employeeId ? user.employeeId : generateName(user.userid)}
                          </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div style={{ display: 'flex', alignItems: "center" }}>
                            <img style={{ width: "25px", height: "25px", borderRadius: "50px", background: "lightgrey" }} src={user?.photo ? user?.photo : AvatartImg} />&nbsp;
                            <span>{user.first_name}</span>
                          </div>


                        </TableCell>
                        <TableCell align="" style={{ textTransform: "capitalize" }}>{ROLE[user.roleId]?.toLowerCase() || '-'}</TableCell>
                        <TableCell align="">{user.email}</TableCell>
                        <TableCell align="">+91-{user?.mobile}</TableCell>
                        <TableCell align="">{user?.last_login && user?.last_login !== '0001-01-01T00:00:00Z' ?
                          isDateIsOlder(user?.last_login, 10) ?
                            moment(user?.last_login).format('DD/MM/YY hh:mm A') :
                            moment(user?.last_login).fromNow()
                          : '-'}</TableCell>

                        {/* <TableCell align=""><MoreHorizIcon style={{ cursor: "pointer" }} onClick={(e) => handleClick(e, user?.userid, user?.first_name)} /></TableCell>
      <Menu
        id={i}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        key={i}
      >
        <MenuItem onClick={() => setModalOpen(true)}>Remove</MenuItem>
    
        <MenuItem onClick={() => ed()}>Edit</MenuItem>
  
      </Menu> */}

                        {/* action-cell-icon-active */}
                        {/* <TableCell className={showAction === user?.userid ? 'action-cell ' : 'action-cell'} align="right"><MoreHorizIcon onClick={(e) => handleClick(e, user?.userid, user?.first_name)} style={{ cursor: "pointer" }} />
                          <div className='action-main-div' style={showAction === user?.userid ? {} : { display: "none" }}>
                            <ul className='action-main-ul'>
                              <li style={{ cursor: hasEditDelPermission(ROLE[RoleId], ROLE[user.roleId]) ? "pointer" : "not-allowed" }} onClick={() => onClickRemove(ROLE[user.roleId])}>Remove</li>
                              <li style={{ cursor: hasEditDelPermission(ROLE[RoleId], ROLE[user.roleId]) ? "pointer" : "not-allowed" }} onClick={() => ed(ROLE[user.roleId])}>Edit</li>
                            </ul>
                          </div>
                        </TableCell> */}
                        <TableCell className={`relative py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0`} align="right">
                          <ActionMenuLayout roleId={user.roleId} userId={user?.userid} />
                        </TableCell>
                        {/* <TableCell align="right">{user.roleId}</TableCell> */}
                        {/* <TableCell align="right">{user.Created}</TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} textAlign={'center'} sx={{ textAlign: 'center' }}>No Record Found</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Paper> */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={rowsvalue.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            style={{ display: "block", borderBottom: "unset" }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
        {/* </div> */}


      </Box >

    </>
  );
}

export default UserDetail;