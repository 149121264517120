import React from 'react';
import IconButton from "@mui/material/IconButton";

const IconButtonComponent = ({ children, ...props }) => {
  return (
    <IconButton
      sx={{
        color: "#000",
        ":hover": {
          bgcolor: "white",
          color: "rgba(27, 83, 249, 1)",
        },
        ":active": {
          bgcolor: "rgba(3, 237, 165, 0.05)",
          color: "rgba(27, 83, 249, 1)",
        },
      }}
      {...props}
    >
      {children}
    </IconButton>
  )
}

export default IconButtonComponent
