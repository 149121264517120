export const UserListSuccess = 'USER_LIST_SUCCESS';
export const UserAddedSuccess = 'USER_ADD_SUCCESS';
export const ClearUserData = 'CLEAR_USER_DATA';


export function setUserListAction(data) {
  return {
    type: UserListSuccess,
    payload: data
  }
}

export function setUserAddAction(data) {
  return {
    type: UserAddedSuccess,
    payload: data
  }
}

export function setClearUserData() {
  return {
    type: ClearUserData,
  }
}
