import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Reducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import localforage from 'localforage';

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, Reducer);

 const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk))); 


const persistor = persistStore(store);
export { store, persistor };