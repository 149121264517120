import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
    apiResData,
    areaData,
    mainData,
    smallData1,
    smallData2,
} from "../../data";
import ToastMessage from "../../components/toastMessage/toastMessage";
import {
    setCSVProjectListChartData
} from "../../stores/actionTypes/dashboardActions";
// import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { decryptData, encryptData, generateNodeName } from "../../constants/Helper";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper'
import { createChart, ColorType, LineType, TickMarkType, LastPriceAnimationMode, isBusinessDay } from 'lightweight-charts';
import { useHook as useDashboardHook } from "../dashboard/useHook";
import TimeRangeFilterModal from "./timeRangeFilterModal";
import { CleaningServices } from "@mui/icons-material";
import { unixTimeToLocal } from "../../constants/Helper";
import { setSaveVisibleRangeData, setSaveIntervals } from "../../stores/actionTypes/equipmentActions";
import { useChartviewHook } from "./useChartviewHook";
import { TimeRangeChangeEventHandler } from "lightweight-charts";

function formatTime(utcTimestamp) {
    const isDate = new Date(utcTimestamp*1000);
    let showDate = moment(isDate).utc().format("HH:mm");
    if (showDate == '00:00') {
        showDate = moment(isDate).utc().format("DD MMM")
    } else {
        showDate = moment(isDate).utc().format("HH:mm")
    }
    return showDate;
}

const ChartView = () => {
    const { fetchHistoryProjectListById, fetchDeviceHistoricalEnergy } = useDashboardHook();
    const { nodeListParam } = useSelector(state => state.dashboard);
    const dispatch = useDispatch()
    var url = new URL(window.location.href);
    var filter = url.searchParams.get("filter");
    const [filterData, setFilterData] = React.useState();
    const [defaultEquipment, setDefaultEquipment] = React.useState('');
    const [projectListChartData, setProjectListChartData] = React.useState([]);
    const { expandPage } = useSelector(store => store.auth);
    const { nodeMetaData } = useSelector(state => state.equipment);
    const [visibleFromDate, setVisibleFromDate] = React.useState('');
    const [visibleToDate, setVisibleToDate] = React.useState('');
    const { componentList, fetchDeviceHistoricalParamData } = useChartviewHook();
    const { pathname, search } = useLocation();
    const navigate = useNavigate()
    const [graphSymbolName, setGraphSymbolName] = React.useState('');
    const thdParam = ['thdv3', 'thdv2', 'thdv1', 'thdi1', 'thdi2', 'thdi3'];
    const [graphUnitName, setGraphUnitName] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const chartContainerRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();

    const backgroundColor = 'white',
        lineColor = '#2962FF',
        textColor = 'black',
        areaTopColor = '#2962FF',
        areaBottomColor = 'rgba(41, 98, 255, 0.28)'


    let newSeries = null
    var timeScale = null
    var timer = null

    console.log('projectListChartData', projectListChartData);

    useEffect(() => {

        chart.current = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                tickMarkFormatter: (time, tickMarkType) => {
                    return formatTime(time)
                },
            },
            width: chartContainerRef.current.clientWidth,
            height: window.innerHeight - 130,
        });

        chart.current.applyOptions({
            rightPriceScale: {
                visible: true,
                scaleMargins: {
                    top: 0.3, // leave some space for the legend
                    bottom: 0.25,
                },
            },
        });

        const newSeries = chart.current.addAreaSeries({
            lineType: LineType.Curved,
            priceScaleId: 'right',
            lineColor: '#2962FF',
            topColor: '#2962FF',
            bottomColor: 'white',
            // areaTopColor: '#2962FF',
            areaBottomColor: 'white',
            lineWidth: 2,
            crossHairMarkerVisible: false,
        });

        let chartData = projectListChartData.map(el => {
            const date = new Date(el.time * 1000)
            return {
                ...el,
                time: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0) / 1000
            }
        });
        chartData.sort(function (x, y) {
            return x.time - y.time;
        })
        const csvDataList = chartData.map(el => ({ rafc: el.rafc, expVal: el.expVal }))
        dispatch(setCSVProjectListChartData(csvDataList))
        newSeries.setData(chartData);

        var timeScale = null;
        timeScale = chart.current.timeScale();

        const symbolName = 'TOTAL LOAD';
        const legend = document.createElement('div');
        const lagendStyle = pathname == '/chart/full-view' ? { left: "6rem", top: "8rem" } : { left: "18rem", top: "11rem" };
        legend.style = `position: absolute; left: ${lagendStyle.left}; top: ${lagendStyle.top}; z-index: 1; font-size: 20px; font-family: sans-serif; line-height: 18px; font-weight: 300;`;
        chartContainerRef.current.appendChild(legend);

        const firstRow = document.createElement('div');
        firstRow.innerHTML = graphSymbolName;
        firstRow.style.color = 'black';
        legend.appendChild(firstRow);


        // Create and style the tooltip html element
        const toolTip = document.createElement('div');
        toolTip.style = `width: auto; height: 65px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 10px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTip.style.background = 'white';
        toolTip.style.color = 'black';
        toolTip.style.borderColor = '#ccc';
        chartContainerRef.current.appendChild(toolTip);

        chart.current.subscribeCrosshairMove((param) => {

            let priceFormatted = '';
            if (param.time) {
                const data = param.seriesData.get(newSeries);
                const price = data.value !== undefined ? data.value : data.close;
                priceFormatted = price.toFixed(2);
            }
            let unit = graphUnitName
            if (
                graphSymbolName == 'TOTAL LOAD'
                || graphSymbolName == 'Load - Phase 1'
                || graphSymbolName == 'Load - Phase 2'
                || graphSymbolName == 'Load - Phase 3') {
                if (priceFormatted > 1000) {
                    unit = 'W'
                } else {
                    unit = 'Kw'
                }
            }
            firstRow.innerHTML = `${graphSymbolName} <strong>${priceFormatted} ${priceFormatted ? unit : ""}</strong>`;
        });

        timeScale.subscribeVisibleLogicalRangeChange(async (range) => {
            if (timer !== null) {
                return;
            }

            timer = setTimeout(async () => {
                var logicalRange = timeScale.getVisibleLogicalRange();
                if (logicalRange !== null) {
                    var barsInfo = newSeries.barsInLogicalRange(logicalRange);
                    if (barsInfo !== null && barsInfo.barsBefore < 100) {
                        let localDate = new Date(barsInfo?.from * 1000);
                        localDate.setMonth(localDate.getMonth() - 1);
                        localDate.setHours(0, 0, 0, 0);
                        let toDate = new Date(barsInfo?.from * 1000);
                        toDate.setHours(0, -5, 0, 0);

                        let payload = { ...filterData }
                        payload.to = toDate.getTime() / 1000
                        payload.from = localDate.getTime() / 1000;
                        let nextPeriodData = await fetchPlotGraphData(payload, 2)
                        nextPeriodData.sort(function (x, y) {
                            return x.time - y.time;
                        })
                        let updatedData = nextPeriodData.map(el => {
                            const date = new Date(el.time * 1000)
                            return {
                                ...el,
                                time: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0) / 1000
                            }
                        });
                        if (updatedData.length > 0) {
                            chartData = [...updatedData, ...chartData];
                            chartData.sort(function (x, y) {
                                return x.time - y.time;
                            })
                            const csvDataList = chartData.map(el => ({ rafc: el.rafc, expVal: el.expVal }))
                            dispatch(setCSVProjectListChartData(csvDataList))
                            newSeries.setData(chartData);
                        }
                    }
                }
                timer = null;
            }, 200);

        });
        chart.current.timeScale().fitContent();
        return () => [chart.current.remove()]

    }, [projectListChartData]);

    // graph resize effect
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    const fetchPlotGraphData = async (filterObj, type) => {
        try {
            let graphData = []
            let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
            setIsLoading(true)
            if (filterObj.node == 'all') {
                let payload = {
                    paramid: filterObj.paramid,
                    from: filterObj.from,
                    to: filterObj.to,
                    limit: 110000,
                    order: "asc"
                }
                let nodeName = filterObj?.paramid !== null ? generateNodeName(filterObj?.paramid[0]) : ''
                const nodeDetails = await fetchHistoryProjectListById(payload)
                if (Object.keys(nodeDetails).length) {

                    let historyProjectListData = nodeDetails[filterObj?.paramid[0]]

                    let tempDataArray = []
                    if (historyProjectListData.length) {

                        historyProjectListData.sort((a, b) => !Array.isArray(a) && a.timestamp < b.timestamp).map((val, key) => {
                            if (val && !Array.isArray(val) && Object.keys(val).length) {

                                if (!Array.isArray(val)) {

                                    let chartObj = {}
                                    let time = new Date(val?.timestamp * 1000);
                                    time = val.timestamp
                                    let longtime = new Date(val?.timestamp * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                                    chartObj.time = time
                                    chartObj.long_time = longtime
                                    chartObj.rafc = moment.unix(val.timestamp).format("yyyy-MM-DD'T'HH:mm:ss'Z'")
                                    chartObj.node_name = allNodeMetaData.find((e) => e[0] === filterObj.paramid)?.length > 0 ?
                                        allNodeMetaData.find((e) => e[0] === filterObj.paramid)[1]?.metadata?.equiment_id : nodeName
                                    chartObj.node_ = 'all'
                                    chartObj.node_id = filterObj.paramid
                                    chartObj.parameter = 'All'
                                    let expVal = Number(val?.value).toFixed(4)

                                    let v = Number(Math.round(val.value + 'e2') + 'e-2');
                                    if (val.value) {
                                        if (Number(val.value) > 1000) {
                                            v = (Number(val?.value) / 1000)
                                        } else {
                                            v = Number(val?.value)
                                        }
                                        let p = Number(Math.round(v + 'e2') + 'e-2');
                                        chartObj.value = p
                                        expVal = Number(v).toFixed(4)
                                        chartObj.expVal = expVal

                                    } else {
                                        chartObj.value = 0
                                        chartObj.expVal = 0
                                    }
                                    tempDataArray.push(chartObj)

                                    if (type == 1) {
                                        newSeries?.update(chartObj)

                                    }

                                }
                            }
                        })
                    }
                    graphData = tempDataArray
                } else {
                    graphData = []
                }

            } else {
                if (thdParam.includes(filterObj.parameter)) {
                    let paramId = nodeListParam?.find((e) => e?.name == filterObj?.parameter)?.param_id

                    if (paramId) {

                        let payload = {
                            paramid: paramId,
                            nodes: [filterObj.node],
                            from: filterObj.from,
                            to: filterObj.to,
                            limit: 110000,
                            order: "asc"
                        }
                        let nodeName = generateNodeName(filterObj?.node)
                        const thdDetails = await fetchDeviceHistoricalParamData(payload)
                        if (Object.keys(thdDetails).length) {

                            let thdData = thdDetails[filterObj?.node]
                            let tempDataArray = []

                            if (thdData.length) {

                                thdData.sort((a, b) => !Array.isArray(a) && a?.timestamp > b?.timestamp).map((val, key) => {

                                    if (val && !Array.isArray(val) && Object.keys(val).length) {

                                        if (!Array.isArray(val)) {

                                            let chartObj = {}
                                            let time = new Date(val.timestamp * 1000).toLocaleString('en-US', { hour: 'numeric', hour12: true });

                                            let longtime = new Date(val.timestamp * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                                            chartObj.time = val?.timestamp
                                            chartObj.long_time = longtime
                                            let v = 0
                                            chartObj.rafc = moment.unix(val.timestamp).format("yyyy-MM-DD'T'HH:mm:ss'Z'")
                                            chartObj.node_name = allNodeMetaData.find((e) => e[0] === filterObj.node)?.length > 0 ?
                                                allNodeMetaData.find((e) => e[0] === filterObj.node)[1]?.metadata?.equiment_id : nodeName
                                            chartObj.node_ = filterObj.node
                                            chartObj.node_id = filterObj.node
                                            chartObj.parameter = componentList.find((e) => e.value == filterObj.parameter)?.title
                                            chartObj.value = Number(val.value);
                                            chartObj.expVal = Number(val.value).toFixed(4);

                                            tempDataArray.push(chartObj)

                                            if (type == 1) {
                                                newSeries?.update(chartObj)
                                            }
                                        }
                                    }
                                })

                            }

                            const uniq = new Set(tempDataArray.map(e => JSON.stringify(e)));
                            const res = Array.from(uniq).map(e => JSON.parse(e));
                            graphData = res

                        } else {
                            graphData = []
                        }
                    }

                } else {
                    let payload = {
                        nodes: [filterObj.node],
                        from: filterObj.from,
                        to: filterObj.to,
                        limit: 110000,
                        order: "asc"
                    }
                    let nodeName = generateNodeName(filterObj?.node)
                    const nodeDetails = await fetchDeviceHistoricalEnergy(payload);
                    if (Object.keys(nodeDetails).length) {

                        let historyProjectListData = nodeDetails[filterObj?.node]
                        let tempDataArray = []

                        if (historyProjectListData.length) {

                            historyProjectListData.sort((a, b) => !Array.isArray(a) && a?.timestamp < b?.timestamp).map((val, key) => {

                                if (val && !Array.isArray(val) && Object.keys(val).length) {

                                    if (!Array.isArray(val)) {

                                        let chartObj = {}
                                        let longtime = new Date(val.timestamp * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                                        chartObj.time = val?.timestamp
                                        chartObj.long_time = longtime
                                        let v = 0
                                        chartObj.rafc = moment.unix(val.timestamp).format("yyyy-MM-DD'T'HH:mm:ss'Z'")
                                        chartObj.node_name = allNodeMetaData.find((e) => e[0] === filterObj.node)?.length > 0 ?
                                            allNodeMetaData.find((e) => e[0] === filterObj.node)[1]?.metadata?.equiment_id : nodeName
                                        chartObj.node_ = filterObj.node
                                        chartObj.node_id = filterObj.node
                                        chartObj.parameter = filterObj.parameter == 'importkwh' ? 'Load' : componentList.find((e) => e.value == filterObj.parameter)?.title
                                        if (filterObj.parameter == 'importkwh') {

                                            v = Number(val.w1) + Number(val.w2) + Number(val.w3)
                                            v = Number(v).toFixed(2)
                                            chartObj.expVal = Number(v).toFixed(4)

                                        } else {

                                            v = Number(val[filterObj.parameter]).toFixed(2);
                                            chartObj.expVal = Number(val[filterObj.parameter]).toFixed(4)

                                        }

                                        if (v) {
                                            chartObj.value = Number(v);
                                        } else {
                                            chartObj.value = 0
                                        }
                                        tempDataArray.push(chartObj)

                                        if (type == 1) {
                                            newSeries?.update(chartObj)
                                        }
                                    }
                                }

                            })
                        }
                        const uniq = new Set(tempDataArray.map(e => JSON.stringify(e)));
                        const res = Array.from(uniq).map(e => JSON.parse(e));
                        graphData = res

                    } else {
                        graphData = []
                    }
                }

            }
            return graphData;
        } catch (error) {
            return []
        } finally {
            setIsLoading(false)
        }
    }

    // get filter data effect
    useEffect(() => {
        (async () => {

            if (filter) {

                let fltr = decryptData(filter);
                let filterObj = {};
                if (fltr && fltr !== null && fltr !== undefined) {
                    filterObj = JSON.parse(fltr);
                    console.log('filterObj', filterObj)
                    setFilterData(filterObj)
                    if (filterObj.node == 'all' || filterObj.parameter == 'importkwh') {
                        setGraphSymbolName('TOTAL LOAD')
                        setGraphUnitName('kW')

                    } else {
                        setGraphSymbolName(componentList.find((e) => e.value == filterObj?.parameter)?.title)
                        setGraphUnitName(componentList.find((e) => e.value == filterObj?.parameter)?.unit)

                    }
                    setDefaultEquipment(filterObj?.paramid[0])
                    const graphData = await fetchPlotGraphData(filterObj, 1)
                    setProjectListChartData(graphData)
                }
            }

        })()

    }, [filter])

    useEffect(() => {

        if (projectListChartData.length && visibleFromDate && visibleToDate) {
            let visibleRanegData = projectListChartData.filter((e) => e.time >= visibleFromDate & e.time <= visibleToDate)
            dispatch(setSaveVisibleRangeData(visibleRanegData))
        }

    }, [projectListChartData, visibleFromDate, visibleToDate])

    // footer time range event handlder
    const onClickFooterTimeRange = (data) => {

        let fromval = moment(data.from).format('M/D/YYYY H:mm')
        let unixFromDate = (moment(fromval, 'M/D/YYYY H:mm').valueOf() / 1000)

        let toval = moment(data.to).format('M/D/YYYY H:mm')
        let unixToDate = (moment(toval, 'M/D/YYYY H:mm').valueOf() / 1000)

        if (filter) {

            let fltr = decryptData(filter);
            let filterObj = {};
            if (fltr && fltr !== null && fltr !== undefined) {
                filterObj = JSON.parse(fltr);
                filterObj.from = unixFromDate
                filterObj.to = unixToDate

                dispatch(setSaveIntervals({ from: unixFromDate, to: unixToDate }))

                let encodedGraphData = encryptData(JSON.stringify(filterObj))
                navigate(pathname + '?filter=' + encodedGraphData)

            }
        }

    }

    useEffect(() => {
        return () => {
            dispatch(setCSVProjectListChartData([]))
        }
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                overflow: "auto",
                bgcolor: "#F5F5F5",
            }}
            className='dashboard-main'
        >
            <Toolbar />
            <Box
                bgcolor={"#fff"}
                boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
                className="h-auto
                pt-[20px] 
                pb-[20px]
                Mediumlg:h-auto"
            >
                <div
                    id='con'
                    className="chart-div"
                    style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
                    ref={chartContainerRef}
                />
                <div style={{
                    height: "5%", width: "100%",
                    position: "relative", bottom: 0, display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: '1rem'
                }}>
                    <div style={{ float: 'right', marginRight: '20px' }}>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) })}>1D</span>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000) })}>15D</span>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date().setMonth(new Date().getMonth() - 1) })}>1M</span>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date().setMonth(new Date().getMonth() - 3) })}>3M</span>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date().setMonth(new Date().getMonth() - 6) })}>6M</span>
                        <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }} onClick={() => onClickFooterTimeRange({ to: new Date(), from: new Date().setFullYear(new Date().getFullYear() - 1) })}>1Y</span>
                        {/* <span style={{ marginRight: '20px', fontSize: '16px', cursor: "pointer" }}>All</span> */}
                    </div>
                </div>
            </Box>
        </Box>
    )
}

export default ChartView
