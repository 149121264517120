export const SaveFilter = 'SAVE_FILTER';
export const SaveNodeMetaData = 'SAVE_NODE_META_DATA';
export const ClearEquipmentData = 'CLEAR_EQUIPMENT_DATA';
export const SaveIntervals = 'SAVE_INTERVALS';
export const SaveVisibleRangeData = 'SAVE_VISIBLE_RANGE_DATA';
export const CallDashboardFunctions = 'CALL_DASHBOARD_FUNCTIONS';
export const SetConfigProfiles = "SET_CONFIG_PROFILES";






export function setSaveFilter(data) {
    return {
        type: SaveFilter,
        payload: data
    }
}

export function setSaveNodeMetaData(data) {
    return {
        type: SaveNodeMetaData,
        payload: data
    }
}

export function setClearEquipmentData() {
    return {
        type: ClearEquipmentData,
    }
}

export function setSaveIntervals(data) {
    return {
        type: SaveIntervals,
        payload: data
    }
}
export function setSaveVisibleRangeData(data) {
    return {
        type: SaveVisibleRangeData,
        payload: data
    }
}

export function setCallDashboardFunctions(data) {
    return {
        type: CallDashboardFunctions,
        payload: data
    }
}

export function SetConfigProfilesFunctions(data) {
    return {
        type: SetConfigProfiles,
        payload: data
    }
}

