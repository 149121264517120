import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Routes } from "../../routes/appRoutes";
import MainComponent from "../../pages/main";
import { setLoginAction } from "stores/actionTypes/authActions";
import * as AuthServices from "stores/services/authApis";
import jwt from "jwt-decode";
import moment from "moment";

export const ProtectRoute = ({ component: Component, ...props }) => {

  const { token } = useSelector(store => store.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleRefreshToken } = bindActionCreators(AuthServices, dispatch);
  let tokenExpTime = localStorage.getItem('expTime');

  if (new URL(window.location.href).searchParams.get("filter")) {
    localStorage.setItem("url", window.location.href);
  } else {
    localStorage.removeItem('url');
  }
  if (token == null || token == undefined || token == "") {
    localStorage.clear();
    navigate('/login', { state: { action: "logout" } })
  }

  const refreshTokenAPI = async () => {
    await handleRefreshToken()
      .then(response => {
        const { success, error, data } = response;
        const { token } = data;
        if (!success) {
          navigate('/login', { state: { action: "logout" } })
        }
        const { RoleId, exp } = jwt(token);
        const loginData = {
          data: token,
          role: RoleId,
          isKeep: true,
          expTime: exp
        }
        dispatch(setLoginAction(loginData));
      })
      .catch(err => {
        navigate('/login', { state: { action: "logout" } })
      })
  }

  const onInit = async () => {
    let exp = moment(new Date(tokenExpTime * 1000))
    let current = moment(new Date())
    var duration = moment.duration(exp.diff(current));
    var hours = duration.asHours();
    if (hours <= 2) {
      await refreshTokenAPI();
    }
  }

  React.useEffect(() => {
    if (tokenExpTime) {
      onInit();
    }
  }, []);

  return Boolean(token) ? <MainComponent>
    <Component {...props} />
  </MainComponent> : <Navigate to={Routes.login} />;
};

export const IsLoggedInRedirectionRoute = ({ component: Component, ...props }) => {
  const { token } = useSelector(store => store.auth);
  return Boolean(token) ? <MainComponent>
    <Navigate to={Routes.dashboard} />
  </MainComponent> : <Component {...props} />;
}
