export const LoginSuccess = 'LOGIN_SUCCESS';
export const LoginFail = 'LOGIN_FAIL';
export const LogoutSuccess = 'LOGOUT_SUCCESS';
export const LogoutFail = 'LOGOUT_FAIL';
export const ForgotPasswordSuccess = 'FORGOT_PASSWORD_SUCCESS';
export const ForgotPasswordFail = 'FORGOT_PASSWORD_FAIL';
export const ReloadPage = 'RELOAD_PAGE';
export const EXPAND = 'EXPAND';
export const CURRENT_USER_DATa = 'CURRENT_USER_DATa';



export function setLoginAction(data) {
  return {
    type: LoginSuccess,
    payload: data
  }
}

export function setLogoutAction(data = "") {
  return {
    type: LogoutSuccess,
    payload: data
  }
}

export function setReloadAction(data) {
  return {
    type: ReloadPage,
    payload: data
  }
}

export function setCurrentUserData(data) {
  return {
    type: CURRENT_USER_DATa,
    payload: data
  }
}

export function setExpandAction(data) {
  return {
    type: EXPAND,
    payload: data
  }
}