import { GetNodeMetaDataApi, GetDeviceHistoricalParamData, AddEquipmentApi, EditEquipmentApi, RemoveEquipmentApi, GetNodeDetails,GetStreetLightApi } from "../../../routes/apiRoutes";
import { axiosPost } from "../commonApis";

export const handleGetNodeMetaData = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(GetNodeMetaDataApi, body, token);
        return response.data;
    }
}

export const handleGetNodeDetails = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(GetNodeDetails, body, token);
        return response.data;
    }
}

export const handleGetDeviceHistoricalParamData = async (token, payload) => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetDeviceHistoricalParamData, body, token);
    return response.data;
}

export const handleAddEquipment = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(AddEquipmentApi, body, token);
        return response.data;
    }
}

export const handleEditEquipment = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(EditEquipmentApi, body, token);
        return response.data;
    }
}

export const handleRemoveEquipment = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(RemoveEquipmentApi, body, token);
        return response.data;
    }
}
export const handlegetStreetlight = (token, payload) => {
    return async () => {
        const body = JSON.stringify(payload);
        const response = await axiosPost(GetStreetLightApi, body, token);
        return response.data;
    }
}