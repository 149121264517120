import React, { useEffect, useState } from "react";
import {
    InputLabel
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import iIcon from "../../assets/iicon.png"
import LoctionIcon from "../../assets/Location.png"
import { useEquipmentHook } from "./useEquipmentsHook";
import { Routes } from "routes/appRoutes";
import AlertMessage from "constants/AlertMessage";
import EquipmentLayout from "./equipmentLayout";
import { SelectFieldComponent } from "components/formFields/common/textFieldComponent";
import { AutocompleteSingleLayout } from "components";
import { generateNodeName } from "constants/Helper";




const AddEditEquipment = () => {

const location =useLocation();
const values =location.state
    const { token, state, EQUIPMENT_TYPES, handleChangeForm, fetchConfig, onClickAddEquipment, onClickEditEquipment, onClickPhaseConnected, handleGetNodeDetails, setState, setDefaultState } = useEquipmentHook()
    const navigate = useNavigate()
    const { state: routeState } = useLocation();
    const { id } = useParams();
    const { nodeMetaData } = useSelector(state => state.equipment);
    const [profiles, setProfiles] = useState([]);
    const [nodeList, setNodeList] = useState([]);
    const STREET_LIGHT_SHOW = process.env.REACT_APP_STREET_LIGHT;

    const getDevicesConfig = async () => {
        const data = await fetchConfig();
        setProfiles(data.map(el => ({ value: el.profile_id, label: el.serial_number })));
    }

    const getNodeDetails = async () => {
        let ep_id = '';
        try {
            const data = await handleGetNodeDetails(token, {  nodes:[id] });
            ep_id = data?.ep_id || ''
        } catch (error) {
            ep_id = '';
        } finally {
            setState(oldState => ({
                ...oldState,
                ep_id: ep_id
            }))
        }
    }

    useEffect(() => {
        getDevicesConfig();
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
        let nodeItems = allNodeMetaData.filter(el => el[1]?.metadata?.equipment_type === 'gateway').map(el => {
            let label = (el[1]?.metadata && (el[1]?.metadata?.node_name || el[1]?.metadata?.equipment_id)) ? (el[1]?.metadata?.node_name || el[1]?.metadata?.equipment_id) : generateNodeName(el[0])
            return {
                value: el[0],
                label: label
            }
        })
        setNodeList(nodeItems);
        if (id) {
            getNodeDetails();
            const equipmentDataDict = allNodeMetaData.find((e) => e[0] === id)?.length > 0 ?
                allNodeMetaData.find((e) => e[0] === id)[1]?.metadata : null;
            console.log('equipmentDataDict', equipmentDataDict);
            let defaultEquipmentType = EQUIPMENT_TYPES.find(el => el?.value === equipmentDataDict?.equipment_type) || '';
            console.log('defaultEquipmentType', defaultEquipmentType);
            let defaultNodeId = nodeItems.find(el => el?.value === equipmentDataDict?.parent_node) || '';
            setDefaultState();
            setState(oldState => ({
                ...oldState,
                node_id: defaultNodeId || '',
                node_name: equipmentDataDict?.node_name || '',
                node_desc: equipmentDataDict?.node_desc || '',
                equipment_id: equipmentDataDict?.equipment_id || '',
                node_path: equipmentDataDict?.node_path || state.node_path,
                temp_node_path: equipmentDataDict?.node_path || state.temp_node_path,
                isNode: equipmentDataDict?.isNode || state.isNode,
                equipment_type: STREET_LIGHT_SHOW === 'YES' ? defaultEquipmentType : equipmentDataDict?.equipment_type,
                address: equipmentDataDict?.address || '',
                total_rated_power: equipmentDataDict?.total_rated_power || '',
                phases_connected: equipmentDataDict?.phases_connected || [],
                description: equipmentDataDict?.description || '',
                lat: equipmentDataDict?.lat || '',
                lng: equipmentDataDict?.lng || '',
                profile_id: '',
            }))
        } else if (routeState?.node_path) {
            setDefaultState();
            setState(oldState => ({
                ...oldState,
                node_path: routeState?.node_path || state.node_path,
                temp_node_path: routeState?.node_path || state.temp_node_path
            }))
        } else {
            setDefaultState();
        }
    }, [id, routeState])


    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            //   x.innerHTML = "Geolocation is not supported by this browser.";
        }
    }

    function showPosition(position) {
        if (position.coords.accuracy > 50) {
            AlertMessage({ msg: "Location not allowed!" })
            return;
        }
        setState({
            ...state,
            // geo_co_ordinates: position.coords.latitude + ',' + position.coords.longitude,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        })
    }

    function showError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                // x.innerHTML = "User denied the request for Geolocation."
                break;
            case error.POSITION_UNAVAILABLE:
                // x.innerHTML = "Location information is unavailable."
                break;
            case error.TIMEOUT:
                // x.innerHTML = "The request to get user location timed out."
                break;
            case error.UNKNOWN_ERROR:
                // x.innerHTML = "An unknown error occurred."
                break;
        }
    }
    useEffect(()=>{
        values&&setState(oldState=>({...oldState,equipment_type:{value:values.Name,label:'Street Light'},node_id:{value:values.id,label:values.nodeNameTitle}}))
    },[values])
    console.log('state.equipment_type', state.equipment_type);
    return (
        <EquipmentLayout>
            <form className='border-2 p-3' style={{ width: '80%', marginLeft: '5%' }}>
                <Box component="div" className='flex items-center justify-between'>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        {id ? 'Edit Equipment' : 'Add New Equipment'}
                    </Typography>
                    {id && (
                        <Link to={Routes.addEquipment} className='' style={{
                            textDecoration: "none",
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'right',
                            letterSpacing: '-0.154px',
                            color: '#1B53F9',
                        }}>
                            + Add Equipment
                        </Link>
                    )}
                </Box>
                <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748] eq-sp-title' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        Equipment Specifications
                    </Typography>
                </Box>
                <div className="eqipment-form">
                    <div className="eq-form-top">


                        {STREET_LIGHT_SHOW === 'YES' && (
                            <>
                                <div className="input-control-main">
                                    <label htmlFor="equipment_type">Equipment Type</label>
                                    <div className="control">
                                        <AutocompleteSingleLayout
                                            options={EQUIPMENT_TYPES}
                                            value={state.equipment_type || null}
                                            name="equipment_type"
                                            label="Select"
                                            handleChange={handleChangeForm}
                                        />
                                        {/* <input type='text' id="equipment_type" name="equipment_type" value={state.equipment_type} onChange={handleChangeForm} placeholder="Equipment Type"
                                    className={state?.error && state?.error?.equipment_type ? "input-error custom-input-control" : "custom-input-control"}
                                /> */}
                                        {
                                            state?.error && state?.error?.equipment_type &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_type}</span>
                                        }
                                    </div>
                                </div>


                                {state.equipment_type?.value === 'street_light' && (
                                    <div className="input-control-main">
                                        <label htmlFor="node_id">Junction Name</label>
                                        <div className="control">
                                            <AutocompleteSingleLayout
                                                options={nodeList}
                                                value={state.node_id ||null}
                                                name="node_id"
                                                label="Select"
                                                handleChange={handleChangeForm}
                                            />
                                            {
                                                state?.error && state?.error?.node_id &&
                                                <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_id}</span>
                                            }
                                        </div>
                                    </div>
                                )}
                            </>
                        )}


                        <div className="input-control-main">
                            <label htmlFor="equipment_id">Equipment ID</label>
                            <div className="control">
                                <input type='text'
                                    id="equipment_id"
                                    name="equipment_id"
                                    value={state.equipment_id}
                                    placeholder="Equipment ID"
                                    onChange={handleChangeForm}
                                    className={state?.error && state?.error?.equipment_id ? "input-error custom-input-control" : "custom-input-control"}

                                />
                                {
                                    state?.error && state?.error?.equipment_id &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_id}</span>
                                }
                            </div>
                        </div>


                        <div className="input-control-main">
                            <label htmlFor="node_path">Node Path</label>
                            <div className="control">
                                <input type='text'
                                    id="node_path"
                                    value={state.node_path} name="node_path"
                                    placeholder="Node Path"
                                    disabled={true}
                                    onChange={handleChangeForm}
                                    className={state?.error && state?.error?.node_path ? "input-error custom-input-control" : "custom-input-control"} />
                                {
                                    state?.error && state?.error?.node_path &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_path}</span>
                                }
                            </div>
                        </div>



                        <div className="input-control-main">
                            <label htmlFor="node_name">Equipment Name</label>
                            <div className="control">
                                <input type='text' id="node_name" name="node_name" onChange={handleChangeForm} value={state.node_name} placeholder="Equipment Name"
                                    className={state?.error && state?.error?.node_name ? "input-error custom-input-control" : "custom-input-control"}
                                />
                                {
                                    state?.error && state?.error?.node_name &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_name}</span>
                                }
                            </div>
                        </div>

                        {STREET_LIGHT_SHOW != 'YES' && (
                            <div className="input-control-main">
                                <label htmlFor="equipment_type">Equipment Type</label>
                                <div className="control">
                                    <input type='text' id="equipment_type" name="equipment_type" value={state.equipment_type} onChange={handleChangeForm} placeholder="Equipment Type"
                                        className={state?.error && state?.error?.equipment_type ? "input-error custom-input-control" : "custom-input-control"}
                                    />
                                    {
                                        state?.error && state?.error?.equipment_type &&
                                        <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_type}</span>
                                    }
                                </div>
                            </div>
                        )}


                        <div className="input-control-main">
                            <label htmlFor="description">Description</label>
                            <div className="control">
                                <textarea id="node_desc" name="node_desc" onChange={handleChangeForm} value={state.node_desc} placeholder="Description"
                                    className={state?.error && state?.error?.node_desc ? "input-error custom-input-control" : "custom-input-control"}
                                />
                                {
                                    state?.error && state?.error?.node_desc &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_desc}</span>
                                }
                            </div>
                        </div>



                        <div className="input-control-main">
                            <label htmlFor="address">Address</label>
                            <div className="control">
                                <input type='text' id="address" name="address" onChange={handleChangeForm} value={state.address} placeholder="Address"
                                    className={state?.error && state?.error?.address ? "input-error custom-input-control" : "custom-input-control"}
                                />
                                {
                                    state?.error && state?.error?.address &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.address}</span>
                                }
                            </div>
                        </div>


                    </div>
                    <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                            Technical Details
                        </Typography>
                    </Box>
                    <div className="eq-form-bottom">

                        <div className="input-control-main">
                            <label htmlFor="total_rated_power">Total Rated Power</label>
                            <div className="control">
                                <input type='number' id="total_rated_power" onChange={handleChangeForm} name="total_rated_power" value={state.total_rated_power} placeholder="Total Rated Power" className="custom-input-control" />
                                {
                                    state?.error && state?.error?.total_rated_power &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.total_rated_power}</span>
                                }
                            </div>
                        </div>



                        <div className="w-full flex items-center  input-control-main">
                            <InputLabel className="w-[200px] font-normal text-[18px]" style={{ width: "auto" }}>
                                Phases Connected
                            </InputLabel>
                            <FormGroup style={{ display: "block" }} className='phases_conntected checkbox-inp  checkbox_main'>

                                <div className="control checkbox-group">
                                    <div className="checkbox-control">
                                        <input type={'checkbox'} id="phases_conntected_red" name="phases_conntected" value="red" checked={state.phases_connected.includes('red')} onClick={onClickPhaseConnected} />
                                        <label for="vehicle1"> Red</label>
                                    </div>
                                    <div className="checkbox-control">
                                        <input type={'checkbox'} id="phases_conntected_yellow" name="phases_conntected" value="yellow" checked={state.phases_connected.includes('yellow')} onClick={onClickPhaseConnected} />
                                        <label for="vehicle1"> Yellow</label>
                                    </div>
                                    <div className="checkbox-control">
                                        <input type={'checkbox'} id="phases_conntected_blue" name="phases_conntected" value="blue" checked={state.phases_connected.includes('blue')} onClick={onClickPhaseConnected} />
                                        <label for="vehicle1"> Blue</label>
                                    </div>
                                </div>

                            </FormGroup>
                        </div>

                        <div className="input-control-main">
                            <label htmlFor="geo_co_ordinates">Geo Co-ordinates</label>
                            <div className="control">
                                <div className="input-group cordinates-group" style={{ display: 'flex' }}>
                                    <input type='text' id="lat" name="lat" onChange={handleChangeForm} value={state.lat} placeholder="Latitude"
                                        className={state?.error && state?.error?.lat ? "input-error custom-input-control geo_co_ordinates_lat_field" : "custom-input-control geo_co_ordinates_lat_field"}
                                    />
                                    <input type='text' id="lng" name="lng" onChange={handleChangeForm} value={state.lng} placeholder="Longitude"
                                        className={state?.error && state?.error?.lng ? "input-error custom-input-control geo_co_ordinates_lng_field" : "custom-input-control geo_co_ordinates_lng_field"}
                                    />
                                    <img src={LoctionIcon} style={{ cursor: "pointer" }} className="geo_co_ordinates_btn" onClick={() => getLocation()} />

                                    {
                                        state?.error && state?.error?.lng && state?.error?.lat &&
                                        <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.lat ? state?.error?.lat : state?.error?.lng}</span>
                                    }


                                </div>
                            </div>

                        </div>


                    </div>
                    {/* {id && (
                        <> */}
                    <div style={{ marginTop: 50 }}>
                        <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                Device binding
                            </Typography>
                        </Box>
                        <div className="eq-form-bottom">

                            <div className="input-control-main">
                                <label htmlFor="ep_id">Physical Device ID</label>
                                <div className="control">
                                    <input type='text' id="ep_id" onChange={handleChangeForm} name="ep_id" value={state.ep_id} placeholder="Physical Device ID" className="custom-input-control" />
                                    {
                                        state?.error && state?.error?.ep_id &&
                                        <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.ep_id}</span>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div style={{ marginTop: 50 }}>
                        <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                Config Profile
                            </Typography>
                        </Box>
                        <div className="eq-form-bottom">

                            <div className="input-control-main">
                                <label htmlFor="profile_id">Profile ID</label>
                                <div className="control">
                                    <AutocompleteSingleLayout
                                        options={profiles}
                                        value={state.profile_id || null}
                                        name="profile_id"
                                        label="Select"
                                        handleChange={handleChangeForm}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* </>
                    )} */}
                    <div className='w-full flex justify-end text-right gap-3  mt-10 footer-button-group'>
                        <Button
                            type='button'
                            onClick={id ? onClickEditEquipment : onClickAddEquipment}
                            style={{ textTransform: "capitalize" }}
                            className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                        >
                            {id ? 'Update' : 'Add'}
                        </Button>
                        <Button
                            type='button'
                            onClick={() => navigate("/equipments")}
                            style={{ textTransform: "capitalize" }}
                            className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                            Cancel
                        </Button>
                    </div>

                </div>
            </form>
        </EquipmentLayout >
    )
}
export default AddEditEquipment