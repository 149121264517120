import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastAlert(props) {
  const position = "top-right"; // top-left , top-right , top-center , bottom-left , bottom-right , bottom-center
  const theme = "light"; //  light    , dark   ,  colored
  const autoClose = 3000;

  const messageSettings = {
    position: position,
    closeButton: false,
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: theme,
  };

  switch (props.msgType) {
    case "success":
      toast.success(props.msg, messageSettings);
      break;

    case "info":
      toast.info(props.msg, messageSettings);
      break;

    case "warn":
      toast.warn(props.msg, messageSettings);
      break;

    case "error":
      toast.error(props.msg, messageSettings);
      break;

    default:
      toast(props.msg, messageSettings);
      break;
  }
}
export default ToastAlert;
