import { LoginApi, ResetPasswordApi, UserLogoutApi, getUserProfile, RefreshTokenApi } from "../../../routes/apiRoutes.jsx";
import { axiosPost, axiosGet, authAPIInstance } from "../commonApis";

export const handleLogin = (loginData) => {
  return async () => {
    const body = JSON.stringify(loginData);
    const response = await authAPIInstance.post(LoginApi, body);
    return response.data;
  }
}

export const handleSendOTP = async (data) => {
  const body = JSON.stringify(data);
  const response = await authAPIInstance.post(ResetPasswordApi, body);
  return response.data
}

export const handleLogout = async (token = "") => {
  const response = await axiosGet(UserLogoutApi, token);
  return response.data;
}

export const handleGetUserProfile = async (token) => {
  const response = await axiosGet(getUserProfile, token);
  return response.data;
}

export const handleRefreshToken = () => {
  return async () => {
    const response = await axiosPost(RefreshTokenApi);
    return response.data;
  }
}