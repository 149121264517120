import { UserListSuccess, UserAddedSuccess, ClearUserData } from "../../actionTypes/userActions";

const inititalState = {
  users: []
}

export const userReducer = (state = inititalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserListSuccess:
      return {
        ...state,
        users: payload
      }
    case UserAddedSuccess:
      return {
        ...state,
        users: [...state.users, payload]
      }
    case ClearUserData:
      return {
        ...state,
        users: []
      }
    default:
      return state;
  }
}