import * as React from 'react';
import Button from '@mui/material/Button';
import { Tabs, Tab, Box, Toolbar, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SelectFieldComponent } from '../../components/formFields/common/textFieldComponent';
import TextFieldComponent from '../../components/formFields/common/textFieldComponent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Grade } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F2FEFA',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const FilterModal = ({ showFilterModal, closeModal, filterOptions, addFilterBtn, handleChange, removeFilterOption, saveFilter, componentList, filterOptionList, handleChangeCondition }) => {
    return (
        <>
            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={showFilterModal}
                className='filter-modal'
            >
                <DialogTitle id="customized-dialog-title" className='filter-modal-title' onClose={closeModal}>
                    Filter
                </DialogTitle>
                <DialogContent >

                    <div >
                        <Box className='filter-form'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SelectFieldComponent
                                        id="components"
                                        name="components"
                                        label="components"
                                        menulist={componentList}
                                        onChange={(e) => handleChange(e)}
                                        value={filterOptions?.components?.value}
                                        autoFocus={false}
                                        classes="w-full flex items-center mb-4 filter-component"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} className="filterCondition filter-option filter-component" style={{ padding: "0px 0px 0px 16px", width: "100%" }}>
                                    <label style={{ marginTop: "10px", display: 'block', color: "#00000099", paddingBottom: "5px" }}>Operations</label>
                                    <Grid item xs={12} class="center-align filterConditionDiv" style={{ display: "flex" }}>
                                        <div className='filter-option-p'>
                                            <input type="radio" value="lessThan" checked={filterOptions?.operation == 'lessThan' ? true : false} name="operation" id="lessThan" onClick={(e) => handleChange(e)} />
                                            <label for="lessThan">Less Than</label>
                                        </div>
                                        <div className='filter-option-p'>
                                            <input type="radio" value="greaterThan" checked={filterOptions?.operation == 'greaterThan'} name="operation" id="greaterThan" onClick={(e) => handleChange(e)} />
                                            <label for="greaterThan">Greater Than</label>
                                        </div>
                                        <div className='filter-option-p'>
                                            <input type="radio" value="between" checked={filterOptions.operation == 'between'} name="operation" id="between" onClick={(e) => handleChange(e)} />
                                            <label for="between">Between</label>
                                        </div>
                                    </Grid>
                                </Grid>

                                {
                                    filterOptions.operation == 'between' ?


                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                label="Start"
                                                id="start"
                                                placeholder="start"
                                                classes="w-full flex items-center mb-4 filter-start filter-component"
                                                name="start"
                                                autoFocus={false}
                                                value={filterOptions?.start}
                                                onChange={(e) => handleChange(e)}
                                                margin="normal"
                                                fullWidth
                                            />

                                            <TextFieldComponent
                                                label="End"
                                                id="end"
                                                placeholder="end"
                                                classes="w-full flex items-center mb-4 filter-end filter-component"
                                                name="end"
                                                autoFocus={false}
                                                value={filterOptions?.end}
                                                onChange={(e) => handleChange(e)}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        :

                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                label="Value"
                                                id="value"
                                                placeholder="Value"
                                                classes="w-full flex items-center mb-4 filter-val filter-component"
                                                name="value"
                                                autoFocus={false}
                                                value={filterOptions?.value}
                                                onChange={(e) => handleChange(e)}
                                                margin="normal"
                                                isRequired={false}
                                                fullWidth
                                            />
                                        </Grid>
                                }
                            </Grid>
                        </Box>







                        <Grid item xs={12}>
                            <center>
                                <Button autoFocus onClick={addFilterBtn} class="filterConditionBtn" style={{ cursor: "pointer" }}>
                                    +
                                </Button>
                            </center>
                        </Grid>
                        <hr color="#e5e5e5" style={{ marginBottom: "20px" }} />

                        {
                            filterOptionList.length > 0 &&
                            <Grid container spacing={1}>
                                {
                                    filterOptionList.length > 0 &&
                                    filterOptionList.map((val, key) => {
                                        // console.log(val)

                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Item style={{
                                                        borderRadius: '10px',
                                                        boxShadow: 'unset',
                                                        textAlign: 'left',
                                                    }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Typography
                                                                fontSize={14}
                                                                fontWeight={800}
                                                                padding={'8px'}
                                                                color="#1B53F9"
                                                            >
                                                                {val?.components?.title}
                                                            </Typography>
                                                            <HighlightOffIcon color="primary" style={{ cursor: "pointer" }} onClick={() => removeFilterOption(key)} />
                                                        </div>
                                                        <Typography
                                                            fontSize={12}
                                                            fontWeight={500}
                                                            color="rgba(0, 0, 0, 0.7)"
                                                            style={{ paddingLeft: "10px" }}
                                                        >
                                                            {/* {val?.operation} {val?.value} */}
                                                            {val?.operation}{' '}
                                                            {
                                                                val?.operation == 'between'
                                                                    ? val.start + ' And ' + val.end
                                                                    : val?.value

                                                            }
                                                        </Typography>
                                                    </Item>
                                                </Grid>
                                                {
                                                    key !== (filterOptionList.length - 1) &&
                                                    <Grid item xs={2}>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={val.condition}
                                                            onChange={(e) => handleChangeCondition(e, key)}
                                                        // label="Age"
                                                        >
                                                            <MenuItem value={"and"}>AND</MenuItem>
                                                            <MenuItem value={"or"}>OR</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                }
                                            </>
                                        )
                                    })

                                }
                                {/* <Grid item xs={4}>
                                <Item>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={800}
                                            padding={'8px'}
                                            color="#1B53F9"
                                        >
                                            Consumption
                                        </Typography>
                                        <HighlightOffIcon color="primary" />
                                    </div>
                                    <Typography
                                        fontSize={12}
                                        fontWeight={500}
                                        color="rgba(0, 0, 0, 0.7)"
                                    >
                                        Less Than 20 kW
                                    </Typography>
                                </Item>
                            </Grid>

                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={"and"}
                                // onChange={handleChange}
                                // label="Age"
                                >
                                    <MenuItem value={"and"}>AND</MenuItem>
                                    <MenuItem value={"or"}>OR</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={4}>
                                <Item>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={800}
                                            padding={'8px'}
                                            color="#1B53F9"
                                        >
                                            Consumption
                                        </Typography>
                                        <HighlightOffIcon color="primary" />
                                    </div>
                                    <Typography
                                        fontSize={12}
                                        fontWeight={500}
                                        color="rgba(0, 0, 0, 0.7)"
                                    >
                                        Less Than 20 kW
                                    </Typography>
                                </Item>
                            </Grid>

                            <Grid item xs={2}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={"and"}
                                // onChange={handleChange}
                                // label="Age"
                                >
                                    <MenuItem value={"and"}>AND</MenuItem>
                                    <MenuItem value={"or"}>OR</MenuItem>
                                </Select>
                            </Grid> */}

                            </Grid>
                        }





                    </div>

                </DialogContent>
                <DialogActions className='filter-footer' style={{ display: "block" }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6}>
                            <Button autoFocus onClick={saveFilter} class="filterConditionSaveBtn filter-footer-btn" style={{ cursor: "pointer", border: "unset" }}>
                                Apply
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button autoFocus class="filterConditionCancleBtn filter-footer-btn" onClick={closeModal} style={{ cursor: "pointer", border: "unset" }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );

}
export default FilterModal