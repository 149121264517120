import React from 'react'
import { Box, Grid, Paper, CssBaseline, Typography, Icon } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LogoTextIcon } from 'assets';

const Image = React.memo(() => {
    return (
        <Grid
            item
            xs={6}
            sm={6}
            md={6}
            position="relative"
            sx={{
                backgroundImage: `url(./loginSide2.webp)`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                    t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
            className='login-right-banner max-w-[57%] flex-[0_0_57%] Mediumlg:flex-[0_0_50%] Mediumlg:max-w-[50%]'

        >
            <div className="absolute w-full top-0 bottom-0 z-10 background-gradient pl-[100px] Mediumlg:pl-[50px] pt-[140px] Mediumlg:pt-[90px]">
                <Grid
                    className="z-30"
                    container
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    justifyContent="left"
                    width={"80%"}
                >
                    <Typography className="text-[48px] Mediumlg:text-[36px] text-[#ffffff] leading-[1] Mediumlg:leading-[1.2] font-thin">
                        A sustainable future
                    </Typography>
                    <Typography className="text-[64px] text-[#ffffff] font-bold leading-[1.2] Mediumlg:leading-[1.4] Mediumlg:text-[45px]">
                        Starts With You.
                    </Typography>
                </Grid>
            </div>
        </Grid>
    )
})

const LogoComponent = React.memo(() => {
    return (
        <Box className="pl-[3em] pt-[4em] Mediumlg:pl-[2em] Mediumlg:pt-[2.5em] logo-main absolute top-0 left-0">
            <Icon
                sx={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'left'
                }}
                className='logo-parent'
            >
                <img src={LogoTextIcon} alt="Logo with Text" className='logo-img h-[40px]  w-[250px] Mediumlg:h-[25px] Mediumlg:w-[170px]' />
            </Icon>
        </Box>
    )
})

const FormElementComponent = React.memo(({ children }) => {
    return (
        <Grid item xs={12} xl={6} sm={8} md={6} component={Paper} elevation={6} square className='login-left-form max-w-[43%] flex-[0_0_43%] Mediumlg:max-w-[50%] Mediumlg:flex-[0_0_50%] flex items-center pt-[100px] pb-[30px]'>
            <Box className="w-full">
                <LogoComponent />
                <Box className="flex flex-col items-center  px-[15px] w-full max-w-[480px] Mediumlg:max-w-[440px] mx-auto login-form-div">
                    {children}
                </Box>
            </Box>
        </Grid>
    )
})

const theme = createTheme({
    palette: {
        primary: {
            light: "#03EDA5",
            main: "#03EDA5",
            dark: "#002884",
            contrastText: "#000",
        },
        secondary: {
            light: "#03EDA5",
            main: "#03EDA5",
            dark: "#ba000d",
            contrastText: "#000",
        },
    },
});

export const AuthLayout = React.memo(({
    children
}) => {

    return (
        <ThemeProvider theme={theme}>
            <Grid container className="login-main" component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <FormElementComponent>
                    {children}
                </FormElementComponent>
                <Image />
            </Grid>
        </ThemeProvider>
    )
})
