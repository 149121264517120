import React from 'react'
import { Button, Box, Checkbox, Grid, Typography, FormControlLabel } from "@mui/material";
import { useHook } from "./useHook";
import TextFieldComponent, { PasswordInputField } from "../../../components/formFields/textFieldComponent";
import { Routes } from "../../../routes/appRoutes";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useLocation, Link } from "react-router-dom";
import { AuthLayout } from 'components';

const Login = React.memo(() => {

    const { formik, showPassword, handleClickShowPassword } = useHook();
    const location = useLocation()

    React.useEffect(() => {
        if (location?.state?.action === 'logout') {
            localStorage.removeItem('url');
        }
    }, [location])

    return (
        <AuthLayout>
            <Grid
                container
                className="flex flex-col items-left justify-left mb-5"
            >
                <Typography className="text-[16px] text-[#2D3748] wlcm-bck">Welcome back</Typography>
                <Typography component="h1" variant="h5" fontWeight="bold" className="text-[#1A202C] text-[30px] Mediumlg:text-[24px] mb-[20px] Mediumlg:mb-[5px] font-bold">
                    Login to your account
                </Typography>
            </Grid>
            <Box component="form" className="grid gap-y-1 mt-[-20px]" noValidate width="100%" >
                <TextFieldComponent
                    type="text"
                    label="Email / Mobile Number"
                    id="username"
                    placeholder="example@email.com "
                    name="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    autoFocus={false}
                    isError={Boolean(formik.errors.username)}
                    isTouched={Boolean(formik.touched.username)}
                    errMsg={formik.errors.username}
                    margin="normal"
                    fullWidth
                    required
                />
                <PasswordInputField
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    label="Password"
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    isError={Boolean(formik.errors.password)}
                    isTouched={Boolean(formik.touched.password)}
                    errMsg={formik.errors.password}
                    autoFocus={false}
                    margin="normal"
                    fullWidth
                    required
                />

                <Grid className="flex flex-row items-center justify-between mb-2 mt-2">
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={formik.values.isKeepLogin}
                                onChange={(e) => formik.setFieldValue('isKeepLogin', e.target.checked)}
                                sx={{
                                    '& input': {
                                        display: 'none'
                                    },
                                }}
                            />
                        }
                        label="Keep me signed in"

                    />
                    <Link className="no-underline text-[#1B53F9] font-normal" to={Routes.forgotPassword}>
                        Forgot password?
                    </Link>
                </Grid>
                <Button
                    type="submit"
                    onClick={formik.handleSubmit}
                    className="w-full h-[50px] bg-[#03EDA5] rounded-md text-[#040404] text-base font-bold capitalize"
                >
                    Login
                </Button>
                <Grid className="text-center items-center mt-10 Mediumlg:mt-[12px]">
                    <Typography variant="body2" className="text-[14px] text-[#616161]">
                        New to Unitgrid?{" "}
                        <Link to="#" className="no-underline text-[#1B53F9]">
                            Sign-up
                        </Link>
                    </Typography>
                </Grid>
            </Box>
        </AuthLayout >
    );
})

export default Login;




