import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Button, Box, Typography } from '@mui/material';

import React, { useEffect, useState } from "react";
import {
    InputLabel
} from "@mui/material";

import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import iIcon from "../../assets/iicon.png"
import LoctionIcon from "../../assets/Location.png"
import { useEquipmentHook } from "../../pages/equipments/useEquipmentsHook";
import { Routes } from "routes/appRoutes";
import AlertMessage from "constants/AlertMessage";
import EquipmentLayout from "../../pages/equipments/equipmentLayout";
import { SelectFieldComponent } from "components/formFields/common/textFieldComponent";
import { AutocompleteSingleLayout } from "components";
import { generateNodeName } from "constants/Helper";
import { validateAll } from "indicative/validator";
import * as EquipmentApi from "../../stores/services/equipmentApi";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { setSaveNodeMetaData } from "../../stores/actionTypes/equipmentActions";
import ToastAlert from "constants/ToastAlert";

const StreetLightDialog = ({ open, closeModal, saveData, formData, nodeId,nodeTitle }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const values = location.state
    const { token,  EQUIPMENT_TYPES, fetchConfig, onClickAddEquipment, onClickEditEquipment, onClickPhaseConnected, handleGetNodeDetails, setState, setDefaultState } = useEquipmentHook()
    const navigate = useNavigate() 
    const { id } = useParams(); 
    const [profiles, setProfiles] = useState([]);
    const [nodeList, setNodeList] = useState([]);
    const [state, setstateData] = useState({
        equipment_id: '',
        node_path: '',
        temp_node_path: '',
        node_name: '',
        equipment_type:'street_light',
        node_desc: '',
        address: '',
        total_rated_power: '',
        phases_connected: [],
        geo_co_ordinates: '',
        lat: '',
        lng: '',
        isNode: true,
        description: '',
        ep_id: '',
        profile_id: '',
        node_id: '',
        error: {}
    });
    const {  handleEditEquipment, handleAddEquipment} = bindActionCreators(EquipmentApi, dispatch);
    const { phases_connected } = state

    const validationrRules = {
        equipment_id: 'required',
        node_name: 'required',
        equipment_type: 'required',
        node_desc: 'required',
        address: 'required',
        total_rated_power: 'number',
        lat: 'number',
        lng: 'number',
        ep_id: 'regex:^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
    
    }
    const validationMessages = {
        'equipment_id.required': 'Equipment Id is Required.',
        'node_name.required': 'Node Name is Required.',
        'equipment_type.required': 'Equipment Type is Required.',
        'node_desc.required': 'Description is Required.',
        'address.required': 'Address is Required.',
        'lat.number': 'Latitude should be a number.',
        'lng.number': 'Longitude should be a number.',
        'ep_id.regex': 'Physical Device ID should be a UUID.',
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            //   x.innerHTML = "Geolocation is not supported by this browser.";
        }
    }
    function showPosition(position) {
        if (position.coords.accuracy > 50) {
            AlertMessage({ msg: "Location not allowed!" })
            return;
        }
        setState({
            ...state,
            // geo_co_ordinates: position.coords.latitude + ',' + position.coords.longitude,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        })
    }

    function showError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                // x.innerHTML = "User denied the request for Geolocation."
                break;
            case error.POSITION_UNAVAILABLE:
                // x.innerHTML = "Location information is unavailable."
                break;
            case error.TIMEOUT:
                // x.innerHTML = "The request to get user location timed out."
                break;
            case error.UNKNOWN_ERROR:
                // x.innerHTML = "An unknown error occurred."
                break;
        }
    }

    const STREET_LIGHT_SHOW = process.env.REACT_APP_STREET_LIGHT; 

    useEffect(() => { 
        if(formData){
            let newformData = {...formData}; 
            setstateData(newformData)

        }
    }, [formData])
    const onClickPhaseConnected1= (e) => {

        let checked = e.target.checked
        let value = e.target.value
        let array = [...phases_connected]

        if (checked) {
            //Either 3 or 1
            if (array.length === 0) {
                array.push(value)
            } else if (array.length >= 1) {
                array = ['red', 'yellow', 'blue'];
            }
        } else {
            array = array.length >= 3 ? [] : array.filter((e) => e !== value)
        }
        setstateData({
            ...state,
            phases_connected: array
        })

    }

    const createForm =()=>{
        onClickAddEquipment1(state)
    }


    const onClickAddEquipment1 = (payload) => {

        validateAll(payload, validationrRules, validationMessages).then(async () => {
            const formaerrror = {};
            setstateData({
                ...state,
                error: formaerrror
            })

            
            let payloadDatas = {
                node_name: payload.node_name,
                node_desc: payload.node_desc,
                metadata: {
                    node_name: payload.node_name,
                    node_desc: payload.node_desc,
                    equipment_id: payload.equipment_id,
                    node_path: payload.node_path,
                    equipment_type: "street_light",
                    address: payload.address,
                    total_rated_power: payload.total_rated_power,
                    phases_connected: payload.phases_connected,
                    description: payload.description,
                    lat: Number(payload.lat),
                    lng: Number(payload.lng),
                    isNode: true,
                }

            }

            if (STREET_LIGHT_SHOW === 'YES') {
                payloadDatas.metadata['parent_node'] = id
            }

            if (payload.ep_id) {
                payloadDatas['ep_id'] = payload.ep_id
            }
            if (payload.profile_id) {
                payloadDatas['profile_id'] = payload.profile_id
            }
            console.log(payloadDatas,"state from create")
            await handleAddEquipment(token, payloadDatas)
                .then(async response => {
                    const { success, error } = response;
                    if (!success) {
                        AlertMessage({ msg: error })
                        return;
                    } else {
                        // dispatch(setSaveNodeMetaData(null));
                        // localStorage.removeItem("nodeList");
                        ToastAlert({ msg: "Equipment added successfully", msgType: "info" })
                        closeModal()                    }
                })
                .catch(err => {
                    // console.log(err)
                    const error = err?.response;
                    AlertMessage({ msg: error?.data?.description })

                    throw err;
                })


        }).catch(errors => {
            // console.log(errors)
            const formaerrror = {};
            if (errors && errors.length) {
                errors.forEach(element => {
                    formaerrror[element.field] = element.message
                });
            } else {
                AlertMessage({ msg:errors.response.data.error || 'Something went wrong' })
            }

            setstateData({
                ...state,
                error: formaerrror
            })

        });

    }

    const updateForm = () => { 
        onClickEditEquipmentDialog(state)
        // saveData(state)
    }
    const onClickEditEquipmentDialog = async (payload) => {
        try { 
            validateAll(payload, validationrRules, validationMessages).then(async () => {
                const formaerrror = {};
                setstateData({
                    ...state,
                    error: formaerrror
                })

                let payloadData = {
                    node_id: nodeId,
                    metadata: {
                        node_name: payload.node_name,
                        node_desc: payload.node_desc,
                        equipment_id: payload.equipment_id,
                        node_path: payload.node_path,
                        equipment_type: payload.equipment_type?.value || payload.equipment_type,
                        address: payload.address,
                        total_rated_power: payload.total_rated_power,
                        phases_connected: payload.phases_connected,
                        description: payload.description,
                        lat: Number(payload.lat),
                        lng: Number(payload.lng),
                        isNode: payload.isNode
                    }
                }


                if (STREET_LIGHT_SHOW === 'YES') {
                    payloadData.metadata['parent_node'] = id
                }

                if (payload.ep_id) {
                    payloadData['ep_id'] = payload.ep_id
                }
                if (payload.profile_id) {
                    payloadData['profile_id'] = payload.profile_id
                }
 

                await handleEditEquipment(token, payloadData)
                    .then(async response => {
                        const { success, error } = response;
                        if (!success) {
                            AlertMessage({ msg: error })
                            return;
                        } else {
                            // dispatch(setSaveNodeMetaData(null));
                            // localStorage.removeItem("nodeList");
                            ToastAlert({ msg: "Equipment Updated successfully", msgType: "info" });
                            closeModal()
                                                  }
                    })
                    .catch(err => {
                        const error = err?.response;
                        AlertMessage({ msg: error?.data?.description })
                        throw err;
                    })


            }).catch(errors => {
                const formaerrror = {};
                if (errors && errors.length) {
                    errors.forEach(element => {
                        formaerrror[element.field] = element.message
                    });
                } else {
                    AlertMessage({ msg: errors || errors?.message || 'Something went wrong' })
                }
                setstateData({
                    ...state,
                    error: formaerrror
                })

            });

        } catch (error) {
            AlertMessage({ msg: error || error?.message || 'Something went wrong' })
        }
    }

    const handleChangeForm = (e) => {
        let value = e.target.value;
        setstateData({
            ...state,
            [e.target.name]: value
        })
    }

    return (
        <>
            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='filter-modal'
            >
                <DialogTitle id="customized-dialog-title" className='filter-modal-title' onClose={closeModal}>
                    <Box sx={{textAlign:'left',display:'flex',flexDirection:'column',rowGap:'10px'}}>
                        <Typography variant='h5' sx={{fontWeight:'bold'}}>{nodeTitle}</Typography>
                        <Typography variant='h5' sx={{fontWeight:'bold'}}> {nodeId?'Edit Street Light':'Create StreetLight'}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent >
                  {state ?  
                   <form className='border-2 streetDialog' style={{ width: '80%', margin: '0 0 0 -20px '}}>


                        <div className="eqipment-form">
                            <div className="eq-form-top">

                                <div className="input-control-main">
                                    <label htmlFor="equipment_id">Equipment ID</label>
                                    <div className="control">
                                        <input type='text'
                                            id="equipment_id"
                                            name="equipment_id"
                                            value={state.equipment_id}
                                            placeholder="Equipment ID"
                                            onChange={handleChangeForm}
                                            className={state?.error && state?.error?.equipment_id ? "input-error custom-input-control" : "custom-input-control"}

                                        />
                                        {
                                            state?.error && state?.error?.equipment_id &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_id}</span>
                                        }
                                    </div>
                                </div>


                               


                                <div className="input-control-main">
                                    <label htmlFor="node_name">Equipment Name</label>
                                    <div className="control">
                                        <input type='text' id="node_name" name="node_name" onChange={handleChangeForm} value={state.node_name} placeholder="Equipment Name"
                                            className={state?.error && state?.error?.node_name ? "input-error custom-input-control" : "custom-input-control"}
                                        />
                                        {
                                            state?.error && state?.error?.node_name &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_name}</span>
                                        }
                                    </div>
                                </div>

                                {STREET_LIGHT_SHOW != 'YES' && (
                                    <div className="input-control-main">
                                        <label htmlFor="equipment_type">Equipment Type</label>
                                        <div className="control">
                                            <input type='text' id="equipment_type" name="equipment_type" value={state.equipment_type} onChange={handleChangeForm} placeholder="Equipment Type"
                                                className={state?.error && state?.error?.equipment_type ? "input-error custom-input-control" : "custom-input-control"}
                                            />
                                            {
                                                state?.error && state?.error?.equipment_type &&
                                                <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_type}</span>
                                            }
                                        </div>
                                    </div>
                                )}


                                <div className="input-control-main">
                                    <label htmlFor="description">Description</label>
                                    <div className="control">
                                        <textarea id="node_desc" name="node_desc" onChange={handleChangeForm} value={state.node_desc} placeholder="Description"
                                            className={state?.error && state?.error?.node_desc ? "input-error custom-input-control" : "custom-input-control"}
                                        />
                                        {
                                            state?.error && state?.error?.node_desc &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_desc}</span>
                                        }
                                    </div>
                                </div>



                                <div className="input-control-main">
                                    <label htmlFor="address">Address</label>
                                    <div className="control">
                                        <input type='text' id="address" name="address" onChange={handleChangeForm} value={state.address} placeholder="Address"
                                            className={state?.error && state?.error?.address ? "input-error custom-input-control" : "custom-input-control"}
                                        />
                                        {
                                            state?.error && state?.error?.address &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.address}</span>
                                        }
                                    </div>
                                </div>


                            </div>
                            <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                    Technical Details
                                </Typography>
                            </Box>
                            <div className="eq-form-bottom">

                                <div className="input-control-main">
                                    <label htmlFor="total_rated_power">Total Rated Power</label>
                                    <div className="control">
                                        <input type='number' id="total_rated_power" onChange={handleChangeForm} name="total_rated_power" value={state.total_rated_power} placeholder="Total Rated Power" className="custom-input-control" />
                                        {
                                            state?.error && state?.error?.total_rated_power &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.total_rated_power}</span>
                                        }
                                    </div>
                                </div>



                                <div className="w-full flex items-center  input-control-main">
                                    <InputLabel className="w-[200px] font-normal text-[18px]" style={{ width: "auto" }}>
                                        Phases Connected
                                    </InputLabel>
                                    <FormGroup style={{ display: "block" }} className='phases_conntected checkbox-inp  checkbox_main'>

                                        <div className="control checkbox-group">
                                            <div className="checkbox-control">
                                                <input type={'checkbox'} id="phases_conntected_red" name="phases_conntected" value="red"  checked={state.phases_connected.includes('red')} onClick={onClickPhaseConnected1} />
                                                <label for="vehicle1"> Red</label>
                                            </div>
                                            <div className="checkbox-control">
                                                <input type={'checkbox'} id="phases_conntected_yellow" name="phases_conntected" value="yellow"  checked={state.phases_connected.includes('yellow')}  onClick={onClickPhaseConnected1} />
                                                <label for="vehicle1"> Yellow</label>
                                            </div>
                                            <div className="checkbox-control">
                                                <input type={'checkbox'} id="phases_conntected_blue" name="phases_conntected" value="blue"  checked={state.phases_connected.includes('blue')} onClick={onClickPhaseConnected1} />
                                                <label for="vehicle1"> Blue</label>
                                            </div>
                                        </div>

                                    </FormGroup>
                                </div>

                                <div className="input-control-main">
                                <label htmlFor="geo_co_ordinates">Geo Co-ordinates</label>
                                <div className="control">
                                    <div className="input-group cordinates-group" style={{ display: 'flex' }}>
                                        <input type='text' id="lat" name="lat" onChange={handleChangeForm} value={state.lat} placeholder="Latitude"
                                            className={state?.error && state?.error?.lat ? "input-error custom-input-control geo_co_ordinates_lat_field" : "custom-input-control geo_co_ordinates_lat_field"}
                                        />
                                        <input type='text' id="lng" name="lng" onChange={handleChangeForm} value={state.lng} placeholder="Longitude"
                                            className={state?.error && state?.error?.lng ? "input-error custom-input-control geo_co_ordinates_lng_field" : "custom-input-control geo_co_ordinates_lng_field"}
                                        />
                                        <img src={LoctionIcon} style={{ cursor: "pointer" }} className="geo_co_ordinates_btn" onClick={() => getLocation()} />

                                        {
                                            state?.error && state?.error?.lng && state?.error?.lat &&
                                            <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.lat ? state?.error?.lat : state?.error?.lng}</span>
                                        }


                                    </div>
                                </div>

                            </div>


                            </div>
                            {/* {id && (
                        <> */}
                            <div style={{ marginTop: 50 }}>
                                <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                        Device binding
                                    </Typography>
                                </Box>
                                <div className="eq-form-bottom">

                                    <div className="input-control-main">
                                        <label htmlFor="ep_id">Physical Device ID</label>
                                        <div className="control">
                                            <input type='text' id="ep_id" onChange={handleChangeForm} name="ep_id" value={state.ep_id} placeholder="Physical Device ID" className="custom-input-control" />
                                            {
                                                state?.error && state?.error?.ep_id &&
                                                <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.ep_id}</span>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                             


                        </div>
                    </form> 
                    : <></>
                    }

                </DialogContent>
                <DialogActions className='filter-footer' style={{ display: "block" }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6}>
                            {/* onClick={saveData}  */}
                            <Button  class="filterConditionSaveBtn filter-footer-btn" onClick={nodeId?updateForm:createForm} style={{ cursor: "pointer", border: "unset" }}>
                           {nodeId?'Update':'Create'}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button  class="filterConditionCancleBtn filter-footer-btn" onClick={closeModal} style={{ cursor: "pointer", border: "unset" }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );

}
export default StreetLightDialog