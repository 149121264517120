// import * as React from 'react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Tabs, Tab, Box, Toolbar, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SelectFieldComponent } from '../../components/formFields/common/textFieldComponent';
import TextFieldComponent from '../../components/formFields/common/textFieldComponent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Grade } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CalendarIcon from "../../assets/Calendar.png"
import SearchIcon from "../../assets/magnifyingglass2.png"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decryptData, encryptData, getFromDate, toUnixTime } from '../../constants/Helper';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setSaveIntervals } from '../../stores/actionTypes/equipmentActions';
import { unixTimeToLocal } from '../../constants/Helper';
import iIcon from "../../assets/iicon.png"
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F2FEFA',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const QUICK_TIME_RANGE_LIST = [
    { title: "Last 5 Minutes", value: '5_MINUTS' },
    { title: "Last 15 Minutes", value: '15_MINUTS' },
    { title: "Last 30 Minutes", value: '30_MINUTS' },
    { title: "Last 1 Hour", value: '1_HOUR' },
    { title: "Last 3 Hour", value: '3_HOUR' },
    { title: "Last 6 Hours", value: '6_HOUR' },
    { title: "Last 12 Hours", value: '12_HOUR' },
    { title: "Last 24 Hours", value: '24_HOUR' },
    { title: "Last 2 Days", value: '2_DAYS' },
    { title: "Last 7 Days", value: '7_DAYS' },
    { title: "Last 15 Days", value: '15_DAYS' },
    { title: "Last 30 Days", value: '30_DAYS' },
    { title: "Last 45 Days", value: '45_DAYS' },
    { title: "This Day", value: 'THIS_DAY' },
];

const TimeRangeFilterModal = ({ showFilterModal, closeModal }) => {
    var url = new URL(window.location.href);
    var filter = url.searchParams.get("filter");
    const { pathname, search } = useLocation();
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const navigate = useNavigate()
    const { graphTimeIntervals } = useSelector(state => state.equipment);
    const dispatch = useDispatch()
    const [timeIntervalData, setTimeIntervalData] = useState([])
    const [filterObjct, setFilterObjct] = useState({})

    const [unixFromDate, setUnixFromDate] = useState('')
    const [unixToDate, setUnixToDate] = useState('')
    const [isValidTimeRange, setIsValidTimeRange] = useState(true)

    const [timeZoneList, setTimeZoneList] = React.useState([
        { title: "(GMT +5:30) Mumbai, Kolkata, New Delhi", value: "1" },
        { title: "(GMT -11:00) Midway Island, Samoa", value: '2' },
        { title: "(GMT +11:00) Pago Pago", value: '3' },
        { title: "(GMT -10:00) Hawaii", value: '4' },
        { title: "(GMT -09:00) Alaska", value: '5' },
        { title: "(GMT -08:00) Juneau", value: '6' },
        { title: "(GMT -08:00) Vancouver", value: '7' },
    ])

    React.useEffect(() => {
        // console.log('filter change')
        if (filter && pathname == '/chart/view' || pathname == '/chart/full-view') {

            let fltr = decryptData(filter);
            let filterObj = {};
            if (fltr && fltr !== null && fltr !== undefined) {
                filterObj = JSON.parse(fltr);
                setFilterObjct(filterObj)
                console.log('filterObj--', filterObj)
                console.log('time---------', moment.unix(filterObj.to).format('MM/DD/YYYY h:mm A'));
                // setFromDate(moment.unix(filterObj.from).format('YYYY-MM-DDThh:mm'))
                // setToDate(moment.unix(filterObj.to).format('YYYY-MM-DDThh:mm'))


                // console.log(moment.unix(filterObj.from).format('YYYY-MM-DDThh:mh'))
                setUnixFromDate(filterObj.from)
                setUnixToDate(filterObj.to)
                setFromDate(moment.unix(filterObj.from).format('MM/DD/YYYY h:mm A'))
                setToDate(moment.unix(filterObj.to).format('MM/DD/YYYY h:mm A'))

                // console.log(moment(new Date(filterObj.from * 1000).toISOString()).format('YYYY-MM-DDThh:mm'))


            }
        }

    }, [filter])

    const onChangeFromToDate = (e, type) => {

        // console.log(moment(e).format('M/D/YYYY H:mm'))
        // return false



        let val = moment(e).format('M/D/YYYY H:mm')



        if (type == 'from') {

            setFromDate(e)
            setUnixFromDate(Math.floor(moment(val, 'M/D/YYYY H:mm').valueOf() / 1000))
        } else {
            setToDate(e)
            setUnixToDate(Math.floor(moment(val, 'M/D/YYYY H:mm').valueOf() / 1000))

        }

    }

    const applyFilter = () => {

        if (filter && pathname == '/chart/view' || pathname == '/chart/full-view') {

            if (unixFromDate < unixToDate) {

                setIsValidTimeRange(true)

                dispatch(setSaveIntervals({ from: unixFromDate, to: unixToDate }))

                let fltr = decryptData(filter);
                let filterObj = {};
                if (fltr && fltr !== null && fltr !== undefined) {
                    filterObj = JSON.parse(fltr);
                    // filterObj.from = Math.floor(new Date(fromDate) / 1000)
                    // filterObj.to = Math.floor(new Date(toDate) / 1000)

                    filterObj.from = unixFromDate
                    filterObj.to = unixToDate

                    let encodedGraphData = encryptData(JSON.stringify(filterObj))
                    closeModal()
                    navigate(pathname + '?filter=' + encodedGraphData)

                }

            } else {

                setIsValidTimeRange(false)

            }
        }
        // closeModal()

    }

    React.useEffect(() => {
        setTimeIntervalData(graphTimeIntervals)
    }, [graphTimeIntervals])

    const onClickInterval = (data) => {

        // console.log(timeIntervalData)

        // console.log(data.from)
        // console.log(data.to)

        // return false
        setUnixFromDate(data.from)
        setUnixToDate(data.to)
        // let fromval = moment(new Date(data.from * 1000)).format('M/D/YYYY H:mm')
        // setUnixFromDate((moment(fromval, 'M/D/YYYY H:mm').valueOf() / 1000))

        // let toval = moment(new Date(data.to * 1000)).format('M/D/YYYY H:mm')
        // setUnixToDate((moment(toval, 'M/D/YYYY H:mm').valueOf() / 1000))

        setFromDate(moment(new Date(data.from * 1000)).format('MM/DD/YYYY h:mm A'))
        setToDate(moment(new Date(data.to * 1000)).format('MM/DD/YYYY h:mm A'))




    }

    const onClickQuickRange = (data) => {

        if (data === 'THIS_DAY') {

            setUnixFromDate(new Date().setHours(0, 0, 0, 0))
            setUnixToDate(new Date())
            setFromDate(moment(new Date().setHours(0,0,0,0)).format('MM/DD/YYYY h:mm A'))
            setToDate(moment(new Date()).format('MM/DD/YYYY h:mm A'))

        } else {

            let rengeFrom = getFromDate(data)

            let fromval = moment(rengeFrom).format('M/D/YYYY H:mm')
            setUnixFromDate((moment(fromval, 'M/D/YYYY H:mm').valueOf() / 1000))

            let toval = moment(new Date()).format('M/D/YYYY H:mm')
            setUnixToDate((moment(toval, 'M/D/YYYY H:mm').valueOf() / 1000))

            setFromDate(moment(new Date(rengeFrom)).format('MM/DD/YYYY h:mm A'))
            setToDate(moment(new Date(new Date())).format('MM/DD/YYYY h:mm A'))
        }
    }

    return (
        <>
            {/* {
                console.log(timeIntervalData)
            } */}

            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={showFilterModal}
                className='chart-filter-modal'
            >

                <DialogContent >

                    <div className='time-range-main'>

                        <Grid container spacing={2}>
                            <Grid item xs={8} className='graph-filter-left'>
                                <h3 >
                                    Absolute Time Range
                                </h3>


                                <div className='date-main'>
                                    <div className='date-control-from'>
                                        <label for="from">From:</label>
                                        <div className='input-group'>
                                            <Datetime
                                                value={fromDate}
                                                onChange={(e) => onChangeFromToDate(e, 'from')}
                                                className="datetime-picker"
                                            />
                                            {/* <input type="datetime-local" id="from" name="from" value={fromDate} onChange={onChangeFromToDate} /> */}
                                            <span className="calender" style={{ cursor: "pointer" }}>
                                                <img src={CalendarIcon} />
                                            </span>
                                        </div>
                                    </div>





                                    <div className='date-control-from'>
                                        <label for="to">To:</label>
                                        <div className='input-group'>
                                            <Datetime
                                                value={toDate}
                                                onChange={(e) => onChangeFromToDate(e, 'to')}
                                                className="datetime-picker"
                                            />
                                            {/* <input type="datetime-local" id="to" name="to" value={toDate} onChange={onChangeFromToDate} /> */}
                                            <span className="calender" style={{ cursor: "pointer" }}>
                                                <img src={CalendarIcon} />
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        !isValidTimeRange &&
                                        <span className="error" style={{ color: "#FF4438" }}>Can not select Time earlier than From Date in To Date</span>
                                    }

                                </div>
                                <div className='interval-main'>
                                    <h4>
                                        Recently Used Intervals
                                    </h4>

                                    <div className='time-intervals'>
                                        {
                                            timeIntervalData?.length > 0 &&
                                            timeIntervalData?.map((data, i) => {
                                                return (
                                                    <>
                                                        <span
                                                            onClick={() => onClickInterval(data)}
                                                            style={{ cursor: "pointer", flex: "unset" }}
                                                            className={(data.from == filterObjct.from &&
                                                                data.to == filterObjct.to) ? 'intetval-active' : ''}>
                                                            {
                                                                moment(new Date(data.from * 1000)).format('YYYY-MM-DD h:mm A') + '  -  ' +
                                                                moment(new Date(data.to * 1000)).format('YYYY-MM-DD h:mm A')
                                                            }
                                                        </span>

                                                    </>
                                                )
                                            })
                                        }
                                        {/* <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span>
                                        <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span>
                                        <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span>
                                        <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span>
                                        <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span>
                                        <span style={{ cursor: "pointer" }}>12.01.23 - 12.02.23</span> */}

                                    </div>
                                </div>
                                {/* <SelectFieldComponent
                                    label={'Time Zone'}
                                    id="timeRange"
                                    name="timeRange"
                                    menulist={timeZoneList}
                                    placeholder="Select Time Range"
                                    autoFocus={false}
                                    // onChange={onChangeTimeRange}
                                    classes="ml-5 timezone-select"
                                    selectClass='w-60'
                                    isRequired={false}
                                /> */}

                            </Grid>
                            <Grid item xs={4} className='graph-filter-right'>
                                <h3 >
                                    Quick Range
                                </h3>
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 350,
                                        '& ul': { padding: 0 },
                                    }}
                                    style={{
                                        border: '1px solid #EFF0F6',
                                        boxShadow: '0px 0px 10px rgba(139, 139, 139, 0.1)',
                                        borderRadius: '14.5px',
                                    }}
                                    subheader={<li />}
                                >
                                    <ListSubheader>Select Quick Range</ListSubheader>
                                    {QUICK_TIME_RANGE_LIST.map((range, i) => (
                                        <ListItem key={`item--${i}`}>
                                            <ListItemText primary={range.title} style={{ cursor: "pointer" }}
                                                onClick={() => onClickQuickRange(range.value)} />
                                        </ListItem>

                                    ))}
                                </List>

                                {/* <div className="graph-filter-search">
                                    <img src={SearchIcon} />

                                    <SelectFieldComponent
                                        // label={'Time Zone'}
                                        id="timeRange"
                                        name="timeRange"
                                        menulist={QUICK_TIME_RANGE_LIST}
                                        placeholder="Select Time Range"
                                        autoFocus={false}
                                        // onChange={onChangeTimeRange}
                                        classes="ml-5 timezone-select"
                                        selectClass='w-60'
                                        isRequired={false}
                                    />

                                </div> */}

                            </Grid>

                        </Grid>






                    </div>

                </DialogContent>
                <DialogActions className='filter-footer' style={{ display: "block" }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6}>
                            <Button autoFocus onClick={applyFilter} class="filterConditionSaveBtn filter-footer-btn" style={{ cursor: "pointer", border: "unset" }}>
                                Apply
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button autoFocus class="filterConditionCancleBtn filter-footer-btn" onClick={closeModal} style={{ cursor: "pointer", border: "unset" }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );

}
export default TimeRangeFilterModal