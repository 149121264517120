// import * as React from "react";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Toolbar,
  Typography,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Bolt as BoltIcon,
  QueryStats as QueryStatsIcon,
  PriorityHigh as PriorityHighIcon,
  TrendingDown as TrendingDownIcon,
  BatteryCharging20 as BatteryCharging20Icon,
  MonitorHeart as MonitorHeartIcon,
  GraphicEq as GraphicEqIcon,
  ElectricMeter as ElectricMeterIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  OpenInFullOutlined as OpenInFullOutlinedIcon,
} from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  BarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  Tooltip as ReChartToolTip,
  Label,
  CartesianGrid,
  Area,
  AreaChart,
  Legend
} from "recharts";
import ToastMessage from "../../components/toastMessage/toastMessage";
import {
  setDeviceInstParamAction,
  setDeviceTHDAction,
  setConsumptionChartSelectedDateAction,
  setConsumptionChartSelectedMonthAction
} from "../../stores/actionTypes/dashboardActions";
import { useHook as useDashboardHook } from "./useHook";
import {
  setSaveIntervals,
  setCallDashboardFunctions,
} from "../../stores/actionTypes/equipmentActions";
import {
  encryptData,
  checkEquipmetIsActive,
  checkApiCallTime,
  sumAvg,
  getAllDaysInMonth,
  years,
  getBarColor,
  getOffsetColor,
} from "../../constants/Helper";
import * as DashboardApis from "../../stores/services/dashboardApis";
import { useHook as useLoginHook } from "../../pages/auth/login/useHook";
import { devideArray } from "../../constants/Helper";
import CSVExportBtn from "../../components/CSVExportBtn/Index";
import {
  monthNames,
  SLUG_FREQUENCY_BARCHART,
  SLUG_POWER_FACTOR_BARCHART,
  SLUG_THD_BARCHART,
  SLUG_VOLTAGE_BARCHART,
} from "constants/variables";
import { useEquipmentHook } from "pages/equipments/useEquipmentsHook";
import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
// await setTimeout(0) 
//------ Top chart tooltip start

const CustomToolTipContent = React.memo(({ active, payload, label, color }) => {
  if (active && payload && payload.length) {
    let powerWattsValue = Number(payload[0]?.payload?.csv_expVal);
    let powerConsumed =
      Number(payload[0]?.value) < 1
        ? `${Number(powerWattsValue).toFixed(2)}W`
        : `${Number(payload[0]?.value).toFixed(2)}kW`;
    return (
      <Box
        bgcolor="#FFFFFF"
        border="0.25px solid #D5D5D5 !important"
        borderRadius={"13px"}
        boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
        padding={2}
      >
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            fontWeight={400}
            color="#8D8D8D"
            fontSize={12}
            sx={{ wordWrap: "break-word" }}
            maxWidth="fit-content"
          >
            Time
          </Typography>
          <Typography fontWeight={400} color={color} fontSize={16}>
            {`${payload[0]?.payload?.long_time != ""
              ? payload[0]?.payload?.long_time
              : ""
              }`}
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            fontWeight={400}
            color="#8D8D8D"
            fontSize={12}
            sx={{ wordWrap: "break-word" }}
            maxWidth="fit-content"
          >
            Power Consumed Of Electricity
          </Typography>
          <Typography fontWeight={400} color={color} fontSize={16}>
            {powerConsumed}
          </Typography>
        </Box>
      </Box>
    );
  }
  return null;
});

const CustomToolTipContentPC = React.memo(
  ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Day
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.name != "" ? payload[0]?.payload?.name : ""
                }`}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Power Consumed
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}kWh`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  }
);

//------ Top chart tooltip end

//------ Bottom chart tooltip start

const CustomToolTipContentcurrent = React.memo(
  ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={"#000"} fontSize={18}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              total Devices
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              from
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              to
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.end} A`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  }
);

const CustomToolTip = React.memo(({ active, payload, text }) => {
  if (active && payload && payload.length) {
    const valueLabel =
      text === "Frequency" ? "Hz" : text === "Voltage" ? "V" : "";
    return (
      <Box
        bgcolor="#FFFFFF"
        border="0.25px solid #D5D5D5 !important"
        borderRadius={"13px"}
        boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
        padding={2}
      >
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            fontWeight={400}
            color={payload[0]?.payload?.fill}
            fontSize={16}
          >
            {`${payload[0]?.value}`}
          </Typography>
          <Typography
            fontWeight={400}
            color="#8D8D8D"
            fontSize={12}
            sx={{ wordWrap: "break-word" }}
            maxWidth="fit-content"
          >
            Active Equipments with
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            fontWeight={400}
            color="#8D8D8D"
            fontSize={12}
            sx={{ wordWrap: "break-word" }}
            maxWidth="fit-content"
          >
            {text}
          </Typography>
          <Typography
            fontWeight={400}
            color={payload[0]?.payload?.fill}
            fontSize={16}
          >
            {`${payload[0]?.payload?.start} - ${payload[0]?.payload?.end} ${valueLabel}`}
          </Typography>
        </Box>
      </Box>
    );
  }
  return null;
});

//------ Bottom chart tooltip end

const TotalPowerLayout = React.memo(
  ({
    hPChartTime,
    activeEquipmentList,
    storageNodeList,
    projectChartData,
    csvHeader,
    projectListChartData,
    expandGraph,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      fetchHistoryProjectList,
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [equipmentList, setEquipmentList] = useState([
      // { name: "All Data", checked: false },
      // { name: "AW - 234", checked: false },
      // { name: "AW - 345", checked: false },
      // { name: "AW - 346", checked: false },
      // { name: "AW - 560", checked: false },
      // { name: "AW - 235", checked: false },
      // { name: "AW - 347", checked: false },
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }


    useEffect(() => {

      if (nodeMetaData) {

        let output = Object.keys(nodeMetaData)?.map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;
          return {
            name: node_name,
            node_id: node_id,
            checked: false
          };
        });

        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('time_Consumption');
            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)
            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];
            setEquipmentList(updatedOutput);
          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }


    }, [nodeMetaData])

    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyPowerParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
      let selectedPayload = {
        "widget_id": "time_Consumption",
        "preferences": {
          selectedParams
        }
      }

      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';

      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
      let searchPayloads = searchData.map((data) => data.node_id);
      await fetchHistoryProjectList(searchPayloads, filterType);
      await postNodeParams(selectedPayload)
      setAnchorEl(null);
    }


    return (
      <Box
        mb="1%"
        width="43.7%"
        bgcolor={"transparent"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
        className="total-chart-main-card z-[9]"
      >
        <Box display="flex" gap={3} alignItems="start">
          <Box width="100%" className="total-chart-main-sec">
            <div className="grid grid-cols-1 sm:grid-cols-2 total-chart-top-sec">
              <div className="total-chart-title-sec">
                <BoltIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                    left: "15px",
                  }}
                  className="BoltIcon"
                />
                <Box display="flex items-center" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Total Power (Watt)
                  </Typography>
                  <Tooltip
                    title="Represents the total power consumption trend of a day."
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography fontSize={11} fontWeight={300} color="#8D8D8D">
                  as of {hPChartTime}
                </Typography>
              </div>
              <div className="active_equipmet_part">
                <Box display="flex" gap={1}>
                  <BoltIcon
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "rgba(217, 217, 217, 0.2)",
                      padding: 0.5,
                      mt: "4px",
                    }}
                    className="BoltIcon"
                  />
                  <Box className="pl-[14%]">
                    <Box className="flex gap-[8px] items-center">
                      <Box className="items-center flex gap-[8px]">
                        <Typography
                          component="span"
                          fontSize={15}
                          fontWeight={700}
                          color="#000000"
                        >
                          {activeEquipmentList.length}
                          <Typography
                            component="span"
                            fontSize={12}
                            fontWeight={600}
                            color="rgba(0, 0, 0, 0.5)"
                          >
                            /{JSON.parse(storageNodeList)?.length}
                          </Typography>
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          color="#4D4D4D"
                          className="leading-[1.3]"
                          component="span"
                        >
                          Active Equipments
                        </Typography>
                      </Box>
                      <Tooltip
                        title="Represents the total power consumption trend of a day."
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Typography fontSize={11} fontWeight={300} color="#8D8D8D">
                      as of {hPChartTime}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-5 items-center total-pow-opt mt-[13px] mb-[16px]">
              <Box className="col-start-1 col-span-2 flex items-center gap-2 justify-between w-full max-w-[94%] ml-auto">
                <div className="flex gap-[8px] ">
                  <Box className="flex items-center  gap-[8px]">
                    <Typography
                      component="span"
                      className="text-[24px] Mediumlg:text-[16px]"
                      fontWeight={700}
                      color="#000000"
                    >
                      {projectChartData?.uv < 1 ? (
                        `${projectChartData?.uv * 1000}W`
                      ) : (
                        <React.Fragment>
                          {
                            projectChartData?.uv
                              ?.toFixed(2)
                              ?.toString()
                              ?.split(".")[0]
                          }
                          <Typography
                            component="span"
                            fontSize={17}
                            fontWeight={600}
                            color="rgba(0, 0, 0, 0.5)"
                            className="Mediumlg:text-[13px]"
                          >
                            .
                            {
                              projectChartData?.uv
                                ?.toFixed(2)
                                ?.toString()
                                ?.split(".")[1]
                            }
                            kW
                          </Typography>
                        </React.Fragment>
                      )}
                    </Typography>
                  </Box>
                  <Tooltip
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </div>
                <Box>
                  <SettingsOutlinedIcon
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    sx={{
                      padding: 0.5,
                      color: "#808080d1",
                    }}
                    style={{ cursor: "pointer" }}
                  />

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                        <FormGroup>
                          {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={equipmentState.every(equipment => equipment.checked)}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const updatedEquipmentState = equipmentState.map(equipment => ({ ...equipment, checked: isChecked }));
                                setEquipmentState(updatedEquipmentState);
                              }}
                            />
                          }
                          label="All Equipments"
                          style={{ color: 'red' }}
                        /> */}
                          {filteredEquipmentList.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={item.checked}
                                  onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>

                  <CSVExportBtn
                    headers={csvHeader}
                    csvData={projectListChartData ? projectListChartData : []}
                    label={
                      <FileDownloadOutlinedIcon
                        sx={{
                          padding: 0.5,
                          color: "#808080d1",
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    }
                    prifix="_total_power"
                  />
                  <OpenInFullOutlinedIcon
                    sx={{
                      padding: 0.5,
                      color: "#808080d1",
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() => expandGraph()}
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
        <Box className="h-[calc(100%-140px)]">
          <ResponsiveContainer
            width="100%"
            height="100%"
            className={"px-[15px]"}
          >
            <AreaChart
              data={projectListChartData}
              className={"total-chart-container"}
              margin={{
                top: 5,
                right: 55,
                left: 20,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorAreaChart" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="rgba(151, 27, 249, 1)"
                    stopOpacity={0.1}
                  />
                  <stop
                    offset="95%"
                    stopColor="rgba(151, 27, 249, 0.09)"
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                fontSize={14}
                fontWeight={400}
                tick={{ fill: "#BEBEBE" }}
                interval={72}
                tickLine={false}
                axisLine={false}
              >
                <Label
                  offset={-4}
                  value="Time"
                  position="insideBottom"
                  fontSize={11}
                  fill="#616161"
                  fontWeight={300}
                />
              </XAxis>
              <YAxis
                fontSize={14}
                fontWeight={400}
                tick={{ fill: "#971BF9" }}
                tickLine={false}
                axisLine={false}
                label={{
                  value: "Power in Kilo-Watt (kW)",
                  angle: -90,
                  fontWeight: 300,
                  fontSize: 11,
                  offset: -4,
                  fill: "#616161",
                  height: "100%",
                  position: "insideLeft",
                }}
              />
              <ReChartToolTip
                wrapperStyle={{ outline: "none" }}
                cursor={false}
                content={<CustomToolTipContent color="#971BF9" />}
              />
              <CartesianGrid vertical={false} stroke="#DDD" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="rgba(151, 27, 249, 1)"
                fillOpacity={1}
                fill="url(#colorAreaChart)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
);

const ConsumptionPatternLayout = React.memo(
  ({
    cPChartTime,
    currentYear,
    onChangeDate,
    years,
    monthNames,
    currentMonth,
    cpTotalChartData,
    csvHeader,
    consumptionPatternChartData,
    firstDayMonth,
    lastDayMonth
    }) => {
      const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      fetchConsumptionPattern,
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [equipmentList, setEquipmentList] = useState([
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }

    useEffect(() => {
      if (nodeMetaData) {
        let output = Object.keys(nodeMetaData).map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


          return {
            name: node_name,
            node_id: node_id,
            checked: false
          }
        });


        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('day_Consumption');

            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

            setEquipmentList(updatedOutput);


          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }

    }, [nodeMetaData])


    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };



    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyConsumptionParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')

      let selectedPayload = {
        "widget_id": "day_Consumption",
        "preferences": {
          selectedParams
        }
      }

      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';

      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
      let searchPayloads = searchData.map((data) => data.node_id);

       let consumptionPatternPayload = {
        from: firstDayMonth,
        to: lastDayMonth,
            nodes: searchPayloads,
            type: filterType
      };

      await fetchConsumptionPattern(consumptionPatternPayload);
      dispatch(setConsumptionChartSelectedDateAction(consumptionPatternPayload))

      await postNodeParams(selectedPayload)
      setAnchorEl(null);
    }






    return (
      <Box
        p="1%"
        width="55%"
        bgcolor={"#fff"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
      >
        <Box display="flex" gap={3} alignItems="start">
          <QueryStatsIcon
            sx={{
              borderRadius: "50%",
              bgcolor: "rgba(217, 217, 217, 0.2)",
              padding: 0.5,
            }}
          />
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Consumption Pattern
                  </Typography>
                  <Tooltip
                    title="Represents the unit consumption trend of a month/year."
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography fontSize={11} fontWeight={300} color="#8D8D8D">
                  as of {cPChartTime}
                </Typography>
              </Box>
              <Box display="flex">
                <FormControl sx={{ mx: 1, minWidth: 80 }} size="small">
                  <Select
                    displayEmpty
                    value={currentYear}
                    onChange={(value) => onChangeDate(value, currentMonth)}
                    input={
                      <OutlinedInput
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          borderRadius: "14.5px",
                          "& fieldset": { border: "none" },
                          color: "#7F7F7F",
                          fontSize: "14px",
                          fontWeight: 500,
                          width: 130,
                          "& .MuiSvgIcon-root": {
                            color: "#7F7F7F",
                          },
                          "& em": {
                            fontStyle: "normal",
                          },
                        }}
                      />
                    }
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Year</em>;
                      }
                      return selected;
                    }}
                    MenuProps={{
                      classes: { paper: "customDropDown" },
                      PaperProps: {
                        sx: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 130,
                          boxShadow: "none",
                          borderRadius: "0 0 20px 20px",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          borderTop: "none",
                          marginTop: "-5px",
                          background: "transparent",
                        },
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {years(2019 - 20).map((yearOption) => (
                      <MenuItem key={yearOption} value={yearOption}>
                        {yearOption}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ mx: 1, minWidth: 80 }} size="small">
                  <Select
                    displayEmpty
                    value={monthNames[currentMonth]}
                    onChange={(value) => onChangeDate(currentYear, value)}
                    input={
                      <OutlinedInput
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          borderRadius: "14.5px",
                          width: 130,
                          "& fieldset": { border: "none" },
                          color: "#7F7F7F",
                          fontSize: "14px",
                          fontWeight: 500,
                          "& .MuiSvgIcon-root": {
                            color: "#7F7F7F",
                          },
                          "& em": {
                            fontStyle: "normal",
                          },
                        }}
                      />
                    }
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Month</em>;
                      }
                      return selected;
                    }}
                    MenuProps={{
                      classes: { paper: "customDropDown" },
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 130,
                          boxShadow: "none",
                          borderRadius: "0 0 20px 20px",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          borderTop: "none",
                          marginTop: "-5px",
                          background: "transparent",
                        },
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {monthNames.map((monthSelected) => (
                      <MenuItem key={monthSelected} value={monthSelected}>
                        {monthSelected}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mt={"30px"}
              mb={"20px"}
            >
              <Box className="flex justify-between w-full">
                <Box display="flex" gap={1}>
                  <Typography
                    className="text-[24px] Mediumlg:text-[16px]"
                    fontWeight={700}
                    color="#000000"
                  >
                    {cpTotalChartData}
                    <Typography
                      component="span"
                      color="rgba(0, 0, 0, 0.5)"
                      fontWeight={600}
                      className="Mediumlg:text-[13px]"
                    >
                      kWh
                    </Typography>
                  </Typography>
                  <Tooltip
                    title="Represents the total units consumption of a month/year"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box className="flex items-center">
                  <Box>
                    {/* <FileDownloadOutlinedIcon
                                sx={{
                                  padding: 0.5,
                                  color: "#E6E6E6"
                                }}
                                style={{ cursor: "pointer" }}
                              /> */}
                    <SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                      className="setting_Popover"
                    >
                      <Typography sx={{ p: 2 }}>
                        <h3>Consumption Pattern</h3>
                        <div className="setting_Popover_Search">
                          <input
                            type="text"
                            placeholder="Select Equipments"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <SearchOutlinedIcon
                            sx={{ padding: 0.5, color: "#808080d1" }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="nodecontainer-scroll">
                          <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                        </div>
                        <div className="setting_Popover_btn">
                          <button type="button" onClick={onApplyConsumptionParams}>Apply</button>
                          <button type="button" onClick={handleClose}>Cancel</button>
                        </div>
                      </Typography>
                    </Popover>

                    <CSVExportBtn
                      headers={csvHeader}
                      csvData={
                        consumptionPatternChartData
                          ? consumptionPatternChartData
                          : []
                      }
                      label={
                        <FileDownloadOutlinedIcon
                          sx={{
                            padding: 0.5,
                            color: "#808080d1",
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      prifix="_consumption_pattern"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="h-[calc(100%-140px)]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={consumptionPatternChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient
                  id="colorBarChart"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#1D47FF" />
                  <stop offset="1" stopColor="#04E3AA" />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                interval={1}
                fontSize={14}
                fontWeight={400}
                tick={{ fill: "#BEBEBE" }}
                tickLine={false}
                axisLine={false}
              >
                <Label
                  offset={-4}
                  value={monthNames[currentMonth] + " " + currentYear}
                  position="insideBottom"
                  fontSize={11}
                  fill="#616161"
                  fontWeight={300}
                />
              </XAxis>
              <YAxis
                allowDataOverflow={true}
                fontSize={14}
                fontWeight={400}
                tick={{ fill: "#464646" }}
                tickLine={false}
                axisLine={false}
                label={{
                  value: "Power Consumption (kW)",
                  angle: -90,
                  position: "insideLeft",
                  fontWeight: 300,
                  fontSize: 11,
                  offset: -4,
                  fill: "#616161",
                }}
              />
              <ReChartToolTip
                wrapperStyle={{ outline: "none" }}
                cursor={false}
                content={<CustomToolTipContentPC color="#1770EA" />}
              />
              <CartesianGrid
                vertical={false}
                stroke="rgba(29, 33, 37, 0.4)"
                strokeOpacity={0.2}
              />
              <Bar
                dataKey="pv"
                fill="url(#colorBarChart)"
                barSize={9}
                radius={[10, 10, 0, 0]}
              />
            </BarChart>
            {/* <BarChart width={600} height={400} data={consumptionPatternChartData}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="pv" fill="#8884d8" />
  </BarChart> */}
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
);

const CurrentLayout = React.memo(
  ({ currentChartData, avgOfCurrentData, onClickBar,currentFunction}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [equipmentList, setEquipmentList] = useState([
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }

    useEffect(() => {
      if (nodeMetaData) {
        let output = Object.keys(nodeMetaData).map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


          return {
            name: node_name,
            node_id: node_id,
            checked: false
          }
        });


        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('Current');

            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

            setEquipmentList(updatedOutput);


          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }

    }, [nodeMetaData])


    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };



    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyPowerParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
      let selectedPayload = {
        "widget_id": "Current",
        "preferences": {
          selectedParams
        }
      }

      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';

      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
     let nodesData =[]
      let searchPayloads = selectedParams.map((data) =>nodesData.push(data.node_id));
      // await fetchHistoryProjectList(searchPayloads, filterType);
console.log(nodesData,"nodesData")
      // await fetchInstEnergyList({ nodes: nodesData });
      await postNodeParams(selectedPayload)
      currentFunction()
      setAnchorEl(null);

    }

    return (
      <Box
        p="1%"
        mb="1%"
        width="19%"
        bgcolor={"#fff"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
        className="Mediumlg:!mb-0"
      >
        <Box
          display="flex"
          gap={1}
          alignItems="start"
          className="flex-col Mediumlg:flex-row Mediumlg:justify-between"
        >
          <Box display="flex" gap={1}>
            <BoltIcon
              sx={{
                borderRadius: "50%",
                bgcolor: "rgba(217, 217, 217, 0.2)",
                padding: 0.5,
              }}
              className="Mediumlg:h-[18px] Mediumlg:w-[18px] Mediumlg:p-[2px]"
            />
            <Box className="flex items-center Mediumlg:items-center" gap={1}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="rgba(0, 0, 0, 0.7)"
                className="Mediumlg:text-[12px]"
              >
                Current (A)
              </Typography>
              <Tooltip
                title="Represents the historical distribution chart of Current A of the active equipements."
                arrow
                placement="right"
              >
                <PriorityHighIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "#E6E6E6",
                    padding: 0.2,
                    color: "white",
                    fontSize: "13px",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          {currentChartData.filter((item) => item.pv !== 0).length > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                gap={1}
                className="Mediumlg:flex-col Mediumlg:max-w-[50px]"
              >
                <Typography
                  fontSize={24}
                  fontWeight={700}
                  color="#000000"
                  className="Mediumlg:text-[14px] mt-[4px] leading:[0.1] Mediumlg:leading-[0.8]"
                >
                  {avgOfCurrentData?.split(".")[0]}
                  <Typography
                    component="span"
                    color="rgba(0, 0, 0, 0.5)"
                    className="Mediumlg:text-[12px] Mediumlg:leading-[0.8]"
                  >
                    .{avgOfCurrentData?.split(".")[1]}A
                  </Typography>
                </Typography>
              </Box>
            </Box>
          )}

<SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                     <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                      <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>

        </Box>
        <Box
          width="100%"
          className="h-[calc(100%-68px)] Mediumlg:h-[calc(100%-18px)]"
        >
          {currentChartData.filter((item) => item.pv !== 0).length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={currentChartData.filter((item) => item.pv !== 0)}>
                <defs>
                  <linearGradient
                    id="colorBarChart1"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="rgba(151, 27, 249, 0.2)" />
                    <stop offset="1" stopColor="rgba(151, 27, 249, 1)" />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  fontSize={10}
                  fontWeight={400}
                  tick={{ fill: "#BEBEBE" }}
                  tickLine={false}
                  axisLine={false}
                >
                  <Label
                    offset={1}
                    value="min."
                    position="insideBottomLeft"
                    fontSize={10}
                    fill="#9F2CFA"
                    fontWeight={500}
                  />
                  <Label
                    offset={1}
                    value="max."
                    position="insideBottomRight"
                    fontSize={10}
                    fill="#9F2DFA"
                    fontWeight={500}
                  />
                </XAxis>
                <ReChartToolTip
                  wrapperStyle={{ outline: "none" }}
                  cursor={false}
                  content={<CustomToolTipContentcurrent color="#971BF9" />}
                />
                <CartesianGrid
                  vertical={false}
                  stroke="rgba(29, 33, 37, 0.4)"
                  strokeOpacity={0.2}
                />
                <Bar
                  dataKey="pv"
                  fill="url(#colorBarChart1)"
                  barSize={8}
                  radius={[10, 10, 0, 0]}
                  onClick={(e) => onClickBar(e, "currentValue")}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
              flexDirection={"column"}
              height="100%"
            >
              <Typography
                fontSize={30}
                fontWeight={700}
                color="#000000"
                className="Mediumlg:text-[14px] mt-[4px] mb-4 pt-[4rem] leading:[0.1] Mediumlg:leading-[0.8]"
              >
                {avgOfCurrentData?.split(".")[0] || 0}
                <Typography
                  fontSize={20}
                  component="span"
                  color="rgba(0, 0, 0, 0.5)"
                  className="Mediumlg:text-[12px] Mediumlg:leading-[0.8]"
                >
                  .{avgOfCurrentData?.split(".")[1] || 0}A
                </Typography>
              </Typography>

              <Typography
                fontSize={12}
                fontWeight={300}
                color="##4D4D4D"
                textAlign={"center"}
                className="Mediumlg:text-[12px] mt-[4px] leading:[0.1] Mediumlg:leading-[1.2]"
              >
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

const renderShape =
  (key, pixel = 10) =>
    ({ height, width, fill, x, y, radius, ...rest }) => {
      let randomId = Math.random() + key;
      return (
        <svg
          x={x}
          y={y}
          radius={radius}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id={`${randomId}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2={`100%`}
            >
              <stop offset="0" stopColor={getOffsetColor(fill)} />
              <stop offset="1" stopColor={fill} />
            </linearGradient>
          </defs>
          <defs>
            <clipPath id="round-corner">
              <rect x="0" y="0" width={width} height={"100%"} rx="5" ry="5" />
            </clipPath>
          </defs>
          <rect
            fill={`url(#${randomId})`}
            clip-path="url(#round-corner)"
            width={width}
            height={height}
            radius={radius}
          />
        </svg>
      );
    };

const CommonResponsiveBarLayout = React.memo(

  ({ data, tooltipText, onClickBar }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <XAxis
            dataKey="name"
            fontSize={10}
            fontWeight={400}
            tick={{ fill: "#808080" }}
            tickLine={false}
            axisLine={false}
          >
            <Label
              offset={1}
              value="min."
              position="insideBottomLeft"
              fontSize={10}
              fill="#07D7B1"
              fontWeight={500}
            />
            <Label
              offset={1}
              value="max."
              position="insideBottomRight"
              fontSize={10}
              fill="#FF4438"
              fontWeight={500}
            />
          </XAxis>
          <ReChartToolTip
            wrapperStyle={{ outline: "none" }}
            cursor={false}
            content={<CustomToolTip text={tooltipText} />}
          />
          <CartesianGrid
            vertical={false}
            stroke="rgba(29, 33, 37, 0.4)"
            strokeOpacity={0.2}
          />
          <Bar
            dataKey="pv"
            fill="url(#colorBarChart2)"
            barSize={8}
            radius={[10, 10, 0, 0]}
            shape={renderShape("a")}
            onClick={onClickBar}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);

const PowerFactorLayout = React.memo(
  ({ powerFectorChartData, avgOfPowerFectorData, onClickBar,powerFactorFunction }) => {
    const chartData = React.useMemo(() => {
      return powerFectorChartData
        .filter((item) => item.pv !== 0)
        .map((elMap) => ({
          ...elMap,
          fill: getBarColor(SLUG_POWER_FACTOR_BARCHART, elMap.start),
        }));
    }, [powerFectorChartData]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [equipmentList, setEquipmentList] = useState([
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }

    useEffect(() => {
      if (nodeMetaData) {
        let output = Object.keys(nodeMetaData).map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


          return {
            name: node_name,
            node_id: node_id,
            checked: false
          }
        });


        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('Power_Factor');

            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

            setEquipmentList(updatedOutput);


          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }

    }, [nodeMetaData])


    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };



    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyPowerParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
      let selectedPayload = {
        "widget_id": "Power_Factor",
        "preferences": {
          selectedParams
        }
      }

      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';

      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
      let searchPayloads = searchData.map((data) => data.node_id);
      // await fetchHistoryProjectList(searchPayloads, filterType);
      await postNodeParams(selectedPayload)
      powerFactorFunction()
      setAnchorEl(null);
    }

    return (
      <Box
        p="1%"
        mb="1%"
        width="19%"
        bgcolor={"#fff"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
        className="Mediumlg:!mb-0"
      >
        <Box
          display="flex"
          gap={1}
          alignItems="start"
          className="flex-col Mediumlg:flex-row  Mediumlg:justify-between"
        >
          <Box display="flex" gap={1}>
            <BatteryCharging20Icon
              sx={{
                borderRadius: "50%",
                bgcolor: "rgba(217, 217, 217, 0.2)",
                padding: 0.5,
              }}
              className="Mediumlg:h-[18px] Mediumlg:w-[18px] Mediumlg:p-[2px]"
            />
            <Box display="flex" gap={1} alignItems="center">
              <Typography
                fontSize={14}
                fontWeight={500}
                color="rgba(0, 0, 0, 0.7)"
                className="Mediumlg:text-[12px]"
              >
                Power Factor
              </Typography>
              <Tooltip
                title="Represents the historical distribution chart of Power Factor of the active equipements."
                arrow
                placement="right"
              >
                <PriorityHighIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "#E6E6E6",
                    padding: 0.2,
                    color: "white",
                    fontSize: "13px",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          {powerFectorChartData.filter((item) => item.pv !== 0).length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              className="Mediumlg:pr-[5px]"
            >
              <Box
                display="flex"
                gap={1}
                className="Mediumlg:flex-col Mediumlg:max-w-[50px]"
              >
                <Typography
                  fontSize={24}
                  fontWeight={700}
                  color={getBarColor(
                    SLUG_POWER_FACTOR_BARCHART,
                    avgOfPowerFectorData
                  )}
                  className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
                >
                  {avgOfPowerFectorData}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    color="#8D8D8D"
                    fontWeight={300}
                    fontSize={8}
                    className="Mediumlg:text-[6px]"
                  >
                    Average Value
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                     <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                      <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>

        </Box>
        <Box
          width="100%"
          className="h-[calc(100%-68px)] Mediumlg:h-[calc(100%-18px)]"
        >
          {powerFectorChartData.filter((item) => item.pv !== 0).length > 0 ? (
            <CommonResponsiveBarLayout
              data={chartData}
              tooltipText={"Power Factor"}
              onClickBar={(e) => onClickBar(e, "powerFactorValue")}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
              flexDirection={"column"}
              height="100%"
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection={"column"}
                className="pt-[4rem]"
              >
                <Typography
                  fontSize={30}
                  fontWeight={700}
                  color="#FF4438"
                  className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
                >
                  {avgOfPowerFectorData || 0}
                </Typography>
                <Typography
                  color="#8D8D8D"
                  fontWeight={300}
                  fontSize={12}
                  className="Mediumlg:text-[6px] mt-[7px]"
                >
                  {" "}
                  Average Value
                </Typography>
              </Box>

              <Typography
                fontSize={12}
                fontWeight={300}
                color="##4D4D4D"
                textAlign={"center"}
                className="Mediumlg:text-[12px] mt-[4px] leading:[0.1] Mediumlg:leading-[1.2]"
              >
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

const THDLayout = React.memo(({ tHDChartData, avgOfTHDData, onClickBar,thdFunction}) => {
  const chartData = React.useMemo(() => {
    return tHDChartData
      .filter((item) => item.pv !== 0)
      .map((elMap) => ({
        ...elMap,
        fill: getBarColor(SLUG_THD_BARCHART, elMap.start),
      }));
  }, [tHDChartData]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    fetchConsumptionPattern,
    postNodeParams,
    fetchSelectedNodeParams
  } = useDashboardHook();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [equipmentList, setEquipmentList] = useState([
  ]);

  const { filters, nodeMetaData } = useSelector(state => state.equipment);

  const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
    // Create a Set of names present in selectParamsArr for faster lookup
    const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

    // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
    const updatedOutput = outputArr?.map(item => {
      if (item.name && selectParamsNames.has(item.name)) {
        return { ...item, checked: true };
      }
      return item;
    });

    return updatedOutput;
  }

  useEffect(() => {
    if (nodeMetaData) {
      let output = Object.keys(nodeMetaData).map((node_id) => {
        let node_data = nodeMetaData[node_id];
        let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


        return {
          name: node_name,
          node_id: node_id,
          checked: false
        }
      });


      async function fetchData() {
        try {
          const selectParams = await fetchSelectedNodeParams('THD');

          let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
          let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

          updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

          setEquipmentList(updatedOutput);


        } catch (error) {
          console.error('Error occurred:', error);
        }
      }

      fetchData();
    }

  }, [nodeMetaData])


  const [searchQuery, setSearchQuery] = useState("");

  const handleCheckboxChange = (event, equipmentName = null) => {
    const updatedList = [...equipmentList];
    let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

    updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
    if (equipmentName === 0) {
      const allChecked = updatedList[0].checked;
      for (let i = 1; i < updatedList.length; i++) {
        updatedList[i].checked = allChecked;
      }
    } else {
      // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
      updatedList.find(data => data.name === 'All Data').checked = false;
    }

    setEquipmentList(updatedList);
  };



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEquipmentList = equipmentList.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const onApplyPowerParams = async () => {

    let searchData;
    let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
    let selectedPayload = {
      "widget_id": "THD",
      "preferences": {
        selectedParams
      }
    }

    let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';

    if (filterType === 'include') {
      searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
    } else {
      searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
    }
    let searchPayloads = searchData.map((data) => data.node_id);
    // await fetchHistoryProjectList(searchPayloads, filterType);
    await postNodeParams(selectedPayload)
    thdFunction()
    setAnchorEl(null);
  }

  return (
    <Box
      p="1%"
      mb="1%"
      width="19%"
      bgcolor={"#fff"}
      boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
      borderRadius={5}
      className="Mediumlg:!mb-0"
    >
      <Box
        display="flex"
        gap={1}
        alignItems="start"
        className="flex-col Mediumlg:flex-row  Mediumlg:justify-between"
      >
        <Box display="flex" gap={1}>
          <MonitorHeartIcon
            sx={{
              borderRadius: "50%",
              bgcolor: "rgba(217, 217, 217, 0.2)",
              padding: 0.5,
            }}
            className="Mediumlg:h-[18px] Mediumlg:w-[18px] Mediumlg:p-[2px]"
          />
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontSize={14}
              fontWeight={500}
              color="rgba(0, 0, 0, 0.7)"
              className="Mediumlg:text-[12px]"
            >
              THD
            </Typography>
            <Tooltip
              title="Represents the historical distribution chart of Max Total Harmonic Distribution (THD) of the active equipements."
              arrow
              placement="right"
            >
              <PriorityHighIcon
                sx={{
                  borderRadius: "50%",
                  bgcolor: "#E6E6E6",
                  padding: 0.2,
                  color: "white",
                  fontSize: "13px",
                }}
              />
            </Tooltip>
          </Box>
        </Box>
        {tHDChartData.filter((item) => item.pv !== 0).length > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            className="Mediumlg:pr-[5px]"
          >
            <Box
              display="flex"
              gap={1}
              className="Mediumlg:flex-col Mediumlg:max-w-[50px]"
            >
              <Typography
                fontSize={24}
                fontWeight={700}
                color={getBarColor(SLUG_THD_BARCHART, avgOfTHDData)}
                className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
              >
                {avgOfTHDData}%
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  color="#8D8D8D"
                  fontWeight={300}
                  fontSize={8}
                  className="Mediumlg:text-[6px]"
                >
                  Average Value
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

<SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                     <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                      <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>

      </Box>
      <Box
        width="100%"
        className="h-[calc(100%-68px)] Mediumlg:h-[calc(100%-18px)]"
      >
        {tHDChartData.filter((item) => item.pv !== 0).length > 0 ? (
          <CommonResponsiveBarLayout
            data={chartData}
            tooltipText={"THD value"}
            onClickBar={(e) => onClickBar(e, "maximumTHD")}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            flexDirection={"column"}
            height="100%"
          >
            <Typography
              fontSize={24}
              fontWeight={700}
              color="#FF4438"
              className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
            >
              {avgOfTHDData || 0}%
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                color="#8D8D8D"
                fontWeight={300}
                fontSize={8}
                className="Mediumlg:text-[6px] mt-[7px]"
              >
                Average Value
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

const FrequencyLayout = React.memo(
  ({ frequencyChartData, avgOfFrequencyData, onClickBar,FrequencyFunction}) => {
    const chartData = React.useMemo(() => {
      return frequencyChartData
        .filter((item) => item.pv !== 0)
        .map((elMap) => ({
          ...elMap,
          fill: getBarColor(SLUG_FREQUENCY_BARCHART, elMap.start),
        }));
    }, [frequencyChartData]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      fetchConsumptionPattern,
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [equipmentList, setEquipmentList] = useState([
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }

    useEffect(() => {
      if (nodeMetaData) {
        let output = Object.keys(nodeMetaData).map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


          return {
            name: node_name,
            node_id: node_id,
            checked: false
          }
        });


        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('Frequency');

            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

            setEquipmentList(updatedOutput);


          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }

    }, [nodeMetaData])


    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };



    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyPowerParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
      let selectedPayload = {
        "widget_id": "Frequency",
        "preferences": {
          selectedParams
        }
      }
  
      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';
  
      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
      let searchPayloads = searchData.map((data) => data.node_id);
      // await fetchHistoryProjectList(searchPayloads, filterType);
      await postNodeParams(selectedPayload)
      FrequencyFunction()
      setAnchorEl(null);
    }
    return (
      <Box
        p="1%"
        mb="1%"
        width="19%"
        bgcolor={"#fff"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
        className="Mediumlg:!mb-0"
      >
        <Box
          display="flex"
          gap={1}
          alignItems="start"
          className="flex-col Mediumlg:flex-row  Mediumlg:justify-between"
        >
          <Box display="flex" gap={1}>
            <GraphicEqIcon
              sx={{
                borderRadius: "50%",
                bgcolor: "rgba(217, 217, 217, 0.2)",
                padding: 0.5,
              }}
              className="Mediumlg:h-[18px] Mediumlg:w-[18px] Mediumlg:p-[2px]"
            />
            <Box display="flex" gap={1} alignItems="center">
              <Typography
                fontSize={14}
                fontWeight={500}
                color="rgba(0, 0, 0, 0.7)"
                className="Mediumlg:text-[12px]"
              >
                Frequency
              </Typography>
              <Tooltip
                title="Represents the historical distribution chart of Frequency Hz of the active equipements."
                arrow
                placement="right"
              >
                <PriorityHighIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "#E6E6E6",
                    padding: 0.2,
                    color: "white",
                    fontSize: "13px",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          {frequencyChartData.filter((item) => item.pv !== 0).length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              className="Mediumlg:pr-[5px]"
            >
              <Box
                display="flex"
                gap={1}
                className="Mediumlg:flex-col Mediumlg:max-w-[50px]"
              >
                <Typography
                  fontSize={24}
                  fontWeight={700}
                  color={getBarColor(
                    SLUG_FREQUENCY_BARCHART,
                    avgOfFrequencyData
                  )}
                  className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
                >
                  {avgOfFrequencyData}Hz
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    color="#8D8D8D"
                    fontWeight={300}
                    fontSize={8}
                    className="Mediumlg:text-[6px]"
                  >
                    Average Value
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

<SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                     <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                      <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>

        </Box>
        <Box
          width="100%"
          className="h-[calc(100%-68px)] Mediumlg:h-[calc(100%-18px)]"
        >
          {frequencyChartData.filter((item) => item.pv !== 0).length > 0 ? (
            <CommonResponsiveBarLayout
              data={chartData}
              tooltipText={"Frequency"}
              onClickBar={(e) => onClickBar(e, "frequency")}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              flexDirection={"column"}
              height="100%"
            >
              <Typography
                fontSize={24}
                fontWeight={700}
                color="#07D7B1"
                className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
              >
                {avgOfFrequencyData || 0}Hz
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  color="#8D8D8D"
                  fontWeight={300}
                  fontSize={8}
                  className="Mediumlg:text-[6px] mt-[7px]"
                >
                  Average Value
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

const VoltageLayout = React.memo(
  ({ voltageChartData, avgOfVoltageData, onClickBar,voltageFunction}) => {
    const chartData = React.useMemo(() => {
      return voltageChartData
        .filter((item) => item.pv !== 0)
        .map((elMap) => ({
          ...elMap,
          fill: getBarColor(SLUG_VOLTAGE_BARCHART, elMap.start),
        }));
    }, [voltageChartData]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
      fetchConsumptionPattern,
      postNodeParams,
      fetchSelectedNodeParams
    } = useDashboardHook();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [equipmentList, setEquipmentList] = useState([
    ]);

    const { filters, nodeMetaData } = useSelector(state => state.equipment);

    const updateCheckedStatus = (outputArr, selectParamsArr = []) => {
      // Create a Set of names present in selectParamsArr for faster lookup
      const selectParamsNames = new Set(selectParamsArr.map(item => item.name));

      // Iterate through 'output' array and update 'checked' value if the name exists in selectParamsNames
      const updatedOutput = outputArr?.map(item => {
        if (item.name && selectParamsNames.has(item.name)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return updatedOutput;
    }

    useEffect(() => {
      if (nodeMetaData) {
        let output = Object.keys(nodeMetaData).map((node_id) => {
          let node_data = nodeMetaData[node_id];
          let node_name = node_data.metadata.node_name ? node_data.metadata.node_name : `Node - ${node_id.slice(0, 5)}`;


          return {
            name: node_name,
            node_id: node_id,
            checked: false
          }
        });


        async function fetchData() {
          try {
            const selectParams = await fetchSelectedNodeParams('Voltage');

            let updatedOutput = await updateCheckedStatus(output, selectParams?.selectedParams);
            let alldataCheckedStatus = updatedOutput?.every(data => data.checked)

            updatedOutput = [{ name: "All Data", checked: alldataCheckedStatus, node_id: 0 }, ...updatedOutput];

            setEquipmentList(updatedOutput);


          } catch (error) {
            console.error('Error occurred:', error);
          }
        }

        fetchData();
      }

    }, [nodeMetaData])


    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (event, equipmentName = null) => {
      const updatedList = [...equipmentList];
      let selectedIndex = updatedList.findIndex(data => data.node_id === equipmentName)

      updatedList[selectedIndex].checked = !updatedList[selectedIndex].checked;
      if (equipmentName === 0) {
        const allChecked = updatedList[0].checked;
        for (let i = 1; i < updatedList.length; i++) {
          updatedList[i].checked = allChecked;
        }
      } else {
        // If an individual checkbox is unchecked, uncheck "All Data" checkbox 
        updatedList.find(data => data.name === 'All Data').checked = false;
      }

      setEquipmentList(updatedList);
    };



    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredEquipmentList = equipmentList.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onApplyPowerParams = async () => {

      let searchData;
      let selectedParams = equipmentList.filter((item) => item.checked == true && item.name.toLowerCase() !== 'all data')
      let selectedPayload = {
        "widget_id": "Voltage",
        "preferences": {
          selectedParams
        }
      }
  
      let filterType = (equipmentList.length / 2) > selectedParams.length ? 'include' : 'exclude';
  
      if (filterType === 'include') {
        searchData = equipmentList.filter((item) => item.checked == true && item.name?.toLowerCase() !== 'all data')
      } else {
        searchData = equipmentList.filter((item) => item.checked != true && item.name?.toLowerCase() !== 'all data')
      }
      let searchPayloads = searchData.map((data) => data.node_id);
      // await fetchHistoryProjectList(searchPayloads, filterType);
      await postNodeParams(selectedPayload)
      voltageFunction()
      setAnchorEl(null);
    }
    return (
      <Box
        p="1%"
        mb="1%"
        width="19%"
        bgcolor={"#fff"}
        boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
        borderRadius={5}
        className="Mediumlg:!mb-0"
      >
        <Box
          display="flex"
          gap={1}
          alignItems="start"
          className="flex-col Mediumlg:flex-row  Mediumlg:justify-between"
        >
          <Box display="flex" gap={1}>
            <ElectricMeterIcon
              sx={{
                borderRadius: "50%",
                bgcolor: "rgba(217, 217, 217, 0.2)",
                padding: 0.5,
              }}
              className="Mediumlg:h-[18px] Mediumlg:w-[18px] Mediumlg:p-[2px]"
            />
            <Box display="flex" gap={1} alignItems="center">
              <Typography
                fontSize={14}
                fontWeight={500}
                color="rgba(0, 0, 0, 0.7)"
                className="Mediumlg:text-[12px]"
              >
                Voltage
              </Typography>
              <Tooltip
                title="Represents the historical distribution chart of Voltage V of the active equipements."
                arrow
                placement="right"
              >
                <PriorityHighIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "#E6E6E6",
                    padding: 0.2,
                    color: "white",
                    fontSize: "13px",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          {voltageChartData.filter((item) => item.pv !== 0).length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              className="Mediumlg:pr-[5px]"
            >
              <Box
                display="flex"
                gap={1}
                className="Mediumlg:flex-col Mediumlg:max-w-[50px]"
              >
                <Typography
                  fontSize={24}
                  fontWeight={700}
                  color={getBarColor(SLUG_VOLTAGE_BARCHART, avgOfVoltageData)}
                  className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
                >
                  {avgOfVoltageData}V
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    color="#8D8D8D"
                    fontWeight={300}
                    fontSize={8}
                    className="Mediumlg:text-[6px]"
                  >
                    Average Value
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <SettingsOutlinedIcon
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        padding: 0.5,
                        color: "#808080d1",
                      }}
                      style={{ cursor: "pointer" }}
                    />
                     <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: "10px", height: "500px", width: "411px" } }}
                    className="setting_Popover"
                  >
                    <Typography sx={{ p: 2 }}>
                      <h3>Total Power</h3>
                      <div className="setting_Popover_Search">
                        <input
                          type="text"
                          placeholder="Select Equipments"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <SearchOutlinedIcon
                          sx={{ padding: 0.5, color: "#808080d1" }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="nodecontainer-scroll">
                      <FormGroup>
                            {filteredEquipmentList.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={(event) => handleCheckboxChange(event, item.node_id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                      </div>
                      <div className="setting_Popover_btn">
                        <button type="button" onClick={onApplyPowerParams}>Apply</button>
                        <button type="button" onClick={handleClose}>Cancel</button>
                      </div>
                    </Typography>
                  </Popover>
        </Box>
        <Box
          width="100%"
          className="h-[calc(100%-68px)] Mediumlg:h-[calc(100%-18px)]"
        >
          {voltageChartData.filter((item) => item.pv !== 0).length > 0 ? (
            <CommonResponsiveBarLayout
              data={chartData}
              tooltipText={"Voltage"}
              onClickBar={(e) => onClickBar(e, "voltageValue")}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              flexDirection={"column"}
              height="100%"
            >
              <Typography
                fontSize={24}
                fontWeight={700}
                color="#07D7B1"
                className="Mediumlg:text-[14px] Mediumlg:mt-[4px] Mediumlg:leading-[0.1]"
              >
                {avgOfVoltageData || 0}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  color="#8D8D8D"
                  fontWeight={300}
                  fontSize={8}
                  className="Mediumlg:text-[6px] mt-[7px]"
                >
                  Average Value
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

const RELOAD_API_TIME = 2;
const componentList = [
  { title: "Voltage (v)", value: "voltageValue" },
  { title: "Current (A)", value: "currentValue" },
  { title: "Total Current", value: "totalcurrentValue" },
  { title: "THD", value: "maximumTHD" },
  { title: "Frequency", value: "frequency" },
  { title: "Power Factor", value: "totalPowerFactorValue" },
  { title: "Power Factor average", value: "powerFactorValue" },
];

const csvHeader = [
  { label: "Time", key: "name" },
  { label: "Value", key: "csv_expVal" },
];

// const projectListPayload = {
//     paramid: [
//         process.env.REACT_APP_TOTAL_POWER_ID
//     ],
//     from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
//     to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
//     limit: 110000,
//     order: "asc"
// }

const Dashboard = React.memo(() => {
  const { logoutUser } = useLoginHook();
  const { fetchNodeMetaData } = useEquipmentHook();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGetDeviceInstParam } = bindActionCreators(
    DashboardApis,
    dispatch
  );

  const { token } = useSelector((state) => state.auth);
  const {
    nodeList,
    instEnergy,
    nodeListCallTime,
    historyProjectList,
    consumptionChartSelectedDate,
    consumptionChartSelectedMonth,
    consumptionPattern,
    nodeListParam,
    deviceInstParam,
    dashboardReload,
    projectParam,
  } = useSelector((state) => state.dashboard);
  const { callFunctions } = useSelector((state) => state.equipment);

  const [firstDayMonth, setFirstDayMonth] = React.useState(
    Math.floor(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
          0,
          0,
          0,
          0
        )
      ) / 1000
    )
  );
  const [lastDayMonth, setLastDayMonth] = React.useState(
    Math.floor(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        )
      ) / 1000
    )
  );
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [reloadGraph, setReloadGraph] = React.useState(false);
  const [activeEquipmentList, setActiveEquipmentList] = React.useState([]);
  const [projectListChartData, setProjectListChartData] = React.useState([]);
  const [powerFectorChartData, setPowerFectorChartData] = React.useState([]);
  const [tHDChartData, setTHDChartData] = React.useState([]);
  const [voltageChartData, setVoltageChartData] = React.useState([]);
  const [currentChartData, setCurrentChartData] = React.useState([]);

  const [frequencyChartData, setFrequencyChartData] = React.useState([]);
  const [projectChartData, setProjectChartData] = React.useState({});
  const [cpTotalChartData, setCpTotalChartData] = React.useState();
  const [cPChartTime, setCPChartTime] = React.useState();
  const [hPChartTime, setHPChartTime] = React.useState();
  const [hplastTime, setHplastTime] = React.useState();
  const [consumptionPatternChartData, setConsumptionPatternChartData] =
    React.useState([]);
  const [avgOfCurrentData, setAvgOfCurrentData] = React.useState();
  const [avgOfTHDData, setAvgOfTHDData] = React.useState();
  const [avgOfPowerFectorData, setAvgOfPowerFectorData] = React.useState();
  const [avgOfFrequencyData, setAvgOfFrequencyData] = React.useState();
  const [avgOfVoltageData, setAvgOfVoltageData] = React.useState();

  const [historyTimeConsumtionList, setHistoryTimeConsumtionList] = React.useState([]);


  const [timeConsumptionSelectedNodeId, setTimeConsumptionSelectedNodeId] = React.useState();
  const [dayConsumptionSelectedNodeId, setDayConsumptionSelectedNodeId] = React.useState();


  const [reloadDashboardInterval, setReloadDashboardInterval] =
    React.useState(false);
  const [allNodeList, setAllNodeList] = React.useState([]);
  const [projectListPayload, setprojectListPayload] = React.useState({

  });
  const [consumptionPatternPayload, setConsumptionPatternPayload] =
    React.useState({
      // from: Math.floor(
      //   new Date(
      //     Date.UTC(
      //       new Date().getFullYear(),
      //       new Date().getMonth(),
      //       1,
      //       0,
      //       0,
      //       0,
      //       0
      //     )
      //   ) / 1000
      // ),
      // to: Math.floor(
      //   new Date(
      //     Date.UTC(
      //       new Date().getFullYear(),
      //       new Date().getMonth() + 1,
      //       0,
      //       23,
      //       59,
      //       59,
      //       999
      //     )
      //   ) / 1000
      // ),
      // nodes: [],
      // type: "exclude"
    });
  const {
    fetchNodeList,
    fetchInstEnergyList,
    fetchHistoryProjectList,
    fetchConsumptionPattern,
    fetchNodeListParam,
    fetchSelectedNodeParams,
    autoRefreshDashboard,
  } = useDashboardHook();



  let storageNodeList = localStorage.getItem("nodeList");

  Array.prototype.max = function () {
    return Math.max.apply(null, this);
  };

  Array.prototype.min = function () {
    return Math.min.apply(null, this);
  };

  useEffect(() => {
    if (storageNodeList) {
      setAllNodeList(JSON.parse(storageNodeList));
    }
  }, [storageNodeList]);


  useEffect(()=> {
    if (consumptionChartSelectedMonth !== null){
      setCurrentYear(consumptionChartSelectedMonth.yearValue);
      setCurrentMonth(consumptionChartSelectedMonth.monthvalue);

      // setFirstDayMonth(consumptionChartSelectedDate.from);
      // setLastDayMonth(consumptionChartSelectedDate.to);
    }
  }, [consumptionChartSelectedMonth])

  useEffect(() => {


    async function fetchData() {
      try {
        const timeConsumptionParams = await fetchSelectedNodeParams('time_Consumption');
        let timeConsumptionNodeId = timeConsumptionParams?.selectedParams.map(data => data.node_id);
        setTimeConsumptionSelectedNodeId(timeConsumptionNodeId);

        const dayConsumptionParams = await fetchSelectedNodeParams('day_Consumption');
        let dayConsumptionNodeId = dayConsumptionParams?.selectedParams.map(data => data.node_id);
        setDayConsumptionSelectedNodeId(dayConsumptionNodeId)
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }

    fetchData();


  }, []);

  useEffect(() => {
    (async () => {

      const selectParams = await fetchSelectedNodeParams('day_Consumption');
      let nodeId = selectParams?.selectedParams.map(data => data.node_id);

      let consumptionStartingPayload = {
        from: Math.floor(
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              1,
              0,
              0,
              0,
              0
            )
          ) / 1000
        ),
        to: Math.floor(
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0,
              23,
              59,
              59,
              999
            )
          ) / 1000
        ),
        nodes: nodeId,
        type: "include"
        
      };

      if (nodeList.length == 0 || reloadGraph === true) {
        await fetchNodeList({ offset: 0, order: "asc" });
      }

      if (nodeListParam.length === 0 || reloadGraph === true) {
        await fetchNodeListParam({ limit: 100, offset: 0 });     
      }
      // console.log( historyProjectList,  'historyProjectList') 
      // if ((historyProjectList?.length === 0 && timeConsumptionSelectedNodeId)) {  
      //   let datafetch = await fetchHistoryProjectList(timeConsumptionSelectedNodeId, 'include');
      //   setHistoryTimeConsumtionList(datafetch)
      //   console.log(timeConsumptionSelectedNodeId, historyProjectList, datafetch,  'include') 

      // }

      // if (consumptionPattern?.length === 0 || reloadGraph === true) {
      //   await fetchConsumptionPattern(reloadGraph ? consumptionPatternPayload : consumptionStartingPayload);
      //   setReloadGraph(false);
      // }
     
      if(reloadGraph === true){
        setReloadGraph(false);
      }
    })();
  }, [
    historyProjectList,    
    nodeList,
    reloadGraph,
    consumptionPatternPayload,
    reloadDashboardInterval,
  ]);

  useEffect(() => {
    (async () => {
    const selectParams = await fetchSelectedNodeParams('day_Consumption');
    let nodeId = selectParams?.selectedParams.map(data => data.node_id);
    let consumptionStartingPayload = {
      from:consumptionChartSelectedDate?consumptionChartSelectedDate.from: Math.floor(
        new Date(
          Date.UTC(
            new Date().getFullYear(),
            new Date().getMonth(),
            1,
            0,
            0,
            0,
            0
          )
        ) / 1000
      ),
      to:consumptionChartSelectedDate?consumptionChartSelectedDate.to:Math.floor(
        new Date(
          Date.UTC(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
            23,
            59,
            59,
            999
          )
        ) / 1000
      ),
      nodes: nodeId,
      type: "include"
    };
    
    let currentDate = new Date();
let monthvalue = currentDate.getMonth();
var yearValue = currentDate.getFullYear();

let month_Date={
  monthvalue:monthvalue,
  yearValue:yearValue
}
    await fetchConsumptionPattern(consumptionStartingPayload);
    dispatch(setConsumptionChartSelectedDateAction(consumptionStartingPayload))
    // dispatch(setConsumptionChartSelectedMonthAction(month_Date))

  })();
  }, []);


// useEffect(() => {
//   (async () => {
//   const selectParams = await fetchSelectedNodeParams('day_Consumption');
//   let nodeId = selectParams?.selectedParams.map(data => data.node_id);
//   let consumptionStartingPayload = {
//     from: Math.floor(
//       new Date(
//         Date.UTC(
//           new Date().getFullYear(),
//           new Date().getMonth(),
//           1,
//           0,
//           0,
//           0,
//           0
//         )
//       ) / 1000
//     ),
//     to: Math.floor(
//       new Date(
//         Date.UTC(
//           new Date().getFullYear(),
//           new Date().getMonth() + 1,
//           0,
//           23,
//           59,
//           59,
//           999
//         )
//       ) / 1000
//     ),
//     nodes: nodeId,
//     type: "include"

//   };
//   await fetchConsumptionPattern(consumptionStartingPayload);
//   let currentDate = new Date();
// let currentMonth = currentDate.getMonth();
// var currentYear = currentDate.getFullYear();
// setCurrentYear(currentYear);
// setCurrentMonth(currentMonth);
// })();

// }, []);

  useEffect(() => {
    (async () => {
      if (historyProjectList?.length === 0 && timeConsumptionSelectedNodeId) {
        let datafetch = await fetchHistoryProjectList(timeConsumptionSelectedNodeId, 'include');
        setHistoryTimeConsumtionList(datafetch)
      }
    })();
  }, [
    reloadGraph,
    timeConsumptionSelectedNodeId
  ]);


  useEffect(() => {
    (async () => {
      if (
        callFunctions == true ||
        (allNodeList.length > 0 && deviceInstParam.length == 0)
      ) {
        if (nodeListParam.length > 0) {
          let nodeListFixParam = ["thdv3", "thdv2", "thdv1"];
          let allnodeListParams = [];

          await Promise.all(
            nodeListParam.map(async (val, key) => {
              if (nodeListFixParam.includes(val.name)) {
                let temp_nodeList = JSON.parse(storageNodeList);
                let payloadData = devideArray(temp_nodeList);
                let payloadParamData = val.param_id;
                let res = [];
                if (payloadData && payloadData.length > 0) {
                  await Promise.all(
                    payloadData.map(async (val, i) => {
                      await handleGetDeviceInstParam(token, {
                        nodes: val,
                        paramid: payloadParamData,
                      })
                        .then((response) => {
                          if (
                            response.success &&
                            response?.data &&
                            response?.data !== null
                          ) {
                            res = [...res, ...response.data];
                          } else {
                            res = [...res];
                          }
                        })
                        .catch((err) => {
                          const error = err?.response?.data;
                          if (
                            error?.description?.includes("token is expired")
                          ) {
                            logoutUser();
                          }
                          throw err;
                        });
                    })
                  );
                }

                allnodeListParams.push(res);
              }
            })
          );

          dispatch(setDeviceTHDAction(allnodeListParams));
          var d = allnodeListParams[0]?.concat(
            allnodeListParams[1],
            allnodeListParams[2]
          );
          if (d.length > 0) {
            dispatch(setDeviceInstParamAction(d));
            dispatch(setCallDashboardFunctions(false));
          }
        }
      }
    })();
  }, [allNodeList, nodeListParam, callFunctions]);

  // useEffect(() => {
  //     setInterval(function () {
  //         if (dashboardReload) {
  //             console.log('Testing For timer After Every 5 minutes')
  //             setReloadDashboardInterval(!reloadDashboardInterval)
  //         }
  //     }, process.env.REACT_APP_AUTO_RELOAD_TIME * 60 * 1000);
  // }, [dashboardReload])
console.log(deviceInstParam,"setDeviceInstParamAction deviceInstParam")

const thdFunction= async()=>{
if (deviceInstParam.length > 0) {
  let nodesData =[];
  const selectParams = await fetchSelectedNodeParams('THD');
  selectParams?.selectedParams.map((data) =>nodesData.push(data.node_id));

  let finalData=deviceInstParam.filter((data)=>nodesData.includes(data.node))
console.log(finalData,"finalDataThD")

  let temp = [];
  finalData.map((val, k) => {
    if (temp.find((e) => e.node === val.node)) {
      let oj = temp.find((e) => e.node === val.node);
      if (oj.value < val.value) {
        let ind = temp.findIndex((e) => e.node === val.node);
        temp[ind] = val;
      }
    } else {
      temp.push(val);
    }
  });

  let tempTHDArray = [];
  temp.map((val, k) => {
    tempTHDArray.push(val.value);
  });

  if (tempTHDArray.length > 0) {
    let avgOfTHD = sumAvg(tempTHDArray);
    setAvgOfTHDData(avgOfTHD);
  }

  let maxValueTHD = tempTHDArray.max();
  let minValueTHD = tempTHDArray.min();
  if (minValueTHD < 0) {
    minValueTHD = tempTHDArray.min() * -1;
  }
  let rangeTHD = Number((maxValueTHD - minValueTHD) / 9);
  let temparrTHD = [];
  let tempMinValueTHD = minValueTHD;

  if (rangeTHD != null && rangeTHD != "" && rangeTHD > 0) {
    for (var i = tempMinValueTHD; tempMinValueTHD < maxValueTHD; i++) {
      let tempObj = {};
      tempObj.start = Number(tempMinValueTHD);
      tempObj.end = Number(Number(tempMinValueTHD) + Number(rangeTHD));
      tempObj.count = 0;
      tempMinValueTHD = Number(
        Number(tempMinValueTHD) + Number(rangeTHD)
      );
      temparrTHD.push(tempObj);
    }
  }
  tempTHDArray.map((val, key) => {
    let tempcheckTHDval = 0;
    var filteredArray = temparrTHD.filter(function (itm) {
      if (
        Number(itm.start) <= val &&
        Number(itm.end) >= val &&
        tempcheckTHDval == 0
      ) {
        itm.count = Number(itm.count + 1);
        tempcheckTHDval = 1;
      }
    });
  });
  let tHDArray = [];
  temparrTHD.map((val, key) => {
    let tempObj = {};
    if (0 === key) {
      tempObj.name = Number(minValueTHD).toFixed(2);
    } else if (temparrTHD.length - 1 === key) {
      tempObj.name = Number(maxValueTHD).toFixed(2);
    } else {
      tempObj.name = "";
    }
    tempObj.start = Number(val.start).toFixed(3);
    tempObj.end = Number(val.end).toFixed(3);
    tempObj.pv = Number(val?.count);
    tHDArray.push(tempObj);
  });
  if (tHDArray.length > 0) {
    setTHDChartData(tHDArray);
  } else {
    for (var i = 1; i < 10; i++) {
      let tempObj = {};
      tempObj.name = "";
      tempObj.pv = Number(0);
      tempObj.start = 0;
      tempObj.end = 0;
      tHDArray.push(tempObj);
    }
    setTHDChartData(tHDArray);
  }
} else {
  let tHDArray = [];
  for (var i = 1; i < 10; i++) {
    let tempObj = {};
    tempObj.name = "";
    tempObj.pv = Number(0);
    tempObj.start = 0;
    tempObj.end = 0;
    tHDArray.push(tempObj);
  }

  let avgOfTHD = "";
  setAvgOfTHDData(avgOfTHD);
  setTHDChartData(tHDArray);
}
}
  useEffect(() => {
    (async () => {
      thdFunction()
      // if (deviceInstParam.length > 0) {
      //   let temp = [];
      //   deviceInstParam.map((val, k) => {
      //     if (temp.find((e) => e.node === val.node)) {
      //       let oj = temp.find((e) => e.node === val.node);
      //       if (oj.value < val.value) {
      //         let ind = temp.findIndex((e) => e.node === val.node);
      //         temp[ind] = val;
      //       }
      //     } else {
      //       temp.push(val);
      //     }
      //   });

      //   let tempTHDArray = [];
      //   temp.map((val, k) => {
      //     tempTHDArray.push(val.value);
      //   });

      //   if (tempTHDArray.length > 0) {
      //     let avgOfTHD = sumAvg(tempTHDArray);
      //     setAvgOfTHDData(avgOfTHD);
      //   }

      //   let maxValueTHD = tempTHDArray.max();
      //   let minValueTHD = tempTHDArray.min();
      //   if (minValueTHD < 0) {
      //     minValueTHD = tempTHDArray.min() * -1;
      //   }
      //   let rangeTHD = Number((maxValueTHD - minValueTHD) / 9);
      //   let temparrTHD = [];
      //   let tempMinValueTHD = minValueTHD;

      //   if (rangeTHD != null && rangeTHD != "" && rangeTHD > 0) {
      //     for (var i = tempMinValueTHD; tempMinValueTHD < maxValueTHD; i++) {
      //       let tempObj = {};
      //       tempObj.start = Number(tempMinValueTHD);
      //       tempObj.end = Number(Number(tempMinValueTHD) + Number(rangeTHD));
      //       tempObj.count = 0;
      //       tempMinValueTHD = Number(
      //         Number(tempMinValueTHD) + Number(rangeTHD)
      //       );
      //       temparrTHD.push(tempObj);
      //     }
      //   }
      //   tempTHDArray.map((val, key) => {
      //     let tempcheckTHDval = 0;
      //     var filteredArray = temparrTHD.filter(function (itm) {
      //       if (
      //         Number(itm.start) <= val &&
      //         Number(itm.end) >= val &&
      //         tempcheckTHDval == 0
      //       ) {
      //         itm.count = Number(itm.count + 1);
      //         tempcheckTHDval = 1;
      //       }
      //     });
      //   });
      //   let tHDArray = [];
      //   temparrTHD.map((val, key) => {
      //     let tempObj = {};
      //     if (0 === key) {
      //       tempObj.name = Number(minValueTHD).toFixed(2);
      //     } else if (temparrTHD.length - 1 === key) {
      //       tempObj.name = Number(maxValueTHD).toFixed(2);
      //     } else {
      //       tempObj.name = "";
      //     }
      //     tempObj.start = Number(val.start).toFixed(3);
      //     tempObj.end = Number(val.end).toFixed(3);
      //     tempObj.pv = Number(val?.count);
      //     tHDArray.push(tempObj);
      //   });
      //   if (tHDArray.length > 0) {
      //     setTHDChartData(tHDArray);
      //   } else {
      //     for (var i = 1; i < 10; i++) {
      //       let tempObj = {};
      //       tempObj.name = "";
      //       tempObj.pv = Number(0);
      //       tempObj.start = 0;
      //       tempObj.end = 0;
      //       tHDArray.push(tempObj);
      //     }
      //     setTHDChartData(tHDArray);
      //   }
      // } else {
      //   let tHDArray = [];
      //   for (var i = 1; i < 10; i++) {
      //     let tempObj = {};
      //     tempObj.name = "";
      //     tempObj.pv = Number(0);
      //     tempObj.start = 0;
      //     tempObj.end = 0;
      //     tHDArray.push(tempObj);
      //   }

      //   let avgOfTHD = "";
      //   setAvgOfTHDData(avgOfTHD);
      //   setTHDChartData(tHDArray);
      // }
    })();
  }, [deviceInstParam]);
  console.log(nodeList,"nodeList")

  

  useEffect(() => {
    (async () => {
      if (
        (allNodeList.length > 0 && Object.values(instEnergy).length === 0) ||
        reloadGraph === true
      ) {
        await fetchInstEnergyList({ nodes: nodeList });
      }
    })();
  }, [allNodeList, reloadGraph, reloadDashboardInterval]);
console.log(instEnergy,"instEnergy")

console.log(Object.entries(instEnergy),"Object.entries(instEnergy)")

const currentFunction=async ()=>{
  if (Object.values(instEnergy).length > 0) {
    let nodesData =[];
        const selectParams = await fetchSelectedNodeParams('Current');
        selectParams?.selectedParams.map((data) =>nodesData.push(data.node_id));       
     const filteredCurrent = Object.fromEntries(
        Object.entries(instEnergy).filter(([key]) => nodesData.includes(key))
    );
      console.log(filteredCurrent,"filteredCurrent")

      let activeCurrentList = Object.values(filteredCurrent).filter(
        (e) => checkEquipmetIsActive(e.timestamp * 1000) === true
      );
      let tempCurrentArray = [];
      activeCurrentList.map((val,key)=>{
        let currentData = Number(val.i1 + val.i2 + val.i3);
        tempCurrentArray.push(Number(currentData));
      })

      let avgOfCurrent = sumAvg(tempCurrentArray);
      setAvgOfCurrentData(avgOfCurrent);
let maxValueCurr = tempCurrentArray.max();
      let minValueCurr = tempCurrentArray.min();
      // if (minValueCurr < 0) {
      //   minValueCurr = tempCurrentArray.min() * -1
      // }
      let Currentrange = Number((maxValueCurr - minValueCurr) / 9);

      let tempCurrArr = [];
      let tempMinValueCurr = minValueCurr;
      if (Currentrange != null && Currentrange != "" && Currentrange > 0) {
        for (var i = tempMinValueCurr; tempMinValueCurr < maxValueCurr; i++) {
          let tempObj = {};
          tempObj.start = Number(tempMinValueCurr);
          tempObj.end = Number(Number(tempMinValueCurr) + Number(Currentrange));
          tempObj.count = 0;
          tempMinValueCurr = Number(
            Number(tempMinValueCurr) + Number(Currentrange)
          );
          tempCurrArr.push(tempObj);
        }
      }

      tempCurrentArray.map((val, key) => {
        let tempcheckCurrval = 0;
        var filteredArray = tempCurrArr.filter(function (itm) {
          if (
            Number(itm.start) <= val &&
            Number(itm.end) >= val &&
            tempcheckCurrval == 0
          ) {
            itm.count = Number(itm.count + 1);
            tempcheckCurrval = 1;
          }
        });
      });

      let currentArray = [];
      tempCurrArr.map((val, key) => {
        let tempObj = {};
        if (0 === key) {
          tempObj.name = Number(minValueCurr).toFixed(2);
        } else if (tempCurrArr.length - 1 === key) {
          tempObj.name = Number(maxValueCurr).toFixed(2);
        } else {
          tempObj.name = "";
        }
        tempObj.start = Number(val.start).toFixed(3);
        tempObj.end = Number(val.end).toFixed(3);
        tempObj.pv = Number(val?.count);
        currentArray.push(tempObj);
      });

      if (currentArray.length > 0) {
        setCurrentChartData(currentArray);
      } else {
        for (var i = 1; i < 10; i++) {
          let tempObj = {};
          tempObj.name = "";
          tempObj.pv = Number(0);
          tempObj.start = 0;
          tempObj.end = 0;
          currentArray.push(tempObj);
        }
        setCurrentChartData(currentArray);
      }

  }
  }
const powerFactorFunction=async ()=>{
  if (Object.values(instEnergy).length > 0) {
    let nodesData =[];
        const selectParams = await fetchSelectedNodeParams('Power_Factor');
        selectParams?.selectedParams.map((data) =>nodesData.push(data.node_id));       
     const filteredPower = Object.fromEntries(
        Object.entries(instEnergy).filter(([key]) => nodesData.includes(key))
    );
      console.log(filteredPower,"filteredPower")

      let activePowerList = Object.values(filteredPower).filter(
        (e) => checkEquipmetIsActive(e.timestamp * 1000) === true
      );

      let tempavgPfArray = [];
      activePowerList.map((val, key) => {
        let avgPf = Number((val.pf1 + val.pf2 + val.pf3) / 3);
        tempavgPfArray.push(Number(avgPf));
      });
      let avgOfPowerFector = sumAvg(tempavgPfArray);
      setAvgOfPowerFectorData(avgOfPowerFector);

      let maxValuePF = tempavgPfArray.max();
      let minValuePF = tempavgPfArray.min();

      let range = Number((maxValuePF - minValuePF) / 9);
      let temparr = [];
      let tempMinValuePF = minValuePF;

      if (range != null && range != "" && range > 0) {
        for (var i = tempMinValuePF; tempMinValuePF < maxValuePF; i++) {
          let tempObj = {};
          tempObj.start = Number(tempMinValuePF);
          tempObj.end = Number(Number(tempMinValuePF) + Number(range));
          tempObj.count = 0;
          tempMinValuePF = Number(Number(tempMinValuePF) + Number(range));
          temparr.push(tempObj);
        }
      }

      tempavgPfArray.map((val, key) => {
        let tempcheckPFval = 0;
        var filteredArray = temparr.filter(function (itm) {
          if (
            Number(itm.start) <= val &&
            Number(itm.end) >= val &&
            tempcheckPFval == 0
          ) {
            itm.count = Number(itm.count + 1);
            tempcheckPFval = 1;
          }
        });
      });

      let powerFectorArray = [];
      temparr.map((val, key) => {
        let tempObj = {};
        if (0 === key) {
          tempObj.name = Number(minValuePF).toFixed(2);
        } else if (temparr.length - 1 === key) {
          tempObj.name = Number(maxValuePF).toFixed(2);
        } else {
          tempObj.name = "";
        }
        tempObj.start = Number(val.start).toFixed(3);
        tempObj.end = Number(val.end).toFixed(3);
        tempObj.pv = Number(val?.count);
        powerFectorArray.push(tempObj);
      });

      if (powerFectorArray.length > 0) {
        setPowerFectorChartData(powerFectorArray);
      } else {
        for (var i = 1; i < 10; i++) {
          let tempObj = {};
          tempObj.name = "";
          tempObj.pv = Number(0);
          tempObj.start = 0;
          tempObj.end = 0;
          powerFectorArray.push(tempObj);
        }
        setPowerFectorChartData(powerFectorArray);
      }
  }
}

const FrequencyFunction= async()=>{
  if (Object.values(instEnergy).length > 0) {
    let nodesData =[];
    const selectParams = await fetchSelectedNodeParams('Frequency');
    selectParams?.selectedParams.map((data) =>nodesData.push(data.node_id));       
 const filteredFrequency = Object.fromEntries(
    Object.entries(instEnergy).filter(([key]) => nodesData.includes(key))
);
  console.log(filteredFrequency,"filteredFrequency")

  let activeFrequencyList = Object.values(filteredFrequency).filter(
    (e) => checkEquipmetIsActive(e.timestamp * 1000) === true
  );
  let tempFrequencyArray = [];
  activeFrequencyList.map((val, key) => {
    tempFrequencyArray.push(Number(val.frequency));
  });
  let avgOfFrequency = sumAvg(tempFrequencyArray);
  setAvgOfFrequencyData(avgOfFrequency);

  let maxValueFreq = tempFrequencyArray.max();
      let minValueFreq = tempFrequencyArray.min();
      // if (minValueFreq < 0) {
      //   minValueFreq = tempFrequencyArray.min() * -1
      // }
      let frequencyrange = Number((maxValueFreq - minValueFreq) / 9);

      let tempFreqArr = [];
      let tempMinValueFreq = minValueFreq;
      if (
        frequencyrange != null &&
        frequencyrange != "" &&
        frequencyrange > 0
      ) {
        for (var i = tempMinValueFreq; tempMinValueFreq < maxValueFreq; i++) {
          let tempObj = {};
          tempObj.start = Number(tempMinValueFreq);
          tempObj.end = Number(
            Number(tempMinValueFreq) + Number(frequencyrange)
          );
          tempObj.count = 0;
          tempMinValueFreq = Number(
            Number(tempMinValueFreq) + Number(frequencyrange)
          );
          tempFreqArr.push(tempObj);
        }
      }

      tempFrequencyArray.map((val, key) => {
        let tempcheckFreqval = 0;
        var filteredArray = tempFreqArr.filter(function (itm) {
          if (
            Number(itm.start) <= val &&
            Number(itm.end) >= val &&
            tempcheckFreqval == 0
          ) {
            itm.count = Number(itm.count + 1);
            tempcheckFreqval = 1;
          }
        });
      });

      let frequencyArray = [];
      tempFreqArr.map((val, key) => {
        let tempObj = {};
        if (0 === key) {
          tempObj.name = Number(minValueFreq).toFixed(2);
        } else if (tempFreqArr.length - 1 === key) {
          tempObj.name = Number(maxValueFreq).toFixed(2);
        } else {
          tempObj.name = "";
        }
        tempObj.start = Number(val.start).toFixed(3);
        tempObj.end = Number(val.end).toFixed(3);
        tempObj.pv = Number(val?.count);
        frequencyArray.push(tempObj);
      });

      if (frequencyArray.length > 0) {
        setFrequencyChartData(frequencyArray);
      } else {
        for (var i = 1; i < 10; i++) {
          let tempObj = {};
          tempObj.name = "";
          tempObj.pv = Number(0);
          tempObj.start = 0;
          tempObj.end = 0;
          frequencyArray.push(tempObj);
        }
        setFrequencyChartData(frequencyArray);
      }

  }
}

const voltageFunction =async()=>{
  if (Object.values(instEnergy).length > 0) {
    let nodesData =[];
    const selectParams = await fetchSelectedNodeParams('Voltage');
    selectParams?.selectedParams.map((data) =>nodesData.push(data.node_id));       
 const filteredVoltage = Object.fromEntries(
    Object.entries(instEnergy).filter(([key]) => nodesData.includes(key))
);
  console.log(filteredVoltage,"filteredVoltage")

  let activeVoltageList = Object.values(filteredVoltage).filter(
    (e) => checkEquipmetIsActive(e.timestamp * 1000) === true
  );
  let tempVoltageArray = [];
  activeVoltageList.map((val, key) => {
    let voltageData = Number((val.v1 + val.v2 + val.v3) / 3);
    tempVoltageArray.push(Number(voltageData));
  });

  let avgOfVoltage = sumAvg(tempVoltageArray);
  setAvgOfVoltageData(avgOfVoltage);
  let maxValueVolt = tempVoltageArray.max();
  let minValueVolt = tempVoltageArray.min();
  // if (minValueVolt < 0) {
  //   minValueVolt = tempVoltageArray.min() * -1
  // }
  let Voltagerange = Number((maxValueVolt - minValueVolt) / 9);

  let tempVoltArr = [];
  let tempMinValueVolt = minValueVolt;
  if (Voltagerange != null && Voltagerange != "" && Voltagerange > 0) {
    for (var i = tempMinValueVolt; tempMinValueVolt < maxValueVolt; i++) {
      let tempObj = {};
      tempObj.start = Number(tempMinValueVolt);
      tempObj.end = Number(Number(tempMinValueVolt) + Number(Voltagerange));
      tempObj.count = 0;
      tempMinValueVolt = Number(
        Number(tempMinValueVolt) + Number(Voltagerange)
      );
      tempVoltArr.push(tempObj);
    }
  }

  tempVoltageArray.map((val, key) => {
    let tempcheckVoltval = 0;
    var filteredArray = tempVoltArr.filter(function (itm) {
      if (
        Number(itm.start) <= val &&
        Number(itm.end) >= val &&
        tempcheckVoltval == 0
      ) {
        itm.count = Number(itm.count + 1);
        tempcheckVoltval = 1;
      }
    });
  });

  let voltageArray = [];
  tempVoltArr.map((val, key) => {
    let tempObj = {};
    if (0 === key) {
      tempObj.name = Number(minValueVolt).toFixed(2);
    } else if (tempVoltArr.length - 1 === key) {
      tempObj.name = Number(maxValueVolt).toFixed(2);
    } else {
      tempObj.name = "";
    }
    tempObj.start = Number(val.start).toFixed(3);
    tempObj.end = Number(val.end).toFixed(3);
    tempObj.pv = Number(val?.count);
    voltageArray.push(tempObj);
  });

  if (voltageArray.length > 0) {
    setVoltageChartData(voltageArray);
  } else {
    for (var i = 1; i < 10; i++) {
      let tempObj = {};
      tempObj.name = "";
      tempObj.pv = Number(0);
      tempObj.start = 0;
      tempObj.end = 0;
      voltageArray.push(tempObj);
    }
    setVoltageChartData(voltageArray);
  }

  }
}


  useEffect(() => {
    if (Object.values(instEnergy).length > 0) {
    currentFunction()
    powerFactorFunction()
    FrequencyFunction()
    voltageFunction()
      let activeList = Object.values(instEnergy).filter(
        (e) => checkEquipmetIsActive(e.timestamp * 1000) === true
      );
      setActiveEquipmentList(activeList);
      // let tempavgPfArray = [];
      // let tempCurrentArray = [];
      // let tempFrequencyArray = [];
      // let tempVoltageArray = [];
  
      // activeList.map((val, key) => {
        // let avgPf = Number((val.pf1 + val.pf2 + val.pf3) / 3);
        // let voltageData = Number((val.v1 + val.v2 + val.v3) / 3);
        // let currentData = Number(val.i1 + val.i2 + val.i3);
        // tempavgPfArray.push(Number(avgPf));
        // tempFrequencyArray.push(Number(val.frequency));
        // tempCurrentArray.push(Number(currentData));
        // tempVoltageArray.push(Number(voltageData));
      // });

      // let avgOfCurrent = sumAvg(tempCurrentArray);
      // setAvgOfCurrentData(avgOfCurrent);

      // let avgOfPowerFector = sumAvg(tempavgPfArray);
      // setAvgOfPowerFectorData(avgOfPowerFector);

      // let avgOfFrequency = sumAvg(tempFrequencyArray);
      // setAvgOfFrequencyData(avgOfFrequency);

      // let avgOfVoltage = sumAvg(tempVoltageArray);
      // setAvgOfVoltageData(avgOfVoltage);

      // let maxValuePF = tempavgPfArray.max();
      // let minValuePF = tempavgPfArray.min();

      // let range = Number((maxValuePF - minValuePF) / 9);
      // let temparr = [];
      // let tempMinValuePF = minValuePF;

      // if (range != null && range != "" && range > 0) {
      //   for (var i = tempMinValuePF; tempMinValuePF < maxValuePF; i++) {
      //     let tempObj = {};
      //     tempObj.start = Number(tempMinValuePF);
      //     tempObj.end = Number(Number(tempMinValuePF) + Number(range));
      //     tempObj.count = 0;
      //     tempMinValuePF = Number(Number(tempMinValuePF) + Number(range));
      //     temparr.push(tempObj);
      //   }
      // }

      // tempavgPfArray.map((val, key) => {
      //   let tempcheckPFval = 0;
      //   var filteredArray = temparr.filter(function (itm) {
      //     if (
      //       Number(itm.start) <= val &&
      //       Number(itm.end) >= val &&
      //       tempcheckPFval == 0
      //     ) {
      //       itm.count = Number(itm.count + 1);
      //       tempcheckPFval = 1;
      //     }
      //   });
      // });

      // let powerFectorArray = [];
      // temparr.map((val, key) => {
      //   let tempObj = {};
      //   if (0 === key) {
      //     tempObj.name = Number(minValuePF).toFixed(2);
      //   } else if (temparr.length - 1 === key) {
      //     tempObj.name = Number(maxValuePF).toFixed(2);
      //   } else {
      //     tempObj.name = "";
      //   }
      //   tempObj.start = Number(val.start).toFixed(3);
      //   tempObj.end = Number(val.end).toFixed(3);
      //   tempObj.pv = Number(val?.count);
      //   powerFectorArray.push(tempObj);
      // });

      // if (powerFectorArray.length > 0) {
      //   setPowerFectorChartData(powerFectorArray);
      // } else {
      //   for (var i = 1; i < 10; i++) {
      //     let tempObj = {};
      //     tempObj.name = "";
      //     tempObj.pv = Number(0);
      //     tempObj.start = 0;
      //     tempObj.end = 0;
      //     powerFectorArray.push(tempObj);
      //   }
      //   setPowerFectorChartData(powerFectorArray);
      // }

      // let maxValueCurr = tempCurrentArray.max();
      // let minValueCurr = tempCurrentArray.min();
      // if (minValueCurr < 0) {
      //   minValueCurr = tempCurrentArray.min() * -1
      // }
      // let Currentrange = Number((maxValueCurr - minValueCurr) / 9);

      // let tempCurrArr = [];
      // let tempMinValueCurr = minValueCurr;
      // if (Currentrange != null && Currentrange != "" && Currentrange > 0) {
      //   for (var i = tempMinValueCurr; tempMinValueCurr < maxValueCurr; i++) {
      //     let tempObj = {};
      //     tempObj.start = Number(tempMinValueCurr);
      //     tempObj.end = Number(Number(tempMinValueCurr) + Number(Currentrange));
      //     tempObj.count = 0;
      //     tempMinValueCurr = Number(
      //       Number(tempMinValueCurr) + Number(Currentrange)
      //     );
      //     tempCurrArr.push(tempObj);
      //   }
      // }

      // tempCurrentArray.map((val, key) => {
      //   let tempcheckCurrval = 0;
      //   var filteredArray = tempCurrArr.filter(function (itm) {
      //     if (
      //       Number(itm.start) <= val &&
      //       Number(itm.end) >= val &&
      //       tempcheckCurrval == 0
      //     ) {
      //       itm.count = Number(itm.count + 1);
      //       tempcheckCurrval = 1;
      //     }
      //   });
      // });

      // let currentArray = [];
      // tempCurrArr.map((val, key) => {
      //   let tempObj = {};
      //   if (0 === key) {
      //     tempObj.name = Number(minValueCurr).toFixed(2);
      //   } else if (tempCurrArr.length - 1 === key) {
      //     tempObj.name = Number(maxValueCurr).toFixed(2);
      //   } else {
      //     tempObj.name = "";
      //   }
      //   tempObj.start = Number(val.start).toFixed(3);
      //   tempObj.end = Number(val.end).toFixed(3);
      //   tempObj.pv = Number(val?.count);
      //   currentArray.push(tempObj);
      // });

      // if (currentArray.length > 0) {
      //   setCurrentChartData(currentArray);
      // } else {
      //   for (var i = 1; i < 10; i++) {
      //     let tempObj = {};
      //     tempObj.name = "";
      //     tempObj.pv = Number(0);
      //     tempObj.start = 0;
      //     tempObj.end = 0;
      //     currentArray.push(tempObj);
      //   }
      //   setCurrentChartData(currentArray);
      // }

      // let maxValueFreq = tempFrequencyArray.max();
      // let minValueFreq = tempFrequencyArray.min();
      // // if (minValueFreq < 0) {
      // //   minValueFreq = tempFrequencyArray.min() * -1
      // // }
      // let frequencyrange = Number((maxValueFreq - minValueFreq) / 9);

      // let tempFreqArr = [];
      // let tempMinValueFreq = minValueFreq;
      // if (
      //   frequencyrange != null &&
      //   frequencyrange != "" &&
      //   frequencyrange > 0
      // ) {
      //   for (var i = tempMinValueFreq; tempMinValueFreq < maxValueFreq; i++) {
      //     let tempObj = {};
      //     tempObj.start = Number(tempMinValueFreq);
      //     tempObj.end = Number(
      //       Number(tempMinValueFreq) + Number(frequencyrange)
      //     );
      //     tempObj.count = 0;
      //     tempMinValueFreq = Number(
      //       Number(tempMinValueFreq) + Number(frequencyrange)
      //     );
      //     tempFreqArr.push(tempObj);
      //   }
      // }

      // tempFrequencyArray.map((val, key) => {
      //   let tempcheckFreqval = 0;
      //   var filteredArray = tempFreqArr.filter(function (itm) {
      //     if (
      //       Number(itm.start) <= val &&
      //       Number(itm.end) >= val &&
      //       tempcheckFreqval == 0
      //     ) {
      //       itm.count = Number(itm.count + 1);
      //       tempcheckFreqval = 1;
      //     }
      //   });
      // });

      // let frequencyArray = [];
      // tempFreqArr.map((val, key) => {
      //   let tempObj = {};
      //   if (0 === key) {
      //     tempObj.name = Number(minValueFreq).toFixed(2);
      //   } else if (tempFreqArr.length - 1 === key) {
      //     tempObj.name = Number(maxValueFreq).toFixed(2);
      //   } else {
      //     tempObj.name = "";
      //   }
      //   tempObj.start = Number(val.start).toFixed(3);
      //   tempObj.end = Number(val.end).toFixed(3);
      //   tempObj.pv = Number(val?.count);
      //   frequencyArray.push(tempObj);
      // });

      // if (frequencyArray.length > 0) {
      //   setFrequencyChartData(frequencyArray);
      // } else {
      //   for (var i = 1; i < 10; i++) {
      //     let tempObj = {};
      //     tempObj.name = "";
      //     tempObj.pv = Number(0);
      //     tempObj.start = 0;
      //     tempObj.end = 0;
      //     frequencyArray.push(tempObj);
      //   }
      //   setFrequencyChartData(frequencyArray);
      // }

      // let maxValueVolt = tempVoltageArray.max();
      // let minValueVolt = tempVoltageArray.min();
      // // if (minValueVolt < 0) {
      // //   minValueVolt = tempVoltageArray.min() * -1
      // // }
      // let Voltagerange = Number((maxValueVolt - minValueVolt) / 9);

      // let tempVoltArr = [];
      // let tempMinValueVolt = minValueVolt;
      // if (Voltagerange != null && Voltagerange != "" && Voltagerange > 0) {
      //   for (var i = tempMinValueVolt; tempMinValueVolt < maxValueVolt; i++) {
      //     let tempObj = {};
      //     tempObj.start = Number(tempMinValueVolt);
      //     tempObj.end = Number(Number(tempMinValueVolt) + Number(Voltagerange));
      //     tempObj.count = 0;
      //     tempMinValueVolt = Number(
      //       Number(tempMinValueVolt) + Number(Voltagerange)
      //     );
      //     tempVoltArr.push(tempObj);
      //   }
      // }

      // tempVoltageArray.map((val, key) => {
      //   let tempcheckVoltval = 0;
      //   var filteredArray = tempVoltArr.filter(function (itm) {
      //     if (
      //       Number(itm.start) <= val &&
      //       Number(itm.end) >= val &&
      //       tempcheckVoltval == 0
      //     ) {
      //       itm.count = Number(itm.count + 1);
      //       tempcheckVoltval = 1;
      //     }
      //   });
      // });

      // let voltageArray = [];
      // tempVoltArr.map((val, key) => {
      //   let tempObj = {};
      //   if (0 === key) {
      //     tempObj.name = Number(minValueVolt).toFixed(2);
      //   } else if (tempVoltArr.length - 1 === key) {
      //     tempObj.name = Number(maxValueVolt).toFixed(2);
      //   } else {
      //     tempObj.name = "";
      //   }
      //   tempObj.start = Number(val.start).toFixed(3);
      //   tempObj.end = Number(val.end).toFixed(3);
      //   tempObj.pv = Number(val?.count);
      //   voltageArray.push(tempObj);
      // });

      // if (voltageArray.length > 0) {
      //   setVoltageChartData(voltageArray);
      // } else {
      //   for (var i = 1; i < 10; i++) {
      //     let tempObj = {};
      //     tempObj.name = "";
      //     tempObj.pv = Number(0);
      //     tempObj.start = 0;
      //     tempObj.end = 0;
      //     voltageArray.push(tempObj);
      //   }
      //   setVoltageChartData(voltageArray);
      // }
    }
    if (historyProjectList && historyProjectList.length > 0) {
      let tempDataArray = [];
      historyProjectList.map((val, key) => {
        let chartObj = {};
        let time = new Date(val.timestamp * 1000).toLocaleString("en-US", {
          hour: "numeric",
          hour12: true,
        });
        let longtime = new Date(val.timestamp * 1000).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        chartObj.name = time;
        chartObj.long_time = longtime;

        chartObj.rafc = moment
          .unix(val.timestamp)
          .format("yyyy-MM-DD'T'HH:mm:ss'Z'");
        chartObj.csv_rafc = moment
          .unix(val.timestamp)
          .format("yyyy-MM-DD'T'HH:mm:ss'Z'");

        chartObj.node_id = projectParam?.consumptionPatternId;
        chartObj.node_name = projectParam?.consumptionPatternId;

        let v = Number(Math.round(val.aggregate + "e2") + "e-2");
        // let v = (val.value).toFixed(2)
        if (val.aggregate) {
          v = val?.aggregate / 1000;
          // let p = Number(Math.round(v + 'e2') + 'e-2');
          let p = Number.parseFloat(v).toFixed(2);
          chartObj.uv = Number(p);
          chartObj.expVal = Number(p);
          chartObj.csv_expVal = Number(p);
        } else {
          chartObj.uv = 0;
          chartObj.expVal = 0;
          chartObj.csv_expVal = 0;
        }

        tempDataArray.push(chartObj);
      });
      setProjectListChartData(tempDataArray);
      // let lastItem = tempDataArray.pop();
      let lastItem = tempDataArray[tempDataArray.length - 1];

      setProjectChartData(lastItem);

      let hplastArray = Object.values(historyProjectList).pop().timestamp;
      setHplastTime(hplastArray);
      var timeOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      let HPChartTime = new Date(hplastArray * 1000).toLocaleString(
        "en-ZA",
        timeOptions
      );
      setHPChartTime(HPChartTime);
    } else {
      setProjectListChartData([]);
      setProjectChartData(0)
    }

    if (
      (consumptionPattern &&
        consumptionPattern !== undefined &&
        consumptionPattern.length > 0 &&
        consumptionPattern !== null) ||
      reloadGraph === true
    ) {
      let barchartData = []
      consumptionPattern?.forEach((data, i) => {
        const date = new Date(data?.timestamp * 1000);
        let mockdata = {

          name: date.getDate(),
          pv: parseFloat(data.aggregate?.toFixed(2)),
          csv_expVal: parseFloat(data.aggregate?.toFixed(2))
        }
        barchartData.push(mockdata)
      })
      const groups = consumptionPattern?.reduce((groups, game) => {
        const date = new Date(game.timestamp * 1000).getDate();
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      let consumptionAggregate = consumptionPattern?.map((data) => data.aggregate);
      let TotalAggregate = 0;
      consumptionAggregate?.map((value) => {
        return (
          TotalAggregate += value
        )
      })
      const consumptionValue =consumptionPattern?.map((data)=>data)
      setCpTotalChartData(TotalAggregate?.toFixed(2));
      if (groups && Object.keys(groups).length > 0) {
        // let lastArray = Object.values(groups).pop();
        let lastArray = consumptionValue.pop();

        let firstArray = Object.values(groups).shift();
        let firstArrayValue = Object.values(firstArray).shift().aggregate;
        let lastArrayValue = Object.values(lastArray).pop().aggregate;
        let PCData = Number(lastArrayValue - firstArrayValue).toFixed(2);
        // setCpTotalChartData(PCData);

        // let lastArraytime = Object.values(lastArray).pop().timestamp;
        let lastArraytime =lastArray.timestamp;
        var timeOptions = {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        let CPChartTime = new Date(lastArraytime * 1000).toLocaleString(
          "en-ZA",
          timeOptions
        );
        setCPChartTime(CPChartTime);
      } else {
        setCpTotalChartData("-- ");
        setCPChartTime("");
      }

      let consumptionTempArray = [];
      let consumptionTempArraykeys = [];
      if (Object.entries(groups).length) {
        try {
          Object.entries(groups).map((val1, key1) => {
            let tempObj = {};
            let uvData = Number(
              val1[1].pop()?.aggregate - val1[1].shift()?.aggregate
            ).toFixed(2); //TypeError: Cannot read properties of undefined (reading 'value')
            tempObj.name = Number(val1[0]);
            tempObj.pv = Number(uvData);
            tempObj.date = val1[1].pop()?.timestamp;
            consumptionTempArray.push(tempObj);
            consumptionTempArraykeys.push(Number(val1[0]));
          });
        } catch (error) {
          console.log("error", error);
          consumptionTempArray = [];
          consumptionTempArraykeys = [];
        }
      }

      let curruntMonthCount = getAllDaysInMonth(currentYear, currentMonth);
      var tempdatearr = [];
      for (var i = 1; i < curruntMonthCount.length + 1; i++) {
        let tempObj = {};
        if (!barchartData.includes(i)) {
          tempObj.name = i;
          // tempObj.date = curruntMonthCount[i];
          tempObj.pv = 0;
          tempdatearr.push(tempObj);
        }
      }
      const mainChartArray = [...barchartData, ...tempdatearr];

      const uniqueArrayOfObjects = mainChartArray.filter((obj, index, self) =>
  index === self.findIndex((item) => item.name === obj.name)
);
  
uniqueArrayOfObjects.sort(function (a, b) {
        return a.name - b.name;
      });
      let ConsumptionPatternChartDataArray = [];
      mainChartArray.map((val, k) => {
        let tempObj = {};

        tempObj.name = Number(val?.name);
        tempObj.pv = Number(val?.pv);

        tempObj.rafc = moment(
          new Date(new Date().setDate(Number(val?.name)))
        ).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
        tempObj.expVal = Number(val?.pv);
        tempObj.csv_rafc = moment.unix(val.date).format("yyyy-MM-DD");
        tempObj.csv_expVal = Number(val?.pv);
        tempObj.node_id = projectParam?.consumptionPatternId;
        tempObj.node_name = projectParam?.consumptionPatternId;

        ConsumptionPatternChartDataArray.push(tempObj);
      });
      setConsumptionPatternChartData(uniqueArrayOfObjects);
    } else {
      setConsumptionPatternChartData(tempdatearr)
      setCpTotalChartData("--")
    }
}, [instEnergy, reloadGraph, consumptionPattern, historyProjectList]);
  // code for auto refresh API start------------------------------------
  const fetchDeviceInstParams = async () => {
    if (nodeListParam.length > 0) {
      let nodeListFixParam = ["thdv3", "thdv2", "thdv1"];
      let allnodeListParams = [];

      await Promise.all(
        nodeListParam.map(async (val, key) => {
          if (nodeListFixParam.includes(val.name)) {
            let temp_nodeList = JSON.parse(storageNodeList);
            let payloadData = devideArray(temp_nodeList);
            let payloadParamData = val.param_id;
            let res = [];
            if (payloadData && payloadData.length > 0) {
              await Promise.all(
                payloadData.map(async (val, i) => {
                  await handleGetDeviceInstParam(token, {
                    nodes: val,
                    paramid: payloadParamData,
                  })
                    .then((response) => {
                      if (
                        response.success &&
                        response?.data &&
                        response?.data !== null
                      ) {
                        res = [...res, ...response.data];
                      } else {
                        res = [...res];
                      }
                    })
                    .catch((err) => {
                      const error = err?.response?.data;
                      if (error?.description?.includes("token is expired")) {
                        logoutUser();
                      }
                      throw err;
                    });
                })
              );
            }

            allnodeListParams.push(res);
          }
        })
      );

      dispatch(setDeviceTHDAction(allnodeListParams));
      var d = allnodeListParams[0]?.concat(
        allnodeListParams[1],
        allnodeListParams[2]
      );
      console.log(d,"setDeviceInstParamAction")
      if (d.length > 0) {
        dispatch(setDeviceInstParamAction(d));
        dispatch(setCallDashboardFunctions(false));
      }
    }
  };

  // const autoRefreshDashboardAPI = async () => {
  //   try {
  //     let nodeListPayload = storageNodeList ? JSON.parse(storageNodeList) : [];
  //     await fetchNodeList({ offset: 0, order: "asc" });
  //     await fetchNodeListParam({ limit: 100, offset: 0 });
  //     await fetchNodeMetaData({ nodes: nodeListPayload });
  //     await fetchHistoryProjectList(projectListPayload);
  //     await fetchConsumptionPattern(consumptionPatternPayload);
  //     setReloadGraph(false);
  //     await fetchDeviceInstParams();
  //     await fetchInstEnergyList({ nodes: nodeListPayload });
  //   } catch (error) {
  //   } finally {
  //     let data = {
  //       dashboardReload: true,
  //       time: new Date(),
  //     };
  //     autoRefreshDashboard(data);
  //   }
  // };

  // useEffect(() => {
  //     let timerId;
  //     if (dashboardReload) {
  //         // autoRefreshDashboardAPI()
  //         timerId = setInterval(function () {
  //             if (dashboardReload === true && nodeListCallTime !== null && checkApiCallTime(nodeListCallTime, process.env.REACT_APP_API_TIME)) {
  //                 // console.log('Testing For timer After Every 15 minutes', dashboardReload, new Date(nodeListCallTime))
  //                 autoRefreshDashboardAPI()
  //             }
  //         }, process.env.REACT_APP_AUTO_RELOAD_TIME * 60 * 1000);
  //     } else {
  //         clearInterval(timerId);
  //     }
  //     return () => clearInterval(timerId);
  // }, [dashboardReload, nodeListCallTime])

  // code for auto refresh API end-----------------------------

  const handleDateChange = async (year, month) => {
    let yearValue = "";
    if (year?.target?.value) {
      yearValue = Number(year?.target?.value);
    } else {
      yearValue = Number(year);
    }

    let monthvalue = "";
    if (isNaN(month)) {
      monthvalue = Number(monthNames.indexOf(month?.target?.value));
    } else {
      monthvalue = Number(month);
    }
    setCurrentYear(yearValue);
    setCurrentMonth(monthvalue);
    var firstDay =
      Math.floor(
        new Date(
          Date.UTC(
            yearValue,
            monthvalue,
            1,
            0,
            0,
            0,
            0
          )
        ) / 1000
      );
    var lastDay =
      Math.floor(
        new Date(
          Date.UTC(
            yearValue,
            monthvalue + 1,
            0,
            23,
            59,
            59,
            999
          )
        ) / 1000
      );
    setFirstDayMonth(firstDay);
    setLastDayMonth(lastDay);
    let month_Date={
      monthvalue:monthvalue,
      yearValue:yearValue
    }


    const selectParams = await fetchSelectedNodeParams('day_Consumption');
    let nodeId = selectParams?.selectedParams.map(data => data.node_id);

    let consumptionPayload = {
      from:firstDay,
      to:lastDay,
      nodes: nodeId,
      type: "include"      
    };

    dispatch(setConsumptionChartSelectedDateAction(consumptionPayload))
    await fetchConsumptionPattern(consumptionPayload);
    dispatch(setConsumptionChartSelectedMonthAction(month_Date))
    setConsumptionPatternPayload(consumptionPayload);
    // setConsumptionPatternPayload({
    //   ...consumptionPatternPayload,
    //   from: firstDay,
    //   to: lastDay,
    // });
    setReloadGraph(true);
  };

  const handleBarClick = (e, graph) => {
    const filterOptions = {
      components: componentList.find((e) => e.value == graph),
      operation: "between",
      value: "",
      start: e.start,
      end: e.end,
      condition: "and",
    };
    let filterList = [filterOptions];
    let encodedFilter = encryptData(JSON.stringify(filterList));
    navigate("/equipments?filter=" + encodedFilter);
  };

  const handleGraphExpand = () => {
    dispatch(
      setSaveIntervals({
        from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
        to: hplastTime,
      })
    );

    let obj = { ...projectListPayload, to: hplastTime };
    obj.parameter = "importkwh";
    obj.node = "all";

    let encodedGraphData = encryptData(JSON.stringify(obj));
    navigate("/chart/view?filter=" + encodedGraphData);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        bgcolor: "#F5F5F5",
      }}
      className="dashboard-main"
    >
      <Toolbar />
      <ToastMessage />
      <Box className="h-[calc(100vh-80px)]">
        <Box className="h-[62%] my-[1%] mx-[32px] pt-[10px] Mediumlg:mx-[20px]">
          <Box display="flex" justifyContent="space-between" className="h-full">
            {/* Total Power Graph Start */}
            <TotalPowerLayout
              hPChartTime={hPChartTime}
              activeEquipmentList={activeEquipmentList}
              storageNodeList={storageNodeList}
              projectChartData={projectChartData}
              csvHeader={csvHeader}
              projectListChartData={projectListChartData}
              expandGraph={handleGraphExpand}
            />
            {/* Total Power Graph end */}

            {/* Consumption Pattern Graph start */}
            <ConsumptionPatternLayout
              cPChartTime={cPChartTime}
              currentYear={currentYear}
              onChangeDate={handleDateChange}
              years={years}
              monthNames={monthNames}
              currentMonth={currentMonth}
              cpTotalChartData={cpTotalChartData}
              csvHeader={csvHeader}
              consumptionPatternChartData={consumptionPatternChartData}
              firstDayMonth={firstDayMonth}
              lastDayMonth={lastDayMonth}
            />
            {/* Consumption Pattern Graph end */}
          </Box>
        </Box>
        <Box className="h-[33%] my-[1%] mx-[32px] Mediumlg:mx-[20px]">
          <Box display="flex" justifyContent="space-between" height="100%">
            {/* Current Graph Start */}
            <CurrentLayout
              currentChartData={currentChartData}
              avgOfCurrentData={avgOfCurrentData}
              onClickBar={handleBarClick}
              currentFunction={currentFunction}
            />
            {/* Current Graph end */}
            {/* PF Graph Start */}
            <PowerFactorLayout
              powerFectorChartData={powerFectorChartData}
              avgOfPowerFectorData={avgOfPowerFectorData}
              onClickBar={handleBarClick}
              powerFactorFunction={powerFactorFunction}
            />
            {/* PF Graph Start */}
            {/* THD Graph Start */}
            <THDLayout
              tHDChartData={tHDChartData}
              avgOfTHDData={avgOfTHDData}
              onClickBar={handleBarClick}
              thdFunction={thdFunction}
            />
            {/* THD Graph Start */}
            {/* Frequency Graph Start */}
            <FrequencyLayout
              frequencyChartData={frequencyChartData}
              avgOfFrequencyData={avgOfFrequencyData}
              onClickBar={handleBarClick}
              FrequencyFunction={FrequencyFunction}
            />
            {/* Frequency Graph Start */}
            {/* Voltage Graph Start */}
            <VoltageLayout
              voltageChartData={voltageChartData}
              avgOfVoltageData={avgOfVoltageData}
              onClickBar={handleBarClick}
              voltageFunction={voltageFunction}
            />
            {/* Voltage Graph Start */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Dashboard;
