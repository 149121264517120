import { IconButton } from '@mui/material';
import { JsonFileIcon } from 'assets';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';

export const FileUploadLayout = ({
    handleChange,
    file
}) => {

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxSize: 128000,
        accept: {
            'application/json': ['.json'], // you need to specify into this array the extensions you want to accept
        },
        onDrop: (files) => {
            handleChange({
                target: {
                    name: 'file_data',
                    value: files.length ? files[0] : ''
                }
            })
            console.log('files', files);
        }
    });

    const handleRemoveFile = () => {
        handleChange({
            target: {
                name: 'file_data',
                value: ''
            }
        })
    }

    return (
        <div>
            {file ? (
                <div className='file-preview-container'>
                    <div className='file-preview-inner'>
                        <em>
                            <img src={JsonFileIcon} width={50} height={50} alt={'file'} />
                        </em>
                        <span style={{
                            // color: 'black',
                            fontSize: '1.2rem',
                            paddingLeft: '1rem'
                        }}>{file?.name || 'JSON File'}</span>
                        <IconButton aria-label="delete" sx={{marginLeft:'50px'}} onClick={handleRemoveFile}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <div className="file-container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <button type="button" onClick={open}>
                            Upload File
                        </button>
                        <p className='ml-10'>or</p>
                        <div>
                            <p className='ml-10'>Drag and Drop File</p>
                            <p className='ml-10 validate-file'>Max size allowed 128kb</p>
                            <p className='ml-10 validate-file'>Accept only JSON file</p>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    )
}