import * as React from "react";
import { bindActionCreators } from "redux";
import * as DashboardApis from "../../stores/services/dashboardApis";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setNodeListAction,
  setInstEnergyAction,
  setHistoryProjectListAction,
  setConsumptionPatternAction,
  setNodeListParamAction,
  setAutoRefreshDashboard
} from "../../stores/actionTypes/dashboardActions";
import { setReloadAction } from "../../stores/actionTypes/authActions";
import { useHook as useLoginHook } from '../../pages/auth/login/useHook';
import AlertMessage from "../../constants/AlertMessage";
import { devideArray } from "../../constants/Helper";

export function useHook() {
  const { logoutUser } = useLoginHook();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleGetNodeList, handleGetDeviceInstEnergy, handleGetHistoryProjectListForEquipmentDetail, handleGetHistoryProjectList, handleGetConsumptionPattern, handleGetNodeListParam, handleGetDeviceInstParam, handleGetDeviceHistoricalEnergy, handleGetRecentNodeEnergy, handleGetSelectedNodeParams, saveNodeParams } = bindActionCreators(DashboardApis, dispatch);
  const { token } = useSelector(state => state.auth);
  const { projectParam } = useSelector(state => state.dashboard);


  const fetchNodeList = async (payload, required_dispatch) => {
    await handleGetNodeList(token, payload)
      .then(response => {
        if (response.success && response.nodes !== null) {
          dispatch(setNodeListAction(response.nodes));
          localStorage.setItem('nodeList', JSON.stringify(response.nodes))
        }
        return;
      })
      .catch(err => {
        const error = err?.response?.data;
        AlertMessage({ msg: error.description || error.error })
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
  }

  const fetchNodeListParam = async (payload, required_dispatch) => {
    await handleGetNodeListParam(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          let data = response.nodeparams
          dispatch(setNodeListParamAction(data));
        } else {
          dispatch(setNodeListParamAction([]));
        }
        return;
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
  }

  const fetchInstEnergyList = async (payload, required_dispatch) => {

    let payloadData = devideArray(payload.nodes)
    let res = {}

    if (payloadData && payloadData.length > 0) {
      await Promise.all(payloadData.map(async (val, i) => {

        await handleGetDeviceInstEnergy(token, { nodes: val })
          .then(response => {
            if (response.success && response?.data && response?.data !== null) {
              res = { ...res, ...response.data }
            }
          })
          .catch(err => {
            const error = err?.response?.data;
            if (error?.description?.includes("token is expired")) {
              logoutUser();
            }
            throw err;
          })

      }))
      dispatch(setInstEnergyAction(res));
      return;
    }

  }

  const fetchDeviceInstParam = async (payload, required_dispatch) => {
    let payloadData = devideArray(payload.nodes)
    let payloadParamData = payload.paramid
    let res = []
    if (payloadData && payloadData.length > 0) {
      await Promise.all(payloadData.map(async (val, i) => {
        await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
          .then(response => {
            if (response.success && response?.data && response?.data !== null) {
              res = [...res, ...response.data];
            } else {
              res = [...res];
            }
          })
          .catch(err => {
            const error = err?.response?.data;
            if (error?.description?.includes("token is expired")) {
              logoutUser();
            }
            throw err;
          })
      }))
      return res;
    }
  }

  const fetchHistoryProjectList = async (nodeparams = [], filterType = "include", IDS = null) => {
    return await handleGetHistoryProjectList(token, nodeparams, filterType, IDS)
      .then(response => {
        if (response && response.data !== null) {
          let data = response;
          // .data['3a0b8e4d-8987-4d12-ad5e-17f3377ee39c']
          // [projectParam?.powerId];
          // let data = response.data[process.env.REACT_APP_TOTAL_POWER_ID]
          dispatch(setHistoryProjectListAction(data));
          return data;
        }
        return;
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
  }

  const fetchHistoryProjectListForEquipmentDetail = async (nodeparams = [], filterType = "include", IDS = null) => {
    return await handleGetHistoryProjectListForEquipmentDetail(token, nodeparams, filterType, IDS)
      .then(response => {
        if (response && response.data !== null) {
          let data = response;
          return data;
        }
        return;
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
  }

  const fetchConsumptionPattern = async (payload, required_dispatch) => {
    let returndata = []
    await handleGetConsumptionPattern(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null && Object.keys(response.data).length > 0) {
          // let data = response.data["3a0b8e4d-8987-4d12-ad5e-17f3377ee39c"]
          let datas = Object.values(response.data)
          let data = datas[0]
          console.log(data, "datadata")
          returndata = data
          if (required_dispatch == false) { } else { dispatch(setConsumptionPatternAction(data)); }
        } else {
          returndata = []
          dispatch(setConsumptionPatternAction([]));
        }

      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
        returndata = []
      })
    return returndata;
  }

  const fetchHistoryProjectListById = async (payload, required_dispatch) => {
    let data = null
    await handleGetHistoryProjectList(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          data = response.data
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
    return data
  }

  const fetchDeviceHistoricalEnergy = async (payload, required_dispatch) => {
    let data = null
    await handleGetDeviceHistoricalEnergy(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          data = response.data
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
    return data
  }

  const fetchrecentNodeEnergy = async (payload, required_dispatch) => {
    let data = null
    await handleGetRecentNodeEnergy(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          data = response.data
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
    return data
  }


  const fetchSelectedNodeParams = async (payload, required_dispatch) => {
    let data = null
    await handleGetSelectedNodeParams(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          data = response.data
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
    return data
  }

  const postNodeParams = async (payload, required_dispatch) => {
    let data = null
    await saveNodeParams(token, payload)
      .then(response => {
        if (response && response.success && response.data !== null) {
          data = response.data
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        if (error?.description?.includes("token is expired")) {
          logoutUser();
        }
        throw err;
      })
    return data
  }

  const reloadHeader = async (data) => {
    dispatch(setReloadAction(data));
  }

  const autoRefreshDashboard = async (data) => {
    dispatch(setAutoRefreshDashboard(data));
  }


  return {
    fetchNodeList,
    fetchInstEnergyList,
    fetchHistoryProjectList,
    fetchHistoryProjectListForEquipmentDetail,
    fetchHistoryProjectListById,
    fetchDeviceHistoricalEnergy,
    reloadHeader,
    autoRefreshDashboard,
    fetchrecentNodeEnergy,
    postNodeParams,
    fetchSelectedNodeParams,
    fetchConsumptionPattern,
    fetchNodeListParam,
    fetchDeviceInstParam
  }
}