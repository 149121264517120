import React from 'react'
import { DeviceList } from './list'
import { useHook } from './useHook'
import { hasPermission } from 'constants/Helper';
import { ROLE } from 'constants/Permissions';
import { useSelector } from 'react-redux';
import jwt from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const DeviceProfile = () => {

    const { fetchConfig, removeConfig } = useHook();
    const { token } = useSelector(state => state.auth);
    const { RoleId } = jwt(token);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(true);
    const [devices, setDevices] = React.useState([]);

    const getConfigData = async () => {
        try {
            setIsLoading(true);
            const configDevices = await fetchConfig();
            setDevices(configDevices);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    const removeConfigAPI = async (profileId) => {
        await removeConfig(profileId)
        await getConfigData();
    }

    React.useEffect(() => {
        if (!hasPermission(ROLE[RoleId], 'DEVICES')) {
            navigate('/')
          }
        getConfigData();
    }, []);


    return (
        <React.Fragment>
            <DeviceList tableRecords={devices} isLoading={isLoading} removeConfigAPI={removeConfigAPI} />
        </React.Fragment>
    )
}

export default DeviceProfile;


