import {
  SaveFilter,
  SaveNodeMetaData,
  ClearEquipmentData,
  SaveIntervals,
  SaveVisibleRangeData,
  CallDashboardFunctions,
  SetConfigProfiles
}
  from "../../actionTypes/equipmentActions";

const inititalState = {
  filters: [],
  nodeMetaData: null,
  graphTimeIntervals: [],
  visibleRangeData: [],
  callFunctions: false,
  configProfiles: []
}

export const equipmentReducer = (state = inititalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SaveFilter:
      return {
        ...state,
        filters: payload
      }
    case SaveNodeMetaData:
      return {
        ...state,
        nodeMetaData: payload
      }

    case CallDashboardFunctions:
      return {
        ...state,
        callFunctions: payload
      }

    case ClearEquipmentData:
      return {
        filters: [],
        nodeMetaData: null,
        visibleRangeData: [],
        graphTimeIntervals: [],
        callFunctions: false
      }
    case SaveIntervals:
      let tempArr = state?.graphTimeIntervals ? state?.graphTimeIntervals : []

      // console.log(tempArr.some(elem => elem.from === payload.from && elem.to === payload.to))

      if (!tempArr.some(elem => elem.from === payload.from && elem.to === payload.to)) {

        if (tempArr.length === 6) {
          tempArr.pop()
          tempArr.unshift(payload);
        } else {
          if (tempArr.length < 6) {
            tempArr.unshift(payload);
          } else {
            tempArr = []
          }

        }
      }
      return {
        ...state,
        graphTimeIntervals: tempArr
      }

    case SaveVisibleRangeData:
      return {
        ...state,
        visibleRangeData: payload
      }
    case SetConfigProfiles:
      return {
        ...state,
        configProfiles: payload
      }
    default:
      return state;
  }
}