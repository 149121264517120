import { useState } from "react";
import { useFormik } from "formik";
import * as AuthServices from "../../../stores/services/authApis";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/appRoutes";
import AlertMessage from "../../../constants/AlertMessage";
import * as Yup from "yup";
import { RequiredMessage } from "../../../constants/validationMessage";

const InititalForgotPasswordDetail = {
  data: "",
  isData: false,
  otp: "",
  password: "",
  confirm: ""
};

const ForgotPasswordSchema = Yup.object({
  data: Yup.string().email("Enter valid email").required(RequiredMessage),
  otp: Yup.string().when("isData", {
    is: (isData) => isData,
    then: Yup.string()
      .matches(/^[0-9]*$/, 'Number only')
      .min(6, 'Enter valid 6 digits OTP')
      .required(RequiredMessage),
  }),
  password: Yup.string().when("isData", {
    is: (isData) => isData,
    then: Yup.string().min(8).required(RequiredMessage),
  }),
  confirm: Yup.string().when("isData", {
    is: (isData) => isData,
    then: Yup.string().min(8).required(RequiredMessage)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })
})

export const useHook = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [resend, setResend] = useState(false);

  const formik = useFormik({
    initialValues: InititalForgotPasswordDetail,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      /**
       * Verify OTP / Reset Password request handler function with conditions
       */
      if (values.isData) {
        const OtpSentData = {
          useremail: values.data,
          resetinfo: {
            otp: parseInt(values.otp),
            passwd: values.password
          }
        };
        AuthServices.handleSendOTP(OtpSentData)
          .then(response => {
            const { success, error } = response;
            if (!success) {
              AlertMessage({ msg: error })
              return;
            }
            AlertMessage({ msg: 'Password updated successfully' })
            navigate(Routes.login);
          })
          .catch(err => {
            let error = err?.response;
            AlertMessage({ msg: error?.data?.description })
            throw err;
          })
        return;
      }
      /**
       * Send OTP request handler function
       */
      const OtpSentData = { useremail: values.data };
      AuthServices.handleSendOTP(OtpSentData)
        .then(response => {
          const { success, error } = response;
          if (!success) {
            AlertMessage({ msg: error })
            return;
          }
          formik.resetForm()
          formik.setFieldValue('isData', true);
          formik.setFieldValue('data', values.data);
          setResend(true);
          AlertMessage({ msg: 'OTP sent on given Email ID' })
        })
        .catch(err => {
          let error = err?.response;
          AlertMessage({ msg: error?.data?.description || error?.data })
          return err;
        })
    }
  });

  function resendOTP(data) {
    AuthServices.handleSendOTP(data)
      .then(response => {
        const { success, error } = response;
        if (!success) {
          AlertMessage({ msg: error })
          return;
        }
        formik.setFieldValue('isData', true);
        setResend(true);
        AlertMessage({ msg: 'OTP sent on given Email ID' })
      })
      .catch(err => {
        let error = err?.response;
        AlertMessage({ msg: error?.data?.description || error?.data })
        return err;
      })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return {
    formik,
    resendOTP,
    showPassword,
    handleClickShowPassword,
    resend
  }
}