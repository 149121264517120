import React, { useState, useRef,useEffect } from 'react';
import { Tabs, Tab, Box, Button, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import TextFieldComponent,
{
  PasswordInputField,
  SelectFieldComponent
} from '../../../../components/formFields/common/textFieldComponent';
import { useUserHook } from './usePersonalDetailHook';
import { Form, FormikProvider, useFormik, FieldArray } from "formik";
import { TabContainer } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import AvatartImg2 from "../../../../assets/Group289724.png"
import EditIcon from "../../../../assets/edit.svg"
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { useDebounceEffect } from '../useDebounceEffect';
import { canvasPreview } from '../canvasPreview';
import ImageCropModal from '../imageCropModal';
import iIcon from "../../../../assets/iicon.png"





function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


const PersonalDetail = () => {
  const { formik, Role, id, showPassword, setShowPassword, state, handleChangeForm, goToNextStep, value, setValue, setavatarBase64 } = useUserHook();
  const [tempAvatar, setTempAvatar] = useState(state.photo ? state.photo : AvatartImg2)
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  const location = useLocation()



  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1)
  const [open, setOpen] = useState(false);
  const [img64, setImg64] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeAvarat = (e) => {
    const file = e.target.files[0]
    if (file) {
      // console.log(file)
      // setTempAvatar(URL.createObjectURL(file))
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log('RESULT', reader.result)
        setImgSrc(reader.result?.toString() || '')
      }
      reader.readAsDataURL(file);
      handleClickOpen()
    } else {
      handleClose()
    }

  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  
  useEffect(() => {
    setTempAvatar(state.photo ? state.photo : AvatartImg2)
  }, [state.photo]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        let url = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        // console.log(url)
        setImg64(url)
        // setTempAvatar(url)
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setavatarBase64('')
  };

  const cropImage = () => {
    setavatarBase64(img64)
    setTempAvatar(img64)
    setOpen(false);

  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        bgcolor: "#FFFFFF",
      }}
    >
      <Toolbar />
      <div className='py-[60px] px-[32px] bg-white w-full h-full'>
        <Box
          sx={{ bgcolor: 'background.paper', display: 'flex' }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className='tab-main'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab icon={<PersonOutlineOutlinedIcon />} className={value == 0 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Profile" {...a11yProps(0)} />
            <Tab icon={<PersonOutlineOutlinedIcon />} className={value == 1 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Role" {...a11yProps(0)} />
            <Tab icon={<VpnKeyOutlinedIcon />} className={value == 2 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Password" {...a11yProps(0)} />


          </Tabs>
          {value === 0 &&
            <TabContainer value={value} index={0} >
              {/* <form className='border-2' onSubmit={formik.handleSubmit} style={{ width: '60%', marginLeft: '5%' }}>
                <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                    Personal Details
                  </Typography>
                </Box>
                <TextFieldComponent
                  type="text"
                  label="First Name"
                  id="first_name"
                  placeholder="First name"
                  classes="w-full flex items-center mb-4"
                  name="first_name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  // autoFocus={true}
                  isError={Boolean(formik.errors.first_name)}
                  isTouched={Boolean(formik.touched.first_name)}
                  errMsg={formik.errors.first_name}
                  margin="normal"
                  fullWidth
                />
                <TextFieldComponent
                  type="text"
                  label="Last Name"
                  id="last_name"
                  placeholder="Last name"
                  classes="w-full flex items-center mb-4"
                  name="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  // autoFocus={false}
                  isError={Boolean(formik.errors.last_name)}
                  // isTouched={Boolean(formik.touched.last_name)}
                  errMsg={formik.errors.last_name}
                  margin="normal"
                  fullWidth
                  required
                />
                <TextFieldComponent
                  type="text"
                  label="Email"
                  id="email"
                  placeholder="Email"
                  classes="w-full flex items-center mb-4 disabled:cursor-not-allowed"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  // autoFocus={false}
                  isError={Boolean(formik.errors.email)}
                  isTouched={Boolean(formik.touched.email)}
                  errMsg={formik.errors.email}
                  disabled={id ? true : false}
                  margin="normal"
                  fullWidth
                  required
                />
                <TextFieldComponent
                  type="text"
                  label="Phone Number"
                  id="mobile"
                  placeholder="Phone number"
                  classes="w-full flex items-center mb-4"
                  name="mobile"
                  onChange={formik.handleChange}
                  value={formik.values.mobile}
                  // autoFocus={false}
                  isError={Boolean(formik.errors.mobile)}
                  isTouched={Boolean(formik.touched.mobile)}
                  errMsg={formik.errors.mobile}
                  margin="normal"
                  fullWidth
                  required
                />
                <TextFieldComponent
                  type="text"
                  label="Address"
                  id="address"
                  placeholder="Address"
                  classes="w-full flex items-center mb-4"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  // autoFocus={false}
                  isError={Boolean(formik.errors.address)}
                  isTouched={Boolean(formik.touched.address)}
                  errMsg={formik.errors.address}
                  margin="normal"
                  isRequired={false}
                  fullWidth
                  required
                />
                <div className='w-full flex justify-end text-right gap-3  mt-10'>
                  <Button
                    type='submit'
                    onClick={formik.handleSubmit}
                    className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                  >
                    {id ? 'Update' : 'Submit'}
                  </Button>
                  <Button
                    type='reset'
                    onClick={() => navigate('/')}
                    className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                  >
                    Cancel
                  </Button>
                </div>
              </form> */}
              <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                <div className='' style={{ width: "60%" }}>

                  <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 3, borderColor: '#1B53F9' }}>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' >
                      {id ? state.first_name : "Personal Details"}
                    </Typography>
                    {/* <span>Step {value + 1}/2</span> */}
                  </Box>

                  <Box component="div" className='flex items-center justify-between mt-5 mb-5' sx={{ borderBottom: 1, borderColor: '#1D212599' }}>
                    <Typography className='w-[350px] font-semibold pb-2 mt-5 text-[20px] m-0 leading-6 text-[#2D3748]' >
                      {"Personal Details"}
                    </Typography>
                    {/* <span>Step {value + 1}/2</span> */}
                  </Box>

                  <div className="eqipment-form">


                    <div className="input-control-main">
                      <label htmlFor="first_name">First Name</label>
                      <div className="control">
                        <input type='text'
                          id="first_name"
                          name="first_name"
                          value={state.first_name}
                          onChange={(e) => handleChangeForm(e)}
                          placeholder="First Name"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.first_name ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.first_name &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.first_name}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="last_name">Last Name</label>
                      <div className="control">
                        <input type='text'
                          id="last_name"
                          name="last_name"
                          value={state.last_name}
                          onChange={handleChangeForm}
                          placeholder="Last Name"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.last_name ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.last_name &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.last_name}</span>
                        }
                      </div>
                    </div>


                    <div className="input-control-main">
                      <label htmlFor="email">Email</label>
                      <div className="control">
                        <input type='email'
                          id="email"
                          name="email"
                          value={state.email}
                          onChange={handleChangeForm}
                          placeholder="Email"
                          disabled={true}
                          className={state?.error && state?.error?.email ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.email &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.email}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="mobile">Phone number</label>
                      <div className="control">
                        <input type='text'
                          id="mobile"
                          name="mobile"
                          value={state.mobile}
                          onChange={handleChangeForm}
                          placeholder="Phone number"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.mobile ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.mobile &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.mobile}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="employeeId">Employee Id</label>
                      <div className="control">
                        <input type='text'
                          id="employeeId"
                          name="employeeId"
                          value={state.employeeId}
                          onChange={handleChangeForm}
                          placeholder="Employee Id"
                          disabled={true}
                          className={state?.error && state?.error?.employeeId ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.employeeId &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.employeeId}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="address">Address</label>
                      <div className="control">
                        <input type='text'
                          id="address"
                          name="address"
                          value={state.address}
                          onChange={handleChangeForm}
                          placeholder="Address"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.address ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.address &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.address}</span>
                        }
                      </div>
                    </div>

                    {/* <SelectFieldComponent
                      label="Role"
                      id="roleId"
                      name="roleId"
                      menulist={Role}
                      onChange={formik.handleChange}
                      value={formik.values.roleId}
                      autoFocus={false}
                      isError={Boolean(formik.errors.roleId)}
                      isTouched={Boolean(formik.touched.roleId)}
                      errMsg={formik.errors.roleId}
                      disabled={id ? true : false}
                      classes="w-full flex items-center mb-4 sm:col-span-2 disabled:cursor-not-allowed"
                      margin="normal"
                      fullWidth
                      required
                    /> */}
                    {
                      !location.pathname.startsWith('/users/view') &&
                      <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>

                        <Button
                          type='reset'
                          onClick={() => navigate('/users')}
                          style={{ textTransform: "capitalize" }}
                          className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                          {'Cancel'.toLocaleLowerCase()}
                        </Button>

                        <Button
                          type='button'
                          onClick={() => goToNextStep(0)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                        >
                          {'Submit'.toLocaleLowerCase()}
                        </Button>

                      </div>
                    }
                  </div>
                </div>

                <div className='img-div'>
                  <img src={tempAvatar}></img>
                  {
                    !location.pathname.startsWith('/users/view') &&

                    <div className='edit-btn'>
                      <input type='file' id="avatar" name="avatar" accept="image/png, image/jpeg" onChange={onChangeAvarat} />
                      {id ? 'Edit' : 'Add'}
                      <img src={EditIcon}></img>
                    </div>
                  }
                </div>
                <ImageCropModal
                  previewCanvasRef={previewCanvasRef}
                  crop={crop}
                  setCrop={setCrop}
                  setCompletedCrop={setCompletedCrop}
                  aspect={aspect}
                  imgRef={imgRef}
                  imgSrc={imgSrc}
                  scale={scale}
                  rotate={rotate}
                  onImageLoad={onImageLoad}
                  completedCrop={completedCrop}
                  open={open}
                  setOpen={setOpen}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  cropImage={cropImage}
                />

              </div>
            </TabContainer>
          }
          {value === 1 &&
            // <TabContainer value={value} index={1} >
            //   <form className='border-2' onSubmit={formik.handleSubmit} style={{ width: '60%', marginLeft: '5%' }}>
            //     <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
            //       <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
            //         Role
            //       </Typography>
            //     </Box>
            //     <SelectFieldComponent
            //       label="Role"
            //       id="roleId"
            //       name="roleId"
            //       menulist={Role}
            //       onChange={formik.handleChange}
            //       value={formik.values.roleId}
            //       autoFocus={false}
            //       isError={Boolean(formik.errors.roleId)}
            //       isTouched={Boolean(formik.touched.roleId)}
            //       errMsg={formik.errors.roleId}
            //       disabled={id ? true : false}
            //       classes="w-full flex items-center mb-4 sm:col-span-2 disabled:cursor-not-allowed"
            //       margin="normal"
            //       fullWidth
            //       required
            //     />
            //     <div className='w-full flex justify-end text-right gap-3  mt-10'>
            //       <Button
            //         type='submit'
            //         onClick={formik.handleSubmit}
            //         className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
            //       >
            //         {id ? 'Update' : 'Submit'}
            //       </Button>
            //       <Button
            //         type='reset'
            //         // onClick={formik.handleReset}
            //         onClick={() => navigate('/')}
            //         className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
            //       >
            //         Cancel
            //       </Button>
            //     </div>
            //   </form>
            // </TabContainer>
            <TabContainer value={value} index={1} >
              <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                <div className='' style={{ width: "60%" }}>
                  <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]'>
                      Role Specifications
                    </Typography>
                    {/* <span>Step {value + 1}/2</span> */}
                  </Box>
                  <div className="eqipment-form">


                    <div className="input-control-main">
                      <label htmlFor="role">Role</label>
                      <div className="control">
                        <select name="roleId" id="roleId"
                          disabled={id ? true : false}
                          className={state?.error && state?.error?.roleId ? "input-error custom-input-control" : "custom-input-control"}

                          onChange={handleChangeForm}>
                          {
                            Role.map((r) => {
                              return (
                                <option value={r.value} selected={r.value === state.roleId} >{r.title}</option>
                              )
                            })
                          }
                        </select>
                        {
                          state?.error && state?.error?.roleId &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.roleId}</span>
                        }
                      </div>
                    </div>

                    {/* 
                    {
                      !location.pathname.startsWith('/users/view') &&
                      <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>
                        <Button
                          type='reset'
                          onClick={() => setValue(0)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                          {'Back'.toLocaleLowerCase()}
                        </Button>
                        <Button
                          type='button'
                          onClick={() => goToNextStep(1)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                        >
                          {'Submit'.toLocaleLowerCase()}
                        </Button>

                      </div>
                    } */}
                  </div>
                </div>

              </div>


            </TabContainer>
          }
          {value === 2 &&
            // <TabContainer value={value} index={2} >
            //   <form className='border-2' onSubmit={formik.handleSubmit} style={{ width: '60%', marginLeft: '5%' }}>
            //     <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
            //       <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
            //         Password Details
            //       </Typography>
            //     </Box>
            //     <PasswordInputField
            //       type={showPassword ? 'text' : 'password'}
            //       showPassword={showPassword}
            //       label="Old Password"
            //       id="old_password"
            //       placeholder="Old Password"
            //       classes="w-full flex items-center mb-4"
            //       name="oldPassword"
            //       onChange={formik.handleChange}
            //       handleClickShowPassword={() => setShowPassword(!showPassword)}
            //       value={formik.values.oldPassword}
            //       autoFocus={false}
            //       isError={Boolean(formik.errors.oldPassword)}
            //       isTouched={Boolean(formik.touched.oldPassword)}
            //       errMsg={formik.errors.oldPassword}
            //       margin="normal"
            //       fullWidth
            //       required
            //     />
            //     <PasswordInputField
            //       type={showPassword ? 'text' : 'password'}
            //       showPassword={showPassword}
            //       label="New Password"
            //       id="password"
            //       placeholder="New Password"
            //       classes="w-full flex items-center mb-4"
            //       name="newPassword"
            //       onChange={formik.handleChange}
            //       handleClickShowPassword={() => setShowPassword(!showPassword)}
            //       value={formik.values.newPassword}
            //       autoFocus={false}
            //       isError={Boolean(formik.errors.newPassword)}
            //       isTouched={Boolean(formik.touched.newPassword)}
            //       errMsg={formik.errors.newPassword}
            //       margin="normal"
            //       fullWidth
            //       required
            //     />
            //     <PasswordInputField
            //       type={showPassword ? 'text' : 'password'}
            //       showPassword={showPassword}
            //       label="Confirm Password"
            //       id="confirm"
            //       placeholder="Confirm Password"
            //       classes="w-full flex items-center mb-4"
            //       name="confirm"
            //       onChange={formik.handleChange}
            //       handleClickShowPassword={() => setShowPassword(!showPassword)}
            //       value={formik.values.confirm}
            //       autoFocus={false}
            //       isError={Boolean(formik.errors.confirm)}
            //       isTouched={Boolean(formik.touched.confirm)}
            //       errMsg={formik.errors.confirm}
            //       margin="normal"
            //       fullWidth
            //       required
            //     />
            //     <div className='w-full flex justify-end text-right gap-3 mt-10'>
            //       <Button
            //         type='submit'
            //         onClick={formik.handleSubmit}
            //         className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
            //       >
            //         {id ? 'Update' : 'Submit'}
            //       </Button>
            //       <Button
            //         type='reset'
            //         // onClick={formik.handleReset}
            //         onClick={() => navigate('/')}
            //         className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
            //       >
            //         Cancel
            //       </Button>
            //     </div>
            //   </form>
            // </TabContainer>
            <TabContainer value={value} index={2} >
              <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                <div className='' style={{ width: "60%" }}>
                  <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]'>
                      Password Details
                    </Typography>
                    {/* <span>Step {value + 1}/2</span> */}
                  </Box>
                  <div className="eqipment-form">


                    <div className="input-control-main">
                      <label htmlFor="oldPassword">Old Password</label>
                      <div className="control">
                        <input type='password'
                          id="oldPassword"
                          name="oldPassword"
                          value={state.oldPassword}
                          onChange={handleChangeForm}
                          placeholder="Old Password"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.oldPassword ? "input-error custom-input-control" : "custom-input-control"}
                        />

                        {
                          state?.error && state?.error?.oldPassword &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.oldPassword}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="newPassword">New Password</label>
                      <div className="control">
                        <input type='password'
                          id="newPassword"
                          name="newPassword"
                          value={state.newPassword}
                          onChange={handleChangeForm}
                          placeholder="New Password"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.newPassword ? "input-error custom-input-control" : "custom-input-control"}
                        />

                        {
                          state?.error && state?.error?.newPassword &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.newPassword}</span>
                        }
                      </div>
                    </div>
                    <div className="input-control-main">
                      <label htmlFor="confirm">Confirm Password</label>
                      <div className="control">
                        <input type='password'
                          id="confirm"
                          name="confirm"
                          value={state.confirm}
                          onChange={handleChangeForm}
                          placeholder="Confirm Password"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.confirm ? "input-error custom-input-control" : "custom-input-control"}
                        />

                        {
                          state?.error && state?.error?.confirm &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.confirm}</span>
                        }
                      </div>
                    </div>


                    {
                      !location.pathname.startsWith('/users/view') &&
                      <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>
                        <Button
                          type='reset'
                          onClick={() => setValue(0)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                          {'Back'.toLocaleLowerCase()}
                        </Button>
                        <Button
                          type='button'
                          onClick={() => goToNextStep(2)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                        >
                          {'Submit'.toLocaleLowerCase()}
                        </Button>

                      </div>
                    }
                  </div>
                </div>

              </div>


            </TabContainer>
          }
        </Box>


      </div>
    </Box>
  )
}

export default PersonalDetail
  ;