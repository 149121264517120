import { useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import * as AuthServices from "../../../stores/services/authApis";
import * as DashboardServices from "../../../stores/services/dashboardApis";
import { useFormik } from "formik";
import { setLoginAction, setLogoutAction, setReloadAction } from "../../../stores/actionTypes/authActions";
import { setClearDashboard, setProjectParam } from "../../../stores/actionTypes/dashboardActions";
import { setClearUserData } from "../../../stores/actionTypes/userActions";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../../constants/AlertMessage";
import { setClearEquipmentData } from "../../../stores/actionTypes/equipmentActions";
import * as Yup from "yup";
import { RequiredMessage } from "../../../constants/validationMessage";
import ToastAlert from "constants/ToastAlert";

const InititalLoginDetail = {
    username: "",
    password: "",
    isKeepLogin: false
};

const LoginSchema = Yup.object({
    username: Yup.string().email().required(RequiredMessage),
    password: Yup.string().min(5).required(RequiredMessage)
})

export const useHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let url = localStorage.getItem('url');
    const { token } = useSelector(state => state.auth);
    const { handleLogin } = bindActionCreators(AuthServices, dispatch);
    const { handleGetProjectParam } = bindActionCreators(DashboardServices, dispatch);
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: InititalLoginDetail,
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            await handleLogin(values)
                .then(response => {
                    const { success, error, data } = response;
                    const { token } = data;
                    if (!success) {
                        ToastAlert({ msg: error, msgType: "info" })
                        return;
                    }
                    handleGetProjectParam(token,{
                        limit: 100,
                        offset: 0
                    }).then((res) => {
                        // console.log('res-project-api', res);
                        if (res.success) {
                            let powerIdIndetifier = "globalLoad";
                            let consumptionPatternIdIndetifier = "globalImportkWh";
                            let powerId = '';
                            let consumptionPatternId = '';
                            powerId = res.projectparams.find(el => el.identifier === powerIdIndetifier)?.param_id || '';
                            consumptionPatternId = res.projectparams.find(el => el.identifier === consumptionPatternIdIndetifier)?.param_id || '';
                            let projectParamDict = { powerId: powerId, consumptionPatternId: consumptionPatternId };
                            dispatch(setProjectParam(projectParamDict))
                        }
                    }).catch((err) => {
                        console.log('err-project-api', err);
                    })
                    const { RoleId, exp } = jwt(token);
                    const loginData = {
                        data: token,
                        role: RoleId,
                        isKeep: values.isKeepLogin,
                        expTime: exp
                    }
                    dispatch(setLoginAction(loginData));
                    if (url) {
                        window.location.href = url
                    }
                })
                .catch(err => {
                    const error = err?.response;
                    ToastAlert({ msg: error?.data?.description || error?.data, msgType: "info" })
                    throw err;
                })
        }
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const logoutUser = async () => {
        await AuthServices.handleLogout(token);
        dispatch(setClearDashboard());
        dispatch(setClearUserData());
        dispatch(setClearEquipmentData());
        dispatch(setLogoutAction());
        localStorage.removeItem('persist:root');
        localStorage.clear();
        navigate('/login', { state: { action: "logout" } })
    }

    const reloadHeader = async (data) => {
        dispatch(setReloadAction(data));
    }

    return {
        formik,
        showPassword,
        handleClickShowPassword,
        logoutUser,
        reloadHeader
    }
}