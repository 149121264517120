import React from "react";
import useResizeObserver from "use-resize-observer";

export default function mergeRefs(...refs) {
    return (instance) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(instance);
            } else if (ref != null) {
                ref.current = instance;
            }
        });
    };
}


const style = {
    flex: 1,
    width: "100%",
    height: "100%",
    minHeight: 0,
    minWidth: 0,
};

export const FillFlexParent = React.forwardRef(function FillFlexParent(
    props,
    forwardRef
) {
    const { ref, width, height } = useResizeObserver();
    console.log('height', height, width);
    return (
        <div style={style} ref={mergeRefs(ref, forwardRef)}>
            {width && height ? props.children({ width, height }) : null}
        </div>
    );
});