import React from 'react'
import { Button, Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useHook } from "./useHook";
import TextFieldComponent, { PasswordInputField } from "../../../components/formFields/textFieldComponent";
import { Routes } from "../../../routes/appRoutes";
import { ValidationMessage } from "../../../components/errorMessage/validationErrorMessage";
import OtpTimer from "otp-timer";
import { AuthLayout } from 'components';

const ForgotPassword = React.memo(() => {

    const { formik, resendOTP, showPassword, handleClickShowPassword, resend } = useHook();

    return (
        <AuthLayout>
            <Grid
                container
                className="flex flex-col items-left justify-left mb-5"
            >
                <Typography component="h1" variant="h5" fontWeight="bold" className="text-[#1A202C] text-[30px] Mediumlg:text-[24px] font-bold">
                    Forgot Password
                </Typography>
                <Typography className="text-[16px] text-[#2D3748]">Don’t Worry, We will help you.</Typography>
                <Typography className="text-[14px] text-[#1D47FF] bg-[#03EDA5] bg-opacity-[0.12] p-[12px] font-medium leading-[1.2] mt-[20px]">Please enter your email or mobile number. You will receive a OTP message to verify your details and reset password.</Typography>
            </Grid>
            <Box component="form" className="grid gap-y-4 m-0" noValidate width="100%" mt={1}>
                <TextFieldComponent
                    type="text"
                    label="Email / Mobile Number"
                    id="data"
                    placeholder="example@email.com"
                    name="data"
                    onChange={formik.handleChange}
                    value={formik.values.data}
                    autoFocus={false}
                    isError={Boolean(formik.errors.data)}
                    isTouched={Boolean(formik.touched.data)}
                    errMsg={formik.errors.data}
                    disabled={formik.values.isData}
                    margin="normal"
                    fullWidth
                    required
                />

                {
                    formik.values.isData && !formik.values.isOtpVerified &&
                    <>
                        <Typography className="text-[14px] text-[#1D47FF] bg-[#03EDA5] bg-opacity-[0.12] p-[12px] font-medium leading-[1.2]">
                            An OTP(One Time Password) has been send to {formik.values.data}. Please enter the OTP below to verify your details.
                        </Typography>
                        <div>
                            <div className={`otp-number ${Boolean(formik.errors.otp) && Boolean(formik.touched.otp) ? 'is-error' : ''}`}>
                                <OtpInput
                                    value={formik.values.otp}
                                    onChange={value => formik.setFieldValue('otp', value)}
                                    numInputs={6}
                                    // separator={<span>-</span>}
                                    inputStyle={{
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "0 1rem",
                                        fontSize: "1rem",
                                        // borderRadius: 4,
                                        // border: "1px solid rgba(0,0,0,0.3)",
                                        border: 'unset',
                                        borderBottom: Boolean(formik.errors.otp) ? '1px solid #ab0505 ' : '1px solid',
                                        width: '15px',
                                    }}
                                />
                            </div>
                            <ValidationMessage
                                isError={Boolean(formik.errors.otp)}
                                isTouch={Boolean(formik.touched.otp)}
                                message={formik.errors.otp}
                            />
                        </div>
                        <PasswordInputField
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            label="Password"
                            showPassword={showPassword}
                            handleClickShowPassword={handleClickShowPassword}
                            isError={Boolean(formik.errors.password)}
                            isTouched={Boolean(formik.touched.password)}
                            errMsg={formik.errors.password}
                            autoFocus={false}
                            margin="normal"
                            fullWidth
                            required
                        />
                        <PasswordInputField
                            type={showPassword ? 'text' : 'password'}
                            id="confirm"
                            name="confirm"
                            placeholder="Re-Enter password"
                            value={formik.values.confirm}
                            onChange={formik.handleChange}
                            label="Confirm Password"
                            showPassword={showPassword}
                            handleClickShowPassword={handleClickShowPassword}
                            isError={Boolean(formik.errors.confirm)}
                            isTouched={Boolean(formik.touched.confirm)}
                            errMsg={formik.errors.confirm}
                            autoFocus={false}
                            margin="normal"
                            fullWidth
                            required
                        />
                    </>
                }
                {
                    resend && <div className="resend-otp">
                        <OtpTimer
                            seconds={60}
                            text="Second Left"
                            ButtonText="Resend OTP"
                            resend={() => {
                                resendOTP({ useremail: formik.values.data })
                            }}
                            background={"#fff"}
                            textColor={"#1B59F8"}
                            buttonColor={"#1B59F8"}
                        />
                    </div>
                }
                <Button
                    type="submit"
                    onClick={formik.handleSubmit}
                    className="w-full h-[50px] bg-[#03EDA5] rounded-md text-[#040404] text-base font-bold capitalize"
                >
                    {formik.values.isData ? 'Reset Password' : 'Send OTP'}
                </Button>
            </Box>
            <Grid className="text-center items-center mt-5 Mediumlg:mt-3">
                <Link to={Routes.login} className="no-underline text-[#1B53F9]">
                    Back to sign-in
                </Link>
            </Grid>
        </AuthLayout>
    );
});

export default ForgotPassword;
