import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Divider, Button } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const BreadcrumbList = ({
    tree,
    handleMovePathClick,
    handleCreateGroupClick
}) => {
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const [route, setRoute] = React.useState('');
    const [routeComponent, setRouteComponent] = React.useState(
        []
    );
    const [crumbs, setCrumbs] = React.useState([]);
    const [path, setPath] = React.useState('');
    const [isNode, setIsNode] = React.useState(false);

    const handleArrowClick = (path, children) => {
        console.log(path, children);
        setRoute(path);
        setPath('')
        setIsNode(false)
        // let modifiedChildren = children.filter(el => el?.children && el?.children.length > 0)
        let modifiedChildren = children.map(el => (el?.children && el?.children.length > 0 ? { ...el, isFolder: true } : { ...el, isFolder: false }))
        setRouteComponent(modifiedChildren);
    };

    const handleRootPathClick = (e) => {
        e.preventDefault();
        setRoute('');
        setPath('')
        setIsNode(false)
        // let modifiedChildren = tree.children.filter(el => el?.children && el?.children.length > 0)
        let modifiedChildren = tree.children.map(el => (el?.children && el?.children.length > 0 ? { ...el, isFolder: true } : { ...el, isFolder: false }))
        setRouteComponent(modifiedChildren || []);
    }

    const handleListItemClick = (e, el) => {
        e.preventDefault();
        e.stopPropagation();
        setPath(el.url);
        if (el?.children && el?.children.length > 0) {
            setIsNode(false)
        } else {
            setIsNode(true)
        }
    }

    const Breadcrumb = ({ crumbs, url, handleItemClick }) => {

        let pathList = url.split("/");
        pathList.shift();
        console.log('pathList', pathList);

        const handleClick = (pathEl) => {
            const routeMeta = crumbs.find(el => el.name == pathEl) || null;
            handleItemClick(routeMeta.url, routeMeta.children)
        }

        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {pathList.map((pathEl, index) => (
                        <React.Fragment key={index}>
                            <li className={`breadcrumb-item ${index === pathList.length - 1 ? 'active' : ''}`}>
                                {index !== pathList.length - 1 && <span onClick={() => handleClick(pathEl)} style={{ cursor: 'pointer', color: 'grey' }}>{pathEl}</span>}
                                {index === pathList.length - 1 && pathEl}
                            </li>
                            {index !== pathList.length - 1 && <li aria-hidden="true" className="breadcrumb-item-arrow"> {" > "} </li>}
                        </React.Fragment>
                    ))}
                </ol>
            </nav>
        )
    };

    const nestedArrayToBreadcrumb = (data) => {
        const buildCrumbs = (data) => {
            const crumbs = [];
            if (data && data.length) {
                data.forEach((item) => {
                    const { name, url, children } = item;
                    if (url && name) {
                        crumbs.push({ name, url, children });
                    }
                    if (children && children.length) {
                        crumbs.push(...buildCrumbs(children));
                    }
                });
            }
            return crumbs;
        };

        const crumbs = buildCrumbs(data);
        console.log('crumbs', crumbs)
        setCrumbs(crumbs);
    };

    React.useEffect(() => {
        if (tree.children && tree.children.length > 0) {
            // let modifiedChildren = tree.children.filter(el => el?.children && el?.children.length > 0)
            let modifiedChildren = tree.children.map(el => (el?.children && el?.children.length > 0 ? { ...el, isFolder: true } : { ...el, isFolder: false }))
            setRouteComponent(modifiedChildren);
        }
        nestedArrayToBreadcrumb(tree.children);
    }, [])

    console.log('routeComponent', routeComponent);
    return (
        <Box>
            <Demo>
                <Box>
                    <ListItem sx={{ background: 'rgba(66, 66, 66, 0.05)' }}>
                        {true && (
                            <ListItemIcon onClick={handleRootPathClick} sx={{ cursor: "pointer" }}>
                                <KeyboardBackspaceIcon />
                            </ListItemIcon>
                        )}
                        <Breadcrumb crumbs={crumbs} url={route} handleItemClick={handleArrowClick} />
                    </ListItem>
                    <Divider />
                    <List dense={dense} sx={{ paddingLeft: 7, maxHeight: 250, overflow: 'auto' }}>
                        {routeComponent.map((el, i) => {
                            // let checkChildren = el?.children && el?.children.some(el => el?.children && el?.children.length > 0)
                            let checkChildren = el?.children && el?.children.length > 0;
                            return (
                                <React.Fragment key={i}>
                                    <ListItem
                                        onClick={(e) => { handleListItemClick(e, el) }}
                                        sx={{ cursor: "pointer" }}
                                        className={path == el?.url ? 'node-path-active' : 'node-path-item'}
                                        secondaryAction={checkChildren ?
                                            (<IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleArrowClick(el.url, el?.children);
                                                    setPath('');
                                                    setIsNode(false);
                                                }
                                                }
                                            >
                                                <KeyboardArrowRightIcon />
                                            </IconButton>) : null
                                        }
                                    >
                                        <ListItemIcon>
                                            {(el?.isFolder || el?.url === 'Root') ? <FolderOutlinedIcon /> : <FiberManualRecordIcon style={{ color: el?.status ? '#02F5A0' : '#FF4438' }} />}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={el.name}
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                    <ListItem
                        sx={{
                            background: 'rgba(66, 66, 66, 0.05)',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ListItemIcon
                            sx={{ paddingLeft: '20px', cursor: 'pointer' }}
                            onClick={() => handleCreateGroupClick(path, false)}
                        >
                            <CreateNewFolderOutlinedIcon sx={{ width: 30, height: 30 }} />
                        </ListItemIcon>
                        <Button
                            disabled={path ? false : true}
                            onClick={() => handleMovePathClick(path, isNode)}
                            variant="contained" className='move-btn-dialog'
                            sx={{ background: '#03EDA5', color: '#040404', fontWeight: 700, marginRight: '10px' }}>Move</Button>
                    </ListItem>
                </Box>
            </Demo>
        </Box>
    );
}
