import { createBrowserRouter, Navigate } from "react-router-dom";
import { ProtectRoute, IsLoggedInRedirectionRoute } from "../components/ProtectedRoute";
import Dashboard from "pages/dashboard";
import Login from "pages/auth/login";
import ForgotPassword from "pages/auth/forgot-password";
import { Routes } from "./appRoutes";
import UserList from "../pages/user/add";
import Equipment from "../pages/equipments/equipments"
import AddUser from "../pages/user/add/addUser";
import UserDetail from "../pages/user/add/personal_detail";
import EquipmentDetail from "../pages/equipments/equipmentDetail";
// import ChartView from "../pages/chartView/chartView";
import ChartView from "../pages/chartView";
import MapView from "../pages/mapView/mapview";
import AddEditEquipment from "pages/equipments/addEdit";
import MoveEquipment from "pages/equipments/move";
import CreateGroup from "pages/equipments/createGroup";
import DeviceProfile from "pages/deviceProfile";
import DeviceConfig from "pages/deviceProfile/add";
import ViewEquipment from "pages/equipments/viewEdquipment";

const Routers = createBrowserRouter([
  {
    path: Routes.emptyPath,
    element: <Navigate to={Routes.dashboard} />,
  },
  {
    path: Routes.login,
    element: <IsLoggedInRedirectionRoute component={Login} />
  },
  {
    path: Routes.forgotPassword,
    element: <IsLoggedInRedirectionRoute component={ForgotPassword} />
  },
  {
    path: Routes.dashboard,
    element: <ProtectRoute component={Dashboard} />
  },
  {
    path: Routes.people,
    element: <ProtectRoute component={UserList} />
  }, {
    path: Routes.addUser,
    element: <ProtectRoute component={AddUser} />
  },
  {
    path: `${Routes.userDetail}/:id`,
    element: <ProtectRoute component={UserDetail} />
  },
  {
    path: Routes.equipments,
    element: <ProtectRoute component={Equipment} />
  },
  {
    path: `${Routes.equipments}/:id`,
    element: <ProtectRoute component={ViewEquipment} />
  },
  {
    path: `${Routes.editUser}/:id`,
    element: <ProtectRoute component={AddUser} />
  },
  {
    path: `${Routes.viewUser}/:id`,
    element: <ProtectRoute component={AddUser} />
  },
  {
    path: `${Routes.equipmentsDetail}/:id`,
    element: <ProtectRoute component={EquipmentDetail} />
  },
  {
    path: `${Routes.viewChart}`,
    element: <ProtectRoute component={ChartView} />
  },
  {
    path: `${Routes.fullViewChart}`,
    element: <ProtectRoute component={ChartView} />
  },
  {
    path: `${Routes.addEquipment}`,
    element: <ProtectRoute component={AddEditEquipment} />
  },
  {
    path: `${Routes.editEquipment}/:id`,
    element: <ProtectRoute component={AddEditEquipment} />
  },
  {
    path: `${Routes.moveEquipment}/:id`,
    element: <ProtectRoute component={MoveEquipment} />
  },
  {
    path: `${Routes.createGroup}/:id`,
    element: <ProtectRoute component={CreateGroup} />
  },
  {
    path: `${Routes.viewMap}`,
    element: <ProtectRoute component={MapView} />
  },
  {
    path: `${Routes.deviceProfile}`,
    element: <ProtectRoute component={DeviceProfile} />
  },
  {
    path: `${Routes.addDevice}`,
    element: <ProtectRoute component={DeviceConfig} />
  },
  {
    path: `${Routes.editDevice}/:id`,
    element: <ProtectRoute component={DeviceConfig} />
  },
]);

export default Routers;