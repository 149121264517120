import Swal from 'sweetalert2'

async function AlertMessage({ msg, icon = null }) {

    let alertConfig = {}
    alertConfig.title = msg
    alertConfig.position = 'top-end'
    alertConfig.showCloseButton = true

    if (icon) {
        alertConfig.icon = icon
    }

    alertConfig.showConfirmButton = false
    alertConfig.timer = 3000

    Swal.fire(alertConfig)
}



export default AlertMessage;