import moment from "moment";
import Permissions from "./Permissions";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import {
  SLUG_POWER_FACTOR_BARCHART,
  SLUG_THD_BARCHART,
  SLUG_FREQUENCY_BARCHART,
  SLUG_VOLTAGE_BARCHART,
  BAR_GREEN_COLOR,
  BAR_YELLOW_COLOR,
  BAR_RED_COLOR,
  BAR_GREY_COLOR,
  POWER_BARCHART_GREEN_RANGE_VALUE,
  POWER_BARCHART_YELLOW_RANGE_VALUE,
  POWER_BARCHART_RED_RANGE_VALUE,
  THD_BARCHART_GREEN_RANGE_VALUE,
  THD_BARCHART_RED_RANGE_VALUE,
  THD_BARCHART_YELLOW_RANGE_VALUE,
  VOLATAGE_BARCHART_GREEN_RANGE_START_VALUE,
  VOLATAGE_BARCHART_YELLOW_RANGE_START_VALUE,
  VOLATAGE_BARCHART_RED_RANGE_END_VALUE,
  VOLATAGE_BARCHART_GREEN_RANGE_END_VALUE,
  VOLATAGE_BARCHART_RED_RANGE_START_VALUE,
  FREQUENCY_BARCHART_GREEN_RANGE_START_VALUE,
  FREQUENCY_BARCHART_YELLOW_RANGE_START_VALUE,
  FREQUENCY_BARCHART_RED_RANGE_START_VALUE,
  FREQUENCY_BARCHART_GREEN_RANGE_END_VALUE,
  FREQUENCY_BARCHART_YELLOW_RANGE_END_VALUE,
  FREQUENCY_BARCHART_RED_RANGE_END_VALUE,
  VOLATAGE_BARCHART_YELLOW_RANGE_END_VALUE,
  BAR_GREY_OFFSET_COLOR,
  BAR_RED_OFFSET_COLOR,
  BAR_YELLOW_OFFSET_COLOR,
  BAR_GREEN_OFFSET_COLOR
} from "./variables";
var CryptoJS = require("crypto-js");


let helper = {
  AuthToken: localStorage.getItem("AuthToken"),
}

export default helper;



export function GetAllDateBetweenDates(startDate, endDate) {
  var dates = [];
  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;

};

export function replaceAll(string, search, replace) {
  return string.split(search).join(replace)
}

// export function encryptData(data) {
//   var encryptText = CryptoJS.AES.encrypt(data.toString(), 'secret key 123').toString();
//   let encrypted = replaceAll(encryptText, "/", "UNITGRID");
//   encrypted = replaceAll(encryptText, "+", "UNIT_GRID_PLUS");
//   return encrypted;
// }

export function encryptData(data) {
  let encoded = base64_encode(data);
  return encoded
}

export function decryptData(data) {
  let decoded = base64_decode(data);
  return decoded
}


// export function decryptData(data) {
//   let replaceText = replaceAll(data, "UNITGRID", "/")
//   replaceText = replaceAll(data, "UNIT_GRID_PLUS", "+")
//   let decryptText = CryptoJS.AES.decrypt(replaceText, 'secret key 123');
//   let decrypted = decryptText.toString(CryptoJS.enc.Utf8);
//   return decrypted;
// }

export function hasPermission(ROLE, MODULE) {
  let RESPONCE;
  if (Permissions[ROLE]) {
    RESPONCE = Permissions[ROLE].includes(MODULE);

  } else {
    RESPONCE = false;
  }
  return RESPONCE;

}

export const generateNodeName = (id) => {
  let name = id?.substring(0, 5);
  return name = 'Node - ' + name
}


export const checkEquipmetIsActive = (time, minuts = 30) => {
  var date = new Date();
  var last = new Date(time);
  if ((date - last) < (minuts * 60 * 1000)) {
    return true
  } else {
    return false
  }
}

export const checkApiCallTime = (time, minuts = 30) => {
  var date = new Date();
  var last = new Date(time);
  if ((date - last) > (minuts * 60 * 1000)) {
    return true
  } else {
    return false
  }
}

export function unixTimeToLocal(unixtime) {

  var u = new Date(unixtime * 1000);

  return {
    day: (u.getUTCDate()),
    month: (u.getUTCMonth()),
    year: (u.getUTCFullYear()),

  }

  // return u.getUTCFullYear() +
  //   '-' + ('0' + u.getUTCMonth()).slice(-2) +
  //   '-' + ('0' + u.getUTCDate()).slice(-2) +
  //   ' ' + ('0' + u.getUTCHours()).slice(-2) +
  //   ':' + ('0' + u.getUTCMinutes()).slice(-2) +
  //   ':' + ('0' + u.getUTCSeconds()).slice(-2) +
  //   '.' + (u.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
};
export const toUnixTime = (year, month, day, hr, min, sec) => {
  const date = new Date(Date.UTC(year, month - 1, day, hr, min, sec));
  return Math.floor(date.getTime() / 1000);
}


export const devideArray = (arr) => {
  var a = arr;

  var arrays = [], size = 50;

  while (a.length > 0) {
    arrays.push(a.splice(0, size))
  }
  return arrays
}


export const createLocationLink = (lat, lng) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

export const isDateIsOlder = (date, day) => {
  const pastTime = new Date(date);
  const now = new Date();

  const thirtyDaysInMs = day * 24 * 60 * 60 * 1000;

  const timeDiffInMs = now.getTime() - pastTime.getTime();

  if (timeDiffInMs >= thirtyDaysInMs) {
    return true
  } else {
    return false
  }
}


export const hasEditDelPermission = (currentUserRole, userRole) => {
  if (currentUserRole === 'SUPER_ADMIN') {
    return true
  } else {
    if (userRole !== 'ADMIN') {
      return true
    } else {
      return false
    }

  }

}


export const getFromDate = (period) => {
  if (period) {

    let time = period?.split('_')
    if (time.length) {
      let date;


      switch (time[1]) {
        case 'MINUTS':
          date = new Date(Date.parse(new Date()) - (new Date((Number(time[0]) * 60 * 1000))));
          break;
        case 'HOUR':
          date = new Date(new Date().setHours(new Date().getHours() - Number(time[0])));
          break;
        case 'DAYS':
          date = new Date(Date.now() - Number(time[0]) * 24 * 60 * 60 * 1000);
          break;

      }

      return date

    }
  }

}

export function sumAvg(tempArray) {
  let tempavgsum = 0;
  let finalSum = '';
  for (let i = 0; i < tempArray.length; i++) {
    tempavgsum += tempArray[i];
  }
  if (tempArray.length > 0) {
    finalSum = Number(tempavgsum / tempArray.length).toFixed(2);
  }
  return finalSum;
}

export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month, 1);
  const dates = [];
  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

export const years = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  years.sort((a, b) => (a > b ? -1 : 1))
  return years;
};

export function getPowerFactoreColor(value){
  if(value > POWER_BARCHART_GREEN_RANGE_VALUE){
    return BAR_GREEN_COLOR
  } else if(value >= POWER_BARCHART_YELLOW_RANGE_VALUE && value <= POWER_BARCHART_GREEN_RANGE_VALUE){
    return BAR_YELLOW_COLOR
  } else if(value < POWER_BARCHART_RED_RANGE_VALUE) {
    return BAR_RED_COLOR
  } else {
    return BAR_GREY_COLOR
  }
}

export function getTHDColor(value){
  if(value < THD_BARCHART_GREEN_RANGE_VALUE){
    return BAR_GREEN_COLOR
  } else if(value >= THD_BARCHART_GREEN_RANGE_VALUE && value <= THD_BARCHART_YELLOW_RANGE_VALUE){
    return BAR_YELLOW_COLOR
  } else if(value > THD_BARCHART_RED_RANGE_VALUE) {
    return BAR_RED_COLOR
  } else {
    return BAR_GREY_COLOR
  }
}

export function getVoltageColor(value){
  if(value >= VOLATAGE_BARCHART_GREEN_RANGE_START_VALUE && value <= VOLATAGE_BARCHART_GREEN_RANGE_END_VALUE){
    return BAR_GREEN_COLOR
  } else if((value >= VOLATAGE_BARCHART_YELLOW_RANGE_START_VALUE && value < VOLATAGE_BARCHART_GREEN_RANGE_START_VALUE) || (value > VOLATAGE_BARCHART_GREEN_RANGE_END_VALUE && value <= VOLATAGE_BARCHART_YELLOW_RANGE_END_VALUE)){
    return BAR_YELLOW_COLOR
  } else if(value > VOLATAGE_BARCHART_RED_RANGE_END_VALUE || value < VOLATAGE_BARCHART_RED_RANGE_START_VALUE) {
    return BAR_RED_COLOR
  } else {
    return BAR_GREY_COLOR
  }
}

export function getFrequencyColor(value){
  if(value >= FREQUENCY_BARCHART_GREEN_RANGE_START_VALUE && value <= FREQUENCY_BARCHART_GREEN_RANGE_END_VALUE){
    return BAR_GREEN_COLOR
  } else if((value > FREQUENCY_BARCHART_YELLOW_RANGE_START_VALUE && value <= FREQUENCY_BARCHART_YELLOW_RANGE_END_VALUE)){
    return BAR_YELLOW_COLOR
  } else if(value > FREQUENCY_BARCHART_RED_RANGE_START_VALUE || value < FREQUENCY_BARCHART_RED_RANGE_END_VALUE) {
    return BAR_RED_COLOR
  } else {
    return BAR_GREY_COLOR
  }
}

export function getBarColor(type, value) {
  switch (type) {
    case SLUG_POWER_FACTOR_BARCHART:
      return getPowerFactoreColor(value);
    case SLUG_THD_BARCHART:
      return getTHDColor(value);
    case SLUG_FREQUENCY_BARCHART:
      return getFrequencyColor(value);
    case SLUG_VOLTAGE_BARCHART:
      return getVoltageColor(value);
    default:
      return BAR_GREEN_COLOR
  }
}

export function getOffsetColor(color) {
  switch (color) {
    case BAR_GREEN_COLOR:
      return BAR_GREEN_OFFSET_COLOR;
    case BAR_YELLOW_COLOR:
      return BAR_YELLOW_OFFSET_COLOR;
    case BAR_RED_COLOR:
      return BAR_RED_OFFSET_COLOR;
    default:
      return BAR_GREY_OFFSET_COLOR
  }
}



