import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Routes } from 'routes/appRoutes'

export const SplashScreenLayout = () => {

    return (
        <Box className=""
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 20%",
                // minHeight: "100vh",
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexDirection: 'row', fontWeight: 600, fontSize: 24, lineHeight: '35px', color: '#2D3748', width: '40rem', justifyContent: 'center', marginBottom: '34px' }}>
                Welcome!
            </Typography>
            <Typography variant="overline" display="block" gutterBottom sx={{ fontWeight: 400, fontSize: 18, lineHeight: '31px', color: '#2D3748', textAlign: 'center' }}>
                There is no device associated with this device.
            </Typography>
            <Typography variant="overline" display="block" gutterBottom sx={{ fontWeight: 400, fontSize: 18, lineHeight: '31px', color: '#2D3748', textAlign: 'center', marginBottom: '70px' }}>
                Click on the button below to start adding new sensors.
            </Typography>
            <Link to={Routes.addDevice} className=''>
                <Button
                    class="filterConditionSaveBtn filter-footer-btn"
                    style={{ cursor: "pointer", border: "unset", width: '230px' }}>
                    Add New Device
                </Button>
            </Link>
        </Box>
    )
}


