import React from 'react';
import { TextField, InputLabel, InputAdornment, IconButton, Select, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ValidationMessage } from '../../errorMessage/validationErrorMessage';

const TextFieldComponent = ({ label, isError, isTouched, errMsg, classes = '', inputClasses = '', isRequired = true, ...props }) => {
  return (
    <div className={classes}>
      <InputLabel className="w-[200px] text-[#4A5568] font-normal text-[16px]">
        {label} 
        {/* {isRequired && <span className='text-md text-[#ab0505]'>*</span>} */}
      </InputLabel>
      <div className='w-full'>
        <TextField
          className={`m-0 input-border ${inputClasses} ${isError && isTouched ? 'is-error' : ''} input-field`}
          autoComplete='off'
          {...props}
          style={{marginTop:"0px"}}
          sx={{
            bgcolor: "#FFFFFF",
            border: 0,
            "& input": {
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "14.5px",
              height: "1px",
            },
            "& fieldset": { border: "none" },
            ":hover": {
              "& input": { bgcolor: "rgba(217, 217, 217, 0.3)" },
            },
            ":active": {
              "& input": {
                bgcolor: "#ffffff",
              },
            },
          }}
        />
        <ValidationMessage
          isError={isError}
          isTouch={isTouched}
          message={errMsg}
        />
      </div>
    </div>
  )
}

export default TextFieldComponent

export const PasswordInputField = ({
  showPassword,
  handleClickShowPassword,
  label,
  classes = '',
  isError,
  isTouched,
  errMsg,
  ...props
}) => {
  return (
    <div className={classes}>
      <InputLabel className="w-[200px] text-[#4A5568] font-normal text-[16px]">
        {label}
      </InputLabel>
      <div className='w-full'>
        <TextField
          className={`m-0 input-border ${isError && isTouched ? 'is-error' : ''}`}
          autoComplete='off'
          {...props}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            bgcolor: "#FFFFFF",
            border: 0,
            "& input": {
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "14.5px",
              height: "1px",
            },
            "& fieldset": { border: "none" },
            ":hover": {
              "& input": { bgcolor: "rgba(217, 217, 217, 0.3)" },
            },
            ":active": {
              "& input": {
                bgcolor: "#ffffff",
              },
            },
          }}
        />
        <ValidationMessage
          isError={isError}
          isTouch={isTouched}
          message={errMsg}
        />
      </div>
    </div>
  )
}

export const SelectFieldComponent = ({
  label,
  isError,
  isTouched,
  errMsg,
  classes = '',
  menulist,
  selectClass ='',
  isRequired = true,
  ...props
}) => {
  return (
    <div className={classes}>
      <InputLabel className="w-[200px] text-[#4A5568] font-normal text-[16px]">
        {label} {isRequired && <span className='text-md text-[#ab0505]'>*</span>}
      </InputLabel>
      <div className='w-full'>
        <Select
          placeholder='Select'
          className={`m-0 custom-style input-border ${isError && isTouched ? 'is-error' : ''}${selectClass}`}
          autoComplete='off'
          {...props}
          sx={{
            select: {
              "& ul": {
                backgroundColor: "#cccccc",
              },
              "& li": {
                fontSize: 12,
              },
            },
            bgcolor: "#FFFFFF",
            border: 0,
            "& button": {
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "14.5px",
              height: "1px",
            },
            // "& fieldset": { border: "none" },
            ":hover": {
              "& button": { bgcolor: "rgba(217, 217, 217, 0.3)" },
            },
            ":active": {
              bgcolor: "#ffffff",
              "& input": {
                borderColor: "#4A5568 !important",
              },
            },
          }}
        >
          {
            menulist.map((item, index) => <MenuItem key={`${item.title}_${index}`} value={item.value}>{item.title}</MenuItem>)
          }
        </Select>
        <ValidationMessage
          isError={isError}
          isTouch={isTouched}
          message={errMsg}
        />
      </div>
    </div>
  )
}
