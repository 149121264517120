import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Toolbar, Typography, Tabs, Tab, } from '@mui/material';
import TextFieldComponent,
{
  PasswordInputField,
  SelectFieldComponent
} from '../../../components/formFields/common/textFieldComponent';
import { useUserHook } from '../useUserHook';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/edit.svg"
import AvatartImg from "../../../assets/User-avatar.png"
import jwt from "jwt-decode";
import { hasPermission } from '../../../constants/Helper';
import { ROLE } from "../../../constants/Permissions"
import { useSelector } from 'react-redux';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';
import ImageCropModal from './imageCropModal';
import AvatartImg2 from "../../../assets/Group289724.png"
import { TabContainer } from "react-bootstrap";
import PropTypes from 'prop-types';
import iIcon from "../../../assets/iicon.png"
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

// import { Tabs, Tab, Box, Button, Toolbar, Typography } from '@mui/material';


function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const PersonalDetail = () => {
  let modelImg = 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80'
  const { formik, Role, showPassword, setShowPassword, setavatarBase64, value, setValue, goToNextStep, state, handleChangeForm } = useUserHook();
  const { token } = useSelector(state => state.auth);
  const { RoleId } = jwt(token);
  const { id } = useParams();
  const [tempAvatar, setTempAvatar] = useState(state.photo ? state.photo : AvatartImg2)
  const navigate = useNavigate()
  const location = useLocation()

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1)
  const [open, setOpen] = useState(false);
  const [img64, setImg64] = useState('')
  // const [value, setValue] = useState(0);

  // console.log(state.photo)

  const handleChange = (event, newValue) => {
    if (location.pathname.startsWith('/users/view')) {
      setValue(newValue);

    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }



  const onChangeAvarat = (e) => {
    const file = e.target.files[0]
    if (file) {
      // console.log(file)
      // setTempAvatar(URL.createObjectURL(file))
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log('RESULT', reader.result)
        setImgSrc(reader.result?.toString() || '')
      }
      reader.readAsDataURL(file);
      handleClickOpen()
    } else {
      handleClose()
    }

  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        let url = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        // console.log(url)
        setImg64(url)
        // setTempAvatar(url)
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setavatarBase64('')
  };

  const cropImage = () => {
    setavatarBase64(img64)
    setTempAvatar(img64)
    setOpen(false);

  }

  useEffect(() => {
    if (!hasPermission(ROLE[RoleId], 'USERS')) {
      navigate('/')
    }

    setTempAvatar(state.photo ? state.photo : AvatartImg2)
  }, [state.photo]);


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        bgcolor: "#FFFFFF",
      }}
    >

      <Toolbar />
      <div className='py-[60px] px-[32px] bg-white w-full h-full'>
        <Box
          sx={{ bgcolor: 'background.paper', display: 'flex' }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className='tab-main'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab icon={<PersonOutlineOutlinedIcon />} className={value == 0 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Profile" {...a11yProps(0)}
            // style={{ alignItems: "flex-start" }}
            />
            <Tab icon={<PersonOutlineOutlinedIcon />} className={value == 1 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Role" {...a11yProps(1)}
            // style={{ alignItems: "flex-start" }}
            />
          </Tabs>
          {value === 0 &&
            <TabContainer value={value} index={0} >
              <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                <div className='' style={{ width: "60%" }}>
                  <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 3, borderColor: '#1B53F9' }}>
                      {id ? state.first_name + "'s Profile" : "Personal Details"}
                    </Typography>
                    <span>Step {value + 1}/2</span>
                  </Box>
                  <div className="eqipment-form">


                    <div className="input-control-main">
                      <label htmlFor="first_name">First Name</label>
                      <div className="control">
                        <input type='text'
                          id="first_name"
                          name="first_name"
                          value={state.first_name}
                          onChange={(e) => handleChangeForm(e)}
                          placeholder="First Name"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.first_name ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.first_name &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.first_name}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="last_name">Last Name</label>
                      <div className="control">
                        <input type='text'
                          id="last_name"
                          name="last_name"
                          value={state.last_name}
                          onChange={handleChangeForm}
                          placeholder="Last Name"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.last_name ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.last_name &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.last_name}</span>
                        }
                      </div>
                    </div>


                    <div className="input-control-main">
                      <label htmlFor="email">Email</label>
                      <div className="control">
                        <input type='text'
                          id="email"
                          name="email"
                          value={state.email}
                          onChange={handleChangeForm}
                          placeholder="Email"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.email ? "input-error custom-input-control" : "custom-input-control"}
                        />
                        {
                          state?.error && state?.error?.email &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.email}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="mobile">Phone number</label>
                      <div className="control">
                        <input type='text'
                          id="mobile"
                          name="mobile"
                          value={state.mobile}
                          onChange={handleChangeForm}
                          placeholder="Phone number"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.mobile ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.mobile &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.mobile}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="employeeId">Employee Id</label>
                      <div className="control">
                        <input type='text'
                          id="employeeId"
                          name="employeeId"
                          value={state.employeeId}
                          onChange={handleChangeForm}
                          placeholder="Employee Id"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.employeeId ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.employeeId &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.employeeId}</span>
                        }
                      </div>
                    </div>

                    <div className="input-control-main">
                      <label htmlFor="address">Address</label>
                      <div className="control">
                        <input type='text'
                          id="address"
                          name="address"
                          value={state.address}
                          onChange={handleChangeForm}
                          placeholder="Address"
                          disabled={location.pathname.startsWith('/users/view')}
                          className={state?.error && state?.error?.address ? "input-error custom-input-control" : "custom-input-control"}

                        />
                        {
                          state?.error && state?.error?.address &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.address}</span>
                        }
                      </div>
                    </div>

                    {/* <SelectFieldComponent
                      label="Role"
                      id="roleId"
                      name="roleId"
                      menulist={Role}
                      onChange={formik.handleChange}
                      value={formik.values.roleId}
                      autoFocus={false}
                      isError={Boolean(formik.errors.roleId)}
                      isTouched={Boolean(formik.touched.roleId)}
                      errMsg={formik.errors.roleId}
                      disabled={id ? true : false}
                      classes="w-full flex items-center mb-4 sm:col-span-2 disabled:cursor-not-allowed"
                      margin="normal"
                      fullWidth
                      required
                    /> */}
                    {

                      <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>

                        <Button
                          type='reset'
                          onClick={() => navigate('/users')}
                          style={{ textTransform: "capitalize" }}
                          className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                          {'Cancel'.toLocaleLowerCase()}
                        </Button>
                        {
                          !location.pathname.startsWith('/users/view') &&

                          <Button
                            type='button'
                            onClick={() => goToNextStep(0)}
                            style={{ textTransform: "capitalize" }}
                            className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                          >
                            {'Next'.toLocaleLowerCase()}
                          </Button>
                        }

                      </div>
                    }
                  </div>
                </div>



                <div className='img-div'>
                  <img src={tempAvatar}></img>
                  {
                    !location.pathname.startsWith('/users/view') &&

                    <div className='edit-btn'>
                      <input type='file' id="avatar" name="avatar" accept="image/png, image/jpeg" onChange={onChangeAvarat} />
                      {id ? 'Edit' : 'Add'}
                      <img src={EditIcon}></img>
                    </div>
                  }
                </div>

                <ImageCropModal
                  previewCanvasRef={previewCanvasRef}
                  crop={crop}
                  setCrop={setCrop}
                  setCompletedCrop={setCompletedCrop}
                  aspect={aspect}
                  imgRef={imgRef}
                  imgSrc={imgSrc}
                  scale={scale}
                  rotate={rotate}
                  onImageLoad={onImageLoad}
                  completedCrop={completedCrop}
                  open={open}
                  setOpen={setOpen}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  cropImage={cropImage}
                />
              </div>


            </TabContainer>

          }

          {value === 1 &&
            <TabContainer value={value} index={1} >
              <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                <div className='' style={{ width: "60%" }}>
                  <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 3, borderColor: '#1B53F9' }}>
                      Role Specifications
                    </Typography>
                    <span>Step {value + 1}/2</span>
                  </Box>
                  <div className="eqipment-form">


                    <div className="input-control-main">
                      <label htmlFor="role">Role</label>
                      <div className="control">
                        <select name="roleId" id="roleId"
                          disabled={id ? true : false}
                          className={state?.error && state?.error?.roleId ? "input-error custom-input-control" : "custom-input-control"}

                          onChange={handleChangeForm}>
                          {
                            Role.map((r) => {
                              return (
                                <option value={r.value} selected={r.value === state.roleId} >{r.title}</option>
                              )
                            })
                          }
                        </select>
                        {
                          state?.error && state?.error?.roleId &&
                          <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.roleId}</span>
                        }
                      </div>
                    </div>


                    {
                      !location.pathname.startsWith('/users/view') &&
                      <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>
                        <Button
                          type='reset'
                          onClick={() => setValue(0)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                        >
                          {'Back'.toLocaleLowerCase()}
                        </Button>
                        <Button
                          type='button'
                          onClick={() => goToNextStep(1)}
                          style={{ textTransform: "capitalize" }}
                          className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                        >
                          {'Submit'.toLocaleLowerCase()}
                        </Button>

                      </div>
                    }
                  </div>
                </div>

              </div>


            </TabContainer>

          }


        </Box>
      </div>
    </Box>
  )
}

export default PersonalDetail
  ;