import React, { useState, useEffect } from "react";
import * as EquipmentApi from "../../stores/services/equipmentApi";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setSaveNodeMetaData } from "../../stores/actionTypes/equipmentActions";
import { useHook as useLoginHook } from '../../pages/auth/login/useHook';
import AlertMessage from "../../constants/AlertMessage";
import { validateAll } from "indicative/validator";
import { useNavigate, useParams } from "react-router-dom";
import { useHook as useDashboardHook } from "../dashboard/useHook";
import { useHook as useDevicesHook } from "../deviceProfile/useHook";
import ToastAlert from "constants/ToastAlert";
import { EQUIPMENT_TYPES } from "constants/variables";


const initialState = {
    equipment_id: '',
    node_path: '',
    temp_node_path: '',
    node_name: '',
    equipment_type:'',
    node_desc: '',
    address: '',
    total_rated_power: '',
    phases_connected: [],
    geo_co_ordinates: '',
    lat: '',
    lng: '',
    isNode: true,
    description: '',
    ep_id: '',
    profile_id: '',
    node_id: '',
    error: {}
};

const validationrRules = {
    equipment_id: 'required',
    node_name: 'required',
    equipment_type: 'required',
    node_desc: 'required',
    address: 'required',
    total_rated_power: 'number',
    lat: 'number',
    lng: 'number',
    ep_id: 'regex:^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',

}

const validationMessages = {
    'equipment_id.required': 'Equipment Id is Required.',
    'node_name.required': 'Node Name is Required.',
    'equipment_type.required': 'Equipment Type is Required.',
    'node_desc.required': 'Description is Required.',
    'address.required': 'Address is Required.',
    'lat.number': 'Latitude should be a number.',
    'lng.number': 'Longitude should be a number.',
    'ep_id.regex': 'Physical Device ID should be a UUID.',
}

export function useEquipmentHook() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { handleGetNodeMetaData, handleAddEquipment, handleEditEquipment, handleRemoveEquipment, handleGetNodeDetails,handlegetStreetlight} = bindActionCreators(EquipmentApi, dispatch);
    const { fetchConfig } = useDevicesHook();
    const { token } = useSelector(state => state.auth);
    const { logoutUser } = useLoginHook();
    const { fetchNodeList } = useDashboardHook();


    const navigate = useNavigate()

    const [state, setState] = useState(initialState)
    const STREET_LIGHT_SHOW = process.env.REACT_APP_STREET_LIGHT;
    const { phases_connected, temp_node_path } = state

    const fetchNodeMetaData = async (payload) => {
        await handleGetNodeMetaData(token, payload)
            .then(response => {

                if (response.success && Object.keys(response.data).length) {
                    dispatch(setSaveNodeMetaData(response.data));
                } else {
                    dispatch(setSaveNodeMetaData(null));
                }
                return;
            })
            .catch(err => {
                const error = err?.response?.data;
                AlertMessage({ msg: error?.description })
                if (error.description.includes("token is expired")) {
                    logoutUser();
                }
                throw err;
            })
    }

    const fetchgetStreetlight = async (payload) => {
        await handlegetStreetlight(token, payload)
            .then(response => {
             let data=[]
                if (response.success && Object.keys(response.data).length) {
                    // dispatch(setSaveNodeMetaData(response.data));
                    data=Object.values(response.data)
                    console.log(Object.values(response.data),"fetchgetStreetlightfetchgetStreetlight");
                } else {
                    // dispatch(setSaveNodeMetaData(null));
                }
                return data;
            })
            .catch(err => {
                const error = err?.response?.data;
                AlertMessage({ msg: error?.description })
                if (error.description.includes("token is expired")) {
                    logoutUser();
                }
                throw err;
            })
    }


    const handleChangeForm = (e) => {
        let value = e.target.value;

        if (e.target.name === 'equipment_id') {
            let npvalue = '';
            if (temp_node_path && id) {
                let node_path = temp_node_path;
                // let node_path_list = node_path.split("/").pop();
                // node_path = node_path.replace(node_path_list, value);
                npvalue = node_path
            } else if (temp_node_path) {
                npvalue = temp_node_path;
                npvalue = temp_node_path + '/' + value;
            }
            setState({
                ...state,
                node_path: npvalue,
                [e.target.name]: value
            })


        } else {

            setState({
                ...state,
                [e.target.name]: value
            })

        }
    }

    const onClickAddEquipment = () => {

        validateAll(state, validationrRules, validationMessages).then(async () => {
            const formaerrror = {};
            setState({
                ...state,
                error: formaerrror
            })

            let payload = {
                node_name: state.node_name,
                node_desc: state.node_desc,
                metadata: {
                    node_name: state.node_name,
                    node_desc: state.node_desc,
                    equipment_id: state.equipment_id,
                    node_path: state.node_path,
                    equipment_type: state.equipment_type?.value || state.equipment_type,
                    address: state.address,
                    total_rated_power: state.total_rated_power,
                    phases_connected: state.phases_connected,
                    description: state.description,
                    lat: Number(state.lat),
                    lng: Number(state.lng),
                    isNode: true
                }

            }

            if (STREET_LIGHT_SHOW === 'YES' && state.equipment_type?.value === 'street_light') {
                payload.metadata['parent_node'] = state.node_id?.value || ''
            }

            if (state.ep_id) {
                payload['ep_id'] = state.ep_id
            }

            if (state.profile_id && state.profile_id?.value) {
                payload['profile_id'] = state.profile_id?.value
            }
            // console.log('payload', payload);
            await handleAddEquipment(token, payload)
                .then(async response => {
                    const { success, error } = response;
                    if (!success) {
                        AlertMessage({ msg: error })
                        return;
                    } else {
                        dispatch(setSaveNodeMetaData(null));
                        localStorage.removeItem("nodeList");
                        ToastAlert({ msg: "Equipment added successfully", msgType: "info" })
                        navigate("/equipments");                        }
                    })
                .catch(err => {
                    // console.log(err)
                    const error = err?.response;
                    AlertMessage({ msg: error?.data?.description })

                    throw err;
                })


        }).catch(errors => {
            // console.log(errors)
            const formaerrror = {};
            if (errors && errors.length) {
                errors.forEach(element => {
                    formaerrror[element.field] = element.message
                });
            } else {
                AlertMessage({ msg:errors.response.data.error || 'Something went wrong' })
            }

            setState({
                ...state,
                error: formaerrror
            })

        });

    }

    const onClickEditEquipment = async (payload) => {
        try {
            validateAll(state, validationrRules, validationMessages).then(async () => {
                const formaerrror = {};
                setState({
                    ...state,
                    error: formaerrror
                })

                let payload = {
                    node_id: id,
                    metadata: {
                        node_name: state.node_name,
                        node_desc: state.node_desc,
                        equipment_id: state.equipment_id,
                        node_path: state.node_path,
                        equipment_type: state.equipment_type?.value || state.equipment_type,
                        address: state.address,
                        total_rated_power: state.total_rated_power,
                        phases_connected: state.phases_connected,
                        description: state.description,
                        lat: Number(state.lat),
                        lng: Number(state.lng),
                        isNode: state.isNode
                    }
                }


                if (STREET_LIGHT_SHOW === 'YES' && state.equipment_type?.value === 'street_light') {
                    payload.metadata['parent_node'] = state.node_id?.value || ''
                }

                if (state.ep_id) {
                    payload['ep_id'] = state.ep_id
                }
                if (state.profile_id && state.profile_id?.value) {
                    payload['profile_id'] = state.profile_id?.value
                }

                await handleEditEquipment(token, payload)
                    .then(async response => {
                        const { success, error } = response;
                        if (!success) {
                            AlertMessage({ msg: error })
                            return;
                        } else {
                            dispatch(setSaveNodeMetaData(null));
                            localStorage.removeItem("nodeList");
                            ToastAlert({ msg: "Equipment Updated successfully", msgType: "info" })
                            navigate("/equipments");                        }
                    })
                    .catch(err => {
                        const error = err?.response;
                        AlertMessage({ msg: error?.data?.description })
                        throw err;
                    })


            }).catch(errors => {
                const formaerrror = {};
                if (errors && errors.length) {
                    errors.forEach(element => {
                        formaerrror[element.field] = element.message
                    });
                } else {
                    AlertMessage({ msg: errors || errors?.message || 'Something went wrong' })
                }
                setState({
                    ...state,
                    error: formaerrror
                })

            });

        } catch (error) {
            AlertMessage({ msg: error || error?.message || 'Something went wrong' })
        }
    }


   

    const onClickMoveEquipment = async (destinationGroup, isNode = false) => {
        try {
            validateAll(state, validationrRules, validationMessages).then(async () => {
                const formaerrror = {};
                setState({
                    ...state,
                    error: formaerrror
                })
                let node_path = destinationGroup ? (destinationGroup === 'Root' ? `${id}` : `${destinationGroup}/${id}`) : state.node_path;
                let payload = {
                    node_id: id,
                    metadata: {
                        node_name: state.node_name,
                        node_desc: state.node_desc,
                        equipment_id: state.equipment_id,
                        node_path: node_path, //destinationGroup || state.node_path,
                        equipment_type: state.equipment_type?.value || state.equipment_type,
                        address: state.address,
                        total_rated_power: state.total_rated_power,
                        phases_connected: state.phases_connected,
                        description: state.description,
                        lat: Number(state.lat),
                        lng: Number(state.lng),
                        isNode: isNode
                    }
                }

                if (STREET_LIGHT_SHOW === 'YES' && state.equipment_type?.value === 'street_light') {
                    payload.metadata['parent_node'] = state.node_id?.value || ''
                }

                if (state.ep_id) {
                    payload['ep_id'] = state.ep_id
                }
                if (state.profile_id) {
                    payload['profile_id'] = state.profile_id
                }
                console.log('payload', payload);
                // return;
                await handleEditEquipment(token, payload)
                    .then(async response => {
                        const { success, error } = response;
                        if (!success) {
                            AlertMessage({ msg: error })
                            return;
                        } else {
                            dispatch(setSaveNodeMetaData(null));
                            localStorage.removeItem("nodeList");
                            ToastAlert({ msg: "Equipment Moved successfully", msgType: "info" })
                            navigate('/equipments');
                        }
                    })
                    .catch(err => {
                        const error = err?.response;
                        AlertMessage({ msg: error?.data?.description })
                        throw err;
                    })


            }).catch(errors => {
                const formaerrror = {};
                if (errors && errors.length) {
                    errors.forEach(element => {
                        formaerrror[element.field] = element.message
                    });
                } else {
                    AlertMessage({ msg: errors || errors?.message || 'Something went wrong' })
                }
                setState({
                    ...state,
                    error: formaerrror
                })

            });

        } catch (error) {
            AlertMessage({ msg: error || error?.message || 'Something went wrong' })
        }
    }

    const onClickRemoveEquipment = async (nodeid) => {
        try {
            let payload = {
                nodeid: nodeid
            }
            await handleRemoveEquipment(token, payload)
                .then(async response => {
                    const { success, error } = response;
                    if (!success) {
                        AlertMessage({ msg: error })
                        return;
                    } else {
                        dispatch(setSaveNodeMetaData(null));
                        localStorage.removeItem("nodeList");
                        ToastAlert({ msg: "Equipment Removed successfully", msgType: "info" })
                        navigate('/equipments');
                    }
                })
                .catch(err => {
                    const error = err?.response;
                    AlertMessage({ msg: error?.data?.description })
                    throw err;
                })
        } catch (error) {
            AlertMessage({ msg: error || error?.message || 'Something went wrong' })
        }
    }

    const onClickCreateGroupEquipment = () => {
        return;

        validateAll(state, validationrRules, validationMessages).then(async () => {
            const formaerrror = {};
            setState({
                ...state,
                error: formaerrror
            })

            let payload = {
                node_name: state.node_name,
                node_desc: state.node_desc,
                metadata: {
                    node_name: state.node_name,
                    node_desc: state.node_desc,
                    equipment_id: state.equipment_id,
                    node_path: state.node_path,
                    equipment_type: state.equipment_type,
                    address: state.address,
                    total_rated_power: state.total_rated_power,
                    phases_connected: state.phases_connected,
                    description: state.description,
                    lat: state.lat,
                    lng: state.lng,
                    // lat: state?.geo_co_ordinates?.split(',').length ? state?.geo_co_ordinates?.split(',')[0] : '',
                    // lng: state?.geo_co_ordinates?.split(',').length ? state?.geo_co_ordinates?.split(',')[1] : ''
                }

            }

            await handleAddEquipment(token, payload)
                .then(async response => {
                    const { success, error } = response;
                    if (!success) {
                        AlertMessage({ msg: error })
                        return;
                    } else {
                        dispatch(setSaveNodeMetaData(null));
                        localStorage.removeItem("nodeList");
                        AlertMessage({ msg: "Equipment added successfully" })
                        navigate('/equipments');
                    }
                })
                .catch(err => {
                    // console.log(err)
                    const error = err?.response;
                    AlertMessage({ msg: error?.data?.description })

                    throw err;
                })


        }).catch(errors => {
            // console.log(errors)
            const formaerrror = {};
            if (errors && errors.length) {
                errors.forEach(element => {
                    formaerrror[element.field] = element.message
                });
            } else {
                AlertMessage({ msg: errors || errors?.message || 'Something went wrong' })
            }

            setState({
                ...state,
                error: formaerrror
            })

        });

    }

    const onClickPhaseConnected = (e) => {

        let checked = e.target.checked
        let value = e.target.value
        let array = [...phases_connected]

        if (checked) {
            //Either 3 or 1
            if (array.length === 0) {
                array.push(value)
            } else if (array.length >= 1) {
                array = ['red', 'yellow', 'blue'];
            }
        } else {
            array = array.length >= 3 ? [] : array.filter((e) => e !== value)
        }
        setState({
            ...state,
            phases_connected: array
        })

    }

    const setDefaultState = () => {
        setState(initialState);
    }


    return {
        fetchNodeMetaData,
        fetchgetStreetlight,
        fetchConfig,
        handleGetNodeDetails,
        handleChangeForm,
        onClickAddEquipment,
        onClickEditEquipment,
        onClickMoveEquipment,
        onClickRemoveEquipment,
        onClickCreateGroupEquipment,
        onClickPhaseConnected,
        setState,
        setDefaultState,
        EQUIPMENT_TYPES,
        state,
        token
    }


}