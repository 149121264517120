import axios from "axios";

let store

export const injectStore = _store => {
  store = _store
}

export const authAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/',
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/',
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});


apiInstance.interceptors.request.use(function (config) {
  config.headers.authorization = store.getState().auth.token
  return config;
}, function (error) {
  return Promise.reject(error);
});

apiInstance.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location = '/login';
    return Promise.resolve();
  }
  return Promise.reject(error);
});

export const axiosPost = async (url, data, token = "") => {
  return apiInstance.post(url, data);
}

export const axiosGet = async (url, token = "") => {
  return apiInstance.get(url);
}

