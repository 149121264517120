import { CreateConfigApi, GetConfigApi, UpdateConfigApi, RemoveConfigApi } from "../../../routes/apiRoutes.jsx";
import { axiosGet, axiosPost } from "../commonApis";

export const handleGetConfig = async (token) => {
    const response = await axiosGet(GetConfigApi, token);
    return response.data;
}

export const handleCreateConfig = async (payload, token) => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(CreateConfigApi, body, token);
    return response.data;
}

export const handleUpdateConfig = async (payload, token) => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(UpdateConfigApi, body, token);
    return response.data;
}

export const handleRemoveConfig = async (payload, token) => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(RemoveConfigApi, body, token);
    return response.data;

}

