import React, { useEffect } from 'react'
import { useHook as useDashboardHook } from "../pages/dashboard/useHook";
import { useEquipmentHook } from "pages/equipments/useEquipmentsHook";
import { useDispatch, useSelector } from 'react-redux';
import { checkApiCallTime, devideArray } from 'constants/Helper';
import * as DashboardApis from "stores/services/dashboardApis";
import { bindActionCreators } from 'redux';
import { setDeviceInstParamAction, setDeviceTHDAction } from 'stores/actionTypes/dashboardActions';
import { setCallDashboardFunctions } from 'stores/actionTypes/equipmentActions';

const firstDayMonth = Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0) / 1000);
const lastDayMonth = Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999) / 1000);

export const useAutoRefreshHook = () => {

    const dispatch = useDispatch();
    const { handleGetDeviceInstParam } = bindActionCreators(DashboardApis, dispatch);
    const { fetchNodeMetaData } = useEquipmentHook();
    const { fetchNodeList, fetchInstEnergyList, fetchHistoryProjectList, fetchConsumptionPattern, fetchNodeListParam,
        autoRefreshDashboard, fetchSelectedNodeParams } = useDashboardHook();
    const { token } = useSelector(state => state.auth);
    const { nodeListCallTime, nodeListParam, dashboardReload, projectParam, consumptionChartSelectedDate } = useSelector(state => state.dashboard);
    let storageNodeList = localStorage.getItem('nodeList');

    const projectListPayload = {
        paramid: [
            projectParam?.powerId
        ],
        from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
        to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
        limit: 110000,
        order: "asc"
    }

    const consumptionPatternPayload = {
        paramid: [
            projectParam?.consumptionPatternId
        ],
        from: firstDayMonth,
        to: lastDayMonth,
        limit: 110000,
        order: "asc"
    }

    const fetchDeviceInstParams = async () => {
        if (nodeListParam.length > 0) {
            let nodeListFixParam = ['thdv3', 'thdv2', 'thdv1'];
            let allnodeListParams = [];

            await Promise.all(nodeListParam.map(async (val, key) => {
                if (nodeListFixParam.includes(val.name)) {
                    let temp_nodeList = JSON.parse(storageNodeList)
                    let payloadData = devideArray(temp_nodeList)
                    let payloadParamData = val.param_id
                    let res = []
                    if (payloadData && payloadData.length > 0) {
                        await Promise.all(payloadData.map(async (val, i) => {
                            await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
                                .then(response => {
                                    if (response.success && response?.data && response?.data !== null) {
                                        res = [...res, ...response.data];
                                    } else {
                                        res = [...res];
                                    }
                                })
                                .catch(err => {
                                    throw err;
                                })
                        }))
                    }
                    allnodeListParams.push(res);
                }
            }));

            dispatch(setDeviceTHDAction(allnodeListParams));
            var d = allnodeListParams[0]?.concat(allnodeListParams[1], allnodeListParams[2]);
            if (d.length > 0) {
                dispatch(setDeviceInstParamAction(d));
                dispatch(setCallDashboardFunctions(false));
            }
        }
    }

    const autoRefreshDashboardAPI = async () => {
        try {
            const selectParams = await fetchSelectedNodeParams('day_Consumption');
            const selectedTimeConsumptionParams = await fetchSelectedNodeParams('time_Consumption');
            let selectedTimeConsumptionNodeId = selectedTimeConsumptionParams?.selectedParams.map(data => data.node_id);
            let nodeId = selectParams?.selectedParams.map(data => data.node_id);

            let consumptionPatternPayload = {
                from: Math.floor(
                    new Date(
                        Date.UTC(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1,
                            0,
                            0,
                            0,
                            0
                        )
                    ) / 1000
                ),
                to: Math.floor(
                    new Date(
                        Date.UTC(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1,
                            0,
                            23,
                            59,
                            59,
                            999
                        )
                    ) / 1000
                ),
                nodes: nodeId,
                type: "include"
      
            }; 
            let nodeListPayload = storageNodeList ? JSON.parse(storageNodeList) : [];
            await fetchNodeList({ "offset": 0, "order": "asc" });
            await fetchNodeListParam({ "limit": 100, "offset": 0 });
            await fetchNodeMetaData({ nodes: nodeListPayload });
            await fetchHistoryProjectList(selectedTimeConsumptionNodeId, "include");
            await fetchConsumptionPattern(consumptionChartSelectedDate)
            await fetchDeviceInstParams();
            await fetchInstEnergyList({ "nodes": nodeListPayload });
        } catch (error) {
        } finally {
            let data = {
                dashboardReload: true,
                time: new Date()
            }
            autoRefreshDashboard(data)
        }
    }

    useEffect(() => {
        if (dashboardReload) {
            autoRefreshDashboardAPI()
        }
    }, [dashboardReload])

    useEffect(() => {
        let timerId;
        if (dashboardReload) {
            // autoRefreshDashboardAPI()
            timerId = setInterval(function () {
                if (dashboardReload === true && nodeListCallTime !== null && checkApiCallTime(nodeListCallTime, process.env.REACT_APP_API_TIME)) {
                    // console.log('Testing For timer After Every 15 minutes', dashboardReload, new Date(nodeListCallTime))
                    autoRefreshDashboardAPI()
                }
            }, process.env.REACT_APP_AUTO_RELOAD_TIME * 60 * 1000);
        } else {
            clearInterval(timerId);
        }
        return () => clearInterval(timerId);
    }, [dashboardReload, nodeListCallTime])

    return {
        autoRefreshDashboard: autoRefreshDashboard
    }

}