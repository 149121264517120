export const drawerWidth = '240px';
export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const SLUG_CURRENT_BARCHART = "current_chart";
export const SLUG_POWER_FACTOR_BARCHART = "pf_chart";
export const SLUG_THD_BARCHART = "thd_chart";
export const SLUG_FREQUENCY_BARCHART = "frequency_chart";
export const SLUG_VOLTAGE_BARCHART = "voltage_chart";
export const BAR_GREEN_COLOR = "rgba(5, 222, 173, 1)";
export const BAR_GREEN_OFFSET_COLOR = "rgba(5, 222, 173, 0.5)";
export const BAR_YELLOW_COLOR = "rgba(255, 188, 15, 1)";
export const BAR_YELLOW_OFFSET_COLOR = "rgba(255, 188, 15, 0.5)";
export const BAR_RED_COLOR = "rgba(255, 68, 56, 1)";
export const BAR_RED_OFFSET_COLOR = "rgba(255, 68, 56, 0.5)";
export const BAR_GREY_COLOR = "rgba(141, 141, 141, 1)";
export const BAR_GREY_OFFSET_COLOR = "rgba(141, 141, 141, 0.5)";

export const FREQUENCY_BARCHART_GREEN_RANGE_START_VALUE = 49.90;
export const FREQUENCY_BARCHART_GREEN_RANGE_END_VALUE = 50.01;
export const FREQUENCY_BARCHART_YELLOW_RANGE_START_VALUE = 49.50;
export const FREQUENCY_BARCHART_YELLOW_RANGE_END_VALUE = 50.50;
export const FREQUENCY_BARCHART_RED_RANGE_START_VALUE = 49.00;
export const FREQUENCY_BARCHART_RED_RANGE_END_VALUE = 51.00;

export const VOLATAGE_BARCHART_GREEN_RANGE_START_VALUE = 219;
export const VOLATAGE_BARCHART_GREEN_RANGE_END_VALUE = 241;
export const VOLATAGE_BARCHART_YELLOW_RANGE_START_VALUE = 207;
export const VOLATAGE_BARCHART_YELLOW_RANGE_END_VALUE = 253;
export const VOLATAGE_BARCHART_RED_RANGE_START_VALUE = 207;
export const VOLATAGE_BARCHART_RED_RANGE_END_VALUE = 253;

export const POWER_BARCHART_GREEN_RANGE_VALUE = 0.95;
export const POWER_BARCHART_YELLOW_RANGE_VALUE = 0.8;
export const POWER_BARCHART_RED_RANGE_VALUE = 0.8;

export const THD_BARCHART_GREEN_RANGE_VALUE = 5;
export const THD_BARCHART_YELLOW_RANGE_VALUE = 10;
export const THD_BARCHART_RED_RANGE_VALUE = 10;

export const TYPE_OPTION_LIST = [
    {
        label: 'Electric Sensor',
        value: 'electric_sensor'
    },
    {
        label: 'Air Sensor',
        value: 'air_sensor'
    },
    {
        label: 'Chemical Sensor',
        value: 'chemical_sensor'
    },
    {
        label: 'Sensor',
        value: 'sensor'
    }
];

export const EQUIPMENT_TYPES = [{
    value: 'gateway', label: 'Gateway',
}, {
    value: 'street_light', label: 'Street Light',
}];