import React from 'react';
import { Dialog } from "@mui/material";
import { useHook } from '../../pages/auth/login/useHook';
import styles from "./style.module.css";

const DialogModal = ({ modalOpen: open, setModalOpen, title, body }) => {
  const { logoutUser } = useHook();

  return (
    <Dialog
      open={open}
      onClose={() => setModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <div className={styles.dialogParentDiv}>
        <div className={styles.dialogTitle}>{title}</div>
        <div className={styles.dialogContentContainer}>
          <p className={styles.dialogContent} id="alert-dialog-description">
            {body}
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={() => { setModalOpen(false); logoutUser(); }} className={styles.btnConfirm} autoFocus>Confirm</button>
          <button onClick={() => setModalOpen(false)} className={styles.btnCancel}>Cancel</button>
        </div>
      </div>
    </Dialog>
  )
}

export default DialogModal
