import * as Yup from "yup";
import { useFormik } from "formik";
import { SetConfigProfilesFunctions } from "../../stores/actionTypes/equipmentActions";
import * as EquipmentApi from "../../stores/services/equipmentApi";
import { bindActionCreators } from "redux";
import { validateAll } from "indicative/validator";
import {
    handleGetConfig,
    handleCreateConfig,
    handleRemoveConfig,
    handleUpdateConfig
} from "stores/services/configApis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import jwt from "jwt-decode";
import { useState, useEffect } from "react";
import ToastAlert from "constants/ToastAlert";

export function useHook() {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = useSelector(state => state.auth);
    const { UUID, RoleId, exp } = jwt(token);
    const [state, setState] = useState({
        photo: '',
        name: '',
        description: '',
        serial_number: '',
        type: [],
        file_data: '',
        edit_file_data: '',
        version: 1.0
    });

    const [avatarBase64, setavatarBase64] = useState('');
    const [value, setValue] = useState(0);

    const handleChangeForm = (e) => {
        let value = e.target.value;
        if(e.target.name === 'file_data'){
            setState({
                ...state,
                edit_file_data: ''
            })
        }
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const goToNextStep = (key) => {
        console.log('yess');

        try {
            let rules = {}
            let message = {}

            switch (key) {
                case 0:
                    rules = {
                        name: 'required',
                        description: 'required',
                        serial_number: 'required',
                        type: 'required|array|min:1',
                        version: 'required|range:1,1000000',
                    }
                    message = {
                        'name.required': 'Name is Required.',
                        'description.required': 'Description is Required.',
                        'serial_number.required': 'Serial number is Required.',
                        'type.min': 'Type is Required.',
                        'version.required': 'Version is Required.',
                        'version.range': 'Version should be positive number.',
                    }
                    break;
                case 1:
                    rules = {
                        file_data: 'required',
                    }
                    message = {
                        'file_data.required': 'File is Required',
                    }
                    break;
                default:
                    break;
            }
            validateAll(state, rules, message).then(async () => {
                const formaerrror = {};
                setState({
                    ...state,
                    error: formaerrror
                })

                if (key !== 1) {
                    setValue(key + 1);
                } else {
                    let base64Rejex = "^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$";
                    let payload = {
                        user_id: UUID,
                        name: state.name,
                        description: state.description,
                        version: state.version,
                        serial_number: state.serial_number,
                        type: state.type.map(el => el.value),
                        data: state.edit_file_data ? state.edit_file_data : await toBase64(state.file_data),
                        metadata: {}
                    }
                    console.log('yesss--2', payload);
                    if (avatarBase64) {
                        payload.metadata = {
                            device_photo: avatarBase64
                        }
                    }
                    if (id) {
                        payload.profile_id = id;
                        await handleUpdateConfig(payload, token)
                            .then(response => {
                                const { success, error } = response;
                                if (success) {
                                    ToastAlert({ msg: `${state.serial_number} is Successfully Configured.`, msgType: "info" })
                                    navigate('/devices');
                                } else {
                                    ToastAlert({ msg: error, msgType: "info" })
                                    return
                                }
                            })
                            .catch(err => {
                                ToastAlert({ msg: err?.message, msgType: "info" })
                                throw err;
                            })
                        return;
                    } else {
                        await handleCreateConfig(payload, token)
                            .then(response => {
                                const { success, error } = response;
                                if (success) {
                                    ToastAlert({ msg: `${state.serial_number} is Successfully Configured.`, msgType: "info" })
                                    navigate('/devices');
                                } else {
                                    ToastAlert({ msg: error, msgType: "info" })
                                    return
                                }
                            })
                            .catch(err => {
                                ToastAlert({ msg: err?.message, msgType: "info" })
                                throw err;
                            })
                        return;
                    }
                }

            }).catch(errors => {
                console.log('errors', errors);
                const formaerrror = {};
                if (errors && errors.length) {
                    errors.forEach(element => {
                        formaerrror[element.field] = element.message
                    });
                } else {
                    ToastAlert({ msg: errors || errors?.message || 'Something went wrong', msgType: "info" })
                }
                setState({
                    ...state,
                    error: formaerrror
                })

            });
        } catch (error) {
            console.log('error', error);
        }

    }

    const fetchConfig = async () => {
        try {
            const response = await handleGetConfig(token);
            const { success, error, data } = response;
            if (success) {
                dispatch(SetConfigProfilesFunctions(data))
                return data
            } else {
                return [];
            }
        } catch (error) {
            dispatch(SetConfigProfilesFunctions([]))
            ToastAlert({ msg: error?.message, msgType: "info" })
            return [];
        }
    }

    const removeConfig = async (id) => {
        try {
            const payload = {
                profile_id: id
            }
            const response = handleRemoveConfig(payload, token);
            const { success, error } = response;
            if (success) {
                ToastAlert({ msg: `Device is Successfully removed.`, msgType: "info" })
                navigate('/devices');
            } else {
                ToastAlert({ msg: error, msgType: "info" })
                return
            }
        } catch (error) {
            ToastAlert({ msg: error?.message, msgType: "info" })
            throw error;
        }
    }

    return {
        state,
        id,
        handleChangeForm,
        goToNextStep,
        value,
        setValue,
        setState,
        setavatarBase64,
        fetchConfig,
        removeConfig
    }

}