import { UserList, AddUserApi, UpdatePasswordApi, UpdateUserApi, DeleteUserApi } from "../../../routes/apiRoutes.jsx";
import { axiosGet, axiosPost } from "../commonApis";

export const handleGetUsers = (token) => {
  return async () => {
    const response = await axiosGet(UserList, token);
    return response.data;
  }
}

export const handleAddUser = (userData, token) => {
  return async () => {
    const body = JSON.stringify(userData);
    const response = await axiosPost(AddUserApi, body, token);
    return response.data;
  }
}

export const handleUpdateLoggedinUser = async (userData, token) => {
  // return async () => {
  const body = JSON.stringify(userData);
  const response = await axiosPost(UpdateUserApi, body, token);
  return response.data;
  // }
}

export const handleUpdateUser = (userData, token) => {
  return async () => {
    const body = JSON.stringify(userData);
    const response = await axiosPost(UpdateUserApi, body, token);
    return response.data;
  }
}

export const handleUpdateUserPassword = async (userData, token) => {
  const body = JSON.stringify(userData);
  const response = await axiosPost(UpdatePasswordApi, body, token);
  return response.data;
}


export const handleDeleteUser = (userData, token) => {
  return async () => {
    const body = JSON.stringify(userData);
    const response = await axiosPost(DeleteUserApi, body, token);
    return response.data;
  }

}
