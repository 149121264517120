import "./App.css";
import { RouterProvider } from "react-router-dom";
import Routers from "./routes/router";
import ToastMessage from "./components/toastMessage/toastMessage";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/fonts/Inter-Black.ttf"
import "./assets/fonts/Inter-Bold.ttf"
import "./assets/fonts/Inter-ExtraBold.ttf"
import "./assets/fonts/Inter-ExtraLight.ttf"
import "./assets/fonts/Inter-Light.ttf"
import "./assets/fonts/Inter-Medium.ttf"
import "./assets/fonts/Inter-Regular.ttf"
import "./assets/fonts/Inter-SemiBold.ttf"
import "./assets/fonts/Inter-Thin.ttf"


function App() {

  if (process.env.NODE_ENV !== "development") {
    console.log = () => { };
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
  }

  return <>
    <ToastMessage />
    <RouterProvider router={Routers} />
  </>;
}

export default App;
