import React, { useState, useEffect } from "react";
import {
    InputLabel,
    OutlinedInput,
    Select
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { Tabs, Box, Tab, Button, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import iIcon from "../../assets/iicon.png"
import LoctionIcon from "../../assets/Location.png"
import { useEquipmentHook } from "./useEquipmentsHook";
import { checkEquipmetIsActive } from "../../constants/Helper";
import { Routes } from "routes/appRoutes";
import AlertMessage from "constants/AlertMessage";
import { BreadcrumbLayout } from "components";
import EquipmentLayout from "./equipmentLayout";
import { logDOM } from "@testing-library/react";
import { CreateGroupModel } from "./createGroup";

const MoveEquipment = () => {

    const { state, handleChangeForm, onClickMoveEquipment, setState, setDefaultState } = useEquipmentHook()
    const navigate = useNavigate()
    const { state: routeState } = useLocation();
    const { id } = useParams();
    const { nodeMetaData } = useSelector(state => state.equipment);
    const { instEnergy } = useSelector(state => state.dashboard);

    const [treeData, setTreeData] = useState({})
    const [allNodeList, setAllNodeList] = useState([]);
    const [destinationGroup, setDestinationGroup] = useState('');
    console.log('treeData--', treeData);

    Array.prototype.diff = function (a) {
        return this.filter(function (i) { return a.indexOf(i) < 0; });
    };

    let storageNodeList = localStorage.getItem('nodeList')

    const [openCreateGroupModel, setOpenCreateGroupModel] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [isNodeGroup, setIsNodeGroup] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const arrMap = (data) => {
        if (data?.children?.length > 0) {
            data?.children?.map((d) => {
                d['name'] = d?.name === d?.nodeId ? d?.equipment_id : d?.name;
                arrMap(d)
            })
        } else {
            delete data?.children
        }
        return data
    }

    const genrateTreeStructure = (array) => {
        const tree = {
            name: 'root',
            url: '',
            children: [{
                name:'Root',
                url:'Root',
                isFolder: true
            }]
        }
        for (let e of array) {
            let node = tree
            if (e.node_path && e.node_path !== 'N/A') {
                const nodenames = e.node_path?.split('/')
                while (nodenames?.length > 0) {
                    const nodename = nodenames.shift()
                    if (!node?.children?.map(e => e.name)?.includes(nodename)) {
                        node?.children?.push({
                            name: nodename,
                            equipment_id: e?.equipment_id,
                            url: [node.url, nodename]?.join('/'),
                            status: e.active,
                            lat: e?.lat,
                            lng: e?.lng,
                            nodeId: e.name,
                            nodeName: nodename,
                            children: [],
                        })
                    }
                    node = node?.children?.filter(e => e?.name === nodename)[0]
                    // node = { ...node, children: node?.children.filter(el => el?.children && el?.children.length > 0)}
                }
            }
        }
        return arrMap(JSON.parse(JSON.stringify(tree, null, 2)))
    }

    const handleMovePathClick = (path, isNode) => {
        let modifiedPath = path;
        if (path[0] === '/') {
            modifiedPath = path.substring(1);
        }
        setDestinationGroup(modifiedPath);
        setIsNodeGroup(isNode);
        handleClose();
    }

    const handleCreateGroupClick = (basepath, isNode) => {
        handleClose();
        let modifiedPath = basepath;
        if (basepath[0] === '/') {
            modifiedPath = basepath.substring(1);
        }
        setDestinationGroup(modifiedPath);
        setIsNodeGroup(isNode);
        setOpenCreateGroupModel(true)
    }

    const handleCloseCreateGroupModel = () => {
        setDestinationGroup('');
        setIsNodeGroup(false);
        setOpenCreateGroupModel(false);
    }

    const handleonClickCreateMoveEquipment = (path) => {
        setOpenCreateGroupModel(false);
        let full_node_path = path ? ((destinationGroup && destinationGroup !== 'Root') ? `${destinationGroup}/${path}` : path) : destinationGroup;
        onClickMoveEquipment(full_node_path, isNodeGroup);
    }

    const handleonClickMoveEquipment = (e) => {
        e.preventDefault();
        onClickMoveEquipment(destinationGroup, isNodeGroup);
    }

    useEffect(() => {
        if (storageNodeList) {
            setAllNodeList(JSON.parse(storageNodeList))
        }
    }, [storageNodeList])

    useEffect(() => {
        setDestinationGroup('');
        if (id) {
            let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
            const equipmentDataDict = allNodeMetaData.find((e) => e[0] === id)?.length > 0 ?
                allNodeMetaData.find((e) => e[0] === id)[1]?.metadata : null;
            console.log('equipmentDataDict', equipmentDataDict);
            setDefaultState();
            setState({
                node_name: equipmentDataDict?.node_name || '',
                node_desc: equipmentDataDict?.node_desc || '',
                equipment_id: equipmentDataDict?.equipment_id || '',
                node_path: equipmentDataDict?.node_path || state.node_path,
                temp_node_path: equipmentDataDict?.node_path || state.temp_node_path,
                isNode: equipmentDataDict?.isNode || state.isNode,
                equipment_type: equipmentDataDict?.equipment_type || '',
                address: equipmentDataDict?.address || '',
                total_rated_power: equipmentDataDict?.total_rated_power || '',
                phases_connected: equipmentDataDict?.phases_connected || [],
                description: equipmentDataDict?.description || '',
                lat: equipmentDataDict?.lat || '',
                lng: equipmentDataDict?.lng || '',
            })
        } else if (routeState?.node_path) {
            setDefaultState();
            setState(oldState => ({
                ...oldState,
                node_path: routeState?.node_path || state.node_path,
                temp_node_path: routeState?.node_path || state.temp_node_path
            }))
        } else {
            setDefaultState();
        }
    }, [id, routeState, nodeMetaData])

    useEffect(() => {
        let tempCsvDataArray = []
        let temp = instEnergy ? Object.entries(instEnergy) : [];
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];

        let tempIdArray = temp.map((d) => {
            return d[0]
        })

        let d_nodes = allNodeList?.diff(tempIdArray)

        if (temp.length > 0) {
            temp.map((v, i) => {

                let csvObj = {}
                csvObj.name = v[0]
                csvObj.active = checkEquipmetIsActive(v[1].timestamp * 1000) ? true : false
                csvObj.node_path = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_path : 'N/A'
                csvObj.equipment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : 'N/A'
                tempCsvDataArray.push(csvObj);
            })
            if (d_nodes.length > 0) {
                d_nodes.map((n) => {

                    let tempObj = {}
                    tempObj.name = n
                    tempObj.active = false
                    tempObj.node_path = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_path : 'N/A'
                    tempObj.equipment_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_id : 'N/A'
                    tempCsvDataArray.push(tempObj);
                })
            }
            setTreeData((genrateTreeStructure(tempCsvDataArray)))
        } else {
            setTreeData((genrateTreeStructure([])))
        }
    }, [instEnergy, allNodeList, nodeMetaData])

    return (
        <EquipmentLayout>
            <form className='border-2 p-3' style={{ width: '80%', marginLeft: '5%' }}>
                <Box component="div" className='flex items-center justify-between'>
                    <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        Move Equipment
                    </Typography>
                    {id && (
                        <Link to={Routes.addEquipment} className='' style={{
                            textDecoration: "none",
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'right',
                            letterSpacing: '-0.154px',
                            color: '#1B53F9',
                        }}>
                            + Add Equipment
                        </Link>
                    )}
                </Box>
                <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748] eq-sp-title' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        Equipment Details
                    </Typography>
                </Box>
                <div className="eqipment-form">
                    <div className="eq-form-top">

                        <div className="input-control-main">
                            <label htmlFor="current_group_id">Current Group</label>
                            <div className="control">
                                <input type='text'
                                    id="current_group_id"
                                    name="current_group_id"
                                    value={state.node_path}
                                    placeholder="Current Group"
                                    disabled={true}
                                    className={"custom-input-control current_group_input"}
                                />
                            </div>
                        </div>

                        <div className="input-control-main">
                            <label htmlFor="destinationGroup">Destination Group</label>
                            <div className="control">

                                <Select
                                    displayEmpty
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Destination Group"
                                    value={destinationGroup}
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <em>{destinationGroup}</em>;
                                        }
                                        return selected;
                                    }}
                                    input={
                                        <OutlinedInput
                                            sx={{
                                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                                borderRadius: "14.5px",
                                                "& fieldset": { border: "none" },
                                                color: "#7F7F7F",
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                width: '100%',
                                                "& .MuiSvgIcon-root": {
                                                    color: "#7F7F7F",
                                                },
                                                "& em": {
                                                    fontStyle: "normal",
                                                },
                                            }}
                                        />
                                    }
                                    MenuProps={{
                                        classes: { paper: 'customDropDown' },
                                        PaperProps: {
                                            sx: {
                                                // maxHeight: 48 * 4.5 + 8,
                                                width: 'auto',
                                                boxShadow: 'none',
                                                borderRadius: '0 0 20px 20px',
                                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                                borderTop: 'none',
                                                marginTop: '-5px',
                                                background: 'transparent'
                                            },
                                        },
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <BreadcrumbLayout tree={treeData} handleMovePathClick={handleMovePathClick} handleCreateGroupClick={handleCreateGroupClick} />
                                </Select>
                                {
                                    state?.error && state?.error?.equipment_id &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_id}</span>
                                }
                            </div>
                        </div>

                    </div>

                    <div className="eq-form-bottom">

                        <div className='w-full flex justify-end text-right gap-3  mt-10 footer-button-group'>
                            <Button
                                type='button'
                                disabled={destinationGroup ? false : true}
                                onClick={handleonClickMoveEquipment}
                                style={{ textTransform: "capitalize" }}
                                className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                            >
                                {'Move'}
                            </Button>
                            <Button
                                type='button'
                                onClick={() => navigate('/equipments')}
                                style={{ textTransform: "capitalize" }}
                                className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>
                </div>
            </form>
            <CreateGroupModel
                open={openCreateGroupModel}
                path={destinationGroup}
                handleMoveClick={handleonClickCreateMoveEquipment}
                handleClose={handleCloseCreateGroupModel}
            />
        </EquipmentLayout>
    )
}
export default MoveEquipment