import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { fabClasses } from '@mui/material';

export const AutocompleteLayout = ({
    options,
    value,
    label,
    name,
    handleChange
}) => {

    const InputSx = {
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px'
        }
    }

    return (
        <Autocomplete
            multiple
            id={name}
            name={name}
            options={options}
            value={value && value.length > 0 ? value : []}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField {...params} placeholder={label} sx={InputSx} />
            )}
            onChange={(event, newValue) => {
                handleChange({
                    target: {
                        name: name,
                        value: newValue
                    }
                });
            }}
            sx={{ width: '100%' }}
        />
    )
}

export const AutocompleteSingleLayout = ({
    options,
    value,
    label,
    name,
    handleChange
}) => {

    const InputSx = {
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
        },
        "& .MuiInputBase-input": {
            height: '7px',
            border:'none !important'
        }
    }

    return (
        <Autocomplete
            multiple={false}
            id={name}
            name={name}
            options={options}
            value={value ? value : null}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField {...params} placeholder={label} sx={InputSx} />
            )}
            onChange={(event, newValue) => {
                console.log('newValue', newValue);
                handleChange({
                    target: {
                        name: name,
                        value: newValue
                    }
                });
            }}
            sx={{ width: '100%' }}
        />
    )
}