import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import GoogleMapReact from 'google-map-react';
import SearchIcon from "../../assets/magnifyingglass2.png"
import NodePin from "./NodePin";
import FolderTree, { testData } from 'react-folder-tree';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SliderSvg from "../../assets/Slider.svg"
import Button from "@mui/material/Button";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import BulletedListSvg from "../../assets/BulletedList.svg"
import LessThanSvg from "../../assets/LessThan.svg"
import LessThan2Svg from "../../assets/LessThan2.svg"
import { useSelector, useDispatch } from 'react-redux';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { generateNodeName, checkEquipmetIsActive, decryptData, encryptData } from '../../constants/Helper';
import { setSaveFilter, setCallDashboardFunctions } from '../../stores/actionTypes/equipmentActions';
import MyLocationIcon from "../../assets/MyLocation.svg"
import UnavailableIcon from "../../assets/Unavailable.svg"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterModal from "../equipments/FilterModal";
import greenPin from "../../assets/pintoo.svg"
import redPin from "../../assets/pin.svg"
import copyIcon from "../../assets/copy-icon.svg"
import { createLocationLink } from '../../constants/Helper';
import ToastAlert from '../../constants/ToastAlert';
import Line148 from "../../assets/Line148.png"
import Enlarge from "../../assets/Enlarge.svg"
import Subtract from "../../assets/Subtract.svg"
import PlusMath from "../../assets/PlusMath.svg"
import GroupFitView from "../../assets/GroupFitView.svg"
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { fitBounds } from "google-map-react";
import { useEquipmentHook } from "../equipments/useEquipmentsHook";
import { useHook as useDashboardHook } from "../dashboard/useHook";
import { setDeviceInstParamAction, setDeviceTHDAction } from "../../stores/actionTypes/dashboardActions";
import { devideArray } from "../../constants/Helper";
import { bindActionCreators } from "redux";
import * as DashboardApis from "../../stores/services/dashboardApis";
import { useHook as useLoginHook } from "../auth/login/useHook";
import { IconButton } from "@mui/material";
import { Tree } from "react-arborist";
import { PowerIcon, VoltageIcon, ActivitiesIcon } from "assets";
import AlertMessage from "constants/AlertMessage";
import { FillFlexParentLayout } from "components";
import StreetLightActive from '../../assets/green.svg'
import StreetLight from '../../assets/red.svg'
import axios from 'axios';
import { baseUrls } from "../../components/constants";
import * as EquipmentApi from "stores/services/equipmentApi";
import MapCartView from './MapDetailsCart'

const FolderOpenIcon = () => {
    return (
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" class="icon FolderOpenIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 0 0-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z"></path></svg>
    )
}

const MapView = () => {
    const DEFAULT_ZOOM = 15
    const { fetchNodeMetaData } = useEquipmentHook()
    const { fetchNodeList, fetchInstEnergyList, fetchNodeListParam } = useDashboardHook();
    const { token } = useSelector(state => state.auth);
    const { logoutUser } = useLoginHook();

    var url = new URL(window.location.href);
    var filter = url.searchParams.get("filter");
    const navigate = useNavigate();
    const [activeAction, setActiveAction] = useState()
    const [toogle, setToogle] = useState(true)
    const { nodeList, instEnergy, historyProjectList, consumptionPattern, nodeListParam, deviceInstParam, deviceTHD } = useSelector(state => state.dashboard);
    const { filters, nodeMetaData } = useSelector(state => state.equipment);
    const [rowsvalue, setRowsValue] = useState([])

    const[zoomValue,setZoomValue]=useState([])
    const[getMarkedNodes,setGetMarkedNodes]=useState([])
    const[paramIds,setParamIds]=useState(false);
    const[svoltage,setVoltageParamID]=useState('');
    const[scurrent,setCurrentParamID]=useState('');
    const[voltageResponse,setVoltageResponse]=useState(null);
    const[currentResponse,setCurrentResponse]=useState(null);
    const[zoomValueData,setzoomValueData]=useState([])
    const[boundary,setBoundary]=useState()


    
    const [maxTHDList, setMaxTHDList] = useState([])
    const [tempRowsvalue, setTempRowsValue] = useState([])
    const dispatch = useDispatch()
    const [filterOptionList, setFilterOptionList] = useState([])
    const [treeData, setTreeData] = useState({})
    const [tempZoom, setTempZoom] = useState(DEFAULT_ZOOM)
    const [mapType, setMapType] = useState('roadmap')
    const [allNodeList, setAllNodeList] = React.useState([]);
    const [map, setMap] = useState()
    const [maps, setMaps] = useState()
    const { handleGetDeviceInstParam } = bindActionCreators(DashboardApis, dispatch);
    const [searchEquipment, setSearchEquipment] = useState('');
    const [centerData, setCenterData] = useState({
        lat: 0,
        lng: 0,
        refreshMap: "refresh",
        zoom: 8
    })
    const [filterOptions, setFilterOptions] = useState({
        components: {},
        operation: "",
        value: "",
        start: "",
        end: "",
        condition: "and"
    })
    const [latLongPayload, setLatLongPayload] = useState();
    const [componentList, setComponentList] = useState([
        { title: "Voltage (v)", value: 'voltageValue' },
        { title: "Current (A)", value: 'currentValue' },
        { title: "Total Current", value: 'totalcurrentValue' },
        { title: "THD", value: 'maximumTHD' },
        { title: "Frequency", value: 'frequency' },
        { title: "Power Factor", value: 'totalPowerFactorValue' },
        { title: "Power Factor average", value: 'powerFactorValue' },
    ])
    const {handlegetStreetlight} = bindActionCreators(EquipmentApi, dispatch);

    const [showFilterModal, setShowFilterModal] = useState(false)
    const [boundsFitted, setBoundsFitted] = useState(false);

    const[equipmentId,setEquipmentId]=useState('');
    const[consumptionValue,setConsumptionValue]=useState('');
    const[maximumTHD,setMaximumTHD]=useState('');
    const[voltageValue,setVoltageValue]=useState('');
    const[viewData,setViewData]=useState(false);
    const[streetLightvoltage,setStreetLightVoltage]=useState('');
    const[current,setCurrent]=useState('');
    const[equipmentType,setEquipmentType]=useState('');
    const[copyLinkData,setCopyLinkData]=useState('');
    const[streetLightData,setStreetLightData]=useState([]);
    const[streetAllLightData,setStreetAllLightData]=useState([]);
    const[streetLightActiveData,setStreetLightActiveData]=useState([]);
    const[streetLightVoltageResponse,setStreetLightVoltageResponse]=useState(null);
    const[streetLightCurrentResponse,setStreetLightCurrentResponse]=useState(null);
    const[storeStreetLight,setStoreStreetLight]=useState({})
    const[storeStreetLightParentId,setStoreStreetLightParentId]=useState(null)
    const[detailsCartProperty,setDetailsCartProperty]=useState(null)
    const arrMap = (data) => {
        if (data?.children.length > 0) {
            data?.children.map((d) => {
                d['name'] = d?.name === d?.nodeId ? d?.equipment_id : d?.name;
                arrMap(d)
            })
        } else {
            delete data?.children
        }
        return data
    }

    const genrateTreeStructure = (array) => {

        let nested_array = [...array].filter(el => el.node_path !== 'N/A' && el.node_path !== '' && el.node_path !== undefined);
        nested_array.sort((a, b) => {
            if (a.node_path < b.node_path)
                return -1;
            if (a.node_path > b.node_path)
                return 1;
            return 0;
        })

        const tree = {
            name: 'root',
            url: '',
            children: []
        }
        for (const e of nested_array) {
            let node = tree
            if (e.node_path && e.node_path !== 'N/A') {
                const nodenames = e.node_path.split('/')
                while (nodenames.length > 0) {
                    const nodename = nodenames.shift()
                    if (!node?.children.map(e => e.name).includes(nodename)) {
                        node?.children.push({
                            name: nodename,
                            // name: e?.node_name || nodename,
                            equipment_id: e?.equipment_id,
                            url: [node.url, nodename]?.join('/'),
                            status: e.active,
                            lat: e?.lat,
                            lng: e?.lng,
                            nodeId: e.name,
                            nodeName: nodename,
                            children: [],
                            _id: Math.random().toString(36).substring(2, 7),
                        })
                    }
                    node = node.children.filter(e => e.name === nodename)[0]
                }
            }


        }

        // console.log(((JSON.stringify(tree, null, 2))))

        return arrMap(JSON.parse(JSON.stringify(tree, null, 2)))

    }

    Array.prototype.diff = function (a) {
        return this.filter(function (i) { return a.indexOf(i) < 0; });
    };

    let storageNodeList = localStorage.getItem('nodeList')

    const showHideMarkers = () => {
        let markersData = rowsvalue;
        if (markersData && markersData.length > 0) {
            return markersData.forEach((marker) => {
                let img = !marker.active ? redPin : greenPin
                if (marker.lat && marker.lat !== 'N/A' && marker.lat != undefined) {
                    let markers = new maps.marker.AdvancedMarkerView({
                        position: { lat: Number(marker.lat), lng: Number(marker.lng) },
                        map,
                        content: buildContent(marker),
                    });

                    const element = markers.element;

                    ["focus", "pointerenter"].forEach((event) => {
                        element.addEventListener(event, () => {
                            highlight(markers, marker);
                        });
                    });
                    ["pointerenter","click"].forEach((event) => {
                        element.addEventListener(event, () => {
                        });
                    });
                    ["blur", "pointerleave"].forEach((event) => {
                        element.addEventListener(event, () => {
                            unhighlight(markers, marker);
                        });
                    });
                    markers.addListener("click", (event) => {
                        let _eventId = event?.domEvent?.target?.id || '';
                        if (event && _eventId && (_eventId === 'copyISPan' || _eventId === 'copyIImg')) {
                            copyLink(marker)
                        } else {
                            return
                        }
                    });
                    return markers;
                }
            });
        }
    }
    useEffect(() => {
        (async () => {


            if (storageNodeList) {
                setAllNodeList(JSON.parse(storageNodeList))

            } else {

                await fetchNodeList({ "offset": 0, "order": "asc" })
            }

            if (nodeListParam.length === 0) {
                await fetchNodeListParam({ "limit": 100, "offset": 0 });
            }


            if (storageNodeList && JSON.parse(storageNodeList).length > 0 && Object.values(instEnergy).length === 0) {
                await fetchInstEnergyList({ "nodes": JSON.parse(storageNodeList) });
            }

        })()
    }, [storageNodeList, nodeListParam])


    useEffect(() => {
        (async () => {
            if (nodeMetaData !== null && Object.keys(nodeMetaData).length == 0 || allNodeList.length && nodeMetaData == null) {

                await fetchNodeMetaData({ nodes: allNodeList })
            }

        })()

    }, [allNodeList, nodeMetaData])

    const getNodeListPerm = async () => {
        if (nodeListParam.length > 0) {
            let nodeListFixParam = ['thdv3', 'thdv2', 'thdv1'];
            let allnodeListParams = [];
            console.log('CALL_1:', allNodeList)
            await Promise.all(nodeListParam.map(async (val, key) => {
                if (nodeListFixParam.includes(val.name)) {
                    let temp_nodeList = JSON.parse(storageNodeList)
                    let payloadData = devideArray(temp_nodeList)
                    console.log('CALL_2:', payloadData)
                    let payloadParamData = val.param_id
                    let res = []
                    if (payloadData && payloadData.length > 0) {
                        await Promise.all(payloadData.map(async (val, i) => {
                            await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
                                .then(response => {
                                    if (response.success && response?.data && response?.data !== null) {
                                        res = [...res, ...response.data];
                                    } else {
                                        res = [...res];
                                    }
                                })
                                .catch(err => {
                                    const error = err?.response?.data;
                                    if (error?.description?.includes("token is expired")) {
                                        logoutUser();
                                    }
                                    throw err;
                                })
                        }))
                    }

                    allnodeListParams.push(res);
                    console.log('CALL_3:', res)
                    // let DeviceInstParamResponce = await fetchDeviceInstParam({ "paramid": val.param_id, "nodes":allNodeList });
                    // allnodeListParams.push(DeviceInstParamResponce);

                }
            }));

            console.log('CALL_4:', allnodeListParams)
            dispatch(setDeviceTHDAction(allnodeListParams));
            var d = allnodeListParams[0]?.concat(allnodeListParams[1], allnodeListParams[2]);
            if (d.length > 0) {
                dispatch(setDeviceInstParamAction(d));
            }
        }
    }

    useEffect(() => {
        (async () => {

            if (deviceInstParam.length > 0) {
                let temp = []
                let tempAll = []
                deviceInstParam.map((val, k) => {

                    if (temp.find(e => e.node === val.node)) {
                        tempAll.push(val)
                        let oj = temp.find(e => e.node === val.node);
                        if (oj.value < val.value) {
                            let ind = temp.findIndex(e => e.node === val.node);
                            temp[ind] = val;
                        }
                    } else {
                        temp.push(val);
                    }
                })
                setMaxTHDList(temp);
            } else {

                await getNodeListPerm()
            }
        })()

    }, [deviceInstParam, nodeListParam])

    useEffect(() => {
        let tempCsvDataArray = []
        let temp = instEnergy ? Object.entries(instEnergy) : [];
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];

        let tempIdArray = temp.map((d) => {
            return d[0]
        })

        let d_nodes = allNodeList?.diff(tempIdArray)

        if (temp.length > 0 || allNodeMetaData.length > 0) {
            temp.map((v, i) => {
                let calculatedConsumptionValue = Number(Number(v[1].w1) + Number(v[1].w2) + Number(v[1].w3)).toFixed(2)
                let consumptionValue = calculatedConsumptionValue > 1000 ? Number(Number(calculatedConsumptionValue) / 1000) : Number(calculatedConsumptionValue)
                let consumptionParams = calculatedConsumptionValue > 1000 ? " kW" : " W"
                let voltageValue = Number(Number(Number(v[1].v1) + Number(v[1].v2) + Number(v[1].v3)) / Number(3)).toFixed(2)
                let currentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3)).toFixed(2)
                let totalcurrentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3) / 3).toFixed(2)
                let powerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3)) / Number(3)).toFixed(2)
                let totalPowerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3))).toFixed(2)



                let csvObj = {}
                csvObj.consumptionValue = Number(consumptionValue).toFixed(2)
                csvObj.consumptionParams = consumptionParams
                csvObj.voltageValue = Number(voltageValue)
                csvObj.currentValue = currentValue
                csvObj.totalcurrentValue = totalcurrentValue
                csvObj.powerFactorValue = powerFactorValue
                csvObj.totalPowerFactorValue = totalPowerFactorValue


                csvObj.name = v[0]
                csvObj.active = checkEquipmetIsActive(v[1].timestamp * 1000) ? true : false
                csvObj.consumption = Number(consumptionValue).toFixed(2) + consumptionParams //+ " / 1.1 "
                csvObj.liveVoltage = Number(voltageValue).toFixed(2) + "V "
                csvObj.ratedPower = ""
                csvObj.description = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.Descrption : 'N/A'

                csvObj.node_path = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_path : 'N/A'

                csvObj.lat = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.lat : 'N/A'

                csvObj.lng = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.lng : 'N/A'


                csvObj.equiment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equiment_id : 'N/A'

                csvObj.totalCurrent = currentValue + " A "
                csvObj.totalCurrent_phase1 = (v[1].i1)
                csvObj.totalCurrent_phase2 = (v[1].i2)
                csvObj.totalCurrent_phase3 = (v[1].i3)


                csvObj.avarageVoltage = voltageValue + "V"
                csvObj.avarageVoltage_phase1 = Number(v[1].v1) + ' V '
                csvObj.avarageVoltage_phase2 = Number(v[1].v2) + ' V '
                csvObj.avarageVoltage_phase3 = Number(v[1].v3) + ' V '

                csvObj.avarageVoltage = voltageValue + "V"
                csvObj.avarageVoltage_phase1_ = Number(v[1].v1)
                csvObj.avarageVoltage_phase2_ = Number(v[1].v2)
                csvObj.avarageVoltage_phase3_ = Number(v[1].v3)


                csvObj.avaragePowerFactor = powerFactorValue
                csvObj.avaragePowerFactor_phase1 = Number(v[1].pf1)
                csvObj.avaragePowerFactor_phase2 = Number(v[1].pf2)
                csvObj.avaragePowerFactor_phase3 = Number(v[1].pf3)


                csvObj.maximumTHD = maxTHDList?.find((e) => e?.node === v[0])?.value
                csvObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[0]?.filter((e) => e.node === v[0])[0]?.value : '0'
                csvObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[1]?.filter((e) => e.node === v[0])[0]?.value : '0'
                csvObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[2]?.filter((e) => e.node === v[0])[0]?.value : '0'

                csvObj.frequency = v[1].frequency
                csvObj.remarks = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.Remark : 'N/A'
                csvObj.equipment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : 'N/A'
                csvObj.equipment_type = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_type : ''
                const nodeName = (allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 && allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name : generateNodeName(v[0])
                csvObj.node_name = nodeName
                tempCsvDataArray.push(csvObj);
            })


            if (d_nodes.length > 0) {
                d_nodes.map((n) => {

                    let tempObj = {}
                    tempObj.consumptionValue = 'N/A'
                    tempObj.consumptionParams = 'N/A'
                    tempObj.voltageValue = 'N/A'
                    tempObj.currentValue = 'N/A'
                    tempObj.totalcurrentValue = 'N/A'
                    tempObj.powerFactorValue = 'N/A'
                    tempObj.totalPowerFactorValue = 'N/A'

                    tempObj.name = n
                    tempObj.active = false
                    tempObj.consumption = 'N/A'
                    tempObj.liveVoltage = 'N/A'
                    tempObj.ratedPower = 'N/A'
                    tempObj.description = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.Descrption : 'N/A'

                    tempObj.node_path = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_path : 'N/A'


                    tempObj.lat = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.lat : 'N/A'


                    tempObj.lng = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.lng : 'N/A'


                    tempObj.equiment_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equiment_id : 'N/A'


                    tempObj.totalCurrent = 'N/A'
                    tempObj.totalCurrent_phase1 = 'N/A'
                    tempObj.totalCurrent_phase2 = 'N/A'
                    tempObj.totalCurrent_phase3 = 'N/A'


                    tempObj.avarageVoltage = 'N/A'
                    tempObj.avarageVoltage_phase1 = 'N/A'
                    tempObj.avarageVoltage_phase2 = 'N/A'
                    tempObj.avarageVoltage_phase3 = 'N/A'

                    tempObj.avarageVoltage = 'N/A'
                    tempObj.avarageVoltage_phase1_ = 'N/A'
                    tempObj.avarageVoltage_phase2_ = 'N/A'
                    tempObj.avarageVoltage_phase3_ = 'N/A'


                    tempObj.avaragePowerFactor = 'N/A'
                    tempObj.avaragePowerFactor_phase1 = 'N/A'
                    tempObj.avaragePowerFactor_phase2 = 'N/A'
                    tempObj.avaragePowerFactor_phase3 = 'N/A'


                    tempObj.maximumTHD = maxTHDList?.find((e) => e?.node === n)?.value
                    tempObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[0]?.filter((e) => e.node === n)[0]?.value : '0'
                    tempObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[1]?.filter((e) => e.node === n)[0]?.value : '0'
                    tempObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[2]?.filter((e) => e.node === n)[0]?.value : '0'

                    tempObj.frequency = 'N/A'
                    tempObj.remarks = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.Remark : 'N/A'
                    tempObj.equipment_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_id : 'N/A'
                    tempObj.equipment_type = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_type : ''
                    const nodeName = (allNodeMetaData.find((e) => e[0] === n)?.length > 0 && allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name : generateNodeName(n)
                    tempObj.node_name = nodeName
                    tempCsvDataArray.push(tempObj);

                })
            }

            const Operations = {
                lessThan: '<',
                greaterThan: '>',
                or: '||',
                and: '&&',
            };

            let filterArray = []
            if (filter && filter !== null && filter !== undefined) {

                let fltr = decryptData(filter);

                if (fltr && fltr !== null && fltr !== undefined) {
                    filterArray = JSON.parse(fltr);
                }

            }

            setFilterOptionList(filterArray)

            dispatch(setSaveFilter(filterArray))




            let cond = '';
            if (filterArray.length > 0) {

                filterArray.map((f, i2) => {

                    if (f.operation !== 'between') {
                        cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' ' + Operations[f.operation] + ' ' + f.value + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ')
                    } else {
                        cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' >= ' + f.start + ' && ' + f?.components?.value + ' <= ' + f.end + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ');
                    }

                })

            }
            // console.log(typeof component)
            // if (component && start && end) {
            //   cond = component + ' >= ' + start + ' && ' + component + ' <= ' + end
            // }

            // console.log(cond)

            if (cond !== '' && cond !== null) {
                let condition = cond;

                let cb = new Function('o', 'return ' + condition?.replace(new RegExp(Object.keys(tempCsvDataArray[0]).join('|'), 'g'), 'o.$&'));

                let data = tempCsvDataArray.filter(cb);








                setTreeData((genrateTreeStructure(data)))

                let defaultCenterNode = data?.find((e) => e.lat && e.lat !== 'N/A' && e.lng)



                if (defaultCenterNode && defaultCenterNode.lat && defaultCenterNode.lng) {


                    setCenterData({
                        ...centerData,
                        lat: defaultCenterNode?.lat,
                        lng: defaultCenterNode?.lng,
                        refreshMap: centerData.refreshMap == "refresh" ? "refreshed" : "refresh"
                    })
                }

                // setCsvData(data)
                setRowsValue(data)
                setTempRowsValue(data)

            } else {

                let defaultCenterNode = tempCsvDataArray?.find((e) => e.lat && e.lat !== 'N/A' && e.lng)



                if (defaultCenterNode && defaultCenterNode.lat && defaultCenterNode.lng) {


                    setCenterData({
                        ...centerData,
                        lat: defaultCenterNode?.lat,
                        lng: defaultCenterNode?.lng,
                        refreshMap: centerData.refreshMap == "refresh" ? "refreshed" : "refresh"
                    })
                }

                setTreeData((genrateTreeStructure(tempCsvDataArray)))
                // setCsvData(tempCsvDataArray)
                setRowsValue(tempCsvDataArray)
                setTempRowsValue(tempCsvDataArray)

            }


        } else {
            setRowsValue([])
        }

    }, [instEnergy, maxTHDList, deviceInstParam, filter, allNodeList, nodeMetaData])

    useEffect(() => {
        const paramIds = nodeListParam.some(item => item.identifier === "svoltage" || item.identifier === "scurrent");
        setParamIds(paramIds)
        },[])
    useEffect(()=>{
        if(paramIds){
            nodeListParam.forEach((data)=>{
                if(data.identifier==="svoltage"){
                    setVoltageParamID(data.param_id)
                }
                if(data.identifier==="scurrent"){
                    setCurrentParamID(data.param_id)
                }
                })
            }
    },[paramIds,getMarkedNodes])

    useEffect(()=>{
        if(getMarkedNodes.length>0&&paramIds){    
            let payloadData = devideArray(getMarkedNodes)  
            fetchVoltage(payloadData,svoltage)
            fetchCurrent(payloadData,scurrent)  
        }
    }, [getMarkedNodes,paramIds])

    useEffect(()=>{
      if(streetLightData.length>0&&paramIds){ 
       let payloadData = devideArray(streetLightData)  
       fetchStreetLightVoltage(payloadData,svoltage)
       fetchStreetLightCurrent(payloadData,scurrent)  
      }
    }, [streetLightData,paramIds])




    const fetchStreetLightVoltage = async (payloadData,payloadParamData) => {
    if (payloadData && payloadData.length > 0) {  
        let voltageResponse = []
        await Promise.all(payloadData.map(async (val, i) => {
               await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
          .then(response => {
            if (response.success && response?.data && response?.data !== null) {
                voltageResponse = [...voltageResponse, ...response.data];
            } else {
                voltageResponse = [...voltageResponse];
            }
          })
          .catch(err => {
            const error = err?.response?.data;
            if (error?.description?.includes("token is expired")) {
              logoutUser();
            }
            throw err;
          })
      })) 
      return setStreetLightVoltageResponse(voltageResponse);
      }
    }
    const fetchStreetLightCurrent = async (payloadData,payloadParamData) => {
        if (payloadData && payloadData.length > 0) {
            let currentResponse = []
            await Promise.all(payloadData.map(async (val, i) => {
                   await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
              .then(response => {
                if (response.success && response?.data && response?.data !== null) {
                    currentResponse = [...currentResponse, ...response.data];
                } else {
                    currentResponse = [...currentResponse];
                }
              })
              .catch(err => {
                const error = err?.response?.data;
                if (error?.description?.includes("token is expired")) {
                  logoutUser();
                }
                throw err;
              })
          }))
          return setStreetLightCurrentResponse(currentResponse);
          }
      }
    const fetchVoltage = async (payloadData,payloadParamData) => {
        if (payloadData && payloadData.length > 0) {  
            let voltageResponse = []
            await Promise.all(payloadData.map(async (val, i) => {
                   await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
              .then(response => {
                if (response.success && response?.data && response?.data !== null) {
                    voltageResponse = [...voltageResponse, ...response.data];
                } else {
                    voltageResponse = [...voltageResponse];
                }
              })
              .catch(err => {
                const error = err?.response?.data;
                if (error?.description?.includes("token is expired")) {
                  logoutUser();
                }
                throw err;
              })
          })) 
          return setVoltageResponse(voltageResponse);
          }
      }
      const fetchCurrent = async (payloadData,payloadParamData) => {
        if (payloadData && payloadData.length > 0) {
            let currentResponse = []
            await Promise.all(payloadData.map(async (val, i) => {
                   await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
              .then(response => {
                if (response.success && response?.data && response?.data !== null) {
                    currentResponse = [...currentResponse, ...response.data];
                } else {
                    currentResponse = [...currentResponse];
                }
              })
              .catch(err => {
                const error = err?.response?.data;
                if (error?.description?.includes("token is expired")) {
                  logoutUser();
                }
                throw err;
              })
          }))
          return setCurrentResponse(currentResponse);
          }
      }

      function mergeTableData(arr1, arr2,arr3) {
        const merged = {};
      
        // Merge values from the first array
        for (const obj of arr1) {
          merged[obj.nodeid] = { ...merged[obj.nodeid], ...obj };
        }
      
        // Merge values from the second array
        for (const obj of arr2) {
          merged[obj.node] = { ...merged[obj.node], ...obj };
        }
        for (const obj of arr3) {
            merged[obj.node] = { ...merged[obj.node], ...obj };
          }    // Convert the merged object back to an array
        const result = Object.values(merged);
        return result;
      }

      function mergeStreetTableData(arr1, arr2,arr3) {
        const merged = {};
      
        for (const obj of arr1) {
          merged[obj.node_id] = { ...merged[obj.node_id], ...obj };
        }
      
        for (const obj of arr2) {
          merged[obj.node] = { ...merged[obj.node], ...obj };
        }
        for (const obj of arr3) {
            merged[obj.node] = { ...merged[obj.node], ...obj };
          }  
        const result = Object.values(merged);
        return result;
      }
      
      const setReletiveTime = (time) => {

        switch (true) {
            case time == 'an hour ago':
                return '1 hour ago';
            case time == 'a day ago':
                return '1 day ago';
            case time == 'a year ago':
                return '1 year ago';
            case time.includes('years ago') && time !== "1 year ago":
                return '-';
            default:
                return time;
        }
    }
    useEffect(() => { 
        if(paramIds){
        const modifiedCurrentResponse = streetLightCurrentResponse?.map(item => {
          const { value,timestamp ,...rest } = item; 
          return { currentvalue: value, currenttimestamp: timestamp ,...rest }; 
        });
        if(streetLightVoltageResponse&&modifiedCurrentResponse){
        let mergedData = mergeStreetTableData(streetAllLightData, streetLightVoltageResponse,modifiedCurrentResponse);
    
        mergedData.map(data =>  {  
    
          const latestTimestamp = Math.max(data.currenttimestamp, data.timestamp);
         data.latestTimestamp=latestTimestamp?latestTimestamp:0;
         data.voltagesorting=data.value?data.value:0;
         data.currentsorting=data.currentvalue?data.currentvalue:0;
    
           
            let lastUpdatedTime = 'N/A';
            if(latestTimestamp){
            let relateiveTime = moment.unix(latestTimestamp).startOf("minute").fromNow();
            let relateiveTimeArr = relateiveTime.split(' ');
            if (relateiveTimeArr.includes('days') && typeof relateiveTimeArr[0] == 'number' && relateiveTimeArr[0] > 3) {
            lastUpdatedTime = moment.unix(latestTimestamp).format('DD/MM/YYYY hh:mm:ss A');
            } else {
            lastUpdatedTime = setReletiveTime(relateiveTime);
            }
        } 
            data.lastUpdatedTime = lastUpdatedTime;
            data.active = checkEquipmetIsActive(data.latestTimestamp * 1000) ? true : false
           return data;
        });
        
        var activeStreetLight =mergedData.filter((data)=> data.active==true);
        setStreetLightActiveData(activeStreetLight)
        let expiryDate =new Date()
         let storingData ={ [storeStreetLightParentId]:{data:mergedData,expiryDate:expiryDate}}
        setStoreStreetLight((prevStoreStreetLight) => ({
            ...prevStoreStreetLight,
            ...storingData,
          }));
        }
        }else{

        }
      }, [streetLightVoltageResponse, streetLightCurrentResponse,paramIds])

      useEffect(() => { 
        if(paramIds){
        const modifiedCurrentResponse = currentResponse?.map(item => {
          const { value,timestamp ,...rest } = item; 
          return { currentvalue: value, currenttimestamp: timestamp ,...rest }; 
        });
        if(voltageResponse&&modifiedCurrentResponse){
        let mergedData = mergeTableData(zoomValue, voltageResponse,modifiedCurrentResponse);
    
        mergedData.map(data =>  {  
    
          const latestTimestamp = Math.max(data.currenttimestamp, data.timestamp);
         data.latestTimestamp=latestTimestamp?latestTimestamp:0;
         data.voltagesorting=data.value?data.value:0;
         data.currentsorting=data.currentvalue?data.currentvalue:0;
    
           
            let lastUpdatedTime = 'N/A';
            if(latestTimestamp){
            let relateiveTime = moment.unix(latestTimestamp).startOf("minute").fromNow();
            let relateiveTimeArr = relateiveTime.split(' ');
            if (relateiveTimeArr.includes('days') && typeof relateiveTimeArr[0] == 'number' && relateiveTimeArr[0] > 3) {
            lastUpdatedTime = moment.unix(latestTimestamp).format('DD/MM/YYYY hh:mm:ss A');
            } else {
            lastUpdatedTime = setReletiveTime(relateiveTime);
            }
        } 
            data.lastUpdatedTime = lastUpdatedTime;
            data.active = checkEquipmetIsActive(data.latestTimestamp * 1000) ? true : false
           return data;
        });
        
        
    
        let streetLightData=mergedData.filter((data)=>data.equipment_type=="street_light")
    
        setzoomValueData(streetLightData);
        }
        }else{
       setzoomValueData(zoomValue);
        }
      }, [voltageResponse, currentResponse,zoomValue,paramIds])

      const fetchgetStreetlightNodeParamData = async (payload) => {
        await handlegetStreetlight(token, payload)
            .then(response => {
                if (response.success && Object.keys(response.data).length) {
                    // dispatch(setSaveNodeMetaData(response.data));
                    let streetLightKey =Object.keys(response?.data)
                    let streetLightValue =Object.values(response?.data);
                    setStreetLightData(streetLightKey)  
                    setStreetAllLightData(streetLightValue)          
                }else{
                    setStreetLightData([])   
                    setStreetAllLightData([])    
                    setStreetLightActiveData([])      
                }
                return ;
            })
            .catch(err => {
                const error = err?.response?.data;
                AlertMessage({ msg: error?.description })
                if (error.description.includes("token is expired")) {
                    logoutUser();
                }
                throw err;
            })
    }

    const [markerList, setMarkerList] = React.useState([]);

    function areNewCoordsInsideOldCoords(oldCoords, newSW, newNE) {
        const { sw: oldSW, ne: oldNE } = oldCoords;
    
        if (
            newSW.lat >= oldSW.lat &&
            newSW.lng >= oldSW.lng &&
            newNE.lat <= oldNE.lat &&
            newNE.lng <= oldNE.lng
        ) {
            return true;
        } else {
            return false;
        }
    }


    useEffect(() => {

        if (map && maps) {
            if(tempZoom >13 && tempZoom < 14){
                const bounds1 = map.getBounds();
                if(bounds1) {
                    (async () => {
                    const sw = bounds1.getSouthWest(); // Get the southwest corner
                    const ne = bounds1.getNorthEast(); // Get the northeast corner
                    let payloadData ={
                        bounds: {
                            sw: {
                                lat:sw.lat(),
                                lng: sw.lng()
                            },
                            ne: {
                                lat: ne.lat(),
                                lng: ne.lng()                
                            }
                        }
                    }

                        setBoundary(payloadData.bounds)

                    const headers = {
                        'Authorization': token, 
                        'Content-Type': 'application/json',
                      };
                       const getMarked =  await axios.post(`${baseUrls}equipment/search`,payloadData,{headers});
    
                       const metadataArray = [];
    
                       for (const nodeId in getMarked.data.data) {
                           if (getMarked.data.data.hasOwnProperty(nodeId)) {
                               const metadata = getMarked.data.data[nodeId].metadata;
                               const nodeObject = {
                                   "nodeid": nodeId
                               };
                       
                               // Add all metadata key-value pairs to the nodeObject
                               for (const key in metadata) {
                                   if (metadata.hasOwnProperty(key)) {
                                       nodeObject[key] = metadata[key];
                                   }
                               }
                       
                               metadataArray.push(nodeObject);
                           }
                       }
     
                       const getMarkedNode =Object.keys(getMarked.data.data)
                      let streetLightValue= metadataArray?.filter((data)=>data.equipment_type==="street_light")
                      
                       setGetMarkedNodes(getMarkedNode)
                       setZoomValue(streetLightValue)
                    })()
                    }
            }

            
            
            if (tempZoom >= 17) {
                const bounds1 = map.getBounds();
            if(bounds1) {
                const sw = bounds1.getSouthWest(); // Get the southwest corner
                const ne = bounds1.getNorthEast(); // Get the northeast corner
                (async () => {

                    let newSW = {
                        lat:sw.lat(),
                        lng: sw.lng()
                    };
                    
                    let newNE = {
                        lat: ne.lat(),
                        lng: ne.lng() 
                    };

             let payloadData ={
                bounds: {
                    sw: {
                        lat:sw.lat(),
                        lng: sw.lng()
                        },
                    ne: {
                        lat: ne.lat(),
                        lng: ne.lng()                
                        }
                    }
                }

                if(!boundary){setBoundary(payloadData.bounds)}

                const isInside = areNewCoordsInsideOldCoords(boundary, newSW, newNE);

                if (!isInside) {                 
                    setBoundary(payloadData.bounds)
                const headers = {
                    'Authorization': token, 
                    'Content-Type': 'application/json',
                  };
                   const getMarked =  await axios.post(`${baseUrls}equipment/search`,payloadData,{headers});

                   const metadataArray = [];

                   for (const nodeId in getMarked.data.data) {
                       if (getMarked.data.data.hasOwnProperty(nodeId)) {
                           const metadata = getMarked.data.data[nodeId].metadata;
                           const nodeObject = {
                               "nodeid": nodeId
                           };
                   
                           // Add all metadata key-value pairs to the nodeObject
                           for (const key in metadata) {
                               if (metadata.hasOwnProperty(key)) {
                                   nodeObject[key] = metadata[key];
                               }
                           }
                   
                           metadataArray.push(nodeObject);
                       }
                   }
 
                   const getMarkedNode =Object.keys(getMarked.data.data)
                  let streetLightValue= metadataArray?.filter((data)=>data.equipment_type==="street_light")
                  
                   setGetMarkedNodes(getMarkedNode)
                   setZoomValue(streetLightValue)
                }
                })()

              }
                let markersData = zoomValueData;
                if (markersData && markersData.length > 0) {

                    markersData.forEach((marker) => {
                        let current_view_port = new maps.LatLngBounds();
                        if (marker.lat && marker.lat !== 'N/A' && marker.lat != undefined) {

                            let markers = new maps.marker.AdvancedMarkerView({
                                position: { lat: Number(marker.lat), lng: Number(marker.lng) },
                                map,
                                content: buildContent(marker),
                            });

                            const element = markers.element;

                            ["focus", "pointerenter"].forEach((event) => {
                                element.addEventListener(event, () => {
                                    highlight(markers, marker);
                                });
                            });
                            ["pointerenter","click"].forEach((event) => {
                                element.addEventListener(event, () => {
                                    setDetailsCartProperty(marker)
                                });
                            });
                            ["blur", "pointerleave"].forEach((event) => {
                                element.addEventListener(event, () => {
                                    unhighlight(markers, marker);
                                });
                            });
                            markers.addListener("click", (event) => {
                                let _eventId = event?.domEvent?.target?.id || '';
                                if (event && _eventId && (_eventId === 'copyISPan' || _eventId === 'copyIImg')) {
                                    copyLink(marker)
                                } else {
                                    return
                                }
                            });
                            setMarkerList(oldMarker => ([
                                ...oldMarker,
                                markers
                            ]));
                        }
                    });
                }
            } else {
                if (markerList.length > 0) {
                    for (let index = 0; index < markerList.length; index++) {
                        markerList[index].setMap(null);
                        // setMarkerList(oldMarker => ([
                        //     ...oldMarker,
                        //     markers
                        // ]));
                    }
                }
                // setBoundary(null)
            }
        }
    }, [map, maps, tempZoom, centerData])
    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            streetViewControl: false,
            scaleControl: true,
            fullscreenControl: false,
            mapTypeId: mapType, //ROADMAP  ,   SATELLITE

            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "off"
                }]
            }],
            mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
            mapTypeControl: false,
            mapTypeControlOptions: {
                position: maps.ControlPosition.RIGHT_BOTTOM,
                style: maps.MapTypeControlStyle.DEFAULT,
                mapTypeIds: ["roadmap", 'satellite'],
            },

            zoomControlOptions: {
                // position: maps.ControlPosition.RIGHT_BOTTOM,
                style: maps.ZoomControlStyle.SMALL
            },

            gestureHandling: "greedy",
            disableDoubleClickZoom: true,
            minZoom: 1,
            maxZoom: 30,

            zoomControl: false,
            clickableIcons: true,

            disableDefaultUI: true
        };
    }

    const removeFilterOption = (i, type = null) => {
        let myArray = [...filterOptionList]
        myArray.splice(i, 1);
        // delete myArray[i]
        setFilterOptionList(myArray)
        if (type == 'list') {
            dispatch(setSaveFilter(myArray))
            let encodedFilter = encryptData(JSON.stringify(myArray))
            navigate('/map/view?filter=' + encodedFilter)
        }

    }

    const openModal = () => {
        // let t = [...filters]
        // setFilterOptionList(t)
        setFilterOptions({
            ...filterOptions,
            operation: "",
            value: "",
            start: "",
            end: "",
            condition: "and"
        })
        setShowFilterModal(true)
    }

    const closeModal = () => {
        setShowFilterModal(false)
    }

    const addFilterBtn = () => {

        if (filterOptions.components !== '' && filterOptions.operation !== '' && (filterOptions.operation == 'between' ? filterOptions.start !== '' && filterOptions.end !== '' : filterOptions.value !== '')) {

            let filterList = [...filterOptionList]
            filterList = [...filterList, filterOptions]

            setFilterOptionList(filterList)

            setFilterOptions({
                ...filterOptions,
                operation: "",
                value: "",
                start: "",
                end: "",
                condition: "and"
            })

        } else {

            alert('All fields are required')

        }
    }

    const handleChange = (e) => {
        // e.preventDefault();
        let name = e.target.name
        let value;

        if (name === 'components') {
            value = componentList.find((e1) => e1?.value == e?.target?.value)
        } else if (name === 'operation') {
            value = e.target.id
        } else {
            value = e.target.value
        }

        setFilterOptions({
            ...filterOptions,
            [name]: value
        })


    };

    const saveFilter = () => {
        let encodedFilter = encryptData(JSON.stringify(filterOptionList))
        dispatch(setSaveFilter(filterOptionList))
        setShowFilterModal(false)
        navigate('/map/view?filter=' + encodedFilter)

    }

    const handleChangeCondition = (e, key) => {
        let lst = [...filterOptionList]
        lst[key].condition = e.target.value
        setFilterOptionList(lst)
    }

    const onClickListView = () => {
        if (url?.search) {
            navigate('/equipments' + url.search)
        } else {
            navigate('/equipments')
        }
    }

    function highlight(markerView, property) {
        markerView.content.classList.add("highlight");
        markerView.element.style.zIndex = 1;
    }

    function unhighlight(markerView, property) {
        markerView.content.classList.remove("highlight");
        markerView.element.style.zIndex = "";
    }

    const detailsCart=(property,streetLight) =>{
        if(property.name){
            let streetLightDatas = streetLight[property.name];
            if(streetLightDatas){
               let currentDate =new Date()
               let expiryDate=new Date(streetLightDatas.expiryDate.getTime() + 50 * 60 * 1000);
               if(currentDate.getTime()<=expiryDate.getTime()){
                setStreetAllLightData(streetLightDatas.data)          
                var activeStreetLight = streetLightDatas.data.filter((data)=> data.active==true);
                setStreetLightActiveData(activeStreetLight)
               }
               else{
                setStoreStreetLightParentId(property.name)
                const payload = {
                    nodeid:property.name
                  };
                fetchgetStreetlightNodeParamData(payload)
               }
         }else{
               setStoreStreetLightParentId(property.name)
               const payload = {
                nodeid:property.name
              };
            fetchgetStreetlightNodeParamData(payload)
         }
        }
        setEquipmentId(property.node_name)
        setConsumptionValue(property.consumptionValue)
        setMaximumTHD(property.maximumTHD)
        setVoltageValue(property.voltageValue)
        setStreetLightVoltage(property.voltagesorting)
        setCurrent(property.currentvalue)
        setEquipmentType(property.equipment_type)
        setCopyLinkData(property)
        setViewData(true)
    }
    const copyLink = (data) => {
        navigator.clipboard.writeText(createLocationLink(data.lat, data.lng))
        ToastAlert({ msg: "Google Map link copied to clipboard!", msgType: "info" })
        // AlertMessage({ msg: "Google Map link copied to clipboard!" })
        // return;
    }

    function buildContent(data) {
        let consumption = data?.consumption ? data?.consumption : '-';
        let maximumTHD = data?.maximumTHD ? data?.maximumTHD + '%' : '-';
        let liveVoltage = data?.liveVoltage ? data?.liveVoltage : '-';
        const content = document.createElement("div");
        // content.classList.add("property");
        content.innerHTML = `
        <img src="${data.equipment_type=="street_light"?!data.active ? StreetLight : StreetLightActive:!data.active ? redPin : greenPin}" style="${data.equipment_type=="street_light"?"bottom: 0px; position: absolute; width:25px; height:5px":"bottom: 0px; position: absolute;"} className="map_marker_img" alt=""  />
          `;
        return content;
    }

    const debounce = (func, wait) => {
        let timeout;
        return function () {
          const context = this;
          const args = arguments;
          const later = function () {
            timeout = null;
            func.apply(context, args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      }

    const handleDrag = debounce((mapEl) => {

        let current_view_zoom = mapEl.getZoom();
        if (current_view_zoom >= 17 === false) return;

            const bounds1 = map.getBounds();
            if(bounds1) {
                const sw = bounds1.getSouthWest(); // Get the southwest corner
                const ne = bounds1.getNorthEast(); // Get the northeast corner
                (async () => {

                    let newSW = {
                        lat:sw.lat(),
                        lng: sw.lng()
                    };
                    
                    let newNE = {
                        lat: ne.lat(),
                        lng: ne.lng() 
                    };
                

                let payloadData ={
                    bounds: {
                        sw: {
                            lat:sw.lat(),
                            lng: sw.lng()
                        },
                        ne: {
                            lat: ne.lat(),
                            lng: ne.lng()                
                        }
                    }
                }

                const isInside = areNewCoordsInsideOldCoords(boundary, newSW, newNE);

                 
                if (!isInside) {
                    setBoundary(payloadData.bounds)
                  const headers = {
                    'Authorization': token, 
                    'Content-Type': 'application/json',
                  };
                   const getMarked =  await axios.post(`${baseUrls}equipment/search`,payloadData,{headers});

                   const metadataArray = [];

                   for (const nodeId in getMarked.data.data) {
                       if (getMarked.data.data.hasOwnProperty(nodeId)) {
                           const metadata = getMarked.data.data[nodeId].metadata;
                           const nodeObject = {
                               "nodeid": nodeId
                           };
                   
                           // Add all metadata key-value pairs to the nodeObject
                           for (const key in metadata) {
                               if (metadata.hasOwnProperty(key)) {
                                   nodeObject[key] = metadata[key];
                               }
                           }
                   
                           metadataArray.push(nodeObject);
                       }
                   }
 
                   const getMarkedNode =Object.keys(getMarked.data.data)
                   let streetLightValue= metadataArray?.filter((data)=>data.equipment_type==="street_light")
                   setGetMarkedNodes(getMarkedNode)
                   setZoomValue(streetLightValue)
                }
                })()

              }


        let markersData = zoomValueData;
        let markers_list = markersData.filter(el => el.lat && el.lat !== 'N/A' && el.lat != undefined);
        markers_list.forEach((marker) => {
            let current_view_port = mapEl.getBounds();
            if (current_view_port.contains({ lat: Number(marker.lat), lng: Number(marker.lng) })) {
                let markers = new maps.marker.AdvancedMarkerView({
                    position: { lat: Number(marker.lat), lng: Number(marker.lng) },
                    map,
                    content: buildContent(marker),
                });

                const element = markers.element;

                ["focus", "pointerenter"].forEach((event) => {
                    element.addEventListener(event, () => {
                        highlight(markers, marker);
                    });
                });
                ["pointerenter","click"].forEach((event) => {
                    element.addEventListener(event, () => {
                        setDetailsCartProperty(marker)
                    });
                });
                ["blur", "pointerleave"].forEach((event) => {
                    element.addEventListener(event, () => {
                        unhighlight(markers, marker);
                    });
                });
                markers.addListener("click", (event) => {
                    let _eventId = event?.domEvent?.target?.id || '';
                    if (event && _eventId && (_eventId === 'copyISPan' || _eventId === 'copyIImg')) {
                        copyLink(marker)
                    } else {
                        return
                    }
                });
                setMarkerList(oldMarker => ([
                    ...oldMarker,
                    markers
                ]));
            } else {
                if (markerList.length > 0) {
                    for (let index = 0; index < markerList.length; index++) {
                        markerList[index].setMap(null);
                    }
                }
            }
        });
    }, 500)


  

    const onClickFitView = () => {

        let markersData = rowsvalue;
        if (markersData && markersData.length > 0) {
            let bounds = new maps.LatLngBounds();
            markersData.forEach((marker) => {
                if (marker.lat && marker.lat !== 'N/A' && marker.lat != undefined) {
                    bounds.extend(new maps.LatLng(marker.lat, marker.lng));
                }
            });

            map.fitBounds(bounds, { width: 225, height: 777 })
            map.setZoom(map.getZoom() - 0.3);
        }
    }

    const handleApiLoaded = (map, maps) => {
        setMap(map)
        setMaps(maps)
        let markersData = rowsvalue;
        let gatewayTypeEquipments = markersData.filter(el => el.equipment_type === 'gateway');
        if (!boundsFitted && gatewayTypeEquipments && gatewayTypeEquipments.length > 0) {
            setBoundsFitted(true);
            let bounds = new maps.LatLngBounds();
            gatewayTypeEquipments.forEach((marker) => {
                if (marker.lat && marker.lat !== 'N/A') {
                    bounds.extend(new maps.LatLng(marker.lat, marker.lng));
                }
            });
            map.fitBounds(bounds, { width: 225, height: 777 })
            map.setZoom(map.getZoom() - 0.3);
        }

        if (gatewayTypeEquipments.length > 0) {

            return gatewayTypeEquipments.forEach((marker) => {

                let img = !marker.active ? redPin : greenPin

                if (marker.lat && marker.lat !== 'N/A' && marker.lat != undefined) {
                    let markers = new maps.marker.AdvancedMarkerView({
                        position: { lat: Number(marker.lat), lng: Number(marker.lng) },
                        map,
                        content: buildContent(marker),
                    });

                    const element = markers.element;

                    ["focus", "pointerenter"].forEach((event) => {
                        element.addEventListener(event, () => {
                            highlight(markers, marker);
                        });
                    });
                    ["pointerenter","click"].forEach((event) => {
                        element.addEventListener(event, () => {
                            setDetailsCartProperty(marker)
                        });
                    });
                    ["blur", "pointerleave"].forEach((event) => {
                        element.addEventListener(event, () => {
                            unhighlight(markers, marker);
                        });
                    });
                    markers.addListener("click", (event) => {
                        let _eventId = event?.domEvent?.target?.id || '';
                        if (event && _eventId && (_eventId === 'copyISPan' || _eventId === 'copyIImg')) {
                            copyLink(marker)
                        } else {
                            return
                        }
                    });
                    return markers;
                }
            });
        }
    };

    function handleZoomChanged(e) {
        setTempZoom(e)
    }

    function handleMapTypeChanged(e) {
        setMapType(e)

    }

    const onClickZoom = (type) => {
        if (type == 'plus') {
            map.setZoom(map.getZoom() + 1);
        } else {
            map.setZoom(map.getZoom() - 1);
        }
    }

    const TitleActions = React.memo((props) => {
        const { data } = props;

        const handleClick = () => {

            // if (centerData.zoom === tempZoom) {
            //     setCenterData({
            //         ...centerData,
            //         lat: data.lat,
            //         lng: data.lng,
            //     })

            // } else {
            setCenterData({
                ...centerData,
                lat: data.lat,
                lng: data.lng,
                refreshMap: centerData.refreshMap == "refresh" ? "refreshed" : "refresh",
                zoom: DEFAULT_ZOOM
            })
            setTimeout(() => {
                setTempZoom(DEFAULT_ZOOM)
            }, 500);
            // }
        }

        return (
            <>
                <FiberManualRecordIcon style={{ color: data.status ? '#02F5A0' : '#FF4438' }} />
                <span className="row-title" style={{ paddingLeft: 13 }}>{data.name}</span>
                {
                    data?.lat && data?.lat !== 'N/A' && data?.lng ?

                        <img src={MyLocationIcon} style={{
                            cursor: "pointer",
                            position: 'absolute',
                            right: '5%'
                        }}
                            onClick={() => handleClick()}
                        />
                        :
                        <img src={UnavailableIcon} style={{
                            cursor: "not-allowed",
                            position: 'absolute',
                            right: '7%'
                        }}
                        />
                }
            </>
        )
    });

    const TreeFolderIcon = React.memo(({ isClosed }) => {
        return (
            isClosed ? <FolderOutlinedIcon sx={{ width: 20, height: 20 }} /> : <FolderOpenIcon />
        )
    })

    const TreeArrowIcon = React.memo(({ isClosed }) => {
        return (
            isClosed ? <ArrowRightIcon sx={{ width: 30, height: 30 }} /> : <ArrowDropDownIcon sx={{ width: 30, height: 30 }} />
        )
    })

    const TreeNode = React.memo((props) => {

        const { innerRef, styles, node, tree } = props;
        const isFolder = node.data?.children && node.data?.children.length > 0 ? true : false;
        const indentExtra = isFolder && node.data?.nodeId === node.data?.nodeName ? Number(node.level) - 1 : node.level;
        const indent = tree.indent * indentExtra;
        const nodeStyle = React.useMemo(() => ({ paddingLeft: indent }), [indent]);
        const isClosed = node.isClosed;

        return (
            <div ref={innerRef} key={node?.id} className="TreeNode" style={{
                ...nodeStyle,
                // background: 'rgb(255, 255, 255)',
                // paddingLeft: 10 
            }}
            >
                {isFolder ? (
                    <>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                node.toggle()
                            }}
                            sx={{ width: 36, height: 36 }}
                        >
                            {node.data?.nodeId === node.data?.nodeName ? <TreeArrowIcon isClosed={isClosed} /> : <TreeFolderIcon isClosed={isClosed} />}
                        </IconButton>
                        {node.data?.nodeId === node.data?.nodeName ? (
                            <TitleActions
                                data={node.data}
                            />
                        ) : <span className="row-title" style={{ paddingLeft: 10 }}>{node.data.name}</span>}
                    </>
                ) : (
                    <TitleActions
                        data={node.data}
                    />
                )}
            </div>
        );
    });

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                    bgcolor: "#F5F5F5",
                }}
                className='dashboard-main'
            >
                <Toolbar />

                <div
                    className="map-view-main"
                    style={{ height: '93.5%', width: '100%', position: 'relative' }}
                >
                    <div className="tree-section-content" style={{ width: "30%", position: 'absolute', zIndex: 999, top: '30px', left: '37px', background: 'transparent', boxShadow: "unset" }}>
                        <div className="tree-section-header" style={{ position: "relative", zIndex: 999, background: "#fff", height: "57px", padding: "unset", gap: "5px", padding: "0 10px" }}>

                            <div className='search-input'>
                                <img src={SearchIcon} />
                                <input type='text' placeholder='Search Equipment' value={searchEquipment} onChange={(e) => setSearchEquipment(e.target.value)} />
                            </div>

                            <Button
                                type="submit"
                                variant=""
                                onClick={() => openModal()}
                                sx={{
                                    width: '130px',
                                    height: '38px',
                                    border: '1px solid #8D8D8D',
                                    borderRadius: '10px',
                                    textTransform: "capitalize"
                                }}
                            >
                                <img src={SliderSvg} style={{ marginRight: "10px" }}></img>
                                <span style={{ color: 'rgb(102 102 102)' }}>Filter</span>
                            </Button>

                            <Button
                                type="submit"
                                variant=""
                                onClick={() => onClickListView()}
                                sx={{
                                    width: '29px',
                                    height: '38px',
                                    minWidth: '45px',
                                    border: '1px solid #8D8D8D',
                                    borderRadius: '10px',
                                    textTransform: "capitalize"
                                }}
                            >
                                <img src={BulletedListSvg}></img>
                            </Button>

                            <Button
                                type="submit"
                                variant=""
                                onClick={() => setToogle(!toogle)}
                                sx={{
                                    width: 25,
                                    height: '38px',
                                    minWidth: '10%',
                                    border: '1px solid #8D8D8D',
                                    borderRadius: '10px',
                                    textTransform: "capitalize",
                                    background: '#b8b8b863'
                                }}
                            >
                                {toogle ? <KeyboardArrowDownOutlinedIcon sx={{ width: 30, height: 30 }} /> : <KeyboardArrowUpOutlinedIcon sx={{ width: 30, height: 30 }} />}
                                {/* <img src={!toogle ? LessThanSvg : LessThan2Svg} style={{ width: 25 }}></img> */}
                            </Button>
                        </div>

                        <div style={{ display: "flex", background: 'rgb(255, 255, 255)' }}>
                            {
                                filters.length > 0 &&
                                filters.map((val, key) => {

                                    return (
                                        <div className='fltr-btn'>
                                            {
                                                val.operation !== 'between' ?
                                                    <span> {val?.components?.title} <span style={{ color: "black", fontSize: "smaller", fontWeight: "300" }}>{(val.operation == "greaterThan" ? '>' : '<') + val.value}</span></span> :
                                                    <span> {val?.components?.title} <span style={{ color: "black", fontSize: "smaller", fontWeight: "300" }}>: {val.start} - {val.end}</span></span>
                                            }
                                            <HighlightOffIcon color="primary" style={{ cursor: "pointer" }} onClick={() => removeFilterOption(key, 'list')} />
                                        </div>
                                    )

                                })
                            }

                        </div>

                        <div className={`map-toogle ${toogle ? 'map-toogle-active' : ''}`}>
                            {!toogle && (
                                <div style={{
                                    background: 'rgb(255, 255, 255)',
                                    maxHeight: "calc(100vh - 200px)",
                                    height: 'auto',
                                    paddingLeft: 10,
                                    // ...treeContainer
                                }}>
                                    {/* <FillFlexParentLayout>
                                        {({width, height}) => ( */}
                                    <Tree
                                        className="react-aborist"
                                        data={treeData.children}
                                        openByDefault={true}
                                        disableEdit={true}
                                        disableDrag={true}
                                        disableDrop={true}
                                        width={'100%'}
                                        height={750}
                                        searchTerm={searchEquipment}
                                        searchMatch={
                                            (node, term) => node.data.name.toLowerCase().includes(searchEquipment.toLowerCase())
                                        }
                                        indent={35}
                                        rowHeight={50}
                                        idAccessor="_id"
                                    >
                                        {TreeNode}
                                    </Tree>
                                    {/* )}
                                    </FillFlexParentLayout> */}
                                </div>
                                // <FolderTree
                                //     data={treeData ? treeData : {}}
                                //     onChange={onTreeStateChange}
                                //     showCheckbox={false}
                                //     readOnly
                                //     iconComponents={iconComponents}
                                // />
                            )}
                        </div>
                    </div>

                    <GoogleMapReact
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        key={centerData.refreshMap}
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, libraries: "marker", version: 'beta' }}
                        defaultCenter={{ lat: Number(centerData?.lat), lng: Number(centerData?.lng) }}
                        center={{ lat: Number(centerData?.lat), lng: Number(centerData?.lng) }}
                        defaultZoom={centerData.zoom}
                        onZoomAnimationEnd={handleZoomChanged}
                        onMapTypeIdChange={handleMapTypeChanged}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        onDragEnd={(map) => handleDrag(map)}
                    >
                        {/* 
                        {
                            rowsvalue.length > 0 &&
                            rowsvalue.map((rec, i) => {
                                if (rec.lat && rec.lat !== 'N/A')
                                    // console.log(rec,i)
                                    return (
                                        <NodePin
                                            lat={rec.lat}
                                            lng={rec.lng}
                                            status={rec.active}
                                            data={rec}

                                        />
                                    )
                            })


                        } */}

                    </GoogleMapReact >

                    <div className="tree-actions-main" style={{

                        display: 'flex',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        width: '10%',
                        minWidth: 'unset',
                        justifyContent: 'center',
                        padding: '0 15px',
                        height: '38px',
                        alignItems: 'center',
                        marginBottom: '10px',
                        position: 'absolute',
                        bottom: '3%',
                        right: '15%',
                        background: ' #FFFFFF',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        gap: "8px"

                    }}>

                        <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => setMapType('roadmap')}>
                            <span style={{ fontWeight: "500", fontSize: "14px", color: mapType == 'roadmap' ? '#000000' : '#7F7F7F' }}>Map</span>
                        </div>

                        <span className="saprater-icon" style={{ height: "100%" }}>
                            <img src={Line148} />
                        </span>

                        <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => setMapType('satellite')}>
                            <span style={{ fontWeight: "500", fontSize: "14px", color: mapType == 'satellite' ? '#000000' : '#7F7F7F' }}>Satellite</span>
                        </div>

                    </div>

                    <div className="tree-actions-main" style={{

                        display: 'flex',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        width: '10%',
                        minWidth: 'unset',
                        justifyContent: 'center',
                        padding: '0 15px',
                        height: '38px',
                        alignItems: 'center',
                        marginBottom: '10px',
                        position: 'absolute',
                        bottom: '3%',
                        right: '4%',
                        background: ' #FFFFFF',
                        border: ' 1px solid rgba(0, 0, 0, 0.1)',
                        gap: "8px"

                    }}>

                        <div className="tree-action" style={{ cursor: "pointer" }} onClick={onClickFitView}>
                            <img src={GroupFitView} />
                        </div>

                        <span className="saprater-icon" style={{ height: "100%" }}>
                            <img src={Line148} />
                        </span>

                        <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => onClickZoom('plus')}>
                            <img src={PlusMath} />
                        </div>
                        <span className="saprater-icon" style={{ height: "100%" }}>
                            <img src={Line148} />
                        </span>
                        <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => onClickZoom('minus')}>
                            <img src={Subtract} />
                        </div>


                    </div>

                </div >
                <MapCartView  detailsCarts={detailsCart} storeStreetLight={storeStreetLight} detailsCartProperty={detailsCartProperty} streetAllLightData={streetAllLightData} streetLightActiveData={streetLightActiveData} viewData={viewData} equipmentType={equipmentType} equipmentId={equipmentId} copyLink={copyLink} consumptionValue={consumptionValue} maximumTHD={maximumTHD} voltageValue={voltageValue} current={current} streetLightvoltage={streetLightvoltage} copyLinkData={copyLinkData}/>

            </Box >         
            <FilterModal
                showFilterModal={showFilterModal}
                closeModal={closeModal}
                filterOptions={filterOptions}
                addFilterBtn={addFilterBtn}
                handleChange={handleChange}
                removeFilterOption={removeFilterOption}
                saveFilter={saveFilter}
                componentList={componentList}
                filterOptionList={filterOptionList}
                handleChangeCondition={handleChangeCondition}
            />
        </>

    )

}


export default MapView