import React from "react";
import { CSVLink } from "react-csv";

const Index = React.memo(({
    headers,
    csvData,
    label,
    prifix
}) => {

    const fileName = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ':' + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds() + ":" + prifix + ".csv"
   
    return (
        <CSVLink data={csvData} headers={headers} className="btncssExp" filename={fileName}>
            {label}
        </CSVLink>
    )

})

export default Index;