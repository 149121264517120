export const areaData = [
  {
    name: "12 am",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "06 am",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 2300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 5000,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 6300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 7000,
    amt: 2100,
  },
  {
    name: "12 pm",
    uv: 3490,
    pv: 5500,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 8000,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 7500,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 9000,
    amt: 2100,
  },
  {
    name: "06 pm",
    // uv: 3490,
    pv: 8500,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 6000,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 1200,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 800,
    amt: 2100,
  },
  {
    name: "",
    // uv: 3490,
    pv: 3500,
    amt: 2100,
  },
  {
    name: "12 am",
    // uv: 3490,
    pv: 4000,
    amt: 2100,
  },
];


export const mainData = [
  {
    name: "01",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "03",
    uv: -2000,
    pv: -9800,
    amt: 2290,
  },
  {
    name: "",
    uv: -2780,
    pv: -3908,
    amt: 2000,
  },
  {
    name: "05",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "07",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "09",
    uv: 3490,
    pv: 2300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 5000,
    amt: 2100,
  },
  {
    name: "11",
    uv: 3490,
    pv: 6300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 7000,
    amt: 2100,
  },
  {
    name: "13",
    uv: 3490,
    pv: 5500,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "15",
    uv: 3490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 8000,
    amt: 2100,
  },
  {
    name: "17",
    uv: 3490,
    pv: 7500,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 9000,
    amt: 2100,
  },
  {
    name: "19",
    uv: 3490,
    pv: 8500,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 6000,
    amt: 2100,
  },
  {
    name: "21",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 1200,
    amt: 2100,
  },
  {
    name: "23",
    uv: 3490,
    pv: 800,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 3500,
    amt: 2100,
  },
  {
    name: "25",
    uv: 3490,
    pv: 4000,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "27",
    uv: 3490,
    pv: 8000,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 6500,
    amt: 2100,
  },
  {
    name: "29",
    uv: 3490,
    pv: 5600,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 4700,
    amt: 2100,
  },
  {
    name: "31",
    uv: 3490,
    pv: 1400,
    amt: 2100,
  },
];

export const smallData1 = [
  {
    name: "12 am",
    uv: 4000,
    pv: 2100,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1300,
    amt: 2210,
  },
  {
    name: "06 am",
    uv: 2000,
    pv: 3800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 6908,
    amt: 2000,
  },
  {
    name: "12 pm",
    uv: 1890,
    pv: 4000,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 1800,
    amt: 2500,
  },
  {
    name: "06 pm",
    uv: 3490,
    pv: 3300,
    amt: 2100,
  },
  {
    name: "",
    uv: 3490,
    pv: 4000,
    amt: 2100,
  },
  {
    name: "12 am",
    uv: 3490,
    pv: 6300,
    amt: 2100,
  },
];

export const smallData2 = [
  {
    name: "01",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    uv: 3490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "12",
    uv: 3490,
    pv: 2300,
    amt: 2100,
  },
];
export const smallData3 = [
  {
    name: "00",
    uv: 2000,
    pv: 1400,
    amt: 2400,
  },
  {
    uv: 5000,
    pv: 3398,
    amt: 2210,
  },
  {
    uv: 1000,
    pv: 4800,
    amt: 2290,
  },
  {
    uv: 2780,
    pv: 2908,
    amt: 2000,
  },
  {
    uv: 1290,
    pv: 4300,
    amt: 2181,
  },
  {
    uv: 2190,
    pv: 1800,
    amt: 2500,
  },
  {
    uv: 2490,
    pv: 4300,
    amt: 2100,
  },
  {
    uv: 1490,
    pv: 1000,
    amt: 2100,
  },
  {
    name: "01",
    uv: 3490,
    pv: 2300,
    amt: 2100,
  },
];

export const apiResData = {
  success: true,
  error: "",
  errorcode: 0,
  data: [
    {
      nodeid: "2ddd902e-3741-498d-898e-5b59cf08bca1",
      v12: 416.97,
      v23: 416.65,
      v31: 413.89,
      i1: 3.442,
      i2: 3.406,
      i3: 4.616,
      pf1: 0.9875,
      pf2: 0.98,
      pf3: 0.9845,
      w1: 819.2367,
      w2: 800.8347,
      w3: 1087.4899,
      importkwh: 438.98,
      exportkwh: 1.36,
      importkvarh: 0.4,
      exportkvarh: 77.97,
      frequency: 49.966,
      timestamp: "2022-11-29 17:39:09 +0000 UTC",
    },
  ],
};

export const historicalData = {
  success: true,
  historical: [
    {
      nodeid: "2ddd902e-3741-498d-898e-5b59cf08bca1",
      count: 5,
      data: [
        {
          v1: 238.77,
          v2: 240.83,
          v3: 239.28,
          v12: 415.97,
          v23: 415.89,
          v31: 413.09,
          pf1: 1,
          pf2: 1,
          pf3: 1,
          importkwh: 424.6,
          exportkwh: 1.36,
          importkvarh: 0.4,
          exportkvarh: 75.39,
          frequency: 49.726,
          timestamp: "2022-11-29 16:28:08 +0530 IST",
        },
        {
          v1: 238.66,
          v2: 240.75,
          v3: 239.14,
          v12: 415.88,
          v23: 415.7,
          v31: 412.93,
          pf1: 1,
          pf2: 1,
          pf3: 1,
          importkwh: 424.6,
          exportkwh: 1.36,
          importkvarh: 0.4,
          exportkvarh: 75.39,
          frequency: 49.734,
          timestamp: "2022-11-29 16:28:31 +0530 IST",
        },
        {
          v1: 238.63,
          v2: 241.16,
          v3: 238.56,
          v12: 415.89,
          v23: 415.42,
          v31: 412.78,
          pf1: 1,
          pf2: 1,
          pf3: 1,
          importkwh: 424.6,
          exportkwh: 1.36,
          importkvarh: 0.4,
          exportkvarh: 75.39,
          frequency: 49.734,
          timestamp: "2022-11-29 16:28:53 +0530 IST",
        },
        {
          v1: 238.65,
          v2: 241.25,
          v3: 238.64,
          v12: 416.08,
          v23: 415.66,
          v31: 412.78,
          pf1: 1,
          pf2: 1,
          pf3: 1,
          importkwh: 424.6,
          exportkwh: 1.36,
          importkvarh: 0.4,
          exportkvarh: 75.39,
          frequency: 48.929,
          timestamp: "2022-11-29 16:29:15 +0530 IST",
        },
        {
          v1: 238.74,
          v2: 241.34,
          v3: 238.76,
          v12: 416.35,
          v23: 415.91,
          v31: 412.96,
          pf1: 1,
          pf2: 1,
          pf3: 1,
          importkwh: 424.6,
          exportkwh: 1.36,
          importkvarh: 0.4,
          exportkvarh: 75.39,
          frequency: 49.759,
          timestamp: "2022-11-29 16:29:38 +0530 IST",
        },
      ],
    },
  ],
};

// export const newResData = {
//   success: true,
//   error: "",
//   errorcode: 0,
//   data: [
//     {
//       nodeid: "23e3a149-bae8-43b2-b3ed-861bd78ba138",
//       w1: 1205.2927,
//       w2: 3638.501,
//       w3: 3194.292,
//       timestamp: 1234,
//     },
//     {
//       nodeid: "c3441492-1530-4970-aa4e-24f706635cc4",
//       w1: 0,
//       w2: 0,
//       w3: 774.5471,
//       timestamp: 5667,
//     },
//     {
//       nodeid: "23e3a149-bae8-43b2-b3ed-861bd78ba138",
//       w1: 1205.0375,
//       w2: 3636.5684,
//       w3: 3193.2322,
//       timestamp: 1234,
//     },
//     {
//       nodeid: "c3441492-1530-4970-aa4e-24f706635cc4",
//       w1: 0,
//       w2: 0,
//       w3: 774.64703,
//       timestamp: 5667,
//     },
//     {
//       nodeid: "23e3a149-bae8-43b2-b3ed-861bd78ba138",
//       w1: 1205.0375,
//       w2: 3636.5684,
//       w3: 3193.2322,
//       timestamp: 3456,
//     },
//     {
//       nodeid: "c3441492-1530-4970-aa4e-24f706635cc4",
//       w1: 0,
//       w2: 0,
//       w3: 774.64703,
//       timestamp: 7789,
//     },
//     {
//       nodeid: "23e3a149-bae8-43b2-b3ed-861bd78ba138",
//       w1: 1205.0375,
//       w2: 3636.5684,
//       w3: 3193.2322,
//       timestamp: 3456,
//     },
//     {
//       nodeid: "c3441492-1530-4970-aa4e-24f706635cc4",
//       w1: 0,
//       w2: 0,
//       w3: 774.64703,
//       timestamp: 7789,
//     },
//   ],
// };
