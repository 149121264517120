import React from 'react';
import { Dialog } from "@mui/material";
import styles from "./style.module.css";

const Index = ({ modalOpen, setModalOpen, title, body,deleteUserFunction }) => {


    return (
        <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <div className={styles.dialogParentDiv}>
                <div className={styles.dialogTitle}>{title}</div>
                <div className={styles.dialogContentContainer}>
                    <p className={styles.dialogContent} id="alert-dialog-description">
                        {body}
                    </p>
                </div>
                <div className={styles.buttonContainer}>
                    <button onClick={() => deleteUserFunction()} className={styles.btnConfirm} autoFocus>Remove</button>
                    <button onClick={() => setModalOpen(false)} className={styles.btnCancel}>Cancel</button>
                </div>
            </div>
        </Dialog>
    )
}

export default Index
