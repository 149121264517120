import React, { useState } from "react";
import { handleGetDeviceHistoricalParamData } from "../../stores/services/equipmentApi/index"
import { useHook as useLoginHook } from '../../pages/auth/login/useHook';
import { useDispatch, useSelector } from "react-redux";

export function useChartviewHook() {
    const { logoutUser } = useLoginHook();

    const { token } = useSelector(state => state.auth);

    const [componentList, setComponentList] = React.useState([

        { title: "Total Load", value: "importkwh", unit: 'kW' }, //W--1000 > kW

        { title: "Frequency", value: 'frequency', unit: 'Hz' }, // Hz

        { title: "Current - Phase 1", value: 'i1', unit: 'A' }, //A
        { title: "Current - Phase 2", value: 'i2', unit: 'A' },
        { title: "Current - Phase 3", value: 'i3', unit: 'A' },

        { title: "Power Factor - Phase 1", value: 'pf1', unit: '' },
        { title: "Power Factor - Phase 2", value: 'pf2', unit: '' },
        { title: "Power Factor - Phase 3", value: 'pf3', unit: '' },

        { title: "Voltage - Phase 1", value: 'v1', unit: 'V' }, //V
        { title: "Voltage - Phase 2", value: 'v2', unit: 'V' },
        { title: "Voltage - Phase 3", value: 'v3', unit: 'V' },

        { title: "Voltage - Line to Line (P1 & P2)", value: 'v12', unit: 'V' }, //V
        { title: "Voltage - Line to Line (P2 & P3)", value: 'v23', unit: 'V' },
        { title: "Voltage - Line to Line (P3 & P1)", value: 'v31', unit: 'V' },


        { title: "Load - Phase 1", value: 'w1', unit: 'kW' }, //W--1000 > kW
        { title: "Load - Phase 2", value: 'w2', unit: 'kW' },
        { title: "Load - Phase 3", value: 'w3', unit: 'kW' },

        { title: "THD -Voltage Phase 1", value: 'thdv1', unit: '%' }, // %
        { title: "THD -Voltage Phase 2", value: 'thdv2', unit: '%' },
        { title: "THD -Voltage Phase 3", value: 'thdv3', unit: '%' },

        { title: "THD - Current Phase 1", value: 'thdi1', unit: '%' }, // %
        { title: "THD - Current Phase 2", value: 'thdi2', unit: '%' },
        { title: "THD - Current Phase 3", value: 'thdi3', unit: '%' },


    ])

    const fetchDeviceHistoricalParamData = async (payload) => {
        let data = null
        await handleGetDeviceHistoricalParamData(token, payload)
            .then(response => {
                if (response && response.success && response.data !== null) {
                    data = response.data
                }
            })
            .catch(err => {
                const error = err?.response?.data;
                if (error?.description?.includes("token is expired")) {
                    logoutUser();
                }
                throw err;
            })
        return data
    }

    return {
        componentList,
        fetchDeviceHistoricalParamData
    }
}