import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import iIcon from "../../assets/iicon.png"
import { useEquipmentHook } from "./useEquipmentsHook";
import { Routes } from "routes/appRoutes";
import EquipmentLayout from "./equipmentLayout";
import { Box, Button, Typography, Dialog, Grid, DialogContent, DialogActions } from '@mui/material';


const CreateGroup = () => {

    const { state, handleChangeForm, onClickCreateGroupEquipment, setState, setDefaultState } = useEquipmentHook()
    const navigate = useNavigate()
    const { state: routeState } = useLocation();
    const { id } = useParams();
    const { nodeMetaData } = useSelector(state => state.equipment);


    return (
        <EquipmentLayout>
            <form className='border-2 p-3' style={{ width: '80%', marginLeft: '5%' }}>
                <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                    Create Group
                </Typography>
                <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography className='w-[350px] font-semibold pb-3 text-[16px] m-0 leading-6 text-[#2D3748] eq-sp-title' sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        Group Details
                    </Typography>
                    {id && (
                        <Link to={Routes.addEquipment} className='' style={{
                            textDecoration: "none",
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'right',
                            letterSpacing: '-0.154px',
                            color: '#1B53F9',
                        }}>
                            + Add Equipment
                        </Link>
                    )}
                </Box>
                <div className="eqipment-form">
                    <div className="eq-form-top">

                        <div className="input-control-main">
                            <label htmlFor="current_group_id">Parent Group</label>
                            <div className="control">
                                <input type='text'
                                    id="current_group_id"
                                    name="current_group_id"
                                    value={state.current_group_id}
                                    placeholder="Current Group"
                                    disabled={true}
                                    className={"custom-input-control"}

                                />
                            </div>
                        </div>

                        <div className="input-control-main">
                            <label htmlFor="equipment_id">New Group Name</label>
                            <div className="control">
                                <input type='text'
                                    id="equipment_id"
                                    name="equipment_id"
                                    value={state.equipment_id}
                                    placeholder="Equipment ID"
                                    onChange={handleChangeForm}
                                    className={state?.error && state?.error?.equipment_id ? "input-error custom-input-control" : "custom-input-control"}

                                />
                                {
                                    state?.error && state?.error?.equipment_id &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.equipment_id}</span>
                                }
                            </div>
                        </div>


                        <div className="input-control-main">
                            <label htmlFor="description">Description</label>
                            <div className="control">
                                <textarea id="node_desc" name="node_desc" onChange={handleChangeForm} value={state.node_desc} placeholder="Description"
                                    className={state?.error && state?.error?.node_desc ? "input-error custom-input-control" : "custom-input-control"}
                                />
                                {
                                    state?.error && state?.error?.node_desc &&
                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.node_desc}</span>
                                }
                            </div>
                        </div>


                    </div>

                    <div className="eq-form-bottom">

                        <div className='w-full flex justify-end text-right gap-3  mt-10 footer-button-group'>
                            <Button
                                type='button'
                                onClick={onClickCreateGroupEquipment}
                                style={{ textTransform: "capitalize" }}
                                className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                            >
                                {'Add'}
                            </Button>
                            <Button
                                type='button'
                                onClick={() => navigate('/equipments')}
                                style={{ textTransform: "capitalize" }}
                                className='w-[200px] p-[5px,0px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>
                </div>
            </form>
        </EquipmentLayout>
    )
}

export const CreateGroupModel = ({
    open,
    path,
    handleMoveClick,
    handleClose
}) => {

    const [text, setText] = React.useState('');

    React.useEffect(() => {
        return () => {
            setText('')
        }
    }, [])

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='filter-modal'
            >
                <DialogContent >
                    <Box className="remove-equipment-confirm-div">
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, fontSize: 24, lineHeight: '35px' }}>
                            Enter group name under { path ? `“${path}”` : `“root”`}
                        </Typography>
                        <div className="eqipment-form" style={{ width: '80%' }}>
                            <div className="eq-form-top" style={{ margin: "10px 0 50px 0" }}>
                                <div className="input-control-main">
                                    <div className="control">
                                        <input type='text'
                                            id="text"
                                            name="text"
                                            value={text}
                                            placeholder="Enter group name"
                                            onChange={(e) => setText(e.target.value)}
                                            className={"custom-input-control"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box >
                </DialogContent>
                <DialogActions className='filter-footer' style={{ display: "block" }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleMoveClick(text)}
                                disabled={(text) ? false : (true)}
                                class="filterConditionSaveBtn filter-footer-btn"
                                style={{ cursor: "pointer", border: "unset" }}>
                                Move
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button class="filterConditionCancleBtn filter-footer-btn" onClick={handleClose} style={{ cursor: "pointer", border: "unset" }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateGroup