import { Box, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React from 'react'
import { Link } from 'react-router-dom';
import { Routes } from 'routes/appRoutes';
import { useHook } from './useHook';
import AvatartImg from "assets/User-avatar.png";
import moment from 'moment';
import RemoveDeviceModal from './remove';
import { TYPE_OPTION_LIST } from 'constants/variables';
import { SplashScreenLayout } from './welcome';

const initialTableHeading = [
    {
        id: "serial_number",
        sortId: "serial_number",
        numeric: false,
        disablePadding: true,
        label: "Serial Number",
        show: true
    },
    {
        id: "name",
        sortId: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
        show: true
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true
    },
    {
        id: "created",
        numeric: false,
        disablePadding: false,
        label: "Created At",
        show: true
    }
]

const ActionMenuLayout = React.memo(({ rowId, deviceId, handleOpenRemoveModal }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleMenuClick(e)}>
                <MoreHorizIcon sx={{ color: '#7F7F7F' }} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button"
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        border: '1px solid #eff0f6',
                        borderRadius: "10px",
                        boxShadow: '0 0 10px hsla(0,0%,55%,.1)',
                    }
                }}
            >
                <MenuItem key="1" sx={{ color: '#888' }} onClick={() => handleOpenRemoveModal(rowId, deviceId)}>
                    Remove
                </MenuItem>
                <MenuItem key="2" sx={{ color: '#888' }} component={Link} to={`${Routes.editDevice}/${rowId}`}>
                    Edit
                </MenuItem>
            </Menu>
        </div>
    )

})

export const DeviceList = ({
    tableRecords,
    isLoading,
    removeConfigAPI
}) => {

    const [tableHeading, setTableHeading] = React.useState(initialTableHeading);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const [removeDeviceModal, setRemoveDeviceModal] = React.useState({
        removeModalOpen: false,
        removeModalId: null,
        profileId: null,
    });

    const handleOpenRemoveModal = (id, profileId) => {
        setRemoveDeviceModal(({
            removeModalOpen: true,
            removeModalId: id,
            profileId: profileId,
        }))
    }

    const handleCloseRemoveModal = () => {
        setRemoveDeviceModal(({
            removeModalOpen: false,
            removeModalId: null,
            profileId: null,
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const tableRecordsList = React.useMemo(() => {
        return stableSort(tableRecords, getComparator(order, orderBy))
    }, [tableRecords, order, orderBy])

    const Row = ({
        row,
        index
    }) => {

        const profilePhoto = (row?.metadata && row?.metadata?.device_photo) ? row?.metadata?.device_photo : AvatartImg;
        const type = row?.type.length > 0 ? TYPE_OPTION_LIST.filter(el => row?.type.includes(el.value)).map(el => el.label).join(', ') : '-';
        const createdAt = row?.created ? moment(row?.created).format('DD/MM/YY h:mm a') : '-';
        return (
            <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    <Link to={`${Routes.editDevice}/` + row?.profile_id} style={{ textDecoration: "none", color: "#1B53F9", fontWeight: "500", fontSize: "14px" }}>
                        {row.serial_number}
                    </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <img style={{ width: "25px", height: "25px", background: "lightgrey", borderRadius: '50px' }} src={profilePhoto} />&nbsp;
                        <span>{row.name}</span>
                    </div>
                </TableCell>
                <TableCell align="">{type}</TableCell>
                <TableCell align="">{createdAt}</TableCell>

                <TableCell className={`relative py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0`} align="right">
                    <Box component={'div'} sx={{ display: 'flex', justifyContent: 'end' }}>
                        {row.data && (
                            <IconButton
                                id="download-button"
                                onClick={(e) => {
                                    let a = document.createElement("a");
                                    a.href = row.data;
                                    a.download = 'ug-device.json';
                                    a.click();
                                }}
                            >
                                <FileDownloadOutlinedIcon sx={{ color: '#7F7F7F' }} />
                            </IconButton>
                        )}
                        <ActionMenuLayout rowId={row.profile_id} deviceId={row.serial_number} handleOpenRemoveModal={handleOpenRemoveModal} />
                    </Box>
                </TableCell>

                {/* <TableCell className={showAction === user?.userid ? 'action-cell ' : 'action-cell'} align="right"><MoreHorizIcon onClick={(e) => handleClick(e, user?.userid, user?.first_name)} style={{ cursor: "pointer" }} />
                    <div className='action-main-div' style={showAction === user?.userid ? {} : { display: "none" }}>
                        <ul className='action-main-ul'>
                            <li style={{ cursor: hasEditDelPermission(ROLE[RoleId], ROLE[user.roleId]) ? "pointer" : "not-allowed" }} onClick={() => onClickRemove(ROLE[user.roleId])}>Remove</li>
                            <li style={{ cursor: hasEditDelPermission(ROLE[RoleId], ROLE[user.roleId]) ? "pointer" : "not-allowed" }} onClick={() => ed(ROLE[user.roleId])}>Edit</li>
                        </ul>
    
                    </div>
                </TableCell> */}
            </TableRow>
        )
    }

    if(!isLoading && tableRecords.length === 0){
        return (
            <SplashScreenLayout/>
        )
    }
    
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
                bgcolor: "#F5F5F5",
            }}
            className='dashboard-main'
        >
            <Toolbar />
            <Box
                bgcolor={"#fff"}
                boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
                borderRadius={5}
                className="h-auto mt-[3%] mb-[2%] mx-[32px] p-[20px] Mediumlg:mx-[20px] Mediumlg:h-auto"
            >
                <Box component="div" >
                    <Box className='flex justify-end mb-5 p-2'>
                        <div className='add-export'>
                            <Link to={Routes.addDevice} className='' style={{
                                textDecoration: "none",
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '14px',
                                lineHeight: '17px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'right',
                                letterSpacing: '-0.154px',
                                color: '#1B53F9',
                            }}>
                                + Add New Device
                            </Link>
                        </div>
                    </Box>
                </Box>
                <Box>
                    <TableContainer sx={{ boxShadow: "unset", maxHeight: 'calc(100vh - 290px)' }} component={Paper} className='h-full'>
                        <Table aria-label="collapsible table" className='eq-table custom-table border-separate'>
                            <TableHead className='Device-table-header custom-table-header'>
                                <TableRow className='custom-table-row'>
                                    {tableHeading.map((headCell) => {
                                        let SORT_ID = headCell?.sortId ? headCell?.sortId : headCell?.id;
                                        return (
                                            <TableCell
                                                key={headCell.id}
                                                className={`${headCell.id + '-cell'}`}
                                                sortDirection={orderBy === SORT_ID ? order : false}
                                                style={headCell.show ? { background: "#f5f5f5", borderBottom: "unset" } : { display: "none" }}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === SORT_ID}
                                                    direction={orderBy === SORT_ID ? order : "asc"}
                                                    onClick={createSortHandler(headCell?.sortId ? headCell?.sortId : headCell?.id)}
                                                >
                                                    <span style={{
                                                        fontSize: "14px",
                                                        fontWeight: "700",
                                                        color: "#7F7F7F",
                                                    }}>
                                                        {headCell.label}</span>
                                                    {orderBy === SORT_ID ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell style={{ background: "#f5f5f5", borderBottom: "unset", cursor: "pointer" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={5} textAlign={'center'} sx={{ textAlign: 'center' }}><CircularProgress sx={{color:'rgb(27, 83, 249)'}}/></TableCell>
                                    </TableRow>
                                ) : (
                                    tableRecordsList.length > 0 ? (
                                        tableRecordsList?.map((row, i) => (
                                            <Row index={i} row={row} />
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} textAlign={'center'} sx={{ textAlign: 'center' }}>No Record Found</TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {removeDeviceModal.removeModalOpen && (
                <RemoveDeviceModal
                    open={removeDeviceModal.removeModalOpen}
                    id={removeDeviceModal.removeModalId}
                    profileId={removeDeviceModal.profileId}
                    handleClose={handleCloseRemoveModal}
                    removeConfigAPI={removeConfigAPI}
                />
            )}
        </Box>
    )
}