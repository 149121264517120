import { useState, useEffect } from "react";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequiredMessage } from "../../../../constants/validationMessage";
import { handleAddUser, handleUpdateUser, handleUpdateUserPassword, handleUpdateLoggedinUser } from "../../../../stores/services/userApis";
import { Routes } from "../../../../routes/appRoutes";
import { validateAll } from "indicative/validator";
import AlertMessage from "../../../../constants/AlertMessage";
import { handleGetUserProfile } from "../../../../stores/services/authApis";
import { useDispatch } from "react-redux";
import { setCurrentUserData } from "../../../../stores/actionTypes/authActions";


export function useUserHook() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, currentUser } = useSelector(state => state.auth);
  const { FirstName, LastName, Mobile, Email, RoleId, Address } = jwt(token);
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(0);
  const [avatarBase64, setavatarBase64] = useState('');


  // console.log(jwt(token))
  // console.log(currentUser)


  // const InitialUserData = {
  //   image: '',
  //   first_name: id ? FirstName : '',
  //   last_name: id ? LastName : '',
  //   mobile: id ? Mobile : '',
  //   email: id ? Email : '',
  //   address: id ? Address : '',
  //   roleId: id ? RoleId : '',
  //   newPassword: '',
  //   oldPassword: '',
  //   confirm: '',
  // }
  const [state, setstate] = useState({
    photo: '',
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    address: '',
    roleId: '',
    employeeId: "",
    newPassword: '',
    oldPassword: '',
    confirm: '',
    error: {}
  })

  const getCurrentUser = async () => {
    await handleGetUserProfile(token)
      .then(response => {
        // console.log(response)
        if (Object.keys(response).length) {
          dispatch(setCurrentUserData(response))
        }
      })
      .catch(err => {
        const error = err?.response?.data;
        AlertMessage({ msg: error.description })
        throw err;
      })
  }

  useEffect(() => {
    // console.log(currentUser)
    if (currentUser && Object.keys(currentUser).length) {
      const tempdata = {
        photo: id ? currentUser.photo : '',
        first_name: id ? currentUser.first_name : '',
        last_name: id ? currentUser.last_name : '',
        mobile: id ? currentUser.mobile : '',
        email: id ? currentUser.email : '',
        address: id ? currentUser.address : '',
        roleId: id ? currentUser.roleId : '',
        employeeId: id ? currentUser.employeeId : '',
      }
      setstate(tempdata)

    }

  }, [id, currentUser]);

  useEffect(() => {
    (async () => {
      // if (id) {
      //   setstate(InitialUserData)
      // }
      if (!currentUser || Object.keys(currentUser).length == 0 && token) {
        await getCurrentUser()
      }
    })();

  }, [id, token]);
  // console.log(jwt(token))

  const UserSchema = Yup.object({
    first_name: Yup.string().required(RequiredMessage),
    last_name: Yup.string().required(RequiredMessage),
    email: Yup.string().email().required(RequiredMessage),
    mobile: Yup.string().required(RequiredMessage).matches(/^[6789]\d{9}$/, 'Invalid mobile number'),
    roleId: Yup.string().required(RequiredMessage),
    newPassword: Yup.string().when("oldPassword", {
      is: (oldPassword) => oldPassword,
      then: Yup.string().min(8).required(RequiredMessage),
    }),
    confirm: Yup.string().when("newPassword", {
      is: (newPassword) => newPassword,
      then: Yup.string().min(8).required(RequiredMessage)
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    })
  });

  const Role = [
    { value: '', title: 'Select Role' },
    { value: '99', title: 'Admin' },
    { value: '98', title: 'Itegrator' },
    { value: '97', title: 'Operator' },
  ];

  // const formik = useFormik({
  //   initialValues: InitialUserData,
  //   validationSchema: UserSchema,
  //   onSubmit: async (values) => {
  //     if (id) {
  //       const data = {
  //         userid: id,
  //         update: [{
  //           type: "first_name",
  //           value: values.first_name
  //         }, {
  //           type: "last_name",
  //           value: values.last_name
  //         }, {
  //           type: "mobile",
  //           value: values.mobile
  //         }]
  //       };
  //       // console.log(data)
  //       await handleUpdateLoggedinUser(data, token)
  //         .then(response => {
  //           // console.log('res')
  //           const { success, error } = response;
  //           if (!success) {
  //             // toast.error(error);
  //             AlertMessage({ msg: error })
  //             return;
  //           }
  //           toast.success('Detail updated successfully');
  //           navigate(Routes.dashboard);
  //         })
  //         .catch(err => {
  //           // console.log('err')
  //           const error = err?.response?.data;
  //           // toast.error(error.description);
  //           AlertMessage({ msg: error.description })
  //           throw err;
  //         })

  //       if (Boolean(values.oldPassword) && Boolean(values.newPassword)) {
  //         const passwords = {
  //           "oldpassword": values.oldPassword,
  //           "newpassword": values.newPassword
  //         }
  //         await handleUpdateUserPassword(passwords, token)
  //           .then(response => {
  //             const { success, error } = response;
  //             if (!success) {
  //               // toast.error(error);
  //               AlertMessage({ msg: error })
  //               return;
  //             }
  //             // toast.success('Password updated successfully');
  //             AlertMessage({ msg: 'Password updated successfully' })
  //             navigate(Routes.dashboard);
  //           })
  //           .catch(err => {
  //             const error = err?.response?.data;
  //             // toast.error(error.description);
  //             AlertMessage({ msg: error.description })
  //             throw err;
  //           })
  //       }
  //       return;
  //     }

  //     await handleAddUser(values, token)
  //       .then(response => {
  //         const { success, error } = response;
  //         if (!success) {
  //           // toast.error(error);
  //           AlertMessage({ msg: error })
  //           return;
  //         }
  //         // toast.success('User added successfully');
  //         AlertMessage({ msg: 'User added successfully' })
  //         navigate(Routes.dashboard);
  //       })
  //       .catch(err => {
  //         const error = err?.response;
  //         AlertMessage({ msg: error?.data?.description || error.data })
  //         // toast.error(error.data);
  //         throw err;
  //       })
  //   },
  // });

  const handleChangeForm = (e) => {
    let value = e.target.value;

    if (e.target.name === 'mobile') {
      value = e.target.value.replace(/[^\d.]|\.(?=.*\.)/g, "");
    }
    setstate({
      ...state,
      [e.target.name]: value
    })
  }

  const goToNextStep = (key) => {

    let rules = {}
    let message = {}

    switch (key) {

      case 0:
        rules = {
          first_name: 'required',
          last_name: 'required',
          email: 'required|email',
          mobile: 'required',
          // address: 'required',
        }

        message = {
          'first_name.required': 'First Name is Required.',
          'last_name.required': 'Last Name is Required.',
          'email.required': 'Email is Required.',
          'email.email': 'Please Enter valid Email.',
          'dob.required': 'Date of birth is Required.',
          'mobile.required': 'Phone Number is Required.',
          'address.required': 'Address is Required.',
        }
        break;

      case 2:

        rules = {
          oldPassword: 'required',
          newPassword: 'required',
          confirm: 'required|same:newPassword',
        }

        message = {
          'oldPassword.required': 'Old Password is Required.',
          'newPassword.required': 'New Password is Required.',
          'confirm.required': 'Confirm Password is Required.',
          'confirm.same': 'New Password and Confirm Password Must be same.',
        }

        break;



      default:

        break;

    }


    validateAll(state, rules, message).then(async () => {
      const formaerrror = {};
      setstate({
        ...state,
        error: formaerrror
      })

      if (id) {
        let userid = id
        let update = [

          {
            type: "first_name",
            value: state.first_name
          }, {
            type: "last_name",
            value: state.last_name
          }, {
            type: "mobile",
            value: state.mobile
          },
          // {
          //   type: "employeeId",
          //   value: state.employeeId
          // },

        ]

        if (avatarBase64) {
          let imgObj = {
            type: "photo",
            value: avatarBase64
          }
          update.push(imgObj)

        }

        if (state.address) {
          update.push({
            type: "address",
            value: state.address
          })
        }

        const data = {
          userid: userid,
          update: update
        }


        // console.log(data)
        if (key == 0) {
          await handleUpdateLoggedinUser(data, token)
            .then(async response => {
              // console.log('res')
              const { success, error } = response;
              if (!success) {
                // toast.error(error);
                AlertMessage({ msg: error })
                return;
              }
              await getCurrentUser()
              // toast.success('Detail updated successfully');
              AlertMessage({ msg: 'Detail updated successfully' })

              navigate(Routes.dashboard);
            })
            .catch(err => {
              // console.log('err')
              const error = err?.response?.data;
              // toast.error(error.description);
              AlertMessage({ msg: error?.description })
              throw err;
            })
        }

        if (Boolean(state.oldPassword) && Boolean(state.newPassword) && key == 2) {
          const passwords = {
            "oldpassword": state.oldPassword,
            "newpassword": state.newPassword
          }
          await handleUpdateUserPassword(passwords, token)
            .then(response => {
              const { success, error } = response;
              if (!success) {
                // toast.error(error);
                AlertMessage({ msg: error })
                // return;
              }
              // toast.success('Password updated successfully');
              AlertMessage({ msg: 'Password updated successfully' })
              navigate(Routes.dashboard);
            })
            .catch(err => {
              const error = err?.response?.data;
              // toast.error(error.description);
              AlertMessage({ msg: error.description })
              throw err;
            })
        }
        // return;
      }





    }).catch(errors => {
      // console.log(errors)
      const formaerrror = {};
      if (errors && errors.length) {
        errors.forEach(element => {
          formaerrror[element.field] = element.message
        });
      } else {
        AlertMessage({ msg: errors || errors?.description || 'Something went wrong' })
      }

      setstate({
        ...state,
        error: formaerrror
      })

    });




  }

  return {
    // formik,
    Role,
    id,
    showPassword,
    setShowPassword,
    state,
    handleChangeForm,
    goToNextStep,
    value,
    setValue,
    setavatarBase64
  }
}