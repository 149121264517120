import {
  NodeListSuccess,
  InstEnergySuccess,
  HistoryProjectListSuccess,
  ConsumptionPatternSuccess,
  ConsumptionSelectedDateSuccess,
  ConsumptionSelectedMonthSuccess,
  NodeListParamSuccess,
  DeviceInstParamSuccess,
  DeviceTHDSuccess,
  ClearDashboardData,
  ProjectListChartSuccess,
  SET_AUTO_REFRESH_DASHBOARD,
  SET_PROJECT_PARAM,
} from "../../actionTypes/dashboardActions";

const inititalState = {
  nodeList: [],
  nodeListCallTime: null,
  dashboardReload: false,
  instEnergy: {},
  historyProjectList: [],
  consumptionPattern: [],
  nodeListParam: [],
  deviceInstParam: [],
  deviceTHD: [],
  projectListChartData: [],
  consumptionChartSelectedDate: null,
  consumptionChartSelectedMonth: null,
  projectParam: null,
};

export const dashboardReducer = (state = inititalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NodeListSuccess:
      return {
        ...state,
        nodeList: payload,
        nodeListCallTime: new Date(),
      };
    case InstEnergySuccess:
      return {
        ...state,
        instEnergy: payload,
      };
    case HistoryProjectListSuccess:
      return {
        ...state,
        historyProjectList: payload,
      };
    case ConsumptionPatternSuccess:
      return {
        ...state,
        consumptionPattern: payload,
      };
    case ConsumptionSelectedDateSuccess:
      return {
        ...state,
        consumptionChartSelectedDate: payload,
      };
      case ConsumptionSelectedMonthSuccess:
        return {
          ...state,
          consumptionChartSelectedMonth: payload,
        };
    case NodeListParamSuccess:
      return {
        ...state,
        nodeListParam: payload,
      };
    case DeviceInstParamSuccess:
      return {
        ...state,
        deviceInstParam: payload,
      };
    case DeviceTHDSuccess:
      return {
        ...state,
        deviceTHD: payload,
      };
    case ProjectListChartSuccess:
      return {
        ...state,
        projectListChartData: payload,
      };
    case ConsumptionSelectedDateSuccess:
      return {
        ...state,
        consumptionChartSelectedDate: payload,
      };
      case ConsumptionSelectedMonthSuccess:
        return {
          ...state,
          consumptionChartSelectedMonth: payload,
        };
    case ClearDashboardData:
      return {
        ...state,
        nodeList: [],
        nodeListCallTime: null,
        instEnergy: {},
        historyProjectList: [],
        consumptionPattern: [],
        consumptionChartSelectedDate: null,
        consumptionChartSelectedMonth: null,
        nodeListParam: [],
        deviceInstParam: [],
      };
    case SET_AUTO_REFRESH_DASHBOARD:
      return {
        ...state,
        dashboardReload:
          payload.dashboardReload !== undefined
            ? payload.dashboardReload
            : state.dashboardReload,
        nodeListCallTime: payload.time,
      };
    case SET_PROJECT_PARAM:
      return {
        ...state,
        projectParam: payload,
      };
    default:
      return state;
  }
};
