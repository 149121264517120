import { LoginSuccess, LoginFail, LogoutSuccess, ReloadPage, EXPAND, CURRENT_USER_DATa } from "../../actionTypes/authActions";

const token = localStorage.getItem('token');
const role = localStorage.getItem('role');

const inititalState = {
  token: token ?? '',
  role: role ?? '',
  dashboardReload: false,
  expandPage: true,
  currentUser: null

}

export const authReducer = (state = inititalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LoginSuccess:
      payload.isKeep && localStorage.setItem('token', payload.data);
      payload.isKeep && localStorage.setItem('role', payload.role);
      payload.isKeep && localStorage.setItem('expTime', payload.expTime);
      return {
        ...state,
        token: payload.data,
        role: payload.role
      }
    case LoginFail:
      return {
        ...state,
        token: '',
        role: ''
      }
    case LogoutSuccess:
      localStorage.clear();
      return {
        ...state,
        token: '',
        role: '',
        dashboardReload: false,
        currentUser: null
      }
    case CURRENT_USER_DATa:
      return {
        ...state,
        currentUser: payload

      }
    case ReloadPage:
      return {
        ...state,
        [payload.page + 'Reload']: payload.reload,
      }
    case EXPAND:
      return {
        ...state,
        expandPage: payload,
      }
    default:
      return state;
  }
}