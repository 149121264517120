import * as React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Toolbar, Typography, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from '../../routes/appRoutes';
import Button from "@mui/material/Button";
import { setSaveFilter } from '../../stores/actionTypes/equipmentActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { decryptData, encryptData, getBarColor } from '../../constants/Helper';
import { generateNodeName, checkEquipmetIsActive } from '../../constants/Helper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from "../../assets/magnifyingglass2.png"
import { useEquipmentHook } from './useEquipmentsHook';
import MapMarkerIcon from "../../assets/MapMarker.svg"
import { data } from 'autoprefixer';
import { useHook as useDashboardHook } from '../dashboard/useHook';
import ToastAlert from '../../constants/ToastAlert';
import RemoveEquipmentModal from './remove';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { bindActionCreators } from "redux";
import * as DashboardApis from "../../stores/services/dashboardApis";
import { useHook as useLoginHook } from "../../pages/auth/login/useHook";
import { devideArray } from "../../constants/Helper";
import * as EquipmentApi from "../../stores/services/equipmentApi";
import AlertMessage from "../../constants/AlertMessage";
import StreetLightDialog from "../../components/equipment/streetlightDialog"

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const convertToDecimal = (value) => {
    if (typeof value === 'number' && value !== 'N/A') {
        return value.toFixed(2)
    } else {
        return value;
    }
}

const ViewEquipment = () => {
    const { id } = useParams();
    const { users } = useSelector(state => state.user);
    const { instEnergy, deviceInstParam, nodeList, deviceTHD } = useSelector(state => state.dashboard);
    const { fetchNodeMetaData ,fetchgetStreetlight} = useEquipmentHook()
    const { filters, nodeMetaData } = useSelector(state => state.equipment);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [filterOptionList, setFilterOptionList] = useState([])
    const search = useLocation()?.search;
    const params = new URLSearchParams(search);
    var url = new URL(window.location.href);
    var component = url.searchParams.get("component");
    var start = url.searchParams.get("start");
    var end = url.searchParams.get("end");
    var filter = url.searchParams.get("filter");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { fetchNodeList, fetchInstEnergyList ,fetchDeviceInstParam} = useDashboardHook();

    const [componentList, setComponentList] = useState([
        { title: "Voltage (v)", value: 'voltageValue' },
        { title: "Current (A)", value: 'currentValue' },
        { title: "Total Current", value: 'totalcurrentValue' },
        { title: "THD", value: 'maximumTHD' },
        { title: "Frequency", value: 'frequency' },
        { title: "Power Factor", value: 'totalPowerFactorValue' },
        { title: "Power Factor average", value: 'powerFactorValue' },
    ])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100000);
    const [csvData, setCsvData] = useState([])
    const [rowsvalue, setRowsValue] = useState([])
    const [maxTHDList, setMaxTHDList] = useState([])
    const [filterOptions, setFilterOptions] = useState({
        components: {},
        operation: "",
        value: "",
        start: "",
        end: "",
        condition: "and"
    })

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [searchEq, setSearchEq] = React.useState('');
    const [tempRowsvalue, setTempRowsValue] = useState([])
    const [allNodeList, setAllNodeList] = React.useState([]);
    const [removeEquipmentModal, setRemoveEquipmentModal] = React.useState({
        removeModalOpen: false,
        removeModalId: null,
        equipmentId: null,
    });

    const [streetLightEditModal, setStreetLightEditModal] = React.useState({
        openModal: false,
        state: null,
        nodeId: null
    });

    const [nodeNameTitle, setNodeNameTitle] = React.useState('');
    const Name ="street_light";
    const handleOpenRemoveModal = (id, equipmentId) => {
        setRemoveEquipmentModal(({
            removeModalOpen: true,
            removeModalId: id,
            equipmentId: equipmentId,
        }))
    }

    const handleCloseRemoveModal = () => {
        setRemoveEquipmentModal(({
            removeModalOpen: false,
            removeModalId: null,
            equipmentId: null,
        }))
    }

    const handleOpenStreetLightModal = (rowId, equipmentId, metaData) => {
        console.log(rowId, equipmentId, metaData, 'meetaaddaataa')
        setStreetLightEditModal(({
            openModal: true,
            state: metaData,
            nodeId: rowId
        }))
    }

    const handleCloseStreetLightModal = () => {
        console.log('refresh', refresh)
        setRefresh(!refresh)
        setStreetLightEditModal(({
            openModal: false,
            state: null,
            nodeId: null
        }))
    }

    const saveData = (data) => { 
    }

    const initialTableHeading = [
        {
            id: "name",
            sortId: "metadata",
            numeric: false,
            disablePadding: true,
            label: "Name",
            show: true
        },
        // {
        //     id: "consumptionValue",
        //     sortId: "calculatedConsumptionValue",
        //     numeric: true,
        //     disablePadding: false,
        //     label: "Consumption",
        //     show: true
        // },
        {
            id: "voltagevalue",
            sortId:"voltagesorting",
            numeric: true,
            disablePadding: false,
            label: "Voltage",
            show: true
        },
        {
            id: "currentvalue",
            sortId:"currentsorting",
            numeric: false,
            disablePadding: false,
            label: "Current",
            show: true
        },
        {
            id: "lastUpdatedTime",
            sortId: "latestTimestamp",
            numeric: false,
            disablePadding: false,
            label: "Last Updated",
            show: true
        },

    ]

    const [tableHeading, setTableHeading] = React.useState(initialTableHeading);

    let storageNodeList = localStorage.getItem('nodeList')

    useEffect(() => {
        (async () => {


            if (storageNodeList) {
                setAllNodeList(JSON.parse(storageNodeList))

            } else {
                await fetchNodeList({ "offset": 0, "order": "asc" })
            }

            if (storageNodeList && JSON.parse(storageNodeList).length > 0 && Object.values(instEnergy).length === 0) {
                await fetchInstEnergyList({ "nodes": JSON.parse(storageNodeList) });
            }

        })()
    }, [storageNodeList])
    const { token } = useSelector(state => state.auth);
    const [streetLightData, setStreetLightData] = useState([]);
    const [mergedStreetLightData, setMergedStreetLightData] = useState([]);

    const [error, setError] = useState(null);
    const[svoltage,setVoltageParamID]=useState('');
    const[scurrent,setCurrentParamID]=useState('');
    const[streetLigthNodes,setStreetLightNodes]=useState([]);
    const[voltageResponse,setVoltageResponse]=useState(null);
    const[currentResponse,setCurrentResponse]=useState(null);
    const[paramIds,setParamIds]=useState(false);
    const[refresh,setRefresh]=useState(false);

    const { handleGetDeviceInstParam } = bindActionCreators(
        DashboardApis,
        dispatch
      );
      const {handlegetStreetlight} = bindActionCreators(EquipmentApi, dispatch);

      const { logoutUser } = useLoginHook();
    const {nodeListParam} = useSelector((state) => state.dashboard);

    useEffect(() => {
    const paramIds = nodeListParam.some(item => item.identifier === "svoltage" || item.identifier === "scurrent");
    setParamIds(paramIds)
    },[])

  


useEffect(() => {
    (async () => {
        if(paramIds){
            nodeListParam.forEach((data)=>{
                if(data.identifier==="svoltage"){
                    setVoltageParamID(data.param_id)
                }
                if(data.identifier==="scurrent"){
                    setCurrentParamID(data.param_id)
                }
                })
            }
        const payload = {
            nodeid: id
          };
            await fetchgetStreetlightNodeParamData(payload)
    })()
}, [id,paramIds, refresh])

const fetchgetStreetlightNodeParamData = async (payload) => {
    await handlegetStreetlight(token, payload)
        .then(response => {
            if (response.success && Object.keys(response.data).length) {
                // dispatch(setSaveNodeMetaData(response.data));
                let streetLightKey =Object.keys(response?.data)
                let streetLightValue =Object.values(response?.data);            
                
               
                setStreetLightData(streetLightValue);

                setStreetLightNodes(streetLightKey)
                setError(null);
            }
            return ;
        })
        .catch(err => {
            const error = err?.response?.data;
            AlertMessage({ msg: error?.description })
            if (error.description.includes("token is expired")) {
                logoutUser();
            }
            throw err;
        })
}

useEffect(()=>{
    if(streetLigthNodes.length>0&&paramIds){    
        let payloadData = devideArray(streetLigthNodes)  
        fetchVoltage(payloadData,svoltage)
        fetchCurrent(payloadData,scurrent)  
    }
}, [streetLigthNodes,paramIds])



  // Merge arrays based on the "name" property
  
  const fetchVoltage = async (payloadData,payloadParamData) => {
    if (payloadData && payloadData.length > 0) {  
        let voltageResponse = []
        await Promise.all(payloadData.map(async (val, i) => {
               await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
          .then(response => {
            if (response.success && response?.data && response?.data !== null) {
                voltageResponse = [...voltageResponse, ...response.data];
            } else {
                voltageResponse = [...voltageResponse];
            }
          })
          .catch(err => {
            const error = err?.response?.data;
            if (error?.description?.includes("token is expired")) {
              logoutUser();
            }
            throw err;
          })
      })) 
      return setVoltageResponse(voltageResponse);
      }
  }
  const fetchCurrent = async (payloadData,payloadParamData) => {
    if (payloadData && payloadData.length > 0) {
        let currentResponse = []
        await Promise.all(payloadData.map(async (val, i) => {
               await handleGetDeviceInstParam(token, { nodes: val, paramid: payloadParamData })
          .then(response => {
            if (response.success && response?.data && response?.data !== null) {
                currentResponse = [...currentResponse, ...response.data];
            } else {
                currentResponse = [...currentResponse];
            }
          })
          .catch(err => {
            const error = err?.response?.data;
            if (error?.description?.includes("token is expired")) {
              logoutUser();
            }
            throw err;
          })
      }))
      return setCurrentResponse(currentResponse);
      }
  }
  
 
  
  function mergeTableData(arr1, arr2,arr3) {
    const merged = {};
  
    // Merge values from the first array
    for (const obj of arr1) {
      merged[obj.node_id] = { ...merged[obj.node_id], ...obj };
    }
  
    // Merge values from the second array
    for (const obj of arr2) {
      merged[obj.node] = { ...merged[obj.node], ...obj };
    }
    for (const obj of arr3) {
        merged[obj.node] = { ...merged[obj.node], ...obj };
      }    // Convert the merged object back to an array
    const result = Object.values(merged);
    return result;
  }
 

  useEffect(() => { 
    if(paramIds){
    const modifiedCurrentResponse = currentResponse?.map(item => {
      const { value,timestamp ,...rest } = item; 
      return { currentvalue: value, currenttimestamp: timestamp ,...rest }; 
    });
    if(voltageResponse&&modifiedCurrentResponse){
    let mergedData = mergeTableData(streetLightData, voltageResponse,modifiedCurrentResponse);

    mergedData.map(data =>  {  

      const latestTimestamp = Math.max(data.currenttimestamp, data.timestamp);
     data.latestTimestamp=latestTimestamp?latestTimestamp:0;
     data.voltagesorting=data.value?data.value:0;
     data.currentsorting=data.currentvalue?data.currentvalue:0;

       
        let lastUpdatedTime = 'N/A';
        if(latestTimestamp){
        let relateiveTime = moment.unix(latestTimestamp).startOf("minute").fromNow();
        let relateiveTimeArr = relateiveTime.split(' ');
        if (relateiveTimeArr.includes('days') && typeof relateiveTimeArr[0] == 'number' && relateiveTimeArr[0] > 3) {
        lastUpdatedTime = moment.unix(latestTimestamp).format('DD/MM/YYYY hh:mm:ss A');
        } else {
        lastUpdatedTime = setReletiveTime(relateiveTime);
        }
    } 
        data.lastUpdatedTime = lastUpdatedTime;
        data.active = checkEquipmetIsActive(data.latestTimestamp * 1000) ? true : false
       return data;
    });
    
    //  mergedData.map(data => {
    //     data.active = checkEquipmetIsActive(latestTimestamp) ? true : false
    //  });


    setMergedStreetLightData(mergedData);
    setRowsValue(mergedData);
    }
    }else{
    setMergedStreetLightData(streetLightData);
    setRowsValue(streetLightData)
    }
  }, [voltageResponse, currentResponse,streetLightData,paramIds])


    useEffect(() => {
        (async () => {
            if (nodeMetaData !== null && Object.keys(nodeMetaData).length == 0 || allNodeList.length && nodeMetaData == null) {
                await fetchNodeMetaData({ nodes: allNodeList })
            }
        })()
    }, [allNodeList, nodeMetaData])

    useEffect(() => {
        if (deviceInstParam.length > 0) {
            let temp = []
            let tempAll = []
            deviceInstParam.map((val, k) => {

                if (temp.find(e => e.node === val.node)) {
                    tempAll.push(val)
                    let oj = temp.find(e => e.node === val.node);
                    if (oj.value < val.value) {
                        let ind = temp.findIndex(e => e.node === val.node);
                        temp[ind] = val;
                    }
                } else {
                    temp.push(val);
                }
            })
            setMaxTHDList(temp);
        }
    }, [deviceInstParam])

    const addFilterBtn = () => {

        if (filterOptions.components !== '' && filterOptions.operation !== '' && (filterOptions.operation == 'between' ? filterOptions.start !== '' && filterOptions.end !== '' : filterOptions.value !== '')) {

            let filterList = [...filterOptionList]
            filterList = [...filterList, filterOptions]

            setFilterOptionList(filterList)

            setFilterOptions({
                ...filterOptions,
                operation: "",
                value: "",
                start: "",
                end: "",
                condition: "and"
            })

        } else {

            // alert('All fields are required')
            ToastAlert({ msg: "All fields are required!", msgType: "warn" })

        }
    }

  

    Array.prototype.diff = function (a) {
        return this.filter(function (i) { return a.indexOf(i) < 0; });
    };

    const setReletiveTime = (time) => {

        switch (true) {
            case time == 'an hour ago':
                return '1 hour ago';
            case time == 'a day ago':
                return '1 day ago';
            case time == 'a year ago':
                return '1 year ago';
            case time.includes('years ago') && time !== "1 year ago":
                return '-';
            default:
                return time;
        }
    }

    useEffect(() => {
        
        let tempCsvDataArray = []
        let temp = instEnergy ? Object.entries(instEnergy) : [];
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];

        // console.log(allNodeMetaData.find((e) => e[0] === '6a0e75ba-7115-4208-aa3e-b2b5868d2d6b')[1].metadata.Remark)
        let node_name = (allNodeMetaData.find((e) => e[0] === id)?.length > 0 && allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.node_name : generateNodeName(id);
        setNodeNameTitle(node_name);

        let tempIdArray = temp.map((d) => {
            return d[0]
        })

        let d_nodes = allNodeList?.diff(tempIdArray);

        if (temp.length > 0 || allNodeMetaData.length > 0) {

            temp.map((v, i) => {
                let calculatedConsumptionValue = Number(Number(v[1].w1) + Number(v[1].w2) + Number(v[1].w3)).toFixed(2)
                let consumptionValue = calculatedConsumptionValue > 1000 ? Number(Number(calculatedConsumptionValue) / 1000) : Number(calculatedConsumptionValue)
                let consumptionParams = calculatedConsumptionValue > 1000 ? " kW" : " W"
                let voltageValue = (Number(v[1].v2) == 0 && Number(v[1].v3) == 0) ? Number(Number(v[1].v1)).toFixed(2) : Number(Number(Number(v[1].v1) + Number(v[1].v2) + Number(v[1].v3)) / Number(3)).toFixed(2)
                let currentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3)).toFixed(2)
                let totalcurrentValue = Number(Number(v[1].i1) + Number(v[1].i2) + Number(v[1].i3) / 3).toFixed(2)
                let powerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3)) / Number(3)).toFixed(2)
                let totalPowerFactorValue = Number(Number(Number(v[1].pf1) + Number(v[1].pf2) + Number(v[1].pf3))).toFixed(2)



                let csvObj = {}
                csvObj.calculatedConsumptionValue = Number(calculatedConsumptionValue)

                let total_rated_power = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.total_rated_power : null

                let total_rated_power_value = total_rated_power > 1000 ? Number(Number(total_rated_power) / 1000) : Number(total_rated_power)
                let total_rated_power_params = total_rated_power > 1000 ? " kW" : " W"

                csvObj.consumptionValue = total_rated_power ? `${consumptionValue.toFixed(2)} ${consumptionParams} / ${total_rated_power_value.toFixed(2)} ${total_rated_power_params}` : `${consumptionValue.toFixed(2)} ${consumptionParams}`

                // csvObj.consumptionValue = Number(consumptionValue)
                csvObj.consumptionParams = consumptionParams
                csvObj.voltageValue = Number(voltageValue)
                csvObj.currentValue = Number(currentValue)
                csvObj.totalcurrentValue = Number(totalcurrentValue)
                csvObj.powerFactorValue = powerFactorValue
                csvObj.totalPowerFactorValue = totalPowerFactorValue

                var relateiveTime = moment(v[1].timestamp * 1000).startOf("minute").fromNow();
                var relateiveTimeArr = relateiveTime.split(' ');
                var lastUpdatedTime = 'N/A';
                if (relateiveTimeArr.includes('days') && typeof relateiveTimeArr[0] == 'number' && relateiveTimeArr[0] > 3) {
                    lastUpdatedTime = moment(v[1].timestamp * 1000).format('DD/MM/YYYY hh:mm:ss A');
                } else {
                    lastUpdatedTime = setReletiveTime(relateiveTime);
                }
                csvObj.lastUpdatedTime = lastUpdatedTime;
                csvObj.timestamp = v[1].timestamp
                csvObj.name = v[0]
                csvObj.active = checkEquipmetIsActive(v[1].timestamp * 1000) ? true : false


                csvObj.consumption = `${consumptionValue} ${consumptionParams}`
                csvObj.liveVoltage = voltageValue + " V "
                csvObj.ratedPower = ""
                csvObj.description = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_desc : 'N/A'


                csvObj.totalCurrent = currentValue + " A "
                csvObj.totalCurrent_phase1 = (v[1].i1)
                csvObj.totalCurrent_phase2 = (v[1].i2)
                csvObj.totalCurrent_phase3 = (v[1].i3)


                csvObj.avarageVoltage = voltageValue + "V"
                csvObj.avarageVoltage_phase1 = Number(v[1].v1) + ' V '
                csvObj.avarageVoltage_phase2 = Number(v[1].v2) + ' V '
                csvObj.avarageVoltage_phase3 = Number(v[1].v3) + ' V '

                csvObj.avarageVoltage = voltageValue + "V"
                csvObj.avarageVoltage_phase1_ = Number(v[1].v1)
                csvObj.avarageVoltage_phase2_ = Number(v[1].v2)
                csvObj.avarageVoltage_phase3_ = Number(v[1].v3)


                csvObj.avaragePowerFactor = powerFactorValue
                csvObj.avaragePowerFactor_phase1 = Number(v[1].pf1)
                csvObj.avaragePowerFactor_phase2 = Number(v[1].pf2)
                csvObj.avaragePowerFactor_phase3 = Number(v[1].pf3)

                let maximumTHD = maxTHDList?.find((e) => e?.node === v[0])?.value || 0;
                csvObj.maximumTHD = Number(maximumTHD)
                csvObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[0]?.filter((e) => e.node === v[0])[0]?.value : '0'
                csvObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[1]?.filter((e) => e.node === v[0])[0]?.value : '0'
                csvObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === v[0]).length > 0 ? deviceTHD[2]?.filter((e) => e.node === v[0])[0]?.value : '0'

                csvObj.frequency = v[1].frequency
                csvObj.remarks = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.Remark : 'N/A'

                const nodeName = (allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 && allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name : generateNodeName(v[0])
                csvObj.node_name = nodeName
                csvObj.equipment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : ''
                csvObj.node_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.parent_node : ''
                tempCsvDataArray.push(csvObj);
            })

            if (d_nodes.length > 0) {
                d_nodes.map((n) => {

                    let tempObj = {}
                    tempObj.consumptionValue = 'N/A'
                    tempObj.consumptionParams = 'N/A'
                    tempObj.voltageValue = 'N/A'
                    tempObj.currentValue = 'N/A'
                    tempObj.totalcurrentValue = 'N/A'
                    tempObj.powerFactorValue = 'N/A'
                    tempObj.totalPowerFactorValue = 'N/A'
                    tempObj.lastUpdatedTime = 'N/A'

                    tempObj.name = n
                    tempObj.active = false
                    tempObj.consumption = 'N/A'
                    tempObj.liveVoltage = 'N/A'
                    tempObj.ratedPower = 'N/A'
                    tempObj.description = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_desc : 'N/A'

                    tempObj.node_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.parent_node : ''
                        
                    tempObj.totalCurrent = 'N/A'
                    tempObj.totalCurrent_phase1 = 'N/A'
                    tempObj.totalCurrent_phase2 = 'N/A'
                    tempObj.totalCurrent_phase3 = 'N/A'


                    tempObj.avarageVoltage = 'N/A'
                    tempObj.avarageVoltage_phase1 = 'N/A'
                    tempObj.avarageVoltage_phase2 = 'N/A'
                    tempObj.avarageVoltage_phase3 = 'N/A'

                    tempObj.avarageVoltage = 'N/A'
                    tempObj.avarageVoltage_phase1_ = 'N/A'
                    tempObj.avarageVoltage_phase2_ = 'N/A'
                    tempObj.avarageVoltage_phase3_ = 'N/A'


                    tempObj.avaragePowerFactor = 'N/A'
                    tempObj.avaragePowerFactor_phase1 = 'N/A'
                    tempObj.avaragePowerFactor_phase2 = 'N/A'
                    tempObj.avaragePowerFactor_phase3 = 'N/A'

                    let maximumTHD = maxTHDList?.find((e) => e?.node === n)?.value || 0;
                    tempObj.maximumTHD = Number(maximumTHD)
                    tempObj.maximumTHD_phase1 = deviceTHD[0]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[0]?.filter((e) => e.node === n)[0]?.value : '0'
                    tempObj.maximumTHD_phase2 = deviceTHD[1]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[1]?.filter((e) => e.node === n)[0]?.value : '0'
                    tempObj.maximumTHD_phase3 = deviceTHD[2]?.filter((e) => e?.node === n).length > 0 ? deviceTHD[2]?.filter((e) => e.node === n)[0]?.value : '0'

                    tempObj.frequency = 'N/A'
                    tempObj.remarks = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.Remark : 'N/A'

                    const nodeName = (allNodeMetaData.find((e) => e[0] === n)?.length > 0 && allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name : generateNodeName(n)
                    tempObj.node_name = nodeName
                    tempObj.equipment_id = '';
                    tempCsvDataArray.push(tempObj);

                })
            }

            const Operations = {
                lessThan: '<',
                greaterThan: '>',
                or: '||',
                and: '&&',
            };

            let filterArray = []
            if (filter && filter !== null && filter !== undefined) {

                let fltr = decryptData(filter);

                if (fltr && fltr !== null && fltr !== undefined) {
                    filterArray = JSON.parse(fltr);
                }

            }
            setFilterOptionList(filterArray)
            dispatch(setSaveFilter(filterArray))

            let cond = '';
            if (filterArray.length > 0) {

                filterArray.map((f, i2) => {

                    if (f.operation !== 'between') {
                        cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' ' + Operations[f.operation] + ' ' + f.value + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ')
                    } else {
                        cond += (cond !== '' ? ' ' : '') + f?.components?.value + ' >= ' + f.start + ' && ' + f?.components?.value + ' <= ' + f.end + ' ' + (i2 !== (filterArray.length - 1) ? Operations[f.condition] : ' ');
                    }

                })

            }

            tempCsvDataArray = tempCsvDataArray.filter(el => el?.node_id === id);
            if (cond !== '' && cond !== null) {
                let condition = cond;

                let cb = new Function('o', 'return ' + condition.replace(new RegExp(Object.keys(tempCsvDataArray[0]).join('|'), 'g'), 'o.$&'));

                let data = tempCsvDataArray.filter(cb);
                // let csvData = data.map(item => { return { ...item, name: item?.node_name ? item?.node_name : generateNodeName(item.name) } });
                setCsvData(data)
                // setRowsValue(data)
                setTempRowsValue(data)

            } else {
                setCsvData(tempCsvDataArray)
                // setRowsValue(tempCsvDataArray)
                setTempRowsValue(tempCsvDataArray)
            }

        } else {
            setCsvData([])
            // setRowsValue([])
        }

    }, [instEnergy, maxTHDList, deviceInstParam, filter, allNodeList, nodeMetaData])

    const handleChange = (e) => {
        // console.log(e)
        // e.preventDefault();
        let name = e.target.name
        let value;

        if (name === 'components') {
            value = componentList.find((e1) => e1?.value == e?.target?.value)
        } else if (name === 'operation') {
            value = e.target.id
        } else {
            value = e.target.value
        }

        setFilterOptions({
            ...filterOptions,
            [name]: value
        })

        // setFilterOptions(
        //   filterOptions.map((item, i1) => {
        //     if (i1 === i) {
        //       return {
        //         ...item,
        //         [name]: value
        //       };
        //     } else {
        //       return item;
        //     }
        //   })
        // );
    };

    const removeFilterOption = (i, type = null) => {
        let myArray = [...filterOptionList]
        myArray.splice(i, 1);
        // delete myArray[i]
        setFilterOptionList(myArray)
        if (type == 'list') {
            dispatch(setSaveFilter(myArray))
            let encodedFilter = encryptData(JSON.stringify(myArray))
            navigate('/equipments?filter=' + encodedFilter)
        }

    }

    const closeModal = () => {
        setShowFilterModal(false)
    }

    const openModal = () => {
        // let t = [...filters]
        // setFilterOptionList(t)
        setFilterOptions({
            ...filterOptions,
            operation: "",
            value: "",
            start: "",
            end: "",
            condition: "and"
        })
        setShowFilterModal(true)

    }

    const saveFilter = () => {
        let encodedFilter = encryptData(JSON.stringify(filterOptionList))
        dispatch(setSaveFilter(filterOptionList))
        setShowFilterModal(false)
        navigate('/equipments?filter=' + encodedFilter)

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const Item = styled(Paper)(({ theme }) => ({
        textAlign: 'center',
        boxShadow: 'none',
        backgroundColor: "transparent",
    }));

    const ActionMenuLayout = React.memo(({ rowId, equipmentId, metaData }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleMenuClick = (e) => {
            setAnchorEl(e.currentTarget);
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleMenuClick(e)}>
                    <MoreHorizIcon sx={{ color: '#7F7F7F' }} />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "long-button"
                    }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            border: '1px solid #eff0f6',
                            borderRadius: "10px",
                            boxShadow: '0 0 10px hsla(0,0%,55%,.1)',
                        }
                    }}
                >
                    <MenuItem key="1" sx={{ color: '#888' }} onClick={() => handleOpenRemoveModal(rowId, equipmentId)}>
                        Remove
                    </MenuItem>
                    <MenuItem key="2" sx={{ color: '#888' }}  onClick={() => handleOpenStreetLightModal(rowId, equipmentId, metaData)} >
                        Edit
                    </MenuItem>
                    {/* <MenuItem key="2" sx={{ color: '#888' }} component={Link} to={`${Routes.editEquipment}/${rowId}`}>
                        Edit
                    </MenuItem> */}
                </Menu>
            </div>
        )

    })

    const Row = React.memo((props) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        return (
            <React.Fragment>

                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={`list-table ${open && `list-table-open`}`}>

                    {
                        tableHeading.length > 0 &&
                        tableHeading.map((heading) => {
                            switch (heading.id) {
                                case 'name':
                                    data = <TableCell component="th" scope="row" align="" width={400}
                                        className={`pl-[3%] Mediumlg:pl-[2%] py-[10px]  name-field-cell border-b-0 border-t-[1px] border-[#e0e0e0] border-l-0 border-r-0 border-solid ${open && `border-b-[1px] border-l-[1px]  rounded-tl-[20px] rounded-bl-[20px]`} `}
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            background: 'white',
                                            zIndex: 800,
                                        }}>




                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            // justifyContent: 'end',
                                            position: 'sticky'
                                        }}>
                                            {/* <TableCell> */}
                                           
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                            
                                            {/* </TableCell> */}
                                            {row.active ? <FiberManualRecordIcon style={{ color: '#02F5A0' }} /> : <FiberManualRecordIcon style={{ color: '#FF4438' }} />}&nbsp;
                                            <Link to={'/equipment/view/' + row.node_id} state={data={status:false,node:row}} className='name-field eq-name' style={{ textDecoration: "none", color: '#1B53F9' }}>{row.metadata.node_name}</Link>
                                        </div>
                                    </TableCell>;
                                    break;
                                case 'consumptionValue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.consumptionValue !== 'N/A' ? row.consumptionValue : '-'}</TableCell>
                                        ;
                                    break;
                                case 'voltagevalue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.value ? row.value + ' V' : '-'}</TableCell>
                                        ;
                                    break;
                                case 'maximumTHD':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`} >{Number(row.maximumTHD).toFixed(2)}</TableCell>
                                        ;
                                    break;
                                case 'frequency':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`} >{Number(row.frequency).toFixed(2)}</TableCell>
                                        ;
                                    break;
                                case 'currentvalue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.currentvalue  ? row.currentvalue + ' A' : '-'}</TableCell>
                                        ;
                                    break;
                                case 'totalcurrentValue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.totalcurrentValue !== 'N/A' ? row.totalcurrentValue + ' A' : '-'}</TableCell>
                                        ;
                                    break;
                                case 'totalPowerFactorValue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.totalPowerFactorValue !== 'N/A' ? row.totalPowerFactorValue : '-'}</TableCell>
                                        ;
                                    break;

                                case 'powerFactorValue':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.powerFactorValue !== 'N/A' ? row.powerFactorValue : '-'}</TableCell>
                                        ;
                                    break;
                                case 'lastUpdatedTime':
                                    data = <TableCell align="" className={`py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] `}`}>{row.lastUpdatedTime !== 'N/A' ? row.lastUpdatedTime : '-'}</TableCell>
                                        ;
                                    break;

                            }
                            return data;

                        })

                    }

                    {/* action-cell-icon-active */}
                    <TableCell className={`relative py-[10px] border-b-0 border-t-[1px] border-solid border-[#e0e0e0] border-l-0 border-r-0 ${open && `border-b-[1px] border-r-[1px]  rounded-tr-[20px] rounded-br-[20px]`}`} align="right">
                        <ActionMenuLayout rowId={row.node_id} equipmentId={row.metadata.equipment_id} metaData={row.metadata} />
                    </TableCell>

                </TableRow>

                <TableRow className={`${!open && `h-0`}`}>
                    <TableCell style={{ padding: 0 }} colSpan={12} className="rounded-l-[10px] !border-b-0">
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box className='box-1 bg-[#ededed] bg-opacity-[0.2] rounded-b-[15px] py-[20px] px-[25px] Mediumlg:px-[15px] '>
                                <Grid container spacing={2} className='grid-1  flex-nowrap'>
                                    <Grid item className='grid-2 discription pl-[3%] Mediumlg:w-[18%]'>
                                        <Item className='item-1 kkk'>
                                            <Typography
                                                fontSize={12}
                                                fontWeight={500}
                                                color="rgba(0, 0, 0, 0.7)"
                                                marginBottom={"10px !important"}
                                                className='Typography'
                                            >
                                                Description
                                            </Typography>
                                            <Typography
                                                fontSize={10}
                                                fontWeight={300}
                                                color="rgba(0, 0, 0, 0.7)"
                                                className='Typography'
                                            >
                                                {row.metadata.node_desc}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    
                                    
                                    <Grid item xs={1} className='grid-2 discription Mediumlg:mx-[5px]'>
                                        <Item className='item-1 '>
                                            <Typography
                                                fontSize={12}
                                                fontWeight={400}
                                                color="rgba(0, 0, 0, 0.7)"
                                                marginBottom={"10px"}
                                                className='Typography'
                                            >
                                                Remarks
                                            </Typography>
                                            <Typography
                                                fontSize={10}
                                                fontWeight={300}
                                                className='Typography'
                                                // style={{ border: "1px solid #000", borderRadius: "15px", padding: "10px", backgroundColor: "white" }}
                                                color="rgba(0, 0, 0, 0.7)"
                                            >
                                                {row.metadata.remarks}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    });

    const handleChangeCondition = (e, key) => {
        let lst = [...filterOptionList]
        lst[key].condition = e.target.value
        setFilterOptionList(lst)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {

        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if(orderBy==="metadata"){
            if (b[orderBy]["node_name"] < a[orderBy]["node_name"]) {
                return -1;
            }
            if (b[orderBy]["node_name"] > a[orderBy]["node_name"]) {
                return 1;
            }
            return 0;
        }else{if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const serachEquipment = (e) => {
        setSearchEq(e.target.value)
        const query = e.target.value;
        if (e.target.value) {
            const searchList = mergedStreetLightData.filter((item) => {
                return item.metadata.node_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;;
            });
            setRowsValue(searchList)
        } else {
            setRowsValue(mergedStreetLightData)
        }

    }

    useEffect(() => {
        let t = document.getElementsByClassName('eq-table')[0]?.clientWidth
        // console.log(t)
        if (t > window.innerHeight) {
            // alert('jdfh')
        }
    }, [document.getElementsByClassName('eq-table')[0]?.clientWidth]);

    useEffect(() => {
        let tabHead = [...initialTableHeading]

        if (filters && filters.length > 0) {
            filters.map((f) => {
                const res = tabHead.find(x => f.components.value == x.id);
                if (!res) {
                    tabHead.push({
                        id: f.components.value,
                        numeric: false,
                        disablePadding: true,
                        label: f.components.title,
                        show: true
                    })
                }

            })
            setTableHeading(tabHead)
        } else {
            setTableHeading(initialTableHeading)

        }

    }, [filters, filter]);

    const onClickMapView = () => {
        if (url?.search) {
            navigate('/map/view' + url.search)
        } else {
            navigate('/map/view')
        }
    }

    const tableRecords = React.useMemo(() => {
        return stableSort(rowsvalue, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [rowsvalue, order, orderBy, page, rowsPerPage])
console.log(tableRecords,"tableRecords")
 let activeRecord =tableRecords.filter((data)=>{return data.active===true})
    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                    bgcolor: "#F5F5F5",
                }}
                className='dashboard-main'
            >
                <Toolbar />
                <Box
                    bgcolor={"#fff"}
                    boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
                    borderRadius={5}
                    className="h-auto mt-[3%] mb-[2%] mx-[32px] p-[20px] Mediumlg:mx-[20px] Mediumlg:h-auto"
                >

                    <Box component="div" >
                        <Box className='flex items-center justify-between mb-2 p-2'>
                            <div className='search-filter'>
                                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <IconButton
                                        component={Link} to={`${Routes.equipments}`}
                                    >
                                        <ArrowBackIosOutlinedIcon />
                                    </IconButton>
                                    <span>Back</span>
                                </Box>
                                <Typography variant="h6" fontSize={24} fontWeight={600}>
                                    {nodeNameTitle}
                                </Typography>
                            </div>
                            <div className='add-export'>

                                {/* <Link to={Routes.addEquipment} state={data={nodeNameTitle,id,Name}} className='' style={{
                                    textDecoration: "none",
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                    letterSpacing: '-0.154px',
                                    color: '#1B53F9',
                                }}> */}
                                    <div onClick={() => handleOpenStreetLightModal()}className='' style={{
                                    textDecoration: "none",
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                    letterSpacing: '-0.154px',
                                    color: '#1B53F9',
                                    cursor: 'pointer'
                                }}> + Add Equipment</div>
                                {/* </Link> */}
                            </div>
                        </Box>
                        <Box className='flex items-center justify-between mb-5 p-2'>
                            <div className='search-filter'>

                                <div className='search-input'>
                                    <img src={SearchIcon} />
                                    <input type='text' placeholder='Search for Components, Operators, Parameters, etc.' value={searchEq} onChange={serachEquipment} />
                                </div>
                                <Button
                                    type="submit"
                                    variant=""
                                    onClick={() => onClickMapView()}
                                    style={{
                                        width:"155px",
                                        height: '38px',
                                        border: '1px solid #444444',
                                        borderRadius: '14.5px',
                                        textTransform: "capitalize"
                                    }}
                                    className="w-[246px] Mediumlg:w-[145px] Mediumlg:text-[16px]"
                                >

                                    <img src={MapMarkerIcon} style={{ marginRight: "10px" }}></img>
                                    Map View
                                </Button>

                                <Box className="items-center flex gap-[8px]" sx={{width:"200px"}}>
                        <Typography
                          component="span"
                          fontSize={15}
                          fontWeight={700}
                          color="#000000"
                        >
                          {activeRecord.length}
                          <Typography
                            component="span"
                            fontSize={12}
                            fontWeight={600}
                            color="rgba(0, 0, 0, 0.5)"
                          >
                            /{tableRecords?.length}
                          </Typography>
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          color="#4D4D4D"
                          className="leading-[1.3]"
                          component="span"
                        >
                          Active Equipments
                        </Typography>
                      </Box>
                            </div>
                        </Box>
                    </Box>
                    <Box>
                        <TableContainer sx={{ boxShadow: "unset", maxHeight: 'calc(100vh - 290px)' }} component={Paper} className='h-full'>
                            <Table aria-label="collapsible table" className='eq-table custom-table border-separate'>
                                <TableHead className='equipment-table-header custom-table-header'>
                                    <TableRow className='custom-table-row'>

                                        {tableHeading.map((headCell) => {
                                            let SORT_ID = headCell?.sortId ? headCell?.sortId : headCell?.id;
                                            return (
                                                <TableCell
                                                    key={headCell.id}
                                                    className={`${headCell.id + '-cell'} first:pl-[7%] Mediumlg:first:pl-[3%]`}
                                                    // align={'right'}
                                                    // padding={headCell.disablePadding ? "none" : "normal"}
                                                    sortDirection={orderBy === SORT_ID ? order : false}
                                                    style={headCell.show ? { background: "#f5f5f5", borderBottom: "unset" } : { display: "none" }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === SORT_ID}
                                                        direction={orderBy === SORT_ID ? order : "asc"}
                                                        onClick={createSortHandler(headCell?.sortId ? headCell?.sortId : headCell?.id)}
                                                    >
                                                        <span style={{
                                                            fontSize: "14px",
                                                            fontWeight: "700",
                                                            color: "#7F7F7F",
                                                        }}>
                                                            {headCell.label}</span>
                                                        {orderBy === SORT_ID ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell style={{ background: "#f5f5f5", borderBottom: "unset", cursor: "pointer" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRecords?.length > 0 ? (
                                        tableRecords?.map((row, i) => (
                                            <Row key={i} row={row} />
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} textAlign={'center'} sx={{ textAlign: 'center' }}>No Record Found</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: 100000 }]}
                            colSpan={3}
                            count={rowsvalue.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            style={{ display: "block", borderBottom: "unset" }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            className='equipment-pagination'
                        />
                    </Box>
                </Box>
            </Box>
            {removeEquipmentModal.removeModalOpen && (
                <RemoveEquipmentModal
                    open={removeEquipmentModal.removeModalOpen}
                    id={removeEquipmentModal.removeModalId}
                    equipmentId={removeEquipmentModal.equipmentId}
                    handleClose={handleCloseRemoveModal}
                />
            )}

            {streetLightEditModal.openModal && (
                <StreetLightDialog
                    open={streetLightEditModal.openModal}
                    closeModal={handleCloseStreetLightModal}
                    saveData={saveData}
                    nodeId={streetLightEditModal.nodeId}
                    formData={streetLightEditModal.state}
                    nodeTitle={nodeNameTitle}
                />
            )}


        </>
    );
}

export default ViewEquipment;