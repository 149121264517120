import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Toolbar, Typography, Tabs, Tab, Menu, MenuItem, } from '@mui/material';
import { useHook } from './useHook';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EditIcon from "assets/edit.svg"
import jwt from "jwt-decode";
import { useSelector } from 'react-redux';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from '../user/add/useDebounceEffect';
import { canvasPreview } from '../user/add/canvasPreview';
import ImageCropModal from '../user/add/imageCropModal';
import AvatartImg2 from "assets/Group289724.png"
import { TabContainer } from "react-bootstrap";
import PropTypes from 'prop-types';
import iIcon from "assets/iicon.png"
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { AutocompleteLayout, FileUploadLayout } from 'components';
import { TYPE_OPTION_LIST } from 'constants/variables';

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const DeviceConfig = () => {

    const { setavatarBase64, value, setValue, goToNextStep, state, setState, handleChangeForm } = useHook();

    const { id } = useParams();
    const [tempAvatar, setTempAvatar] = useState(state.photo ? state.photo : AvatartImg2)
    const { configProfiles } = useSelector(state => state.equipment);
    const navigate = useNavigate()
    const location = useLocation()

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)
    const [open, setOpen] = useState(false);
    const [img64, setImg64] = useState('')

    const handleChange = (event, newValue) => {
        if (location.pathname.startsWith('/users/view')) {
            setValue(newValue);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }


    const onChangeAvarat = (e) => {
        const file = e.target.files[0]
        if (file) {
            var reader = new FileReader();
            reader.onloadend = function () {
                setImgSrc(reader.result?.toString() || '')
            }
            reader.readAsDataURL(file);
            handleClickOpen()
        } else {
            handleClose()
        }

    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                let url = await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
                setImg64(url)
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setavatarBase64('')
    };

    const cropImage = () => {
        setavatarBase64(img64)
        setTempAvatar(img64)
        setOpen(false);
    }

    useEffect(() => {
        let updateProfileConfigModel = (configProfiles && configProfiles.length > 0) ? configProfiles.find(el => el.profile_id === id) : null;
        if(updateProfileConfigModel){
            setState(oldState => ({
                ...oldState,
                name: updateProfileConfigModel?.name || '',
                description: updateProfileConfigModel?.description || '',
                serial_number: updateProfileConfigModel?.serial_number || '',
                type: TYPE_OPTION_LIST.filter(el => updateProfileConfigModel.type.includes(el.value)) || [],
                file_data: updateProfileConfigModel?.data || '',
                edit_file_data: updateProfileConfigModel?.data || '',
                photo: updateProfileConfigModel?.metadata?.device_photo || '',
                version: updateProfileConfigModel?.version || ''
            }))
            setavatarBase64(updateProfileConfigModel?.metadata?.device_photo || '')
        } else {

        }
    },[id])

    useEffect(() => {
        setTempAvatar(state.photo ? state.photo : AvatartImg2)
    }, [state.photo]);

    const handleRemoveFile = () => {
        setavatarBase64('')
        setTempAvatar(AvatartImg2)
    }

    const ProfileActionMenu = ({
        handleRemoveFile,
        handleChangeFile,
    }) => {

        const inputOpenFileRef = React.createRef()
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleMenuClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        const handleOpenFile = () => {
            inputOpenFileRef.current.click()
        }

        return (
            <div>
                <input type='file' ref={inputOpenFileRef} name="avatar" accept="image/png, image/jpeg" onChange={handleChangeFile} style={{ display: "none" }} />
                <div className='edit-btn' onClick={handleMenuClick}>
                    {'Edit'}
                    <img src={EditIcon}></img>
                </div>
                <Menu
                    id="fade-menu"
                    className='profile-menu'
                    sx={{
                        width: 91
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleRemoveFile} >Remove</MenuItem>
                    <MenuItem onClick={handleOpenFile} >Change</MenuItem>
                </Menu>
            </div>
        )
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
                bgcolor: "#FFFFFF",
            }}
        >

            <Toolbar />
            <div className='py-[60px] px-[32px] bg-white w-full h-full'>
                <Box
                    sx={{ bgcolor: 'background.paper', display: 'flex' }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className='tab-main'
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab icon={<PersonOutlineOutlinedIcon />} className={value == 0 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label={id ? "Update Profile" : "Create Profile"} {...a11yProps(0)}
                        />
                        <Tab icon={<FilterAltOutlinedIcon />} className={value == 1 ? 'prof-tab-active tabcus' : 'tabcus'} iconPosition="start" label="Parameters" {...a11yProps(1)}
                        />
                    </Tabs>
                    {value === 0 &&
                        <TabContainer value={value} index={0} >
                            <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                                <div className='' style={{ width: "60%" }}>
                                    <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]'>
                                            {id ? "Update Profile" : "Create Profile"}
                                        </Typography>
                                        <span>Step {value + 1}/2</span>
                                    </Box>
                                    <div className="eqipment-form">


                                        <div className="input-control-main">
                                            <label htmlFor="name">Name</label>
                                            <div className="control">
                                                <input type='text'
                                                    id="name"
                                                    name="name"
                                                    value={state.name}
                                                    onChange={(e) => handleChangeForm(e)}
                                                    placeholder="Name"
                                                    disabled={id ? true : false}
                                                    className={state?.error && state?.error?.name ? "input-error custom-input-control" : "custom-input-control"}
                                                />
                                                {
                                                    state?.error && state?.error?.name &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.name}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="input-control-main">
                                            <label htmlFor="serial_number">Serial Number</label>
                                            <div className="control">
                                                <input type='text'
                                                    id="serial_number"
                                                    name="serial_number"
                                                    value={state.serial_number}
                                                    onChange={handleChangeForm}
                                                    placeholder="Serial Number"
                                                    disabled={id ? true : false}
                                                    className={state?.error && state?.error?.serial_number ? "input-error custom-input-control" : "custom-input-control"}
                                                />
                                                {
                                                    state?.error && state?.error?.serial_number &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.serial_number}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="input-control-main">
                                            <label htmlFor="description">Description</label>
                                            <div className="control">
                                                <textarea id="description" name="description" onChange={handleChangeForm} value={state.description} placeholder="Description"
                                                    className={state?.error && state?.error?.description ? "input-error custom-input-control" : "custom-input-control"}
                                                />
                                                {
                                                    state?.error && state?.error?.description &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.description}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="input-control-main">
                                            <label htmlFor="version">Version</label>
                                            <div className="control">
                                                <input type='number'
                                                    id="version"
                                                    name="version"
                                                    value={state.version}
                                                    onChange={handleChangeForm}
                                                    placeholder="Version"
                                                    className={state?.error && state?.error?.version ? "input-error custom-input-control" : "custom-input-control"}
                                                />
                                                {
                                                    state?.error && state?.error?.version &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.version}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="input-control-main">
                                            <label htmlFor="type">Type</label>
                                            <div className="control">
                                                <AutocompleteLayout
                                                    options={TYPE_OPTION_LIST}
                                                    value={state.type}
                                                    label="Select"
                                                    name={'type'}
                                                    handleChange={handleChangeForm}
                                                />
                                                {
                                                    state?.error && state?.error?.type &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.type}</span>
                                                }
                                            </div>
                                        </div>


                                        {
                                            <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>
                                                <Button
                                                    type='reset'
                                                    onClick={() => navigate('/devices')}
                                                    style={{ textTransform: "capitalize" }}
                                                    className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                                                >
                                                    {'Cancel'.toLocaleLowerCase()}
                                                </Button>
                                                {
                                                    !location.pathname.startsWith('/users/view') &&

                                                    <Button
                                                        type='button'
                                                        onClick={() => goToNextStep(0)}
                                                        style={{ textTransform: "capitalize" }}
                                                        className='w-[200px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                                                    >
                                                        {'Next'.toLocaleLowerCase()}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>



                                <div className='img-div'>
                                    <img src={tempAvatar}></img>
                                    {
                                        !location.pathname.startsWith('/users/view') &&

                                        <ProfileActionMenu handleChangeFile={onChangeAvarat} handleRemoveFile={handleRemoveFile} />
                                        // <div className='edit-btn'>
                                        //     <input type='file' id="avatar" name="avatar" accept="image/png, image/jpeg" onChange={onChangeAvarat} />
                                        //     {id ? 'Edit' : 'Add'}
                                        //     <img src={EditIcon}></img>
                                        // </div>
                                    }
                                </div>

                                <ImageCropModal
                                    previewCanvasRef={previewCanvasRef}
                                    crop={crop}
                                    setCrop={setCrop}
                                    setCompletedCrop={setCompletedCrop}
                                    aspect={aspect}
                                    imgRef={imgRef}
                                    imgSrc={imgSrc}
                                    scale={scale}
                                    rotate={rotate}
                                    onImageLoad={onImageLoad}
                                    completedCrop={completedCrop}
                                    open={open}
                                    setOpen={setOpen}
                                    handleClickOpen={handleClickOpen}
                                    handleClose={handleClose}
                                    cropImage={cropImage}
                                />
                            </div>
                        </TabContainer>

                    }

                    {value === 1 &&
                        <TabContainer value={value} index={1} >
                            <div className='profile-add-main' style={{ marginLeft: '5%', width: "86%" }} >
                                <div className='' style={{ width: "60%" }}>
                                    <Box component="div" className='flex items-center justify-between mb-5' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Typography className='w-[350px] font-semibold pb-5 text-[24px] m-0 leading-6 text-[#2D3748]'>
                                            Add Parameters
                                        </Typography>
                                        <span>Step {value + 1}/2</span>
                                    </Box>
                                    <div className="eqipment-form">


                                        <div className="input-control-main">
                                            <label htmlFor="role">Technical Details</label>
                                            <div className="control">
                                                <FileUploadLayout name="file_data" file={state.file_data} handleChange={handleChangeForm} />
                                                {
                                                    state?.error && state?.error?.file_data &&
                                                    <span className="error"><span className="error-icon"><img src={iIcon} /></span>{state?.error?.file_data}</span>
                                                }
                                            </div>
                                        </div>

                                        {
                                            !location.pathname.startsWith('/users/view') &&
                                            <div className='w-full flex justify-end text-right gap-3 ' style={{ justifyContent: "center", marginTop: "20%" }}>
                                                <Button
                                                    type='reset'
                                                    onClick={() => setValue(0)}
                                                    style={{ textTransform: "capitalize" }}
                                                    className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center'
                                                >
                                                    {'Back'.toLocaleLowerCase()}
                                                </Button>
                                                <Button
                                                    type='button'
                                                    onClick={() => goToNextStep(1)}
                                                    style={{ textTransform: "capitalize" }}
                                                    className='w-[227px] p-[15px] h-[48px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center'
                                                >
                                                    {'Submit'.toLocaleLowerCase()}
                                                </Button>

                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                        </TabContainer>
                    }

                </Box>
            </div>
        </Box>
    )
}

export default DeviceConfig;