import React from 'react'
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Avatar, Box, Divider, Icon, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { LogoTextIcon, LogoIcon } from 'assets';
import { Routes } from "routes/appRoutes";

const drawerWidth = '240px';
const DrawerLayout = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
    }),
);

const menuList = [
    {
        path: Routes.dashboard,
        icon: <DashboardOutlinedIcon sx={{ color: "inherit" }} />,
        module: 'DASHBOARD',
        label: 'Dashboard'
    },
    {
        path: Routes.equipments,
        icon: <ConstructionIcon />,
        module: 'EQUIPMENTS',
        label: 'Equipments'
    },
    {
        path: Routes.people,
        icon: <GroupsOutlinedIcon />,
        module: 'USERS',
        label: 'People'
    }
]
export const Drawer = React.memo(({
    open,
    toggleDrawer
}) => {

    <DrawerLayout variant="permanent" open={open}>
        <Box
            sx={{
                ":hover": {
                    background:
                        "linear-gradient(279.04deg, rgba(3, 237, 165, 0.05) -8.15%, rgba(27, 86, 244, 0.05) 107.52%);",
                },
                borderRadius: 2.5,
            }}
        >
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    px: [1],
                    py: 3,
                }}
            >
                <Icon
                    sx={{
                        height: "29px",
                        width: "174px",
                    }}
                >
                    <img src={open ? LogoTextIcon : LogoIcon} alt="Logo With Text" />
                </Icon>
            </Toolbar>
        </Box>
        <Divider sx={{ width: "75%", mx: "auto" }} />
        <Box
            display={"flex"}
            flexDirection="column"
            height={"100%"}
            justifyContent={"space-between"}
        >
            <List component="nav" sx={{ pb: 0, px: open && 4 }}>
                <ListItem
                    sx={{
                        flexDirection: "row-reverse",
                        pr: open ? 0 : 2,
                        justifyContent: open ? 'end' : 'center'
                    }}
                >
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{
                            bgcolor: "rgba(230, 230, 230, 0.6)",
                            ":hover": {
                                color: "rgba(27, 83, 249, 1)",
                                bgcolor: "rgba(3, 237, 165, 0.05)",
                            },
                        }}
                    >
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </ListItem>
                <Divider sx={{ width: !open ? "75%" : "100%", mx: "auto" }} />
            </List>
        </Box>
    </DrawerLayout>
})