import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { dashboardReducer } from "./dashboardReducer";
import { equipmentReducer } from "./equipmentReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  equipment: equipmentReducer,
});