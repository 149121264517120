import React, { useEffect, useState } from "react";
import {
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import { checkEquipmetIsActive, generateNodeName } from "../../constants/Helper";
import { Box, Button, Toolbar, Typography } from '@mui/material';
import arrowUp from "../../assets/arrowUp.png"
import arrowDown from "../../assets/arrowDown.png"
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Line148 from "../../assets/Line148.png"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useEquipmentHook } from "./useEquipmentsHook";
import { Routes } from "routes/appRoutes";
import RemoveEquipmentModal from "./remove";
import { Tree } from "react-arborist";
import { FillFlexParentLayout } from "components";


const FolderOpenIcon = () => {
    return (
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="icon FolderOpenIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 0 0-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z"></path></svg>
    )
}
const EquipmentLayout = React.memo(({
    children
}) => {

    const { state, setState, setDefaultState } = useEquipmentHook()
    const navigate = useNavigate()
    const { state: routeState } = useLocation();
    const { id } = useParams();
    const onTreeStateChange = (state, event) => { };
    const [activeAction, setActiveAction] = useState()
    const { filters, nodeMetaData } = useSelector(state => state.equipment);
    const { instEnergy } = useSelector(state => state.dashboard);
    const [rowsvalue, setRowsValue] = useState([])
    const [treeData, setTreeData] = useState({})
    const [tree, setTree] = useState(null)
    console.log('treeData', treeData);

    const [allNodeList, setAllNodeList] = React.useState([]);

    let storageNodeList = localStorage.getItem('nodeList')

    const [removeEquipmentModal, setRemoveEquipmentModal] = React.useState({
        removeModalOpen: false,
        removeModalId: null,
        equipmentId: null,
    });

    useEffect(() => {
        if (storageNodeList) {
            setAllNodeList(JSON.parse(storageNodeList))
        }
    }, [storageNodeList])

    useEffect(() => {
        if (id) {
            let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
            const equipmentDataDict = allNodeMetaData.find((e) => e[0] === id)?.length > 0 ?
                allNodeMetaData.find((e) => e[0] === id)[1]?.metadata : null;
            setDefaultState();
            setState({
                node_name: equipmentDataDict?.node_name || '',
                node_desc: equipmentDataDict?.node_desc || '',
                equipment_id: equipmentDataDict?.equipment_id || '',
                node_path: equipmentDataDict?.node_path || state.node_path,
                temp_node_path: equipmentDataDict?.node_path || state.temp_node_path,
                isNode: equipmentDataDict?.isNode || state.isNode,
                equipment_type: equipmentDataDict?.equipment_type || '',
                address: equipmentDataDict?.address || '',
                total_rated_power: equipmentDataDict?.total_rated_power || '',
                phases_connected: equipmentDataDict?.phases_connected || [],
                description: equipmentDataDict?.description || '',
                lat: equipmentDataDict?.lat || '',
                lng: equipmentDataDict?.lng || '',
                // geo_co_ordinates: `${equipmentDataDict?.lat},${equipmentDataDict?.lng}`
            })
        } else if (routeState?.node_path) {
            setDefaultState();
            setState(oldState => ({
                ...oldState,
                node_path: routeState?.node_path || state.node_path,
                temp_node_path: routeState?.node_path || state.temp_node_path
            }))
        } else {
            setDefaultState();
        }
    }, [id, routeState, nodeMetaData])


    var url = new URL(window.location.href);
    var filter = url.searchParams.get("filter");

    const handleOpenRemoveModal = (id, equipmentId) => {
        setRemoveEquipmentModal(({
            removeModalOpen: true,
            removeModalId: id,
            equipmentId: equipmentId,
        }))
    }

    const handleCloseRemoveModal = () => {
        setRemoveEquipmentModal(({
            removeModalOpen: false,
            removeModalId: null,
            equipmentId: null
        }))
    }

    const ActionMenuGroupFileLayout = React.memo(({ isEditableOnly, handleEquipmentEdit, handleEquipmentDelete, handleEquipmentMove }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleMenuClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="group-button"
                    aria-controls={open ? "group-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '5%',
                    }}
                    onClick={(e) => handleMenuClick(e)}>
                    <MoreHorizIcon sx={{ color: '#7F7F7F' }} />
                </IconButton>
                <Menu
                    id="group-menu"
                    MenuListProps={{
                        "aria-labelledby": "group-button"
                    }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            border: '1px solid #eff0f6',
                            borderRadius: "10px",
                            boxShadow: '0 0 10px hsla(0,0%,55%,.1)',
                        }
                    }}
                >
                    <MenuItem key="1" sx={{ color: '#888' }} onClick={handleEquipmentEdit}>
                        Edit Equipment
                    </MenuItem >
                    {!isEditableOnly && (
                        <div>
                            <MenuItem key="2" sx={{ color: '#888' }} onClick={handleEquipmentDelete}>
                                Delete Equipment
                            </MenuItem>
                            <MenuItem key="3" sx={{ color: '#888' }} onClick={handleEquipmentMove}>
                                Move Equipment
                            </MenuItem>
                        </div>
                    )}

                </Menu>
            </div>
        )
    })

    const arrMap = (data) => {
        if (data?.children?.length > 0) {

            data?.children?.map((d) => {
                d['name'] = d?.name === d?.nodeId ? d?.equipment_id : d?.name;
                arrMap(d)
            })

        } else {
            delete data?.children
        }

        return data

    }

    const genrateTreeStructure = (array) => {

        let nested_array = [...array].filter(el => el.node_path !== 'N/A' && el.node_path !== '' && el.node_path !== undefined);
        nested_array.sort((a, b) => {
            if (a.node_path < b.node_path)
                return -1;
            if (a.node_path > b.node_path)
                return 1;
            return 0;
        })
        const tree = {
            name: 'root',
            url: '',
            children: [],
        }

        for (const e of nested_array) {
            let node = tree
            if (e.node_path && e.node_path !== 'N/A') {
                const nodenames = e.node_path?.split('/')
                while (nodenames?.length > 0) {
                    const nodename = nodenames.shift()
                    if (!node?.children?.map(e => e.nodeName)?.includes(nodename)) {
                        // if (node && Object.keys(node).length === 0) {
                        //     node.name = nodename
                        //     node.url = nodename
                        //     node.children = []

                        // } else {

                        node?.children?.push({
                            name: nodename,
                            // name: e?.node_name || nodename,
                            equipment_id: e?.equipment_id,
                            url: [node.url, nodename]?.join('/'),
                            status: e.active,
                            lat: e?.lat,
                            lng: e?.lng,
                            nodeId: e.name,
                            nodeName: nodename,
                            isNode: e?.isNode,
                            children: [],
                            nickname: Math.random().toString(36).substring(2, 7),
                        })
                        // }
                    }
                    node = node?.children?.filter(e => e?.nodeName === nodename)[0]
                }
            }
        }
        return arrMap(JSON.parse(JSON.stringify(tree, null, 2)))
    }

    const TitleActions = React.memo((props) => {
        const { data, isEditableOnly = false } = props;

        const handleEquipmentEdit = (e, id) => {
            e.preventDefault();
            setActiveAction()
            navigate(`${Routes.editEquipment}/${id}`)
        }

        const handleEquipmentDelete = (e, id, equipment_id) => {
            e.preventDefault();
            handleOpenRemoveModal(id, equipment_id);
        }

        const handleEquipmentMove = (e, id) => {
            e.preventDefault();
            setActiveAction()
            navigate(`${Routes.moveEquipment}/${id}`)
        }

        return (
            <>
                <FiberManualRecordIcon style={{ color: data.status ? '#02F5A0' : '#FF4438' }} />
                <span className="row-title" style={{ paddingLeft: 13 }}>{data.name}</span>
                <ActionMenuGroupFileLayout
                    isEditableOnly={isEditableOnly}
                    handleEquipmentEdit={(e) => handleEquipmentEdit(e, data.nodeId)}
                    handleEquipmentDelete={(e) => handleEquipmentDelete(e, data.nodeId, data.equipment_id)}
                    handleEquipmentMove={(e) => handleEquipmentMove(e, data.nodeId)}
                />
            </>
        )
    });

    const TreeFolderIcon = React.memo(({ isClosed }) => {
        return (
            isClosed ? <FolderOutlinedIcon sx={{ width: 20, height: 20 }} /> : <FolderOpenIcon />
        )
    })

    const TreeArrowIcon = React.memo(({ isClosed }) => {
        return (
            isClosed ? <ArrowRightIcon sx={{ width: 30, height: 30 }} /> : <ArrowDropDownIcon sx={{ width: 30, height: 30 }} />
        )
    })

    const TreeNode = React.memo((props) => {

        const { innerRef, styles, node, tree } = props;
        const isFolder = (node.data?.children && node.data?.children.length > 0) ? true : false;
        const indentExtra = isFolder && node.data?.nodeId === node.data?.nodeName ? Number(node.level) - 1 : node.level;
        const indent = tree.indent * indentExtra;
        const nodeStyle = React.useMemo(() => ({ paddingLeft: indent }), [indent]);
        const isClosed = node.isClosed;


        return (
            <div ref={innerRef} key={node?.id} className="TreeNode" style={nodeStyle}
            >
                {isFolder ? (
                    <>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                node.toggle()
                            }}
                            sx={{ width: 36, height: 36 }}
                        >
                            {node.data?.nodeId === node.data?.nodeName ? <TreeArrowIcon isClosed={isClosed} /> : <TreeFolderIcon isClosed={isClosed} />}
                        </IconButton>
                        {node.data?.nodeId === node.data?.nodeName ? (
                            <TitleActions
                                data={node.data}
                                isEditableOnly={true}
                            />
                        ) : <span className="row-title" style={{ paddingLeft: 13 }}>{node.data.name}</span>}

                    </>
                ) : (
                    <TitleActions
                        data={node.data}
                    />
                )}
            </div>
        );
    });

    Array.prototype.diff = function (a) {
        return this.filter(function (i) { return a.indexOf(i) < 0; });
    };

    useEffect(() => {
        let tempCsvDataArray = []
        let temp = instEnergy ? Object.entries(instEnergy) : [];
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];

        let tempIdArray = temp.map((d) => {
            return d[0]
        })

        let d_nodes = allNodeList?.diff(tempIdArray)

        if (temp.length > 0 || allNodeMetaData.length > 0) {
            temp.map((v, i) => {
                let calculatedConsumptionValue = Number(Number(v[1].w1) + Number(v[1].w2) + Number(v[1].w3)).toFixed(2)

                let csvObj = {}

                csvObj.name = v[0]
                csvObj.active = checkEquipmetIsActive(v[1].timestamp * 1000) ? true : false


                csvObj.node_path = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_path : 'N/A'



                csvObj.equipment_id = allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 ?
                    allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : 'N/A'

                const nodeName = (allNodeMetaData.find((e) => e[0] === v[0])?.length > 0 && allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === v[0])[1]?.metadata?.equipment_id : generateNodeName(v[0])
                csvObj.node_name = nodeName;

                tempCsvDataArray.push(csvObj);
            })


            if (d_nodes.length > 0) {
                d_nodes.map((n) => {

                    let tempObj = {}

                    tempObj.name = n
                    tempObj.active = false


                    tempObj.node_path = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_path : 'N/A'

                    tempObj.equipment_id = allNodeMetaData.find((e) => e[0] === n)?.length > 0 ?
                        allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.equipment_id : 'N/A'

                    const nodeName = (allNodeMetaData.find((e) => e[0] === n)?.length > 0 && allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === n)[1]?.metadata?.node_name : generateNodeName(n)
                    tempObj.node_name = nodeName;

                    tempCsvDataArray.push(tempObj);

                })
            }

            // const treeData = genrateTreeStructure(tempCsvDataArray);
            setTreeData((genrateTreeStructure(tempCsvDataArray)))
            setRowsValue(tempCsvDataArray)


        } else {
            setTreeData((genrateTreeStructure([])))
            setRowsValue([])
        }

    }, [instEnergy, allNodeList, nodeMetaData])

    const treeContainer = {
        display: 'flex',
        height: '100vh',
        flex: 1,
        minWidth: 0
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
                bgcolor: "#F5F5F5",
            }}
            className='dashboard-main-roboto add-equipment-page'
        >
            <Toolbar />
            <Box my={3} mx={4}>
                <Box
                    p="1%"
                    mb="1%"
                    height={'100%'}
                    bgcolor={"#fff"}
                    boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
                    borderRadius={3}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <div className="add-eqipment-left">
                                <div className="tree-actions-main">

                                    <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => tree?.openAll()}>
                                        <img src={arrowDown} />
                                        <span>Expand All</span>
                                    </div>

                                    <span className="saprater-icon">
                                        <img src={Line148} />
                                    </span>

                                    <div className="tree-action" style={{ cursor: "pointer" }} onClick={() => tree?.closeAll()}>
                                        <img src={arrowUp} />
                                        <span>Collapse All</span>
                                    </div>

                                </div>
                                <div className="tree-section-content">
                                    <div className="tree-section-header">
                                        {/* Name
                                        <img src={arrowDown} /> */}
                                    </div>
                                    <div
                                        style={{ ...treeContainer, paddingLeft: 10 }}
                                    >
                                        <FillFlexParentLayout>
                                            {({ width, height }) => (
                                                <Tree
                                                    ref={(t) => setTree(t)}
                                                    className="react-aborist"
                                                    data={treeData.children}
                                                    openByDefault={true}
                                                    disableEdit={true}
                                                    disableDrag={true}
                                                    disableDrop={true}
                                                    width={'100%'}
                                                    height={height}
                                                    indent={35}
                                                    rowHeight={50}
                                                    idAccessor="nickname"
                                                >
                                                    {TreeNode}
                                                </Tree>
                                            )}
                                        </FillFlexParentLayout>
                                    </div>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            {children}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {
                removeEquipmentModal.removeModalOpen && (
                    <RemoveEquipmentModal
                        open={removeEquipmentModal.removeModalOpen}
                        id={removeEquipmentModal.removeModalId}
                        equipmentId={removeEquipmentModal.equipmentId}
                        handleClose={handleCloseRemoveModal}
                    />
                )
            }
        </Box >
    )
})

export default EquipmentLayout