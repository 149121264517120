export const LogoTextIcon = require("./unitgrid-text-logo.svg").default;
export const LogoIcon = require("./unitgrid-logo.svg").default;
export const PowerIcon = require("./power.svg").default;
export const VoltageIcon = require("./v.svg").default;
export const ActivitiesIcon = require("./activities.svg").default; 
export const DeviceProfileIcon = require("./Sensor.svg").default; 
export const MenuPeopleIcon = require("./menuPeople.svg").default; 
export const MenuToolsIcon = require("./menuTools.svg").default; 
export const ConversionIcon = require("./Conversion.svg").default; 
export const CrossRoadIcon = require("./cross-road.svg").default; 
export const JsonFileIcon = require("./json_file_img.png"); 