import React, { useEffect } from 'react'
import { PowerIcon, VoltageIcon, ActivitiesIcon } from "assets";
import copyIcon from "../../assets/copy-icon.svg"

const MapCartView = ({ detailsCarts, storeStreetLight, detailsCartProperty, streetLightActiveData, streetAllLightData, viewData, equipmentType, equipmentId, copyLink, consumptionValue, maximumTHD, voltageValue, current, streetLightvoltage, copyLinkData }) => {

  useEffect(() => {
    if (detailsCartProperty) {
      detailsCarts(detailsCartProperty, storeStreetLight)
    }
  }, [detailsCartProperty])

  return (
    <>
      {
        viewData ?
          equipmentType !== "street_light" ?
            <div className="map_Nodes">
              <div className="map_Nodes-title">

                <div className="map_Nodes-title-nodeName">
                  <p>{equipmentId}</p>
                  <span onClick={() => copyLink(copyLinkData)}>
                    <img src={copyIcon} className="map_marker_img" alt="copy" />
                  </span>
                </div>
              </div>

              <div className="map_Nodes-content">
                <div className="map_Nodes-content_value">
                  <div>
                    <img src={PowerIcon} className="" />
                    <p>Power Consumption</p>
                  </div>
                  <h4>{consumptionValue && consumptionValue !== 'N/A' ? consumptionValue + 'W' : 'N/A'}</h4>
                </div>

                <div className="map_Nodes-content_value">
                  <div>
                    <img src={ActivitiesIcon} className="" />
                    <p>THD</p>
                  </div>
                  <h4 style={{ color: '#FF4438' }}>{maximumTHD ? maximumTHD + '%' : '-'}</h4>
                </div>

                <div className="map_Nodes-content_value">
                  <div>
                    <img src={VoltageIcon} className="" />
                    <p>Voltage</p>
                  </div>
                  <h4>{voltageValue && voltageValue !== 'N/A' ? voltageValue + 'V' : 'N/A'}</h4>
                </div>

                <div className="map_Nodes_StreetLight">
                  <h4>Street Lights </h4>
                  {/* <h4><span> {streetLightActiveData.length}</span>/{streetAllLightData.length}</h4> */}
                </div>
                <div className="map_Nodes-title-active">
                  <div>
                    <p>Total</p>
                    <h5  className="map_Nodes-title-active_Total">{streetAllLightData.length}</h5>
                  </div>
                  <div>
                    <p >Active</p>
                    <h5 className="map_Nodes-title-active_Active">{streetLightActiveData.length}</h5>
                  </div>
                  <div>
                    <p>InActive</p>
                    <h5 className="map_Nodes-title-active_InActive">{streetAllLightData.length-streetLightActiveData.length}</h5>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="map_Nodes">
              <div className="map_Nodes-title">

                <div className="map_Nodes-title-nodeName">
                  <p>{equipmentId}</p>
                  <span onClick={() => copyLink(copyLinkData)}>
                    <img src={copyIcon} className="map_marker_img" alt="copy" />
                  </span>
                </div>
              </div>

              <div className="map_Nodes-content">
                <div className="map_Nodes-content_value">
                  <div>
                    <img src={PowerIcon} className="" />
                    <p>Current</p>
                  </div>
                  <h4>{current && current !== 'N/A' ? current.toFixed(2) + 'A' : '-'}</h4>
                </div>

                <div className="map_Nodes-content_value">
                  <div>
                    <img src={VoltageIcon} className="" />
                    <p>Voltage</p>
                  </div>
                  <h4>{streetLightvoltage && streetLightvoltage !== 'N/A' ? streetLightvoltage.toFixed(2) + 'V' : '-'}</h4>
                </div>
              </div>
            </div>
          :
          <div className="map_Nodes map_Nodes_NoData">
            {/* <div className="map_Nodes-title"> */}
            {/* <div className="map_Nodes-title-active">
                        <h4>Active Equipment</h4>
                        <h4><span> {activeValue.length}</span>/{rowsvalue.length}</h4>
                        </div> */}
            {/* </div> */}
            <p>Hover the Device to View Data</p>
          </div>
      }
    </>
  )
}

export default MapCartView