import * as Yup from "yup";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { RequiredMessage } from "../../constants/validationMessage";
import * as UserApis from "../../stores/services/userApis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Routes } from "../../routes/appRoutes";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { setUserAddAction, setUserListAction } from "../../stores/actionTypes/userActions";
import { validateAll } from "indicative/validator";
import AlertMessage from "../../constants/AlertMessage";
import { hasEditDelPermission } from "../../constants/Helper";
import { ROLE } from "../../constants/Permissions"


export function useUserHook() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const { handleGetUsers, handleAddUser, handleUpdateUser, handleDeleteUser } = bindActionCreators(UserApis, dispatch);
  const { token } = useSelector(state => state.auth);
  const { users } = useSelector(state => state.user);
  const { FirstName, LastName, Mobile, Email, RoleId, Address } = jwt(token);
  const currentUser = users.find((e) => e.userid === id)
  const [avatarBase64, setavatarBase64] = useState('');
  const [value, setValue] = useState(0);


  const [showPassword, setShowPassword] = useState(false);
  const InitialUserData = {
    photo: id ? currentUser?.photo : '',
    first_name: id ? currentUser?.first_name : '',
    last_name: id ? currentUser?.last_name : '',
    mobile: id ? currentUser?.mobile : '',
    email: id ? currentUser?.email : '',
    address: id ? currentUser?.address : '',
    roleId: id ? currentUser?.roleId : '',
    employeeId: id ? currentUser?.employeeId : '',
    error: {}
  }
  const [state, setstate] = useState({
    photo: '',
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    address: '',
    roleId: '',
    employeeId: "",
    error: {}
  })


  useEffect(() => {
    (async () => {


      if (id) {
        if (!location.pathname.startsWith('/users/view') && !hasEditDelPermission(ROLE[RoleId], ROLE[currentUser?.roleId])) {
          navigate(Routes.people);
        }
      }

      if (id) {
        setstate({
          photo: id ? currentUser?.photo : '',
          first_name: id ? currentUser?.first_name : '',
          last_name: id ? currentUser?.last_name : '',
          mobile: id ? currentUser?.mobile : '',
          email: id ? currentUser?.email : '',
          address: id ? currentUser?.address : '',
          roleId: id ? currentUser?.roleId : '',
          employeeId: id ? currentUser?.employeeId : '',
          error: {}
        })
      }

      // if (users.length == 0) {
      //   fetchUserList()
      // }

    })();

  }, [id, currentUser, currentUser?.roleId]);

  const UserSchema = Yup.object({
    first_name: Yup.string().required(RequiredMessage),
    last_name: Yup.string().required(RequiredMessage),
    email: Yup.string().email().required(RequiredMessage),
    mobile: Yup.string().required(RequiredMessage).matches(/^[6789]\d{9}$/, 'Invalid mobile number'),
    roleId: Yup.string().required(RequiredMessage)
  });

  const Role = [
    { value: '', title: 'Select Role' },
    { value: '99', title: 'Admin' },
    { value: '98', title: 'Itegrator' },
    { value: '97', title: 'Operator' },
  ];
  let updateD = ['first_name', 'last_name', 'mobile']

  // const formik = useFormik({
  //   initialValues: InitialUserData,
  //   validationSchema: UserSchema,
  //   onSubmit: async (values) => {

  //     if (id) {
  //       let v = Object.entries(values)
  //       let updateArray = []
  //       await Promise.all(v.map((vlu, k) => {
  //         let tempObj = {}
  //         if (updateD.includes(vlu[0])) {
  //           tempObj.type = vlu[0]
  //           tempObj.value = vlu[1]
  //           updateArray.push(tempObj)
  //         }
  //       }))
  //       if (avatarBase64) {
  //         let imgObj = {
  //           type: "photo",
  //           value: avatarBase64
  //         }
  //         updateArray.push(imgObj)

  //       }
  //       let data = {
  //         userid: id,
  //         update: updateArray
  //       }
  //       // console.log(data)
  //       await handleUpdateUser(data, token)
  //         .then(response => {
  //           const { success, error } = response;

  //           if (!success) {
  //             // toast.error(error);
  //             AlertMessage({ msg: error })
  //             return;
  //           }
  //           let udata = {
  //             Created: currentUser.Created,
  //             address: values.address,
  //             email: values.email,
  //             employeeId: "eid",
  //             first_name: values.first_name,
  //             last_name: values.last_name,
  //             mobile: values.mobile,
  //             roleId: values.roleId,
  //             userid: id,
  //           }
  //           if (avatarBase64) {
  //             udata.photo = avatarBase64
  //           }
  //           let allUser = [...users]
  //           let currentUserIndex = users.findIndex((e) => e.userid === id)
  //           allUser[currentUserIndex] = udata
  //           dispatch(setUserListAction(allUser));
  //           // dispatch(setUserAddAction(values));
  //           toast.success('User Updated successfully');
  //           navigate(Routes.people);
  //         })
  //         .catch(err => {
  //           // console.log(err)
  //           const error = err?.response;
  //           toast.error(error.data?.description);

  //           throw err;
  //         })
  //       return;
  //     } else {
  //       if (avatarBase64) {
  //         values.photo = avatarBase64
  //       }

  //       await handleAddUser(values, token)
  //         .then(response => {
  //           const { success, error } = response;
  //           if (!success) {
  //             toast.error(error);
  //             return;
  //           }
  //           fetchUserList()
  //           dispatch(setUserAddAction(values));
  //           toast.success('User added successfully');
  //           navigate(Routes.people);
  //         })
  //         .catch(err => {
  //           const error = err?.response;
  //           toast.error(error.data);
  //           throw err;
  //         })
  //     }
  //   }
  // });

  const fetchUserList = async () => {
    try {
      setIsLoading(true);
      await handleGetUsers(token)
        .then(response => {
          dispatch(setUserListAction(response.users));
          return;
        })
        .catch(err => {
          const error = err?.response?.data;
          toast.error(error.description);
          throw err;
        })
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  const deleteUser = (data, name) => {
    handleDeleteUser(data, token)
      .then(response => {
        const { success, error } = response;

        if (!success) {
          // toast.error(error);
          AlertMessage({ msg: error })
          return;
        }
        let allUser = [...users]
        allUser = users.filter((e) => e.userid !== data.uuid)
        dispatch(setUserListAction(allUser));
        AlertMessage({ msg: "User " + name + " is successfully deleted." })
      })
      .catch(err => {
        const error = err?.response?.data;
        // toast.error(error.description);
        AlertMessage({ msg: error.description })
        throw err;
      })
  }

  const goToNextStep = (key) => {

    let rules = {}
    let message = {}

    switch (key) {

      case 0:
        rules = {
          first_name: 'required',
          last_name: 'required',
          email: 'required|email',
          mobile: 'required',
          address: 'required',
          employeeId: 'required',

        }

        message = {
          'first_name.required': 'First Name is Required.',
          'last_name.required': 'Last Name is Required.',
          'email.required': 'Email is Required.',
          'email.email': 'Please Enter valid Email.',
          'dob.required': 'Date of birth is Required.',
          'mobile.required': 'Phone Number is Required.',
          'address.required': 'Address is Required.',
          'employeeId.required': 'Employee Id is Required.',
        }
        break;

      case 1:

        rules = {
          roleId: 'required',
        }

        message = {
          'roleId.required': 'Please select role.',
        }

        break;



      default:

        break;

    }


    validateAll(state, rules, message).then(async () => {
      const formaerrror = {};
      setstate({
        ...state,
        error: formaerrror
      })


      if (key !== 1) {
        setValue(key + 1);

      } else {
        if (id) {
          let v = Object.entries(state)
          let updateArray = []
          await Promise.all(v.map((vlu, k) => {
            let tempObj = {}
            if (updateD.includes(vlu[0])) {
              tempObj.type = vlu[0]
              tempObj.value = vlu[1]
              updateArray.push(tempObj)
            }
          }))
          if (avatarBase64) {
            let imgObj = {
              type: "photo",
              value: avatarBase64
            }
            updateArray.push(imgObj)

          }
          let data = {
            userid: id,
            update: updateArray
          }
          // console.log(data)
          await handleUpdateUser(data, token)
            .then(response => {
              const { success, error } = response;

              if (!success) {
                // toast.error(error);
                AlertMessage({ msg: error })
                return;
              }
              let udata = {
                Created: currentUser.Created,
                address: state.address,
                email: state.email,
                employeeId: state.employeeId,
                first_name: state.first_name,
                last_name: state.last_name,
                mobile: state.mobile,
                roleId: state.roleId,
                userid: id,
              }
              if (avatarBase64) {
                udata.photo = avatarBase64
              }
              let allUser = [...users]
              let currentUserIndex = users.findIndex((e) => e.userid === id)
              allUser[currentUserIndex] = udata
              dispatch(setUserListAction(allUser));
              // dispatch(setUserAddAction(values));
              // toast.success('User Updated successfully');
              AlertMessage({ msg: "User Updated successfully" })
              navigate(Routes.people);
            })
            .catch(err => {
              // console.log(err)
              const error = err?.response;
              // toast.error(error.data?.description);
              AlertMessage({ msg: error.data?.description })
              throw err;
            })
          return;
        } else {

          let dataObj = {
            address: state.address,
            email: state.email,
            employeeId: state.employeeId,
            first_name: state.first_name,
            last_name: state.last_name,
            mobile: state.mobile,
            roleId: state.roleId,
          }

          if (avatarBase64) {
            dataObj.photo = avatarBase64
          }

          await handleAddUser(dataObj, token)
            .then(response => {
              const { success, error } = response;
              if (!success) {
                AlertMessage({ msg: error })
                return;
              }
              fetchUserList()
              // dispatch(setUserAddAction());
              // toast.success('User added successfully');
              AlertMessage({ msg: "User added successfully" })
              navigate(Routes.people);
            })
            .catch(err => {
              const error = err?.response;
              // toast.error(error.data);
              AlertMessage({ msg: error?.data?.description })

              throw err;
            })
        }



      }

    }).catch(errors => {
      console.log(errors)
      const formaerrror = {};
      if (errors && errors.length) {
        errors.forEach(element => {
          formaerrror[element.field] = element.message
        });
      } else {
        AlertMessage({ msg: errors || errors?.message || 'Something went wrong' })
      }

      setstate({
        ...state,
        error: formaerrror
      })

    });




  }

  const handleChangeForm = (e) => {
    let value = e.target.value;

    if (e.target.name === 'mobile') {
      value = e.target.value.replace(/[^\d.]|\.(?=.*\.)/g, "");
    }
    setstate({
      ...state,
      [e.target.name]: value
    })
  }

  return {
    // formik,
    Role,
    id,
    showPassword,
    isLoading,
    setShowPassword,
    fetchUserList,
    setavatarBase64,
    deleteUser,
    value,
    setValue,
    goToNextStep,
    state,
    handleChangeForm
  }
}